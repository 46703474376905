import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Divider as MuiDivider, Grid, Stack, Typography } from "@mui/material";
import { useAsyncMutation, useForm } from "src/hooks";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { IChangeOrders } from "src/ts/interfaces/changeWorkOrder";
import changeOrdersService from "src/services/changeOrdersService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { IMemorandum } from "src/ts/interfaces/memorandum";
import { IAssets } from "src/ts/interfaces/companyAssets";
import memorandumService from "src/services/memorandumService";
import assetTransferService from "src/services/assetTransferService";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { CheckCircleOutline, HighlightOffRounded } from "@mui/icons-material";

import { MY_PENDINGS_REQUEST_TILES } from "src/constants";
import PermissionGPSPopUp from "./PermissionGPSPopUp";
import { IRequestTechnicianPermission } from "src/ts/interfaces/requestTechnicianPermission";
import requestTechnicianPermissionService from "src/services/requestTechnicianPermissionService";

const Divider = styled(MuiDivider)(spacing);

interface DecisionModalProps {
  showDecisionModal: boolean;
  setShowDecisionModal: (val: boolean) => void;
  changeOrders: IChangeOrders | undefined;
  memoradum: IMemorandum | undefined;
  assets: IAssets | undefined;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
  title: string;
  gpsPermission: IRequestTechnicianPermission | undefined;
}

interface ChangeOrderData {
  changeOrders: IChangeOrders;
}

interface MemoradumData {
  memorandums: IMemorandum;
}

interface AssetsData {
  assets: IAssets;
}
interface gpsPermissionData {
  gps: IRequestTechnicianPermission;
}

const initialValues = {
  comments: "",
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ChangeOrderData = (props: ChangeOrderData) => {
  const { changeOrders } = props;
  return (
    <>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2">Description:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>{changeOrders?.notes}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2"> Created on:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>
            {" "}
            {convertUTCDateToLocalDate(
              changeOrders?.createdDate.toString()
            )?.format("MM/DD/YYYY hh:mm A")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2"> Created by:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>{changeOrders?.createdBy?.getFullName}</Typography>
        </Grid>
      </Grid>
      <Divider my={3}></Divider>
    </>
  );
};
// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-unused-vars
const MemoradumData = (props: MemoradumData) => {
  const { memorandums } = props;
  return (
    <>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2">Description:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>{memorandums?.notes}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2"> Created on:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>
            {" "}
            {convertUTCDateToLocalDate(
              memorandums?.createdDate.toString()
            )?.format("MM/DD/YYYY hh:mm A")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={1.8}>
          <Typography variant="subtitle2"> Created by:</Typography>
        </Grid>
        <Grid item xs={10.2}>
          <Typography>{memorandums?.createdBy?.getFullName}</Typography>
        </Grid>
      </Grid>
      <Divider my={3}></Divider>
    </>
  );
};

const AssetsEquipment = (props: AssetsData) => {
  const { assets } = props;
  return (
    <>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.4}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Name:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography>{assets?.equipment?.name}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.4}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Description:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography>{assets?.equipment?.description}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.4}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Serial Number:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography>{assets?.equipment?.serialNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.4}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Model:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography>{assets?.equipment?.model}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.4}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Comments:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography> {assets?.requestTransfer?.comments}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const AssetsVehicle = (props: AssetsData) => {
  const { assets } = props;
  return (
    <>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Make:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{assets?.vehicle?.make}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Model:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{assets?.vehicle?.model}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" textAlign={"right"}>
            License Plate:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{assets?.vehicle?.licensePlate}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Vehicle ID Number:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{assets?.vehicle?.vehicleIdNumber}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Comments:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography> {assets?.requestTransfer?.comments}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const GpsPermissionPending = (props: gpsPermissionData) => {
  const { gps } = props;
  return (
    <>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.5}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Project Name:
          </Typography>
        </Grid>
        <Grid item xs={9.5}>
          <Typography>{gps?.projectName}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.5}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Building Name:
          </Typography>
        </Grid>
        <Grid item xs={9.5}>
          <Typography>{gps?.buildingName}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.5}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Request By:
          </Typography>
        </Grid>
        <Grid item xs={9.5}>
          <Typography>{gps?.technicianName}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={2.5}>
          <Typography variant="subtitle2" textAlign={"right"}>
            Comments:
          </Typography>
        </Grid>
        <Grid item xs={9.5}>
          <Typography> {gps?.technicianComments}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default function DecisionPopUp({
  showDecisionModal,
  setShowDecisionModal,
  changeOrders,
  memoradum,
  assets,
  setRefresh,
  refresh,
  title,
  gpsPermission,
}: DecisionModalProps) {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.comments === "") {
      temp.comments = "This field is required";
    } else {
      temp.comments = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [finalDesicion, setFinalDesicion] = useState(false);
  const [finalDesicionModal, setFinalDesicionModal] = useState(false);
  const [showPermissionPopUp, setShowPermissionPopUp] = useState(false);

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { execute: executeAssetsDecision } = useAsyncMutation(
    assetTransferService.updateByCode,
    {
      successfulMessage: `The transfer was ${
        finalDesicion ? "accepted" : "rejected"
      }`,
      onSuccess: () => {
        setShowDecisionModal(false);
        setIsLoading(!isLoading);
      },
    }
  );

  const { execute: executeChangeOrders } = useAsyncMutation(
    changeOrdersService.ChangeStatusCurrent,
    {
      successfulMessage: `The Change/Work Order was ${
        finalDesicion ? "accepted" : "rejected"
      }`,
      onSuccess: () => {
        setShowDecisionModal(false);
        setIsLoading(!isLoading);
      },
    }
  );

  const { execute: executeMemoradum } = useAsyncMutation(
    memorandumService.ChangeStatusCurrent,
    {
      successfulMessage: `The Memoradum was ${
        finalDesicion ? "accepted" : "rejected"
      }`,
      onSuccess: () => {
        setShowDecisionModal(false);
        setIsLoading(!isLoading);
      },
    }
  );

  const { execute: executeGpsPermission } = useAsyncMutation(
    requestTechnicianPermissionService.update
  );

  useEffect(() => {
    values.comments = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDecisionModal]);

  const handleAcceptReject = async (decision: boolean) => {
    if (!validate()) return;
    if (title === MY_PENDINGS_REQUEST_TILES.TECHNICIANGPS && decision) {
      setShowPermissionPopUp(true);
    } else {
      setFinalDesicion(decision);
      setFinalDesicionModal(true);
    }
  };

  const handleSaveAnswer = async () => {
    if (changeOrders) {
      changeOrders.changeOrdersStatusId = finalDesicion ? 2 : 3;
      changeOrders.rejectedComment = values.comments;
      changeOrders.updatedDate = new Date();
      await executeChangeOrders(changeOrders);
    } else if (memoradum) {
      memoradum.memorandumStatusId = finalDesicion ? 2 : 3;
      memoradum.rejectedComment = values.comments;
      memoradum.updatedDate = new Date();
      await executeMemoradum(memoradum);
    } else if (assets) {
      await executeAssetsDecision(
        assets.requestTransfer?.code,
        finalDesicion ? "accept" : "reject",
        values.comments
      );
    } else if (gpsPermission) {
      await executeGpsPermission(
        gpsPermission?.guid,
        "reject",
        values?.comments,
        0,
        1
      );
    }
    setFinalDesicionModal(false);
    setShowDecisionModal(false);
    setRefresh(!refresh);
  };

  return (
    <>
      <Popup
        title={`${title}`}
        openPopup={showDecisionModal}
        setOpenPopup={setShowDecisionModal}
        onClose={() => {
          setShowDecisionModal(false);
        }}
        size="sm"
      >
        <>
          <Grid container>
            {changeOrders && <ChangeOrderData changeOrders={changeOrders} />}
            {memoradum && <MemoradumData memorandums={memoradum} />}
            {assets && assets.equipment && <AssetsEquipment assets={assets} />}
            {assets && assets.vehicle && <AssetsVehicle assets={assets} />}
            {gpsPermission && <GpsPermissionPending gps={gpsPermission} />}
            <Grid item xs={12} mt={3}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={values.comments}
                placeholder={"Comments"}
                onChange={handleInputChange}
                error={errors.comments}
              ></FormTextArea>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="primary"
            variant="outlined"
            onClick={() => {
              setShowDecisionModal(false);
            }}
          />
          <FormButton
            size="small"
            text="Reject"
            color="primary"
            variant="outlined"
            onClick={() => {
              handleAcceptReject(false);
            }}
            startIcon={<HighlightOffRounded />}
          />
          <FormButton
            size="small"
            text="Approve"
            color="primary"
            onClick={() => {
              handleAcceptReject(true);
            }}
            startIcon={<CheckCircleOutline />}
          />
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Warning"}
        text={`Are you certain you want to ${
          finalDesicion ? "Accept" : "Reject"
        } the ${title}?`}
        showPopup={finalDesicionModal}
        setShowPopup={setFinalDesicionModal}
        onSave={handleSaveAnswer}
        onCancel={() => {
          setFinalDesicionModal(false);
        }}
        isSubmitting={false}
      />
      {showPermissionPopUp && (
        <PermissionGPSPopUp
          setShowPermissionPopUp={setShowPermissionPopUp}
          showPermissionPopUp={showPermissionPopUp}
          gpsPermission={gpsPermission}
          comments={values.comments}
          setShowDecisionModal={setShowDecisionModal}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </>
  );
}
