import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import HeaderPage from "src/components/page/HeaderPage";
import { IChangeOrders } from "src/ts/interfaces/changeWorkOrder";
import changeOrdersService from "src/services/changeOrdersService";
import { IMemorandum } from "src/ts/interfaces/memorandum";
import memorandumService from "src/services/memorandumService";
import { IAssets } from "src/ts/interfaces/companyAssets";
import companyAssetsService from "src/services/companyAssetsService";
import DecisionPopUp from "./modals/DecisionPopUp";
import {
  AssetsConstant,
  MY_PENDINGS_REQUEST_TILES,
  ROLES,
} from "src/constants";
import { Link } from "react-router-dom";
import requestTechnicianPermissionService from "src/services/requestTechnicianPermissionService";
import { IRequestTechnicianPermission } from "src/ts/interfaces/requestTechnicianPermission";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const getColumnsChangeOrder = (
  setRowSelectedChangeOrder: (val: IChangeOrders) => void,
  setShowDecisionModal: (val: boolean) => void,
  companyId: string
): ColumnType[] => [
  {
    id: "projectName",
    label: "Project Name",
    type: "custom",
    sort: true,
    callback: (row: IChangeOrders) => {
      return (
        <Link to={`/app/Project/Edit/${row.projectId}/${companyId}`}>
          {row.projectName}
        </Link>
      );
    },
  },
  {
    id: "notes",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "firstName",
    label: "Created By",
    type: "custom",
    sort: true,
    callback: (row: IChangeOrders) => (
      <Typography>{`${row.firstName} ${row.lastName}`}</Typography>
    ),
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IChangeOrders) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                setRowSelectedChangeOrder(row);
                setShowDecisionModal(true);
              }}
              tooltip="Decision"
            />
          </Stack>
        </>
      );
    },
  },
];

const getColumnsMemoradum = (
  setRowSelectedMemoradum: (val: IMemorandum) => void,
  setShowDecisionModal: (val: boolean) => void,
  companyId: string
): ColumnType[] => [
  {
    id: "projectName",
    label: "Project Name",
    type: "custom",
    sort: true,
    callback: (row: IMemorandum) => {
      return (
        <Link to={`/app/Project/Edit/${row.projectId}/${companyId}`}>
          {row.projectName}
        </Link>
      );
    },
  },
  {
    id: "notes",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "firstName",
    label: "Created By",
    type: "custom",
    sort: true,
    callback: (row: IChangeOrders) => (
      <Typography>{`${row.firstName} ${row.lastName}`}</Typography>
    ),
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IMemorandum) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                setRowSelectedMemoradum(row);
                setShowDecisionModal(true);
              }}
              tooltip="Decision"
            />
          </Stack>
        </>
      );
    },
  },
];

const getColumnsEquipment = (
  setRowSelectedAssets: (val: IAssets) => void,
  setShowDecisionModal: (val: boolean) => void
): ColumnType[] => [
  {
    id: "name",
    label: "Equipment Name",
    type: "custom",
    sort: true,
    callback: (row: IAssets) => <Typography>{row.equipment?.name}</Typography>,
  },
  {
    id: "description",
    label: "Equipment Description",
    type: "custom",
    sort: true,
    callback: (row: IAssets) => (
      <Typography>{row.equipment?.description}</Typography>
    ),
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    type: "custom",
    sort: true,
    callback: (row: IAssets) => (
      <Typography>{row.equipment?.serialNumber}</Typography>
    ),
  },
  {
    id: "model",
    label: "Model",
    type: "custom",
    sort: true,
    align: "left",
    callback: (row: IAssets) => {
      return (
        <>
          <Typography>{row.equipment?.model}</Typography>
        </>
      );
    },
  },
  {
    id: "cretedByName",
    label: "Created By",
    type: "string",
    sort: true,
    align: "left",
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    align: "left",
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "comments",
    label: "Comments",
    type: "custom",
    sort: false,
    align: "left",
    callback: (row: IAssets) => {
      return (
        <>
          <Typography>{row.requestTransfer?.comments}</Typography>
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IAssets) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                setRowSelectedAssets(row);
                setShowDecisionModal(true);
              }}
              tooltip="Decision"
            />
          </Stack>
        </>
      );
    },
  },
];

const getColumnsVehicle = (
  setRowSelectedAssets: (val: IAssets) => void,
  setShowDecisionModal: (val: boolean) => void
): ColumnType[] => [
  {
    id: "make",
    label: "Make",
    type: "custom",
    sort: true,
    width: "16.66%",
    callback: (row: IAssets) => <Typography>{row.vehicle?.make}</Typography>,
  },
  {
    id: "model",
    label: "Model",
    type: "custom",
    sort: true,
    width: "16.66%",
    callback: (row: IAssets) => <Typography>{row.vehicle?.model}</Typography>,
  },
  {
    id: "licensePlate",
    label: "License Plate",
    type: "custom",
    sort: true,
    width: "16.66%",
    callback: (row: IAssets) => (
      <Typography>{row.vehicle?.licensePlate}</Typography>
    ),
  },
  {
    id: "vehicleIdNumber",
    label: "Vehicle ID Number",
    type: "custom",
    sort: true,
    width: "16.66%",
    callback: (row: IAssets) => (
      <Typography>{row.vehicle?.vehicleIdNumber}</Typography>
    ),
  },
  {
    id: "cretedByName",
    label: "Created By",
    type: "string",
    sort: true,
    align: "left",
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    align: "left",
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "comments",
    label: "Comments",
    type: "custom",
    sort: false,
    align: "left",
    callback: (row: IAssets) => {
      return (
        <>
          <Typography>{row.requestTransfer?.comments}</Typography>
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IAssets) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                setRowSelectedAssets(row);
                setShowDecisionModal(true);
              }}
              tooltip="Decision"
            />
          </Stack>
        </>
      );
    },
  },
];

const getColumnsGpsPending = (
  setRowSelectedGpsPermission: (val: IRequestTechnicianPermission) => void,
  setShowDecisionModal: (val: boolean) => void,
  companyId: string
): ColumnType[] => [
  {
    id: "projectName",
    label: "Project Name",
    type: "custom",
    sort: true,
    width: "16.66%",
    callback: (row: IRequestTechnicianPermission) => {
      return (
        <Link to={`/app/Project/Edit/${row.projectId}/${companyId}`}>
          {row.projectName}
        </Link>
      );
    },
  },
  {
    id: "buildingName",
    label: "Building Name",
    type: "string",
    sort: true,
    width: "16.66%",
  },
  {
    id: "technicianName",
    label: "Requested By",
    type: "string",
    sort: true,
    width: "16.66%",
  },
  {
    id: "createdDate",
    label: "CreatedDate",
    type: "utcDate",
    format: "MM/DD/YYYY hh:mm A",
    sort: true,
    width: "16.66%",
  },
  {
    id: "technicianComments",
    label: "Comments",
    type: "string",
    sort: false,
    align: "left",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IRequestTechnicianPermission) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                setRowSelectedGpsPermission(row);
                setShowDecisionModal(true);
              }}
              tooltip="Decision"
            />
          </Stack>
        </>
      );
    },
  },
];

const MyPendingRequests = () => {
  const { user } = useAuth();

  const [refresh, setRefresh] = useState(false);
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const [selectdTab, setSelectedTab] = useState(0);
  const [rowSelectedChangeOrder, setRowSelectedChangeOrder] =
    useState<IChangeOrders>();
  const [rowSelectedMemoradum, setRowSelectedMemoradum] =
    useState<IMemorandum>();
  const [rowSelectedAssets, setRowSelectedAssets] = useState<IAssets>();
  const [rowSelectedGpsPermission, setRowSelectedGpsPermission] =
    useState<IRequestTechnicianPermission>();
  const [title, setTile] = useState("");

  const {
    execute: changeOrderExecute,
    data: dataChangeOrder,
    isLoading: isLoadingChangeOrder,
  } = useAsyncQuery<IChangeOrders[]>(changeOrdersService.getPending);

  const {
    execute: memoradumExecute,
    data: dataMemoradum,
    isLoading: isLoadingMemoradum,
  } = useAsyncQuery<IMemorandum[]>(memorandumService.getPending);

  const {
    execute: assetExecute,
    data: dataAssets,
    isLoading: isLoadingAsset,
  } = useAsyncQuery<IAssets[]>(companyAssetsService.getPendingAsset);

  const {
    execute: gpsPermissionExecute,
    data: datagpsPermission,
    isLoading: isLoadingGpsPermission,
  } = useAsyncQuery<IRequestTechnicianPermission[]>(
    requestTechnicianPermissionService.getPendigPermissions
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectdTab]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const getData = async () => {
    setRowSelectedChangeOrder(undefined);
    setRowSelectedMemoradum(undefined);
    setRowSelectedAssets(undefined);
    setRowSelectedGpsPermission(undefined);
    if (selectdTab === 0) {
      await changeOrderExecute(user?.userId);
      setTile(MY_PENDINGS_REQUEST_TILES.CHANGEORDER);
    } else if (selectdTab === 1) {
      await memoradumExecute(user?.userId);
      setTile(MY_PENDINGS_REQUEST_TILES.MEMORADUM);
    } else if (selectdTab === 2) {
      await assetExecute(user?.userId, AssetsConstant.EQUIPMENT);
      setTile(MY_PENDINGS_REQUEST_TILES.EQUIPMENT);
    } else if (selectdTab === 3) {
      await assetExecute(user?.userId, AssetsConstant.VEHICLE);
      setTile(MY_PENDINGS_REQUEST_TILES.VEHICLE);
    } else if (selectdTab === 4) {
      await gpsPermissionExecute(user?.userId);
      setTile(MY_PENDINGS_REQUEST_TILES.TECHNICIANGPS);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const columnsChangeOrder = getColumnsChangeOrder(
    setRowSelectedChangeOrder,
    setShowDecisionModal,
    user?.companyId
  );
  const columnsMemoradum = getColumnsMemoradum(
    setRowSelectedMemoradum,
    setShowDecisionModal,
    user?.companyId
  );
  const columsEquipment = getColumnsEquipment(
    setRowSelectedAssets,
    setShowDecisionModal
  );
  const columsVehicle = getColumnsVehicle(
    setRowSelectedAssets,
    setShowDecisionModal
  );
  const columsGps = getColumnsGpsPending(
    setRowSelectedGpsPermission,
    setShowDecisionModal,
    user?.companyId
  );

  const roleAsset = user?.role !== ROLES.ProjectManager;

  const roleGPS =
    user?.role === ROLES.CompanyOwner ||
    user?.role === ROLES.Administrator ||
    user?.role === ROLES.ProjectManager;

  return (
    <>
      <HeaderPage
        title={"My Pending Requests"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Stack direction="row">
                <Grid item xs={6}></Grid>
              </Stack>
            </Grid>
            <Grid
              container
              spacing={10}
              sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
            >
              <Grid item xs={12} ml={4}>
                <Tabs
                  value={selectdTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Change/Work Order" id="0" value={0} />
                  <Tab label="Memorandum Of Understanding" id="1" value={1} />
                  {roleAsset && <Tab label="Test Equipment" id="2" value={2} />}
                  {roleAsset && <Tab label="Vehicles" id="3" value={3} />}
                  {roleGPS && (
                    <Tab label="Technicians GPS Permissions" id="3" value={4} />
                  )}
                </Tabs>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {selectdTab === 0 && (
                <LocalEnhancedTable<IChangeOrders>
                  refreshGrid={true}
                  columns={columnsChangeOrder}
                  data={dataChangeOrder}
                  showSkeleton={isLoadingChangeOrder}
                  defaultRowPerPage={5}
                />
              )}
              {selectdTab === 1 && (
                <LocalEnhancedTable<IMemorandum>
                  refreshGrid={true}
                  columns={columnsMemoradum}
                  data={dataMemoradum}
                  showSkeleton={isLoadingMemoradum}
                  defaultRowPerPage={5}
                />
              )}
              {selectdTab === 2 && (
                <LocalEnhancedTable<IAssets>
                  refreshGrid={true}
                  columns={columsEquipment}
                  data={dataAssets}
                  showSkeleton={isLoadingAsset}
                  defaultRowPerPage={5}
                  orderBySecondLevel={true}
                  defaultSortColumn="equipment"
                />
              )}
              {selectdTab === 3 && (
                <LocalEnhancedTable<IAssets>
                  refreshGrid={true}
                  columns={columsVehicle}
                  data={dataAssets}
                  showSkeleton={isLoadingAsset}
                  defaultRowPerPage={5}
                  orderBySecondLevel={true}
                  defaultSortColumn="vehicle"
                />
              )}
              {selectdTab === 4 && (
                <LocalEnhancedTable<IRequestTechnicianPermission>
                  refreshGrid={true}
                  columns={columsGps}
                  data={datagpsPermission}
                  showSkeleton={isLoadingGpsPermission}
                  defaultRowPerPage={5}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showDecisionModal && (
        <DecisionPopUp
          showDecisionModal={showDecisionModal}
          setShowDecisionModal={setShowDecisionModal}
          changeOrders={rowSelectedChangeOrder}
          memoradum={rowSelectedMemoradum}
          assets={rowSelectedAssets}
          setRefresh={setRefresh}
          refresh={refresh}
          title={title}
          gpsPermission={rowSelectedGpsPermission}
        />
      )}
    </>
  );
};

export default MyPendingRequests;
