import { useEffect, useState } from "react";
import {
  useAsyncQuery,
  useAuth,
  useForm,
  useLog,
  usePermissions,
} from "src/hooks";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { IConfiguration } from "src/ts/interfaces";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import {
  FormButton,
  FormCheckBox,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import { useNavigate } from "react-router-dom";
import {
  EquipmentCalibrationOthers,
  EquipmentCalibrationShow,
  EquipmentCalibrationTitles,
  typeProject,
} from "src/constants";
import projectService from "src/services/project/projectService";
import { projectPutService } from "src/services";
import {
  IConfigurationOptions,
  IProjectBidOtherConfiguration,
} from "src/ts/interfaces/project/projectDto";
import projectBidOtherService from "src/services/project/projectBidOtherService";
import { AddIcon, DeleteIcon, EditIcon } from "src/components/icons";
import EditOtherBid from "./components/EditOtherBid";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";
import { Permission as PermissionTypes } from "src/ts/enums";

export const time = [
  {
    key: 1,
    value: (
      <>
        {<b>{EquipmentCalibrationShow.OneYear.Text}</b>}
        {` ${EquipmentCalibrationShow.OneYear.Description}`}
      </>
    ),
  },
  {
    key: 2,
    value: (
      <>
        {<b>{EquipmentCalibrationShow.SixMonths.Text}</b>}
        {` ${EquipmentCalibrationShow.SixMonths.Description}`}
      </>
    ),
  },
];

export const HVACBelts = [
  {
    key: 1,
    value: `${EquipmentCalibrationOthers.HVACMechanical}`,
  },
  {
    key: 2,
    value: `${EquipmentCalibrationOthers.AMPEnergy}`,
  },
  {
    key: 3,
    value: `${EquipmentCalibrationOthers.AbovePurposely}`,
  },
];

export const HVACElectric = [
  {
    key: 1,
    value: `${EquipmentCalibrationOthers.HVACElectric}`,
  },
  {
    key: 2,
    value: `${EquipmentCalibrationOthers.AMPEnergy}`,
  },
  {
    key: 3,
    value: `${EquipmentCalibrationOthers.AbovePurposely}`,
  },
];

export const Configuration: IConfigurationOptions[] = [
  {
    text: EquipmentCalibrationOthers.AirTesting,
    value: "airTestingBalancing",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.DuctLeakage,
    value: "ductLeakageTesting",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.HydronicWater,
    value: "hydronicWaterBalancing",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.HydromocPipe,
    value: "hydronicPipeleakage",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.HvacEnergy,
    value: "hvacEnergyPerformance",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.Commissioning,
    value: "commissioning",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.SoundTesting,
    value: "soundTestingLocations",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.VibrationTesting,
    value: "vibrationTestingForMotors",
    type: typeProject.PROJECTS,
  },
  {
    text: EquipmentCalibrationOthers.UFADPLenum,
    value: "ufadPlenumLeakage",
    type: typeProject.PROJECTS,
  },

  {
    text: EquipmentCalibrationOthers.hvacEnergyPerformance,
    value: "hvacEnergyPerformance",
    type: typeProject.INFECTIOUS,
  },
  {
    text: EquipmentCalibrationOthers.Other,
    value: "otherTesting",
    type: typeProject.BOTH,
  },

  {
    text: EquipmentCalibrationOthers.AbovePurposelyContractor,
    value: "additionalTestingCheck",
    type: typeProject.PROJECTS,
  },
];

const initialValues = {
  airTestingBalancing: false,
  ductLeakageTesting: false,
  hydronicWaterBalancing: false,
  hydronicPipeleakage: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  soundTestingLocations: false,
  vibrationTestingForMotors: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  additionalTestingReq: 0,
  additionalTestingCheck: false,
  equipmentCalibrationReportRequired: false,
  minimumDate: 0,
  beltsAndPulleys: 0,
  costForElectricMotors: 0,
  buildingEnergyEfficiency: false,
};
const initialValuesOther: IProjectBidOtherConfiguration[] = [
  { description: "" },
];

interface StudiesListProjectProps {
  projectResponse: number;
}

export default function ConfigurationProject({
  projectResponse,
}: StudiesListProjectProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { log } = useLog();
  const [editOtherBidPropsShowPopUp, setEditOtherBidPropsShowPopUp] =
    useState(false);
  const [deleteStudyModal, setDeleteStudyModal] = useState(false);
  const [configuration, setConfiguration] = useState<IConfigurationOptions[]>(
    []
  );
  const [editPopUp, setEditPopUp] = useState({
    id: 0,
    description: "",
  });

  const { values, setValues } = useForm(initialValues, false, []);
  const { values: projectBid, setValues: setProjectBid } = useForm(
    initialValuesOther,
    false,
    []
  );

  const { execute: exacuteConfiguration, data: dataConfiguration } =
    useAsyncQuery<IConfiguration>(getProjectSingleService.getConfiguration);

  const { execute: executeOtherBid, data: dataOtherBid } = useAsyncQuery<
    IProjectBidOtherConfiguration[]
  >(projectBidOtherService.get);

  const { execute: executeDeleteOtherBid } = useAsyncQuery(
    projectBidOtherService.deleteOtherBid
  );

  const { execute: executeChanges } = useAsyncQuery(
    projectPutService.putAdditionalTestingReq
  );

  const { readOnly } = usePermissions(PermissionTypes.Project);

  useEffect(() => {
    exacuteConfiguration(projectResponse);
    executeOtherBid(projectResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataConfiguration && dataConfiguration !== null) {
      setValues({
        ...dataConfiguration,
        minimumDate:
          dataConfiguration.minimumDate === null
            ? 0
            : dataConfiguration.minimumDate,
        beltsAndPulleys:
          dataConfiguration.beltsAndPulleys === null
            ? 0
            : dataConfiguration.beltsAndPulleys,
        costForElectricMotors:
          dataConfiguration.costForElectricMotors === null
            ? 0
            : dataConfiguration.costForElectricMotors,
        additionalTestingCheck:
          dataConfiguration.additionalTestingReq === 6 ? true : false,
      });
    }

    if (dataConfiguration && dataConfiguration.isInfectiousControlProject) {
      const dataInfectious = Configuration.filter(
        (item: IConfigurationOptions) => {
          return (
            item.type === typeProject.INFECTIOUS ||
            item.type === typeProject.BOTH
          );
        }
      );
      setConfiguration(dataInfectious);
    } else {
      const dataProjects = Configuration.filter(
        (item: IConfigurationOptions) => {
          return (
            item.type === typeProject.PROJECTS || item.type === typeProject.BOTH
          );
        }
      );
      setConfiguration(dataProjects);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfiguration]);

  useEffect(() => {
    setProjectBid(dataOtherBid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOtherBid]);

  useEffect(() => {
    if (values.otherTesting && projectBid.length === 0) {
      const additionalLength = projectBid.length || 0;
      setProjectBid([
        {
          ...projectBid,
          id: additionalLength,
          description: "",
          [`description${additionalLength}`]: "",
        },
      ]);
    } else if (values.otherTesting && projectBid && projectBid.length > 0) {
      const updatedDataOtherBid = projectBid.map(
        (item: IProjectBidOtherConfiguration, index: number) => ({
          ...item,
          [`description${item.id}`]: item.description,
          description: item.description,
          isDisabled: item.description === "" ? false : true,
        })
      );

      const lengthofNew = projectBid.filter(
        (item: IProjectBidOtherConfiguration) => {
          return item.description === "";
        }
      ).length;

      const newLine = [
        {
          id: projectBid.length,
          description: "",
          [`description${projectBid.length}`]: "",
          isDisabled: false,
        },
      ];
      if (lengthofNew === 0) {
        setProjectBid([...updatedDataOtherBid, ...newLine]);
      } else {
        setProjectBid([...updatedDataOtherBid]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherTesting]);

  const handleInputChangeMinimumDate = async (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    const valueSelected = Number(value);
    try {
      await projectService.putOneYearSixMonth(valueSelected, projectResponse);
      log.success("Data was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleInputChangeBeltsAndPulleys = async (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    const valueSelected = Number(value);
    try {
      await projectPutService.putBeltsAndPulleys(
        valueSelected,
        projectResponse
      );
      log.success("Data was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleInputChangeCostForElectricMotors = async (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    const valueSelected = Number(value);
    try {
      await projectPutService.putCostForElectricMotros(
        valueSelected,
        projectResponse
      );
      log.success("Data was updated.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleInputChangeConfiguration = async (e: any) => {
    const { name, value } = e.target;

    if (name === "additionalTestingCheck" && value) {
      const configurationFalse = {
        airTestingBalancing: false,
        ductLeakageTesting: false,
        hydronicWaterBalancing: false,
        hydronicPipeleakage: false,
        hvacEnergyPerformance: false,
        commissioning: false,
        soundTestingLocations: false,
        vibrationTestingForMotors: false,
        ufadPlenumLeakage: false,
        otherTesting: false,
        buildingEnergyEfficiency: false,
      };

      setValues({
        ...values,
        ...configurationFalse,
        additionalTestingCheck: true,
      });
      executeChanges(projectResponse, {
        ...values,
        ...configurationFalse,
        additionalTestingCheck: true,
        additionalTestingReq: 6,
      });
    } else {
      executeChanges(projectResponse, {
        ...values,
        [name]: value,
        additionalTestingCheck: false,
      });
      setValues({
        ...values,
        [name]: value,
        additionalTestingCheck: false,
        additionalTestingReq: 0,
      });
    }
    log.success("Data was updated.");
  };

  const handleInputChangeOther = (e: any) => {
    const { name, value } = e.target;

    const number = name.match(/\d+/)[0];

    const updatedProjectBid = projectBid.map(
      (item: IProjectBidOtherConfiguration) => {
        if (item.id === parseInt(number)) {
          return {
            ...item,
            [`description${number}`]: value,
            description: value,
          };
        }

        return item;
      }
    );

    setProjectBid(updatedProjectBid);
  };

  const handlerDelete = async () => {
    const deleteOtherBid = projectBid.filter(
      (item: IProjectBidOtherConfiguration) => {
        return item.id !== editPopUp.id;
      }
    );
    setProjectBid(deleteOtherBid);
    executeDeleteOtherBid(editPopUp.id);
  };

  const handlerAdd = async (description: string, id: number) => {
    if (description === "") return log.warning("Entry a description");
    try {
      const response = await projectBidOtherService.postOtherBid(
        projectResponse,
        encodeURIComponent(description)
      );

      const valuesData = projectBid.map(
        (item: IProjectBidOtherConfiguration) => {
          if (item.id === id) {
            return {
              ...item,
              isDisabled: true,
              id: response.data.id,
            };
          }

          return item;
        }
      );
      const newLine = [
        {
          description: "",
          [`description${projectBid.length}`]: "",
          isDisabled: false,
          id: projectBid.length,
        },
      ];

      setProjectBid([...valuesData, ...newLine]);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const otherBidShow =
    values.otherTesting && projectBid && projectBid.length > 0;

  const nextStepHandler = () => {
    navigate(
      `/app/ProjectContractors/Edit/${projectResponse}/${user?.companyId}`
    );
    log.success("The information was saved succesfully");
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormCheckBox
                value={true}
                name=""
                onChange={() => {}}
                label={`${EquipmentCalibrationTitles.EquimentCalibration}`}
                disabled={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container mt={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              {EquipmentCalibrationTitles.Equipment}
            </Typography>
            <FormRadioGroup
              value={values.minimumDate}
              name="minimumDate"
              onChange={handleInputChangeMinimumDate}
              label={""}
              items={time}
              disabled={readOnly}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid container mt={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              {EquipmentCalibrationTitles.HVACBelt}
            </Typography>
            <FormRadioGroup
              value={values.beltsAndPulleys}
              name="beltsAndPulleys"
              onChange={handleInputChangeBeltsAndPulleys}
              label={""}
              items={HVACBelts}
              disabled={readOnly}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid container mt={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              {EquipmentCalibrationTitles.HVACElectric}
            </Typography>
            <FormRadioGroup
              value={values.costForElectricMotors}
              name="costForElectricMotors"
              onChange={handleInputChangeCostForElectricMotors}
              label={""}
              items={HVACElectric}
              disabled={readOnly}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid container mt={2}>
        <Card sx={{ width: "inherit" }}>
          <CardContent>
            <Stack direction={"column"}>
              {configuration.map(
                (item: IConfigurationOptions, index: number) => {
                  return (
                    <>
                      <FormCheckBox
                        key={index}
                        name={item.value}
                        value={values[item.value]}
                        label={item.text}
                        onChange={handleInputChangeConfiguration}
                        disabled={readOnly}
                      />
                      <Grid item xs={7}>
                        {otherBidShow &&
                          item.text === EquipmentCalibrationOthers.Other &&
                          projectBid.map(
                            (
                              item2: IProjectBidOtherConfiguration,
                              index2: number
                            ) => {
                              return (
                                <Stack mt={2} direction={"row"} spacing={1}>
                                  <FormText
                                    name={`description${item2.id}`}
                                    value={
                                      projectBid[index2][
                                        `description${item2.id}`
                                      ]
                                    }
                                    label=""
                                    onChange={handleInputChangeOther}
                                    readOnlyText={item2.isDisabled}
                                  />

                                  {!item2.isDisabled && (
                                    <IconButton
                                      disabled={readOnly}
                                      onClick={() => {
                                        handlerAdd(
                                          item2.description,
                                          item2.id ?? 0
                                        );
                                      }}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  )}
                                  {item2.isDisabled && (
                                    <IconButton
                                      disabled={readOnly}
                                      onClick={() => {
                                        setEditPopUp({
                                          description: item2.description,
                                          id: item2.id ?? 0,
                                        });

                                        setEditOtherBidPropsShowPopUp(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                  {item2.isDisabled && (
                                    <IconButton
                                      disabled={readOnly}
                                      onClick={() => {
                                        setEditPopUp({
                                          description: item2.description,
                                          id: item2.id ?? 0,
                                        });

                                        setDeleteStudyModal(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              );
                            }
                          )}
                      </Grid>
                    </>
                  );
                }
              )}
            </Stack>

            <Grid container justifyContent={"center"} mt={6}>
              <Stack
                direction={"row"}
                spacing={4}
                mt={5}
                justifyContent={"center"}
              >
                <FormBackButton
                  size="small"
                  text="Previous Step"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      `/app/StudiesReport/Edit/${projectResponse}/${user?.companyId}`
                    );
                  }}
                  isSubmitting={false}
                />
                <FormButton
                  size="small"
                  text="Close"
                  variant="outlined"
                  onClick={() => {
                    navigate(`/app/ProjectArchitect/${user?.companyId}/all`);
                  }}
                />
                <FormNextButton
                  size="small"
                  text="Next Step"
                  color="primary"
                  variant="outlined"
                  onClick={nextStepHandler}
                  isSubmitting={false}
                />
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {editOtherBidPropsShowPopUp && (
        <EditOtherBid
          editOtherBidPropsShowPopUp={editOtherBidPropsShowPopUp}
          setEditOtherBidPropsShowPopUp={setEditOtherBidPropsShowPopUp}
          id={editPopUp.id}
          descriptionBid={editPopUp.description}
          setProjectBid={setProjectBid}
          projectBid={projectBid}
        />
      )}

      <MatchWordConfirmModal
        onConfirm={handlerDelete}
        text={`Are you certain you want to delete permanently this study?`}
        matchWord={"delete"}
        isDialogOpen={deleteStudyModal}
        setIsDialogOpen={setDeleteStudyModal}
      />
    </>
  );
}
