import { Skeleton, TextField } from "@mui/material";
import { Mode } from "src/ts/types";
import FormReadOnlyField from "./FormReadOnlyField";

interface FormTextProps {
  name: string;
  label: string | React.ReactNode;
  value: string | undefined;
  error?: any;
  placeholder?: string;
  onChange: (e: any) => void;
  disabled?: any;
  mode?: Mode;
  showSkeleton?: boolean;
  variant?: string;
  fullWidth?: boolean;
}

export default function FormTextArea(props: FormTextProps) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    placeholder,
    disabled,
    mode,
    showSkeleton,
    variant,
    fullWidth,
  } = props;

  if (mode && mode === "read")
    return <FormReadOnlyField label={label} value={value ?? "-"} />;

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <TextField
          id="outlined-multiline-static"
          placeholder={placeholder}
          variant={variant}
          fullWidth={fullWidth ?? true}
          multiline
          label={label}
          size="small"
          rows={3}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          {...(error && { error: true, helperText: error })}
        />
      )}
    </>
  );
}
