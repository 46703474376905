import React, { useEffect, useState } from "react";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
  FormTextArea,
  FormTimePicker,
} from "src/components/formControls";
import {
  Grid,
  Typography,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Chip as MuiChip,
  IconButton,
  Avatar,
  Skeleton,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import moment from "moment";
import { CertificationType } from "src/constants";
import HeaderPage from "src/components/page/HeaderPage";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import trainingCoursesService from "src/services/trainingCoursesService";
import { ITrainingCourses } from "src/ts/interfaces/catalogs/trainingCourse";
import trainingCoursesAttendanceService from "src/services/trainingCoursesAttendanceService";
import {
  AttendanceTypeEnum,
  ITrainingCoursesAttendance,
} from "src/ts/interfaces/catalogs/trainingCoursesAttendance";
import AddStudentToTrainingCoursePopup from "./AddStudentToTrainingCoursePopup";
import {
  AccessTime,
  ArrowBack,
  ArrowForward,
  Block,
  Check,
  Close,
  Remove,
} from "@mui/icons-material";
import { ITrainingCoursesAttendanceDate } from "src/ts/interfaces/catalogs/trainingCoursesAttendanceDate";
import trainingCoursesAttendanceDateService from "src/services/trainingCoursesAttendanceDateService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { StaticDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GridActionButton } from "src/components/gridControls";
import trainingCoursesAttendancePrivateNoteService from "src/services/trainingCoursesAttendancePrivateNoteService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import trainingCoursesStudentService from "src/services/trainingCoursesStudentService";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

const columns = (
  handleSaveAttendanceType: (
    trainingCoursesAttendanceDateId: number,
    trainingCoursesStudentId: number,
    type: AttendanceTypeEnum,
    comments: string,
    date: string
  ) => Promise<void>,
  setExcusedModal: React.Dispatch<React.SetStateAction<boolean>>,
  setStudentId: React.Dispatch<React.SetStateAction<number>>,
  setlateTimeModal: React.Dispatch<React.SetStateAction<boolean>>,
  setearlylateTimeModal: React.Dispatch<React.SetStateAction<boolean>>,
  setComments: React.Dispatch<React.SetStateAction<string>>,
  setPhoto: React.Dispatch<React.SetStateAction<string>>,
  setPhotoModal: React.Dispatch<React.SetStateAction<boolean>>,
  handleViewPrivateNote: (
    attendanceDateId: number,
    comments: string,
    studentId: number
  ) => void,
  handleRemoveStudent: (id: number, name: string) => Promise<void>,
  setCurrentTime: React.Dispatch<React.SetStateAction<string>>,
  setCurrentTimeEnd: React.Dispatch<React.SetStateAction<string>>,
  isDeleted: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Student",
    type: "custom",
    sort: true,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <Grid container>
          <Grid item xs={4}>
            <Avatar
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setPhoto(row?.photo);
                setPhotoModal(true);
              }}
              src={row?.photo ? `data:image/png;base64, ${row.photo}` : ""}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography pt={2.5} pl={1}>
              {row.trainingCoursesStudent?.student?.name}{" "}
              {row.trainingCoursesStudent?.student?.lastName}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: "attendance",
    label: "Attendance",
    type: "custom",
    sort: true,
    contentAlign: "center",
    align: "center",
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <Chip
            label={row.attendance === -1 ? "-" : `${row.attendance}%`}
            color={"default"}
            m={1}
            size="small"
          />
        </>
      );
    },
  },
  {
    id: "present",
    label: "Present",
    type: "custom",
    sort: false,
    hide: isDeleted,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <IconButton
            aria-label="Present"
            size="large"
            sx={{
              backgroundColor:
                row.attendanceType === AttendanceTypeEnum.Present
                  ? "rgb(46, 125, 50)"
                  : "",
              color:
                row.attendanceType === AttendanceTypeEnum.Present
                  ? "rgb(255, 255, 255)"
                  : "",
            }}
            onClick={(e: any) => {
              handleSaveAttendanceType(
                row.trainingCoursesAttendanceDateId ?? 0,
                row.trainingCoursesStudentId ?? 0,
                AttendanceTypeEnum.Present,
                row.comments,
                moment(new Date()).format("LT")
              );
            }}
          >
            <Check />
          </IconButton>
        </>
      );
    },
  },
  {
    id: "arriveLate",
    label: "Arrived Late",
    type: "custom",
    sort: false,
    hide: isDeleted,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <IconButton
            aria-label="Arrived Late"
            size="large"
            sx={{
              backgroundColor:
                (row.arriveLateTime !== null &&
                  (row.attendanceType === AttendanceTypeEnum.ArriveLate ||
                    row.attendanceType === AttendanceTypeEnum.LeaveEarly)) ||
                row.attendanceType === AttendanceTypeEnum.ArriveLate
                  ? "rgb(237, 108, 2)"
                  : "",
              color:
                (row.arriveLateTime !== null &&
                  (row.attendanceType === AttendanceTypeEnum.ArriveLate ||
                    row.attendanceType === AttendanceTypeEnum.LeaveEarly)) ||
                row.attendanceType === AttendanceTypeEnum.ArriveLate
                  ? "rgb(255, 255, 255)"
                  : "",
            }}
            onClick={(e: any) => {
              setCurrentTime(moment(new Date()).format("LT"));
              setCurrentTimeEnd(moment(new Date()).format("LT"));
              setlateTimeModal(true);
              setStudentId(row.trainingCoursesStudentId ?? 0);
            }}
          >
            <AccessTime />
          </IconButton>
        </>
      );
    },
  },
  {
    id: "leaveEarly",
    label: "Left Early",
    type: "custom",
    sort: false,
    hide: isDeleted,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <IconButton
            aria-label="Leave Early"
            size="large"
            sx={{
              backgroundColor:
                (row.leaveEarlyTime !== null &&
                  (row.attendanceType === AttendanceTypeEnum.ArriveLate ||
                    row.attendanceType === AttendanceTypeEnum.LeaveEarly)) ||
                row.attendanceType === AttendanceTypeEnum.LeaveEarly
                  ? "rgb(237, 108, 2)"
                  : "",
              color:
                (row.leaveEarlyTime !== null &&
                  (row.attendanceType === AttendanceTypeEnum.ArriveLate ||
                    row.attendanceType === AttendanceTypeEnum.LeaveEarly)) ||
                row.attendanceType === AttendanceTypeEnum.LeaveEarly
                  ? "rgb(255, 255, 255)"
                  : "",
            }}
            onClick={(e: any) => {
              setCurrentTime(moment(new Date()).format("LT"));
              setCurrentTimeEnd(moment(new Date()).format("LT"));
              setearlylateTimeModal(true);
              setStudentId(row.trainingCoursesStudentId ?? 0);
            }}
          >
            <AccessTime />
          </IconButton>
        </>
      );
    },
  },
  {
    id: "absent",
    label: "Absent",
    type: "custom",
    sort: false,
    hide: isDeleted,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <IconButton
            aria-label="Absent"
            size="large"
            sx={{
              backgroundColor:
                row.attendanceType === AttendanceTypeEnum.Absent
                  ? "rgb(211, 47, 47)"
                  : "",
              color:
                row.attendanceType === AttendanceTypeEnum.Absent
                  ? "rgb(255, 255, 255)"
                  : "",
            }}
            onClick={(e: any) => {
              handleSaveAttendanceType(
                row.trainingCoursesAttendanceDateId ?? 0,
                row.trainingCoursesStudentId ?? 0,
                AttendanceTypeEnum.Absent,
                row.comments,
                moment(new Date()).format("LT")
              );
            }}
          >
            <Close />
          </IconButton>
        </>
      );
    },
  },
  {
    id: "excused",
    label: "Excused",
    type: "custom",
    sort: false,
    hide: isDeleted,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <IconButton
            aria-label="Excused"
            size="large"
            sx={{
              backgroundColor:
                row.attendanceType === AttendanceTypeEnum.Excused
                  ? "rgb(2, 136, 209)"
                  : "",
              color:
                row.attendanceType === AttendanceTypeEnum.Excused
                  ? "rgb(255, 255, 255)"
                  : "",
            }}
            onClick={(e: any) => {
              setComments(row.comments);
              setExcusedModal(true);
              setStudentId(row.trainingCoursesStudentId ?? 0);
            }}
          >
            <Block />
          </IconButton>
        </>
      );
    },
  },
  {
    id: "privateNotes",
    label: "Private Notes",
    type: "custom",
    sort: false,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <GridActionButton
            type="view"
            onClick={() => {
              handleViewPrivateNote(
                row.trainingCoursesAttendanceDateId ?? 0,
                row.trainingCoursesAttendancePrivateNote !== undefined &&
                  row.trainingCoursesAttendancePrivateNote?.length > 0
                  ? row.trainingCoursesAttendancePrivateNote[0].comments
                  : "",
                row.trainingCoursesStudentId ?? 0
              );
            }}
            tooltip="View Private Note"
          />
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ITrainingCoursesAttendance) => {
      return (
        <>
          <GridActionButton
            type="remove"
            onClick={() => {
              handleRemoveStudent(
                row.trainingCoursesStudent?.studentId ?? 0,
                row.trainingCoursesStudent?.student?.name +
                  " " +
                  row.trainingCoursesStudent?.student?.lastName
              );
            }}
            tooltip="Remove Student"
          />
        </>
      );
    },
  },
];

const TrainingCourseStudents = () => {
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [excusedModal, setExcusedModal] = useState(false);
  const [privateNoteModal, setPrivateNoteModal] = useState(false);
  const [removeStudentModal, setRemoveStudentModal] = useState(false);
  const [dayOffModal, setDayOffModal] = useState(false);
  const [lateTimeModal, setlateTimeModal] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  const [earlylateTimeModal, setearlylateTimeModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [attendanceId, setAttendanceId] = useState(0);
  const [comments, setComments] = useState("");
  const [studentName, setStudentName] = useState("");
  const [privateNote, setPrivateNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [currentTime, setCurrentTime] = useState<string>(
    moment(new Date()).format("LT")
  );
  const [currentTimeEnd, setCurrentTimeEnd] = useState<string>(
    moment(new Date()).format("LT")
  );
  const todayDate = new Date(Date.now());
  let { id } = useParams<{ id: string }>();
  let trainingCourseId = Number(id ?? 0);
  const { execute, isLoading, data, setData } = useAsyncQuery<
    ITrainingCoursesAttendance[]
  >(trainingCoursesAttendanceService.getByDate);

  const {
    execute: executeDate,
    data: attendanceDate,
    setData: setAttendanceDate,
  } = useAsyncQuery<ITrainingCoursesAttendanceDate>(
    trainingCoursesAttendanceDateService.getByDate
  );

  const { execute: executeCreateDate } =
    useAsyncMutation<ITrainingCoursesAttendanceDate>(
      trainingCoursesAttendanceDateService.create,
      {
        hideSuccessfulMessage: true,
        onSuccess: async (result, request: any) => {
          setAttendanceDate(result);
          await executeTrainingCourseAttendance(
            result.id,
            request[2]?.trainingCoursesStudentId,
            request[2]?.attendanceType,
            request[2]?.comments,
            request[2]?.date
          );
          await handleGetAll();
        },
      }
    );

  const { execute: executeUpdateDate } =
    useAsyncMutation<ITrainingCoursesAttendanceDate>(
      trainingCoursesAttendanceDateService.create,
      {
        successfulMessage: "Daily Curriculum was updated",
        onSuccess: async (result, request: any) => {
          setAttendanceDate(result);
          await handleGetAll();
        },
      }
    );

  const { execute: executeDayOff } =
    useAsyncMutation<ITrainingCoursesAttendanceDate>(
      trainingCoursesAttendanceDateService.remove,
      {
        successfulMessage: "Day was setted as off",
        onSuccess: async (result, request: any) => {
          executeDate(trainingCourseId, moment(currentDate).toISOString());
          handleGetAll();
        },
      }
    );

  const {
    execute: executeGetTrainingCourse,
    data: trainingCourses,
    isLoading: isLoadingTrainingCourse,
  } = useAsyncQuery<ITrainingCourses>(trainingCoursesService.getById, {
    onSuccess: (result) => {
      let datecurrents = currentDate;
      if (datecurrents === null) {
        datecurrents = new Date();
      }
      if (
        new Date(result.beginDate) > new Date(datecurrents) ||
        new Date(result.endDate) < new Date(datecurrents)
      ) {
        if (new Date(result.endDate) < new Date(datecurrents)) {
          setCurrentDate(new Date(result.endDate));
        } else setCurrentDate(new Date(result.beginDate));
      } else setCurrentDate(new Date(datecurrents));
    },
  });

  const { execute: executeUpdatePrivateNote } = useAsyncMutation(
    trainingCoursesAttendancePrivateNoteService.updateAttendance,
    {
      onSuccess: async (result) => {
        await handleGetAll();
      },
    }
  );

  const { execute: executeTrainingCourseAttendance, isSubmitting } =
    useAsyncMutation(trainingCoursesAttendanceService.updateAttendance, {
      successfulMessage: "Attendance was updated",
      onSuccess: (result) => {
        setData([...result]);
      },
    });

  const { execute: executeMarkAll, isSubmitting: isSubmittingMarkAll } =
    useAsyncMutation(trainingCoursesAttendanceService.markAll, {
      successfulMessage: "Attendance was updated",
      onSuccess: (result, request) => {
        setData([...result]);
        executeDate(trainingCourseId, moment(currentDate).toISOString());
      },
    });

  const { execute: executeTrainingCourse } = useAsyncMutation(
    trainingCoursesStudentService.updateTrainingCourse,
    {
      onSuccess: async () => {
        await handleGetAll();
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeGetTrainingCourse(trainingCourseId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      executeDate(trainingCourseId, moment(currentDate).toISOString());
      handleGetAll();
    };
    if (currentDate !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      await handleGetAll();
    };
    if (!addStudentModal && currentDate !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStudentModal]);

  const handleGetAll = async () => {
    await execute(trainingCourseId, moment(currentDate).toISOString());
  };

  const handleBack = () => {
    if (currentDate !== null) {
      let newDate = currentDate.setDate(currentDate.getDate() - 1);
      setCurrentDate(new Date(newDate));
    }
  };

  const handleNext = () => {
    if (currentDate !== null) {
      let newDate = currentDate.setDate(currentDate.getDate() + 1);
      setCurrentDate(new Date(newDate));
    }
  };

  const handleViewPrivateNote = (
    attendanceDateId: number,
    comments: string,
    studentId: number
  ) => {
    setStudentId(studentId);
    setAttendanceId(attendanceDateId);
    setPrivateNote(comments);
    setPrivateNoteModal(true);
  };

  const handleSaveExcused = async () => {
    await handleSaveAttendanceType(
      attendanceDate ? attendanceDate?.id : 0,
      studentId,
      AttendanceTypeEnum.Excused,
      comments,
      currentTime
    );
    setAddStudentModal(false);
    setComments("");
  };

  const handleSaveArriveLate = async () => {
    await handleSaveAttendanceType(
      attendanceDate ? attendanceDate?.id : 0,
      studentId,
      AttendanceTypeEnum.ArriveLate,
      comments,
      currentTime
    );
    setlateTimeModal(false);
  };

  const handleSaveArriveLateLeave = async () => {
    await handleSaveAttendanceType(
      attendanceDate ? attendanceDate?.id : 0,
      studentId,
      AttendanceTypeEnum.LeaveEarly,
      comments,
      currentTimeEnd
    );
    setlateTimeModal(false);
  };

  const handleSaveTrainingDate = async () => {
    await executeUpdateDate(
      trainingCourseId,
      moment(currentDate).toISOString(),
      null,
      attendanceDate.dailyCurriculum
    );
  };

  const handleSavePrivateNote = async () => {
    await executeUpdatePrivateNote(attendanceId, privateNote, studentId);
  };

  const handleRemoveStudent = async (id: number, name: string) => {
    setStudentName(name);
    setStudentId(id);
    setRemoveStudentModal(true);
  };

  const handleConfirmDelete = async () => {
    await executeTrainingCourse(studentId, trainingCourseId, false);
  };

  const handleConfirmDayOff = async () => {
    if (currentDate !== null) {
      await executeDayOff(trainingCourseId, moment(currentDate).toISOString());
    }
  };

  const handleAll = async (type: AttendanceTypeEnum) => {
    await executeMarkAll(
      trainingCourseId,
      moment(currentDate).toISOString(),
      type
    );
  };

  const handleSaveAttendanceType = async (
    trainingCoursesAttendanceDateId: number,
    trainingCoursesStudentId: number,
    type: AttendanceTypeEnum,
    comments: string,
    date: string
  ) => {
    if (attendanceDate === null) {
      const initialValues: ITrainingCoursesAttendance = {
        id: 0,
        trainingCoursesStudentId: trainingCoursesStudentId ?? 0,
        comments: comments,
        attendanceType: type,
        photo: "",
        date: date,
      };
      await executeCreateDate(
        trainingCourseId,
        moment(currentDate).toISOString(),
        initialValues,
        null
      );
    } else {
      await executeTrainingCourseAttendance(
        trainingCoursesAttendanceDateId,
        trainingCoursesStudentId,
        type,
        comments,
        date
      );
    }
  };

  return (
    <>
      <HeaderPage
        title={"Training Course Attendance"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <FormButton
            text={"Add Student(s)"}
            onClick={() => {
              setAddStudentModal(true);
            }}
            size="small"
            color="primary"
          />
        }
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <Typography variant="h4" textAlign={"center"} pb={5}>
                {trainingCourses?.name}
              </Typography>
              <Typography>
                <Typography
                  variant="subtitle2"
                  textAlign={"right"}
                  display={"inline-block"}
                  width={90}
                >
                  Description:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {" "}
                  {trainingCourses?.description}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  variant="subtitle2"
                  textAlign={"right"}
                  display={"inline-block"}
                  width={90}
                >
                  Begin Date:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {" "}
                  {moment(trainingCourses?.beginDate).format("MM/DD/YYYY")}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  variant="subtitle2"
                  textAlign={"right"}
                  display={"inline-block"}
                  width={90}
                >
                  End Date:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {" "}
                  {moment(trainingCourses?.endDate).format("MM/DD/YYYY")}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  variant="subtitle2"
                  display={"inline-block"}
                  width={90}
                  textAlign={"right"}
                >
                  Certification:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {" "}
                  {trainingCourses?.certification ===
                  CertificationType.EnergyAuditorInfectiousBuildingControl
                    ? "Energy Auditor Infectious Building Control"
                    : trainingCourses?.certification ===
                      CertificationType.TestingAndBalancing
                    ? "Testing And Balancing"
                    : "Energy Auditor Infectious Building Control / Testing And Balancing"}
                </Typography>
              </Typography>
              <Typography>
                <Typography
                  variant="subtitle2"
                  textAlign={"right"}
                  display={"inline-block"}
                  width={90}
                >
                  School:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {" "}
                  {trainingCourses?.schools?.name}
                </Typography>
              </Typography>
              <Grid pt={7}>
                <FormTextArea
                  name={"dailyCurriculum"}
                  label={"Daily Curriculum"}
                  value={
                    (attendanceDate?.dailyCurriculum === "null"
                      ? ""
                      : attendanceDate?.dailyCurriculum) ?? ""
                  }
                  onChange={(e: any) => {
                    setAttendanceDate({
                      ...attendanceDate,
                      dailyCurriculum:
                        e.target.value === "null" ? null : e.target.value,
                    });
                  }}
                ></FormTextArea>
              </Grid>
              <Grid textAlign={"right"} pt={5}>
                <FormButton
                  text={"Save"}
                  onClick={handleSaveTrainingDate}
                  size="medium"
                ></FormButton>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                slotProps={{ actionBar: { actions: [] } }}
                value={dayjs(currentDate === null ? new Date() : currentDate)}
                openTo="day"
                maxDate={dayjs(trainingCourses?.endDate)}
                minDate={dayjs(trainingCourses?.beginDate)}
                onChange={(e: any) => {
                  setCurrentDate(new Date(e));
                }}
              />
            </Grid>
            {!attendanceDate?.isDeleted && (
              <Grid container spacing={3} ml={5}>
                <Grid item pl={5}>
                  <FormButton
                    text={"Mark All Present"}
                    onClick={() => {
                      handleAll(AttendanceTypeEnum.Present);
                    }}
                    variant="outlined"
                    startIcon={<Check></Check>}
                    size="small"
                  ></FormButton>
                </Grid>
                <Grid item>
                  <FormButton
                    text={"Mark All Absent"}
                    onClick={() => {
                      handleAll(AttendanceTypeEnum.Absent);
                    }}
                    variant="outlined"
                    startIcon={<Close></Close>}
                    size="small"
                  ></FormButton>
                </Grid>
                <Grid item>
                  <FormButton
                    text={"Clear Marks"}
                    onClick={() => {
                      handleAll(AttendanceTypeEnum.Clear);
                    }}
                    variant="outlined"
                    startIcon={<Remove></Remove>}
                    size="small"
                  ></FormButton>
                </Grid>
              </Grid>
            )}
            <Grid item pt={0} spacing={0} xs={12}>
              <Divider pt={0} />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2}>
                  {new Date(
                    trainingCourses?.beginDate ??
                      (currentDate === null ? new Date() : currentDate)
                  ) <
                    new Date(
                      currentDate === null
                        ? new Date().toDateString()
                        : currentDate.toDateString()
                    ) && (
                    <IconButton
                      aria-label="Absent"
                      size="large"
                      onClick={handleBack}
                    >
                      <ArrowBack />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={8} textAlign={"center"}>
                  <Typography typography={"h5"}>
                    {isLoadingTrainingCourse ? (
                      <>
                        <Skeleton></Skeleton>
                      </>
                    ) : (
                      <>
                        <Typography>
                          {todayDate.toDateString() ===
                          (currentDate === null
                            ? new Date()
                            : currentDate
                          ).toDateString() ? (
                            "Today"
                          ) : (
                            <>
                              <Typography>
                                {moment(
                                  currentDate === null
                                    ? new Date()
                                    : currentDate
                                ).format("dddd")}
                              </Typography>
                              <Typography>
                                {moment(
                                  currentDate === null
                                    ? new Date()
                                    : currentDate
                                ).format("L")}
                              </Typography>
                            </>
                          )}
                        </Typography>
                        {!attendanceDate?.isDeleted ? (
                          <Typography>
                            <Link
                              onClick={() => {
                                setDayOffModal(true);
                              }}
                              to={""}
                            >
                              Day off
                            </Link>
                          </Typography>
                        ) : (
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Day Off
                          </Typography>
                        )}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign={"right"}>
                  {new Date(
                    trainingCourses?.endDate ??
                      (currentDate === null ? new Date() : currentDate)
                  ) >
                    new Date(
                      (currentDate === null
                        ? new Date()
                        : currentDate
                      ).toDateString()
                    ) && (
                    <IconButton
                      aria-label="Absent"
                      size="large"
                      onClick={handleNext}
                    >
                      <ArrowForward />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITrainingCoursesAttendance>
                refreshGrid={refreshGrid}
                columns={columns(
                  handleSaveAttendanceType,
                  setExcusedModal,
                  setStudentId,
                  setlateTimeModal,
                  setearlylateTimeModal,
                  setComments,
                  setPhoto,
                  setPhotoModal,
                  handleViewPrivateNote,
                  handleRemoveStudent,
                  setCurrentTime,
                  setCurrentTimeEnd,
                  attendanceDate?.isDeleted ?? false
                )}
                data={Object.values(data === null ? [] : data)}
                showSkeleton={isLoading || isSubmitting || isSubmittingMarkAll}
                orderColumn="desc"
                defaultSortColumn="createdDate"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AddStudentToTrainingCoursePopup
        historyPopup={addStudentModal}
        setHistoryPopup={setAddStudentModal}
        trainingCourseId={trainingCourseId}
      ></AddStudentToTrainingCoursePopup>
      <DialogMessagePopup
        title={"Excused comments"}
        text={
          <>
            <FormTextArea
              name={"Coments"}
              label={"Comments"}
              value={comments === "null" ? "" : comments}
              onChange={(e: any) => {
                setComments(e.target.value);
              }}
            ></FormTextArea>
          </>
        }
        showPopup={excusedModal}
        setShowPopup={setExcusedModal}
        isSubmitting={isSubmitting}
        onSave={handleSaveExcused}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Private Notes"}
        text={
          <>
            <FormTextArea
              name={"Coments"}
              label={"Comments"}
              value={privateNote === "null" ? "" : privateNote}
              onChange={(e: any) => {
                setPrivateNote(e.target.value);
              }}
            ></FormTextArea>
          </>
        }
        showPopup={privateNoteModal}
        setShowPopup={setPrivateNoteModal}
        isSubmitting={isSubmitting}
        onSave={handleSavePrivateNote}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Arrived Late"}
        size={"xs"}
        text={
          <Grid textAlign={"center"}>
            <FormTimePicker
              name={"currentTime"}
              label={"Late Time"}
              value={currentTime}
              onChange={(e: any) => {
                setCurrentTime(e.target.value);
              }}
            ></FormTimePicker>
          </Grid>
        }
        showPopup={lateTimeModal}
        setShowPopup={setlateTimeModal}
        isSubmitting={isSubmitting}
        onSave={handleSaveArriveLate}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Left Early"}
        size={"xs"}
        text={
          <Grid textAlign={"center"}>
            <FormTimePicker
              name={"currentTimeEnd"}
              label={"Early Time"}
              value={currentTimeEnd}
              onChange={(e: any) => {
                setCurrentTimeEnd(e.target.value);
              }}
            ></FormTimePicker>
          </Grid>
        }
        showPopup={earlylateTimeModal}
        setShowPopup={setearlylateTimeModal}
        isSubmitting={isSubmitting}
        onSave={handleSaveArriveLateLeave}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Photo"}
        size={"xs"}
        text={
          <Grid textAlign={"center"}>
            <Avatar
              sx={{ width: "390px", height: "390px" }}
              onClick={() => {}}
              src={photo ? `data:image/png;base64, ${photo}` : ""}
            />
          </Grid>
        }
        showPopup={photoModal}
        setShowPopup={setPhotoModal}
        isSubmitting={false}
        hideAccept={true}
        cancelTextButton="Close"
      ></DialogMessagePopup>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={removeStudentModal}
        setIsDialogOpen={setRemoveStudentModal}
        disabled={!confirmDelete}
        text={
          <>
            <Typography>
              Are you certain you want to temove student: {studentName}
              from the training course?
            </Typography>
          </>
        }
        otherText={
          <>
            <Typography>
              With this action you will not be able to retrieve preciously
              entered information.
            </Typography>
            <FormCheckBox
              name={"confirmDelete"}
              label={
                "I confirm I want to delete student from the training course"
              }
              value={confirmDelete}
              onChange={() => {
                setConfirmDelete(!confirmDelete);
              }}
            ></FormCheckBox>
          </>
        }
        onConfirm={handleConfirmDelete}
      ></MatchWordConfirmModal>
      <MatchWordConfirmModal
        matchWord={"PROCEED"}
        isDialogOpen={dayOffModal}
        setIsDialogOpen={setDayOffModal}
        confirmText="Yes, Mark as Day Off"
        text={
          <>
            <Typography>
              Are you certain you want to mark{" "}
              {(currentDate === null ? new Date() : currentDate).toDateString()}{" "}
              as day off?
            </Typography>
          </>
        }
        otherText={
          <>
            By taking this action, you will not be able to retrieve previously
            entered information, and it will bot be possible to save any state
            for the student.
          </>
        }
        onConfirm={handleConfirmDayOff}
      ></MatchWordConfirmModal>
    </>
  );
};

export default TrainingCourseStudents;
