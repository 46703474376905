import axios from "../utils/axios";

const updateAttendance = async (
  trainingCoursesAttendanceId: number,
  comments: string,
  studentId: number
) => {
  return axios.put(
    `TrainingCoursesAttendancePrivateNote?attendaceDateId=${trainingCoursesAttendanceId}&comments=${comments}&studentId=${studentId}`
  );
};

const trainingCoursesAttendancePrivateNoteService = {
  updateAttendance,
};

export default trainingCoursesAttendancePrivateNoteService;
