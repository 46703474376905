import { IRequestTechnicianPermission } from "src/ts/interfaces/requestTechnicianPermission";
import axios from "src/utils/axios";

const getCurrentStatus = async (code: string) => {
  return await axios.get<IRequestTechnicianPermission>(
    `RequestTechnicianPermission?code=${code}`
  );
};

const update = async (
  code: string,
  status: string,
  comments: string,
  hours: string,
  unit: string
) => {
  return await axios.put(
    `RequestTechnicianPermission?code=${code}&status=${status}&comments=${comments}&hours=${hours}&unit=${unit}`
  );
};

const getPendigPermissions = async (userId: number) => {
  return axios.get<IRequestTechnicianPermission[]>(
    `RequestTechnicianPermission/GetPending?userID=${userId}`
  );
};

const requestTechnicianPermissionService = {
  getCurrentStatus,
  update,
  getPendigPermissions,
};

export default requestTechnicianPermissionService;
