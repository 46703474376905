import { Typography, Stack } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { IAssets } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";

const AssetAssignedToYouVehicleColumns = (
  setCurrentRow: (row: IAssets) => void,
  setShowEditModal: (row: boolean) => void,
  setShowSatelliteModal: (row: boolean) => void,
  setShowAssigment: (row: boolean) => void,
  setShowAssignTransfer: (row: boolean) => void,
  setShowAssessmentHistory: (row: boolean) => void,
  permission: boolean,
  navigate: NavigateFunction,
  companyId: string,
  fullAccess: boolean
): ColumnType[] => {
  return [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: false,
      width: "5%",
    },

    {
      id: "make",
      label: "Make",
      type: "custom",
      sort: false,
      width: "16.66%",
      callback: (row: IAssets) => <Typography>{row.vehicle?.make}</Typography>,
    },
    {
      id: "model",
      label: "Model",
      type: "custom",
      sort: false,
      width: "16.66%",
      callback: (row: IAssets) => <Typography>{row.vehicle?.model}</Typography>,
    },
    {
      id: "year",
      label: "Year",
      type: "custom",
      sort: false,
      width: "16.66%",
      callback: (row: IAssets) => <Typography>{row.vehicle?.year}</Typography>,
    },
    {
      id: "transferTo",
      label: "Transfer to",
      type: "custom",
      sort: false,
      width: "16.66%",
      callback: (row: IAssets) => {
        return (
          <>
            {row?.requestTransfer?.transferTo?.name === undefined ? (
              ""
            ) : (
              <Typography>{`${row?.requestTransfer?.transferTo?.name} ${row?.requestTransfer?.transferTo?.lastName}`}</Typography>
            )}
            {row.requestTransfer !== null &&
              row.requestTransfer?.transferTo === null &&
              !row.hasOrIsSatellite && (
                <Typography color="primary.main"> Office/Shop</Typography>
              )}
            {row.requestTransfer !== null &&
              row.requestTransfer?.transferTo === null &&
              row.hasOrIsSatellite && (
                <Typography
                  onClick={() => {
                    setCurrentRow(row);
                    setShowSatelliteModal(true);
                  }}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  color={"primary.main"}
                >
                  {" "}
                  Office/Shop
                </Typography>
              )}
          </>
        );
      },
    },

    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      width: "16.66%",
      callback: (row: IAssets) => {
        return (
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "tools" : "view"}
              onClick={() => {
                setCurrentRow(row);
                setShowEditModal(true);
              }}
              tooltip="Transfer to office"
              disabled={
                row.requestTransfer != null &&
                row.requestTransfer.transferTo == null
              }
              hidden={permission}
            />
            {fullAccess && (
              <GridActionButton
                type="compare"
                onClick={() => {
                  if (row.requestTransfer != null) {
                    setCurrentRow(row);
                    setShowAssigment(true);
                  } else {
                    setCurrentRow(row);
                    setShowAssignTransfer(true);
                  }
                }}
                tooltip="Assign/Transfer Vehicle"
              />
            )}

            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(
                  `/app/companyAssetsDocument/${row.id}/${companyId}/VEHI`
                );
              }}
              tooltip="Upload Vendor Documentation"
            />
            <GridActionButton
              type="report"
              onClick={() => {
                setCurrentRow(row);
                setShowAssessmentHistory(true);
              }}
              tooltip="Assessment Report"
            />
          </Stack>
        );
      },
    },
  ];
};

const CompanyAssetVehicleColumns = (
  setCurrentRow: (row: IAssets) => void,
  setShowSatelliteModal: (row: boolean) => void,
  setShowAssessmentHistory: (row: boolean) => void,
  setTransferAssigType: (row: string) => void,
  navigate: NavigateFunction,
  companyId: string
): ColumnType[] => {
  return [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: false,
    },

    {
      id: "make",
      label: "Make",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.make}</Typography>,
    },
    {
      id: "model",
      label: "Model",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => (
        <Typography>{row?.vehicle?.model}</Typography>
      ),
    },
    {
      id: "year",
      label: "Year",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.year}</Typography>,
    },
    {
      id: "assignedTo",
      label: "Assigned to",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => {
        return (
          <>
            {!row.officeShop && row.assignedTo !== null && (
              <Typography>{`${row?.assignedTo?.name} ${row.assignedTo?.lastName}`}</Typography>
            )}
            {row.officeShop && !row.hasOrIsSatellite && (
              <Typography color="primary.main">Office/Shop</Typography>
            )}
            {row.officeShop && row.hasOrIsSatellite && (
              <Typography
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                color={"primary.main"}
                onClick={() => {
                  setTransferAssigType("assignedTo");
                  setCurrentRow(row);
                  setShowSatelliteModal(true);
                }}
              >
                Office/Shop
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: "transferTo",
      label: "Transfer to",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => {
        return (
          <>
            <Typography>
              {row?.requestTransfer?.transferTo?.name === undefined ? (
                ""
              ) : (
                <Typography>{`${row?.requestTransfer?.transferTo?.name} ${row?.requestTransfer?.transferTo?.lastName}`}</Typography>
              )}
              {row.requestTransfer !== null &&
                row.requestTransfer?.transferTo === null &&
                !row.hasOrIsSatellite && (
                  <Typography color="primary.main"> Office/Shop</Typography>
                )}
              {row.requestTransfer !== null &&
                row.requestTransfer?.transferTo === null &&
                row.hasOrIsSatellite && (
                  <Typography
                    onClick={() => {
                      setTransferAssigType("transferTo");
                      setCurrentRow(row);
                      setShowSatelliteModal(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    color={"primary.main"}
                  >
                    {" "}
                    Office/Shop
                  </Typography>
                )}
            </Typography>
          </>
        );
      },
    },

    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => {
        return (
          <div>
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(
                  `/app/companyAssetsDocument/${row.id}/${companyId}/VEHI`
                );
              }}
              tooltip="Upload Vendor Documentation"
            />
            <GridActionButton
              type="report"
              onClick={() => {
                setCurrentRow(row);
                setShowAssessmentHistory(true);
              }}
              tooltip="Assessment Report"
            />
          </div>
        );
      },
    },
  ];
};

export { AssetAssignedToYouVehicleColumns, CompanyAssetVehicleColumns };
