import React, { useEffect, useState } from "react";
import { Grid, Link, Stack, Typography } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import {
  FormButton,
  FormCancelButton,
  FormCheckBox,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "../formControls";
import { IKeyValue } from "src/ts/interfaces";
import {
  CatalogService,
  SubscriptionService,
  localUnionsService,
} from "src/services";
import { SubscriptionType } from "src/constants";
import { useAsyncMutation, useForm, useLog } from "src/hooks";
import { Validator } from "src/ts/types";
import { ISignUp } from "src/ts/interfaces/account";
import accountService from "src/services/accountService";
import { useNavigate } from "react-router-dom";
import laborUnionsService from "src/services/catalogs/laborUnionsService";
import teamMemberServices from "src/services/teamMemberServices";

interface SignUpProps {
  teamMemberId?: number;
}

const initialValues: ISignUp = {
  submit: false,
  subscriptionId: 0,
  company: "",
  isTermsAndConditionsAccepted: false,
  otherAuthority: "",
  otherCompanyType: "",
  smwiaLocalUnion: null,
  unionAffiliated: false,
  user: {
    userName: "",
    password: "",
    confirmPassword: "",
  },
};
const types = [
  {
    key: 1,
    value: "Yes",
  },
  {
    key: 0,
    value: "No",
  },
];

function getUrlParameter(param: any) {
  let sPageURL = window.location.href,
    sURLVariables = sPageURL.split(/[&||?]/),
    res;

  for (let i = 0; i < sURLVariables.length; i += 1) {
    let paramName = sURLVariables[i],
      sParameterName = (paramName || "").split("=");

    if (sParameterName[0] === param) {
      res = sParameterName[1];
    }
  }

  return res;
}

function SignUp(props: SignUpProps) {
  const navigate = useNavigate();
  const { log } = useLog();
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  const subscriptionId = parseInt(getUrlParameter("subtype") ?? "0");

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.userName = new Validator(fieldValues.user, "userName")
      .emailFormat("Insert a correct email: demo@gmail.com")
      ?.toString();

    temp.password = new Validator(fieldValues.user, "password")
      .isRequired("Password is required")
      .validateIf(fieldValues.password === fieldValues.confirmPassword)
      ?.toString();

    temp.confirmPassword = new Validator(fieldValues.user, "confirmPassword")
      .isRequired("Confirm Password is required")
      .validateIf(fieldValues.password === fieldValues.confirmPassword)
      ?.toString();

    temp.subscriptionId = new Validator(fieldValues, "subscriptionId")
      .selectedOption(0, "Subscription is required")
      ?.toString();

    if (fieldValues.subscriptionId === SubscriptionType.Free) {
      temp.authorityId = new Validator(fieldValues, "authorityId")
        .selectedOption(0, "Authority is required")
        ?.toString();
    }

    temp.company = new Validator(fieldValues, "company")
      .isRequired("Company is required")
      ?.toString();

    if (
      !temp.password &&
      !temp.confirmPassword &&
      fieldValues.user.password !== fieldValues.user.confirmPassword
    ) {
      temp.password = "Password not match";
      temp.confirmPassword = "Password not match";
    } else {
      temp.password = "";
      temp.confirmPassword = "";
    }

    if (values.unionAffiliated) {
      if (fieldValues.laborUnions === undefined)
        temp.laborUnions = "Labor Union is required";
      else temp.laborUnions = "";

      if (values.laborUnions === 2) {
        if (fieldValues.laborUnionsOther === undefined)
          temp.laborUnionsOther = "Labor Union Other is required";
        else temp.laborUnionsOther = "";
      }

      if (fieldValues.localUnions === undefined) {
        temp.localUnions = "Local Union is required";
      } else temp.localUnions = "";

      if (
        inputOther &&
        values.localUnions >= 248 &&
        values.localUnions <= 250
      ) {
        if (fieldValues.localUnionsOther === undefined) {
          temp.localUnionsOther = "Local Unions Other is required";
        } else temp.localUnionsOther = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    {
      ...initialValues,
      subscriptionId:
        subscriptionId !== 0 ? subscriptionId : initialValues.subscriptionId,
    },
    true,
    validate
  );
  const { signIn } = useAuth();
  const [subcriptionData, setSubcriptionData] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [authorityData, setAuthorityData] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [companyType, setCompanyType] = useState<IKeyValue<number, string>[]>(
    []
  );
  const [laborUnion, setLaborUnion] = useState<IKeyValue<number, string>[]>([]);
  const [localUnion, setLocalUnion] = useState<IKeyValue<number, string>[]>([]);

  const { execute, isSubmitting } = useAsyncMutation<string>(
    accountService.register,
    {
      hideSuccessfulMessage: true,
      hideErrorMessage: true,
      onSuccess: async () => {
        await signIn(values.user.userName, values.user.password);
        navigate("/contactInformation");
      },
      onError: async (error) => {
        log.error(error?.message?.DuplicateUserName ?? "Action was sucessful");
      },
    }
  );

  useEffect(() => {
    const getSubscriptions = async () => {
      const request = await SubscriptionService.getKeyValues();
      setSubcriptionData(request);

      const requestAuthority =
        await SubscriptionService.getKeyValuesAuthority();
      setAuthorityData(requestAuthority);
      const companyTypeResult = await CatalogService.getAllDropdown(
        "companyType"
      );
      setCompanyType(companyTypeResult);
      const laborUnionResult = await laborUnionsService.getKeyValues();
      setLaborUnion(laborUnionResult);
    };

    const getTeamMember = async () => {
      setIsSubmmiting(true);
      try {
        const response = await teamMemberServices.dataTeamMember(
          props.teamMemberId ?? 0
        );

        let newValues = { ...values };
        newValues.user.userName = response.data.email;
        setValues({
          ...newValues,
        });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmmiting(false);
      }
    };

    getSubscriptions();

    if (props.teamMemberId !== 0 && props.teamMemberId !== undefined)
      getTeamMember();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBuildingSubscription = async () => {
      if (props.teamMemberId !== 0 && props.teamMemberId !== undefined) {
        let newValues = { ...values };
        newValues.subscriptionId = subcriptionData[0].key;
        setValues({
          ...newValues,
        });
      }
    };
    if (subcriptionData.length > 0) getBuildingSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcriptionData]);

  useEffect(() => {
    const getSubscriptions = async () => {
      const localUnionResult = await localUnionsService.getKeyValuesByLabor(
        values.laborUnions
      );
      setLocalUnion(localUnionResult);
    };

    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.laborUnions]);

  const handleSignUp = async () => {
    if (!validate()) return;
    await execute(values);
  };

  const handleInputChangeUser = (e: any) => {
    const { name, value } = e.target;
    let newValues = { ...values };
    newValues.user[name] = value;

    setValues({
      ...newValues,
    });
  };

  const handleGoBack = () => {
    navigate("/auth/sign-in");
  };

  const [inputOther, setInputOther] = useState(false);
  const handleInputChangeOtherLaborUnion = (e: any) => {
    setInputOther(false);

    let newValues = { ...values };
    newValues.laborUnionsId = e.target.value;
    newValues.laborUnions = e.target.value;
    newValues.localUnions = undefined;

    setValues({
      ...newValues,
    });
  };

  const handleInputChangeOtherLocalUnion = (e: any) => {
    setInputOther(true);

    let newValues = { ...values };
    newValues.localUnionsId = e.target.value;
    newValues.localUnions = e.target.value;

    setValues({
      ...newValues,
    });
  };

  const islaborUnionsOther = () => {
    const found = laborUnion.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.laborUnions === found?.key;
  };

  const isLocalUnionOther = () => {
    const found = localUnion.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.localUnions === found?.key;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormText
          type="email"
          name="userName"
          label="Email address"
          autoComplete="new-password"
          value={values.user.userName}
          error={errors.userName}
          fullWidth
          onChange={handleInputChangeUser}
          autocomplete={false}
          showSkeleton={isSubmmiting}
          disabled={
            props.teamMemberId !== 0 && props.teamMemberId !== undefined
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormText
          type="password"
          name="password"
          label="Password"
          autoComplete="new-password"
          placeholder="Password - Your password must have at least one non letter or digit character"
          value={values.user.password}
          error={errors.password}
          fullWidth
          onChange={handleInputChangeUser}
        />
      </Grid>

      <Grid item xs={12}>
        <FormText
          type="password"
          name="confirmPassword"
          label="Confirm password"
          value={values.user.confirmPassword}
          error={errors.confirmPassword}
          fullWidth
          onChange={handleInputChangeUser}
        />
      </Grid>

      <Grid item xs={12}>
        <FormSelect
          name={"subscriptionId"}
          label={"Subscription"}
          value={values.subscriptionId}
          onChange={handleInputChange}
          options={subcriptionData}
          error={errors.subscriptionId}
          defaultValue={{ key: 0, value: "Select Subscription" }}
          disabled={
            props.teamMemberId !== 0 && props.teamMemberId !== undefined
          }
        ></FormSelect>
      </Grid>
      {SubscriptionType.Free === values.subscriptionId && (
        <Grid item xs={12}>
          <FormSelect
            name={"authorityId"}
            label={"Authority"}
            value={values.authorityId}
            onChange={handleInputChange}
            options={authorityData}
            error={errors.authorityId}
            defaultValue={{ key: 0, value: "Select Authority" }}
          ></FormSelect>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormText
          type="text"
          name="company"
          label="Company"
          value={values.company}
          error={errors.company}
          fullWidth
          onChange={handleInputChange}
        />
      </Grid>
      {SubscriptionType.Pay === values.subscriptionId && (
        <Grid item xs={12}>
          <FormSelect
            name={"companyTypeId"}
            label={"Company Type"}
            value={values.companyTypeId}
            onChange={handleInputChange}
            options={companyType}
            error={errors.companyTypeId}
            defaultValue={{ key: null, value: "Select Company Type" }}
          ></FormSelect>
        </Grid>
      )}
      {SubscriptionType.Pay === values.subscriptionId && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography pt={2}>Union Affiliated</Typography>
            </Grid>
            <Grid item xs={8}>
              <FormRadioGroup
                items={types}
                name={"default"}
                row={true}
                value={Number(values.unionAffiliated)}
                onChange={(e) => {
                  setValues({
                    ...values,
                    unionAffiliated: e.target.value === "1" ? true : false,
                  });
                }}
              ></FormRadioGroup>
            </Grid>
            {values.unionAffiliated && (
              <>
                <Grid item xs={12} pt={3}>
                  <FormSelect
                    name={"laborUnions"}
                    label={"Labor Union"}
                    value={values.laborUnions}
                    onChange={handleInputChangeOtherLaborUnion}
                    options={laborUnion}
                    error={errors.laborUnions}
                    defaultValue={{ key: null, value: "Select Labor Union" }}
                  ></FormSelect>
                </Grid>

                {islaborUnionsOther() && (
                  <Grid item xs={12} pt={3}>
                    <FormText
                      name={"laborUnionsOther"}
                      label={"Labor Union Other"}
                      value={values.laborUnionsOther}
                      onChange={handleInputChange}
                      error={errors.laborUnionsOther}
                    ></FormText>
                  </Grid>
                )}
              </>
            )}
            {values.unionAffiliated && (
              <>
                <Grid item xs={12} pt={3}>
                  <FormSelect
                    name={"localUnions"}
                    label={"Local Union"}
                    value={values.localUnions}
                    onChange={handleInputChangeOtherLocalUnion}
                    options={localUnion}
                    error={errors.localUnions}
                    defaultValue={{ key: null, value: "Select Local Union" }}
                  ></FormSelect>
                </Grid>

                {inputOther && isLocalUnionOther() && (
                  <Grid item xs={12} pt={3}>
                    <FormText
                      name={"localUnionsOther"}
                      label={"Local Unions Other"}
                      value={values.localUnionsOther}
                      onChange={handleInputChange}
                      error={errors.localUnionsOther}
                    ></FormText>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormCheckBox
          name="isTermsAndConditionsAccepted"
          label={
            <Typography>
              <Typography display={"inline"}>
                I understand and agree to{" "}
              </Typography>
              <Link
                href="https://auditmaster.pro/legal?id=privacy_statement"
                target="_blank"
              >
                AMP's Privacy Policy and Terms of Service
              </Link>
            </Typography>
          }
          value={values.isTermsAndConditionsAccepted}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <FormButton
            text={"Sign up"}
            onClick={handleSignUp}
            fullWidth
            disabled={!values.isTermsAndConditionsAccepted}
            isSubmitting={isSubmitting}
            size="medium"
          ></FormButton>
          <FormCancelButton
            onClick={handleGoBack}
            isSubmitting={false}
            text="Go Back"
            fullWidth={true}
            size="medium"
          ></FormCancelButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default SignUp;
