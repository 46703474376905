import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
} from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import { CompanyService } from "src/services";
import trainingCoursesStudentService from "src/services/trainingCoursesStudentService";
import { ICompanyTrainingCourse } from "src/ts/interfaces";
import { ITrainingCourses } from "src/ts/interfaces/catalogs/trainingCourse";
import { ColumnType } from "src/types/enhancedTable";

interface IProps {
  trainingCourseId: number;
  historyPopup: boolean;
  setHistoryPopup: Dispatch<SetStateAction<boolean>>;
}

const columns = (
  setValue: Dispatch<SetStateAction<ICompanyTrainingCourse | undefined>>
): ColumnType[] => [
  {
    id: "trainingCourse",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: ICompanyTrainingCourse) => {
      return (
        <FormCheckBox
          name={"isSelectedTrainingCourse"}
          label={undefined}
          value={row.isSelectedTrainingCourse}
          onChange={() => {
            row.isSelectedTrainingCourse = true;
            setValue({ ...row });
            //handleSelectStudent(row.userId, row.isSelectedTrainingCourse);
          }}
        ></FormCheckBox>
      );
    },
  },
  {
    id: "name",
    label: "Company/Student Name",
    type: "string",
    sort: true,
  },
  {
    id: "owner",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "companyAddress",
    label: "Address",
    type: "string",
    sort: true,
  },
];

const AddStudentToTrainingCoursePopup = ({
  trainingCourseId,
  historyPopup,
  setHistoryPopup,
}: IProps) => {
  const [value, setValue] = useState<ICompanyTrainingCourse>();
  const { execute, data, isLoading } = useAsyncQuery(
    CompanyService.getByTrainingCoursesId
  );

  const { execute: executeTrainingCourse, isSubmitting } = useAsyncMutation(
    trainingCoursesStudentService.updateTrainingCourse,
    {
      successfulMessage: "The current training course was updated",
    }
  );

  const { execute: executeTrainingCourseList, isSubmitting: isSubmittingList } =
    useAsyncMutation(trainingCoursesStudentService.updateTrainingCourseList, {
      successfulMessage: "The current training course was updated",
      onSuccess: () => {
        setHistoryPopup(false);
      },
    });

  useEffect(() => {
    const getData = async () => {
      await execute(trainingCourseId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingCourseId, historyPopup]);

  const handleClose = () => {
    setHistoryPopup(false);
  };

  const handleSelectStudent = async (id: number, select: boolean) => {
    await executeTrainingCourse(id, trainingCourseId, select);
  };

  const handleAddSelectedStudents = async () => {
    executeTrainingCourseList(trainingCourseId, data);
  };

  return (
    <Popup
      title="Add Student"
      openPopup={historyPopup}
      setOpenPopup={setHistoryPopup}
      onClose={handleClose}
      size="md"
    >
      <>
        <LocalEnhancedTable<ITrainingCourses>
          columns={columns(setValue)}
          data={Object.values(data ?? [])}
          orderColumn="desc"
          defaultSortColumn="id"
          showSkeleton={isLoading}
        />
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormAcceptButton
          text="Add Selected Student(s)"
          onClick={handleAddSelectedStudents}
          isSubmitting={false}
        ></FormAcceptButton>
        <FormCancelButton
          text="Close"
          onClick={handleClose}
          isSubmitting={false}
        />
      </Stack>
    </Popup>
  );
};

export default AddStudentToTrainingCoursePopup;
