import React, { useState } from "react";
import { TextField, Grid, Typography, InputAdornment } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VisaIcon from "./assets/visa-logo.png"; // Replace with actual path to Visa icon
import MasterCardIcon from "./assets/master-card.png"; // Replace with actual path to MasterCard icon

interface CreditCardFormProps {
  values: any;
  setValues: React.Dispatch<any>;
  formErrors: Record<string, string>;
  cardNumberFieldName: string;
  expiryMonthFieldName: string;
  expityYearFieldName: string;
  ccvFieldName: string;
  disableCardNumber?: boolean;
  disableExpiryMonth?: boolean;
  disableExpiryYear?: boolean;
  disableCCV?: boolean;
  showCCV?: boolean;
}

const CreditCardForm = (props: CreditCardFormProps) => {
  const {
    values,
    setValues,
    formErrors,
    cardNumberFieldName,
    expiryMonthFieldName,
    expityYearFieldName,
    ccvFieldName,
    disableCardNumber,
    disableExpiryMonth,
    disableExpiryYear,
    disableCCV,
    showCCV = true,
  } = props;
  const [cardType, setCardType] = useState("");
  const [error, setError] = useState<any>();

  const validateCardNumber = (number: string) => {
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const masterCardRegex = /^5[1-5][0-9]{14}$/;
    if (visaRegex.test(number)) {
      setCardType("Visa");
      return true;
    } else if (masterCardRegex.test(number)) {
      setCardType("MasterCard");
      return true;
    }
    setCardType("");
    return false;
  };

  const handleCardNumberChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setValues({ ...values, [cardNumberFieldName]: value });
    setError({ ...error, cardNumber: !validateCardNumber(value) });
  };

  const handleExpiryMonthChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setValues({ ...values, [expiryMonthFieldName]: value });
    setError({ ...error, expiryMonth: !(value >= 1 && value <= 12) });
  };

  const handleExpiryYearChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setValues({ ...values, [expityYearFieldName]: value });
    const currentYear = new Date().getFullYear() % 100;
    setError({ ...error, expiryYear: !(value >= currentYear) });
  };

  const handleCcvChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setValues({ ...values, [ccvFieldName]: value });
    setError({ ...error, ccv: value.length < 3 });
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          label="Card Number"
          variant="outlined"
          value={values[cardNumberFieldName]}
          onChange={handleCardNumberChange}
          error={!!error?.cardNumber || !!formErrors[cardNumberFieldName]}
          helperText={
            error?.cardNumber
              ? "Invalid card number"
              : formErrors[cardNumberFieldName] || ""
          }
          InputProps={{
            startAdornment:
              cardType === "Visa" ? (
                <InputAdornment position="start">
                  <img alt="" src={VisaIcon} style={{ width: 24 }} />
                </InputAdornment>
              ) : cardType === "MasterCard" ? (
                <InputAdornment position="start">
                  <img alt="" src={MasterCardIcon} style={{ width: 24 }} />
                </InputAdornment>
              ) : (
                <CreditCardIcon />
              ),
          }}
          disabled={disableCardNumber}
          placeholder="Enter the card number"
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size="small"
          label="Expiration Month"
          variant="outlined"
          value={values[expiryMonthFieldName]}
          onChange={handleExpiryMonthChange}
          error={!!error?.expiryMonth || !!formErrors[expiryMonthFieldName]}
          helperText={
            error?.expiryMonth
              ? "Invalid month"
              : formErrors[expiryMonthFieldName] || ""
          }
          disabled={disableExpiryMonth}
          placeholder="MM"
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size="small"
          label="Expiration Year"
          variant="outlined"
          value={values[expityYearFieldName]}
          onChange={handleExpiryYearChange}
          error={!!error?.expiryYear || !!formErrors[expityYearFieldName]}
          helperText={
            error?.expiryYear
              ? "Invalid year"
              : formErrors[expityYearFieldName] || ""
          }
          disabled={disableExpiryYear}
          placeholder="YY"
        />
      </Grid>
      {showCCV && (
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label="CCV"
            variant="outlined"
            value={values[ccvFieldName]}
            onChange={handleCcvChange}
            error={!!error?.ccv || !!formErrors[ccvFieldName]}
            helperText={
              error?.ccv ? "Invalid CCV" : formErrors[ccvFieldName] || ""
            }
            disabled={disableCCV}
            placeholder="CCV"
          />
        </Grid>
      )}
    </>
  );
};

export default CreditCardForm;
