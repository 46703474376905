import { ITrainingCoursesAttendanceDate } from "src/ts/interfaces/catalogs/trainingCoursesAttendanceDate";
import axios from "../utils/axios";
import { ITrainingCoursesAttendance } from "src/ts/interfaces/catalogs/trainingCoursesAttendance";

const getByDate = async (trainingCourseId: number, date: Date) => {
  return axios.get<ITrainingCoursesAttendanceDate>(
    `TrainingCoursesAttendanceDate?trainingCourseId=${trainingCourseId}&date=${date}`
  );
};

const create = async (
  trainingCourseId: number,
  date: Date,
  data: ITrainingCoursesAttendance,
  dailyCurriculum: string
) => {
  return axios.put<ITrainingCoursesAttendanceDate>(
    `TrainingCoursesAttendanceDate?trainingCourseId=${trainingCourseId}&date=${date}&dailyCurriculum=${dailyCurriculum}`
  );
};

const remove = async (trainingCourseId: number, date: Date) => {
  return axios.delete<ITrainingCoursesAttendanceDate>(
    `TrainingCoursesAttendanceDate?trainingCourseId=${trainingCourseId}&date=${date}`
  );
};

const trainingCoursesAttendanceDateService = {
  getByDate,
  create,
  remove,
};

export default trainingCoursesAttendanceDateService;
