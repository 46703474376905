import React, { useEffect, useState } from "react";
import { Grid, Switch, Typography, Chip as MuiChip } from "@mui/material";
import { Stack, spacing, SpacingProps } from "@mui/system";
import { useAsyncQuery, useLog } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import styled from "@emotion/styled";
import {
  FormAcceptButton,
  FormButton,
  FormCancelButton,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { AddIcon } from "src/components/icons";
import Popup from "src/components/Popup";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import {
  ITermsAndConditionsOptions,
  OptionType,
} from "src/ts/interfaces/catalogs/termsAndConditionsOptions";
import termsAndConditionsOptionsService from "src/services/termsAndConditionsOptionsService";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import draftToHtmlService from "src/services/draftToHtmlService";
import { useParams } from "react-router-dom";
import useFormTyped from "src/hooks/useFormTyped";

const optionType = [
  { key: 1, value: "CheckBox" },
  { key: 2, value: "TextArea" },
  { key: 3, value: "TextBox" },
  { key: 4, value: "RadioButton" },
];

interface ITermsAndConditionsTabProps {
  tabId: number;
  setOptions: (e: ITermsAndConditionsOptions[], tabId: number) => void;
  isNew: boolean;
}

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const initialValues: ITermsAndConditionsOptions = {
  id: 0,
  termsAndConditionsTabsId: 0,
  isRequired: true,
  description: "",
  descriptionHTML: "",
  descriptionNew: "",
  subItems: [],
  optionType: OptionType.CheckBox,
};

const columnsOptions = (
  handleEdit: (e: any, edit: boolean) => void,
  handleAddSubItem: (e: any) => void,
  handleDelete: (e: ITermsAndConditionsOptions) => void,
  viewMode: boolean
): ColumnType[] => [
  {
    id: "description",
    label: "Description",
    type: "custom",
    sort: false,
    callback: (row: ITermsAndConditionsOptions) => {
      return (
        <>
          {(row.optionType === OptionType.CheckBox ||
            row.optionType === OptionType.RadioButton) && (
            <FormTextEditor
              value={row.description}
              onChange={function (e: any): void {
                throw new Error("Function not implemented.");
              }}
              name={"description" + row.id}
              readonly={true}
            ></FormTextEditor>
          )}
          {row.optionType !== OptionType.CheckBox &&
            row.optionType !== OptionType.RadioButton && (
              <Typography>{row.placeHolder}</Typography>
            )}
        </>
      );
    },
  },
  {
    id: "isRequired",
    label: "Is Required",
    type: "custom",
    sort: false,
    callback: (row: ITermsAndConditionsOptions) => {
      return (
        <>
          {row.isRequired && (
            <Chip label="Required" color="primary" m={1} size="small" />
          )}
        </>
      );
    },
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    hide: viewMode,
    callback: (row: ITermsAndConditionsOptions) => (
      <div>
        <Stack direction="row">
          {(row.parentId === 0 || row.parentId === null) && (
            <GridActionButton
              type="add"
              onClick={() => {
                handleAddSubItem(row);
              }}
              tooltip="Add Sub Option"
            />
          )}
          <GridActionButton
            type={"edit"}
            onClick={() => {
              handleEdit(row, true);
            }}
            tooltip={"Edit"}
          />
          <GridActionButton
            type="delete"
            onClick={() => {
              handleDelete(row);
            }}
            tooltip="Delete"
          />
        </Stack>
      </div>
    ),
  },
];

export type termsParams = {
  id: string;
  type: string;
};

const TermsAndConditionsTab = (props: ITermsAndConditionsTabProps) => {
  const { log } = useLog();
  let params = useParams<termsParams>();
  const type = params?.type;
  const validate: any = (fieldValues: ITermsAndConditionsOptions = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { execute, data, setData, isLoading } = useAsyncQuery<
    ITermsAndConditionsOptions[]
  >(termsAndConditionsOptionsService.getAll);

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ITermsAndConditionsOptions>(initialValues, false, validate);

  const { readOnly } = usePermissions(PermissionTypes.Catalogs);
  const [popUpTab, setPopUpTab] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await execute(Number(props.tabId));
    };
    if (props.isNew) setData([]);
    else getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabId]);

  useEffect(() => {
    updatOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const viewMode = () => {
    return type === "View";
  };

  const handleSwicthChange = (e: any) => {
    values.isRequired = !values.isRequired;
    setValues({ ...values });
  };

  const handleEdit = (rowItem: any, edit: boolean) => {
    if (!edit) {
      const defaultItem: ITermsAndConditionsOptions = {
        id: 0,
        termsAndConditionsTabsId: 0,
        isRequired: true,
        description: "",
        descriptionHTML: "",
        descriptionNew: "",
        subItems: [],
        optionType: OptionType.CheckBox,
      };
      setValues({ ...defaultItem });
    } else {
      setValues({ ...rowItem });
    }
    setIsEdit(edit);
    setPopUpTab(true);
  };

  const handleAddTab = () => {
    if (
      values.optionType === OptionType.CheckBox ||
      values.optionType === OptionType.RadioButton
    ) {
      values.description = values.descriptionNew;
      values.descriptionHTML = draftToHtmlService.convertToHTML(
        values.description
      );
    }

    values.id = data.length === 0 ? 1 : data[data.length - 1].id + 1;
    values.parentId = parentId;
    if (parentId === 0) {
      setData((data) => [...data, values]);
    } else {
      const parent = data.find((element) => {
        return element.id === parentId;
      });
      parent?.subItems?.push(values);
      if (parent) setValues({ ...parent });
    }
    setPopUpTab(false);
    updatOptions();
  };

  const updatOptions = () => {
    props.setOptions(data, props.tabId);
    setRefreshGrid(!refreshGrid);
  };

  const handleEditTab = (olddata: any) => {
    if (
      values.optionType === OptionType.CheckBox ||
      values.optionType === OptionType.RadioButton
    ) {
      values.description = values.descriptionNew;
      values.descriptionHTML = draftToHtmlService.convertToHTML(
        values.description
      );
    }
    setValues({ ...values });
    if (!values.parentId) {
      const index = olddata.findIndex((emp: any) => emp.id === values.id);
      olddata[index] = values;
    } else {
      const index = olddata.findIndex((emp: any) => emp.id === values.parentId);
      const subIndex = olddata[index].subItems?.findIndex(
        (emp: any) => emp.id === values.id
      );
      if (olddata[index].subItems !== undefined)
        olddata[index].subItems[subIndex ?? 0] = values;
    }
    setData([...olddata]);
    setPopUpTab(false);
  };

  const handleUpdateTextEditor = (e: any) => {
    values.descriptionNew = e.target.value;
    setValues({ ...values });
  };

  const handleAddSubItem = (e: any) => {
    setParentId(e.id);
    const defaultItem: ITermsAndConditionsOptions = {
      id: 0,
      termsAndConditionsTabsId: 0,
      isRequired: true,
      description: "",
      descriptionHTML: "",
      descriptionNew: "",
      subItems: [],
    };
    handleEdit(defaultItem, false);
  };

  const handleEditParentItem = (e: any, isEditItem: boolean) => {
    setParentId(0);
    handleEdit(e, isEditItem);
  };

  const handleDelete = (e: ITermsAndConditionsOptions) => {
    if (e.parentId !== 0 && e.parentId !== null) {
      data.forEach(function (item) {
        item.subItems?.forEach(function (subItem) {
          if (subItem === e) {
            item.subItems?.splice(item.subItems?.indexOf(subItem), 1);
          }
        });
      });
      setData({ ...data });
    } else {
      let filter = Object.values(data).filter((item: any) => item !== e);
      setData(filter);
    }
    log.success("Option was deleted");
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={2}>
          {!viewMode() && (
            <FormButton
              text={"Create New Option"}
              onClick={() => {
                setParentId(0);
                handleEdit(initialValues, false);
              }}
              startIcon={<AddIcon />}
              size="small"
              color="success"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <LocalEnhancedTable<ITermsAndConditionsOptions>
            refreshGrid={refreshGrid}
            columns={columnsOptions(
              handleEditParentItem,
              handleAddSubItem,
              handleDelete,
              viewMode()
            )}
            data={Object.values(data ?? [])}
            showSkeleton={isLoading}
            subItems={"subItems"}
          />
        </Grid>
      </Grid>
      <Popup
        title={"Options"}
        openPopup={popUpTab}
        setOpenPopup={setPopUpTab}
        onClose={() => {
          setPopUpTab(false);
        }}
        size={"md"}
      >
        <Stack>
          <Grid container>
            <Grid item xs={4} pb={3}>
              <FormSelect
                name={"optionType"}
                label={"Type"}
                value={values.optionType}
                onChange={handleInputChange}
                options={optionType}
              ></FormSelect>
            </Grid>
            <Grid item xs={8} pb={3}></Grid>
            {(values.optionType === OptionType.CheckBox ||
              values.optionType === OptionType.RadioButton) && (
              <Grid item xs={12} pb={1}>
                <FormTextEditor
                  value={values.description}
                  title={"Description"}
                  onChange={handleUpdateTextEditor}
                  name={"description"}
                  disable={readOnly}
                ></FormTextEditor>
              </Grid>
            )}
            {(values.optionType === OptionType.TextArea ||
              values.optionType === OptionType.TextBox) && (
              <>
                <Grid item xs={12} pb={3}>
                  <FormText
                    name={"labelText"}
                    label={"Label Text"}
                    value={values.labelText}
                    onChange={handleInputChange}
                  ></FormText>
                </Grid>
                <Grid item xs={12} pb={1}>
                  <FormTextArea
                    name={"placeHolder"}
                    label={"Place Holder"}
                    value={values.placeHolder}
                    onChange={handleInputChange}
                  ></FormTextArea>
                </Grid>
              </>
            )}
            <Grid item pt={2} xs={2}>
              <Typography>Is Required</Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                name="isRequired"
                checked={values.isRequired}
                onChange={handleSwicthChange}
                value={true}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setPopUpTab(false);
            }}
            isSubmitting={false}
          />
          <FormAcceptButton
            onClick={() => {
              isEdit ? handleEditTab(data) : handleAddTab();
            }}
            isSubmitting={false}
          />
        </Stack>
      </Popup>
    </>
  );
};

export default TermsAndConditionsTab;
