import { useEffect, useState } from "react";
import {
  IPostShareDocumentRequest,
  IProjectInfectiousComplianceControl,
  IProjectReporDisplayt,
  IShareStudiesEmail,
  TableData,
} from "src/ts/interfaces";
import {
  Grid,
  InputAdornment,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { FormButton, FormText } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import CloseIcon from "@mui/icons-material/Close";
import Study from "./Study";
import {
  useAppDispatch,
  useAuth,
  useListSelection,
  useLog,
  usePermissions,
} from "src/hooks";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectService from "src/services/project/projectService";
import { DuplicateDeleteStudy } from "src/ts/interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SelectDuplicateQuantityPopup from "./SelectDuplicateQuantityPopup";
import projectDuplicateService from "src/services/project/projectDuplicateService";
import { Stack } from "@mui/system";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import StudiesGrid from "./StudiesGrid";
import TableViewIcon from "@mui/icons-material/TableView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { Permission as PermissionTypes } from "src/ts/enums";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { pdfExportService } from "src/services";
import FileSaver from "file-saver";
import ProgressBarModal from "src/components/modals/ProgressBarModal";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import sharedDocumentServices from "src/services/sharedDocumentServices";
import floatingBarPdfGeneration from "src/redux/slices/flotatingBarPdfGeneration";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import projectReportService from "src/services/study/projectReportService";

interface Props {
  projectId: number;
  project: ProjectDTO;
  refreshStudies: boolean;
  setRefreshStudies: (val: boolean) => void;
  onSelectStudy: (study: IProjectReporDisplayt) => void;
  isPartOfProject: boolean;
  projectInfectiousControl: IProjectInfectiousComplianceControl;
  studies: IProjectReporDisplayt[];
  setShowProjectCompletionFee: (value: boolean) => void;
  isLoading: boolean;
  isEnergy?: boolean;
  isEnergyComplete?: boolean;
}

interface Search {
  search: string;
}

const initialValues: Search = {
  search: "",
};

const StudiesList = ({
  projectId,
  refreshStudies,
  setRefreshStudies,
  onSelectStudy,
  project,
  isPartOfProject,
  projectInfectiousControl,
  studies,
  setShowProjectCompletionFee,
  isLoading,
  isEnergy,
}: Props) => {
  //const [studies, setStudies] = useState<IProjectReporDisplayt[]>([]);
  // const [items, setItems] = useState<number[]>([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showRecreatePopup, setShowRecreatePopup] = useState(false);
  const [showProgressBarModal, setProgressBarModal] = useState(false);
  const {
    internalData,
    toggleSelection,
    isSelected,
    selectedItem,
    totalSelected,
    clearSelection,
    setData,
    isAllSelected,
    toggleSelectionAll,
    setSelected,
    selectedIds,
  } = useListSelection<IProjectReporDisplayt>([], []);

  const { user } = useAuth();
  const [gridView, setGridView] = useState(true);
  const { log } = useLog();
  const dispatch = useAppDispatch();
  const {
    values: filterValues,
    setValues: setFilterValues,
    handleInputChange: handleFilterChange,
  } = useFormTyped<Search>(initialValues, true, []);

  useEffect(() => {
    if (isAllSelected) log.info(`${studies.length} studies selected`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllSelected]);

  useEffect(() => {
    if (filterValues.search !== "") {
      const filteredStudies = studies.filter(
        (study) =>
          study.system
            .toLowerCase()
            .includes(filterValues.search.toLowerCase()) ||
          study.projectCode
            .toLowerCase()
            .includes(filterValues.search.toLowerCase()) ||
          study.reportTypeName
            .toLowerCase()
            .includes(filterValues.search.toLowerCase())
      );

      setData([...filteredStudies], []);

      //for sort comming soon
      //setItems(filteredStudies.map((studies) => studies.subStudyOrder));
    } else {
      setData([...studies], []);
      //for sort comming soon
      //setItems(studies.map((studies) => studies.subStudyOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.search]);

  // target id will only be set if dragging from one dropzone to another.
  // function onChange(
  //   sourceId: string,
  //   sourceIndex: number,
  //   targetIndex: number,
  //   targetId?: string | undefined
  // ) {
  //   const nextState = swap(items, sourceIndex, targetIndex);
  //   console.log(nextState);

  //   console.log(studies[sourceIndex]);
  //   console.log(studies[targetIndex]);

  //   const newOrder = nextState
  //     .map((position, index): IProjectReporDisplayt => {
  //       return { ...studies[index], subStudyOrder: position };
  //     })
  //     .sort((a: IProjectReporDisplayt, b: IProjectReporDisplayt) =>
  //       a.subStudyOrder > b.subStudyOrder ? 1 : -1
  //     );

  //   newOrder.map((item) =>
  //     console.log(`${item.reportTypeCode} - ${item.subStudyOrder} `)
  //   );

  //   //setItems(nextState);
  //   setItems(newOrder.map((studies) => studies.subStudyOrder));
  //   setStudies([...newOrder]);
  // }
  ////////////////////////////////////////////////////////////////////////////////
  /*const getData = async () => {
    const res = await projectReportService.getStudiesByProject(projectId);

    setStudies(res.data);
    setData([...res.data], []);
    //setItems(res.data.map((studies) => studies.subStudyOrder));
  };

  useEffect(() => {
    if (projectId > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refreshStudies]);*/
  /////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (studies) setData([...studies], []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies]);

  const handleClearSearch = () => {
    setFilterValues({ ...filterValues, search: "" });
    //setData([...studies], []);
  };

  useEffect(() => {
    if (project) setGridView(project.gridView);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItem.length > 0) {
      setDisableDelete(false);
      const anyCompleteInUseOrNoCanBeDeleted = selectedItem.some(
        (item) => item.isComplete || item.isInUse || !item.canBeDelete
      );
      setDisableDelete(anyCompleteInUseOrNoCanBeDeleted);
    }
  }, [selectedItem]);

  const showDeleteStudiesHandler = () => {
    setShowDeletePopup(true);
  };

  const deleteStudiesHandler = async () => {
    setIsSubmitting(true);
    const studyForDelete = selectedItem.map((study) => {
      const deleteStudy: DuplicateDeleteStudy = {
        ...study,
        isSelected: true,
        quantity: 0,
      };

      return deleteStudy;
    });

    await projectService.deleteStudies(studyForDelete);
    setIsSubmitting(false);
    setRefreshStudies(!refreshStudies);
    clearSelection();
  };

  const showDuplicateStudiesHandler = () => {
    setShowDuplicatePopup(true);
  };

  const showRecreateStudiesHandler = () => {
    setShowRecreatePopup(true);
  };

  const duplicateStudiesHandler = async (
    quantity: number,
    includeActuals: boolean
  ) => {
    const studyForDuplicate = selectedItem.map((study) => {
      const deleteStudy: DuplicateDeleteStudy = {
        ...study,
        isSelected: true,
        quantity: 0,
      };

      return deleteStudy;
    });
    setIsSubmitting(true);
    await projectDuplicateService.duplicateStudies(
      quantity,
      includeActuals,
      studyForDuplicate
    );
    setIsSubmitting(false);
    setRefreshStudies(!refreshStudies);
    setShowDuplicatePopup(false);
    clearSelection();
  };

  const recreateStudiesHandler = async () => {
    const selectedStudies = studies
      .filter((study) => selectedIds?.includes(study.id))
      .map((study) => {
        let selectedStudy: IProjectReporDisplayt = {
          id: study.id,
          reportId: study.reportId,
          reportTypeId: study.reportTypeId,
          system: study.system,
          reportCategoryTypeId: 0,
          projectCode: "",
          studyStatus: "",
          studyStatusId: null,
          reportTypeCode: study.reportTypeCode,
          reportTypeRoute: "",
          reportTypeRouteComplete: "",
          reportTypeName: "",
          reportTypeColor: "",
          reportTypeIcon: "",
          parentReportId: null,
          parentReportTypeId: null,
          isComplete: false,
          completedDate: null,
          haveSubStudies: false,
          countSubreport: 0,
          reportLabel: "",
          subStudyOrder: 0,
          infectiousControlData: null,
          dateModified: null,
          countReport: 0,
          canCompleteProject: false,
          isInUse: false,
          canBeDelete: false,
          itemsThatNeedAttentionCount: 0,
          actualEvaluationStatus: "",
          actualEvaluationStatusColor: "",
          updatedBy: "",
          required: study.required,
        };

        return selectedStudy;
      });

    await projectService.deleteRecreateStudies(projectId, selectedStudies);

    log.success(
      "The pdf report(s) selected and summary have been successfully deleted"
    );

    setRefreshStudies(!refreshStudies);
    setShowRecreatePopup(false);
    clearSelection();
  };

  const [toggleAll, setToggleAll] = useState<boolean | null>(null);

  const selectAllHandler = () => {
    toggleSelectionAll();
    setToggleAll(!toggleAll);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItemGrid, setSelectedItemsGrid] = useState<number[] | null>(
    null
  );

  const [disableDelete, setDisableDelete] = useState(false);

  const onSelectHandler = (selected: number[]) => {
    setSelectedItemsGrid(selected);
    setSelected(selected);
  };

  const toggleViewHandler = async () => {
    try {
      setToggleAll(null);
      const newGridValue = !gridView;
      setGridView(!gridView);

      project.gridView = newGridValue;
      await projectService.updateTableViewPreference(projectId, project);
      clearSelection();
      log.success("Grid view preference was saved");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const changeGridOrderHandler = (studies: IProjectReporDisplayt[]) => {
    //setStudies([...studies]);
    setRefreshStudies(!refreshStudies);
    setData([...studies], []);
  };

  const { fullAccess: projectAdminPermission } = usePermissions(
    PermissionTypes.Project_Admin
  );
  const { fullAccess: projectPermission } = usePermissions(
    PermissionTypes.Project
  );

  const showDeleteStudtButton =
    (projectAdminPermission || projectPermission) &&
    (project?.projectStatus?.name === "New" ||
      project?.projectStatus?.name === "Working" ||
      project?.projectStatus?.name === "Removed" ||
      project?.projectStatus?.name === "Incomplete" ||
      project?.projectStatus?.name === "New contest") &&
    !isEnergy;

  const disableDuplicateButton =
    totalSelected < 1 ||
    project?.projectStatus?.name === "Complete" ||
    project?.projectStatus?.name === "Paid" ||
    project?.projectStatus?.name === "Contest" ||
    project?.projectStatus?.name === "In Review" ||
    projectInfectiousControl?.projectStatusId === 11 ||
    !isPartOfProject;

  const showDownloadButton =
    project?.projectStatus?.name !== "New" &&
    project?.projectStatus?.name !== "Working" &&
    project?.projectStatus?.name !== "Removed" &&
    project?.projectStatus?.name !== "Incomplete" &&
    project?.projectStatus?.name !== "New contest";

  const noSelectedItem = totalSelected < 1;

  const isCompanyAdmin =
    user?.role === "CompanyOwner" ||
    user?.role === "Administrator" ||
    user?.role === "ProjectManager";

  const isSuperAdmin = user?.role === "SuperAdministrator";
  const showDeleteAndRecreate = isSuperAdmin || isCompanyAdmin;

  const [progress, setProgress] = useState(0);
  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };

  const downloadStudiesHandler = async () => {
    if (
      (project.isPaid == null || project.isPaid <= 0) &&
      project.isInfectiousControlProject === false
    ) {
      setShowProjectCompletionFee(true);
    } else {
      //setProgressBarModal(true);
      //setProgress(0);
      dispatch(floatingBarPdfGeneration.actions.setOpen(true));
      const selectedStudies = studies.map((study) => {
        let selectedStudy: DuplicateDeleteStudy = {
          isSelected: selectedIds?.includes(study.id) ?? false,
          id: study.id,
          reportId: study.reportId,
          reportTypeId: study.reportTypeId,
          quantity: 0,
          system: study.system,
        };

        return selectedStudy;
      });

      const response = await pdfExportService.getMassiveProjectReportPDFs(
        projectId,
        selectedStudies,
        onProgressDownload
      );

      FileSaver.saveAs(response.data, "ProjectReports.zip");
      setProgressBarModal(false);
    }
  };

  const [showShareDocumentsByEmailPopup, setShareDocumentsByEmailPopup] =
    useState(false);
  const shareDocumentByEmailHandler = () => {
    setShareDocumentsByEmailPopup(true);
  };

  const handleOnDragEnd = async (result: any) => {
    let newList = Array.from(internalData);
    const [draggedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, draggedItem);
    newList.forEach((element, key) => (element.subStudyOrder = key));
    setData([...newList], []);
    await projectReportService.updateOrder(newList);
  };

  const handleSendEmail = async (dataTable: TableData[]) => {
    try {
      const selectedStudies = studies
        .filter((study) => selectedIds?.includes(study.id))
        .map((study) => {
          let selectedStudy: DuplicateDeleteStudy = {
            isSelected: selectedIds?.includes(study.id) ?? false,
            id: study.id,
            reportId: study.reportId,
            reportTypeId: study.reportTypeId,
            quantity: 0,
            system: study.system,
          };

          return selectedStudy;
        });

      const emails = dataTable.map((item) => {
        let newItem: IShareStudiesEmail = {
          id: item.id ?? 0,
          name: item.name ?? "",
          lastName: item?.userType === "email" ? "" : item.lastName ?? "",
          email: item.email ?? "",
          type:
            item?.userType === "TeamMember"
              ? "team"
              : item?.userType === "LicenseedUser"
              ? "user"
              : "email",
        };

        return newItem;
      });

      const transaction: IPostShareDocumentRequest = {
        projectId: projectId,
        ReportRequests: selectedStudies,
        emails: emails,
      };

      await sharedDocumentServices.share(transaction);
      log.success(
        "The files were successfully sent to the chosen email address(es)"
      );
      setRefreshStudies(!refreshStudies);
      clearSelection();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      {isLoading ? (
        <Grid container>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
          <Grid item xs={6} pl={5}>
            <Skeleton height={150} />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {studies && studies?.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormText
                    name="search"
                    label="Search"
                    value={filterValues.search}
                    onChange={handleFilterChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Clear"
                          onClick={handleClearSearch}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction="row"
                    spacing={3}
                    justifyContent="flex-start"
                  >
                    <FormButton
                      size="small"
                      text={isAllSelected ? "Unselect All" : "Select All"}
                      startIcon={
                        isAllSelected ? <RemoveDoneIcon /> : <DoneAllIcon />
                      }
                      onClick={selectAllHandler}
                      // disabled={isAllSelected}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={3} justifyContent="flex-end">
                    <FormButton
                      size="small"
                      text={gridView ? "List View" : "Grid View"}
                      startIcon={
                        gridView ? <ViewListIcon /> : <TableViewIcon />
                      }
                      onClick={toggleViewHandler}
                      variant="outlined"
                    />
                    {showDeleteStudtButton && (
                      <>
                        <FormButton
                          size="small"
                          text={"Delete"}
                          startIcon={<DeleteIcon />}
                          onClick={showDeleteStudiesHandler}
                          disabled={
                            disableDelete ||
                            totalSelected < 1 ||
                            !isPartOfProject
                          }
                          variant="outlined"
                        />
                        <FormButton
                          size="small"
                          text={"Duplicate"}
                          startIcon={<ContentCopyIcon />}
                          onClick={showDuplicateStudiesHandler}
                          disabled={disableDuplicateButton}
                          variant="outlined"
                        />
                      </>
                    )}
                    {showDownloadButton && (
                      <>
                        <FormButton
                          size="small"
                          text={"Download"}
                          startIcon={<LocalPrintshopIcon />}
                          onClick={downloadStudiesHandler}
                          disabled={noSelectedItem}
                          variant="outlined"
                          tooltip="Download selected items below"
                        />
                        <FormButton
                          size="small"
                          text={"Email"}
                          startIcon={<LocalPrintshopIcon />}
                          onClick={shareDocumentByEmailHandler}
                          disabled={noSelectedItem}
                          variant="outlined"
                          tooltip="Email selected items below"
                        />
                      </>
                    )}
                    {showDeleteAndRecreate && (
                      <FormButton
                        size="small"
                        text={"Delete and recreate PDF files"}
                        startIcon={<LocalPrintshopIcon />}
                        onClick={showRecreateStudiesHandler}
                        disabled={noSelectedItem}
                        variant="outlined"
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          )}
          {studies && studies?.length > 0 && (
            <>
              {gridView ? (
                <StudiesGrid
                  data={studies}
                  search={filterValues.search}
                  toggleAll={toggleAll}
                  onSelectHandler={onSelectHandler}
                  onGridChange={changeGridOrderHandler}
                  project={project}
                  setProgressBarModal={setProgressBarModal}
                  setShowProjectCompletionFee={setShowProjectCompletionFee}
                  onSelectStudy={onSelectStudy}
                  isEnergy={isEnergy}
                />
              ) : (
                <Grid item xs={12}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="studies">
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {internalData
                            ?.sort((a, b) =>
                              a.subStudyOrder > b.subStudyOrder ? 1 : -1
                            )
                            ?.map((item, index) => (
                              <Draggable
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <Grid
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    item
                                    xs={12}
                                    md={6}
                                    key={item.id}
                                  >
                                    <Study
                                      study={item}
                                      onToggleSelection={toggleSelection}
                                      isSelected={isSelected}
                                      onSelectStudy={onSelectStudy}
                                      isSelectable={true}
                                      isEnergy={isEnergy}
                                    />
                                  </Grid>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>
              )}
            </>
          )}

          {/*TODO: Drag and drop 
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={2}
            rowHeight={180}
            style={{ height: "800px" }}
          >
            {items.map((item, index) => (
              // <GridItem key={item}>
              <Grid item xs={6}>
                <Task study={studies[item]} />
              </Grid>
              // </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider> */}
        </Grid>
      )}

      <DialogMessagePopup
        title="Warning"
        text="Are you certain you want to delete the selected reports?"
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        onSave={deleteStudiesHandler}
        isSubmitting={false}
      />
      <SelectDuplicateQuantityPopup
        isDialogOpen={showDuplicatePopup}
        setIsDialogOpen={setShowDuplicatePopup}
        onSave={duplicateStudiesHandler}
        isSubmitting={isSubmitting}
      />
      <DialogMessagePopup
        title="Information"
        text={
          <>
            <Typography>
              Are you certain you want delete selected study(ies) PDF file(s)?
            </Typography>
            <Typography>
              With this action, the PDF document(s) will created again when
              downloaded next time.
            </Typography>
          </>
        }
        showPopup={showRecreatePopup}
        setShowPopup={setShowRecreatePopup}
        onSave={recreateStudiesHandler}
        isSubmitting={false}
      />
      <ProgressBarModal
        progress={progress}
        isDialogOpen={showProgressBarModal}
        setIsDialogOpen={setProgressBarModal}
        text={"Download zip file"}
      />

      <ShareByEmailPopUpComponent
        sendbyEmailShow={showShareDocumentsByEmailPopup}
        setSendbyEmailShow={setShareDocumentsByEmailPopup}
        sendEmailFunction={handleSendEmail}
        sendToExternal={true}
        hideLogSuccess={true}
      />
    </>
  );
};

export default StudiesList;
