import {
  Checkbox as CheckboxMui,
  MenuItem,
  Radio as RadioMui,
  Select as SelectMui,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { IKeyValue } from "src/ts/interfaces";
import SpreadSheetInput from "./SpreadSheetInput";
import SpreadSheetCell from "./SpreadSheetCell";
import SpreadSheetRow from "./SpreadSheetRow";
import SpreadsheetNumericInput2 from "./SpreadsheetNumericInput2";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types/common";

const Select = styled(SelectMui)`
  margin: -2;
`;

const Checkbox = styled(CheckboxMui)`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
`;

const Radio = styled(RadioMui)`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
`;

const padding = "2px";

export interface ISpreadsheetColumn {
  name: string;
  label?: React.ReactNode;
  type?:
    | "text"
    | "string"
    | "index"
    | "radioButton"
    | "custom"
    | "checkBox"
    | "select"
    | "numericInput"
    | "numeric";
  onChange?: (row: any, e: any, index: number) => void;
  render?: (row: any, e: any, index: number) => any;
  items?: IKeyValue<any, any>[];
  width?: string;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  borderColor?: string;
  color?: string;
  hide?: boolean;
  showTotals?: boolean;
  decimalScale?: number;
  maxValue?: number;
  thousandSeparator?: boolean;
  disabled?: boolean;
  mode?: Mode;
  colorMode?: string;
  placeholder?: string;
  minWidth?: string;
  textFooter?: string;
  emptyFieldAccept?: boolean;
}

interface TextInputCellProp<T extends { id: number }> {
  item: T;
  name: string;
  value: string;
  onUpdate: any;
  borderColor?: string;
  onChange?: (row: any, e: any, index: number) => void;
  index: number;
  disable?: boolean;
  mode?: Mode;
  placeholder?: string;
}

const TextInputCell = React.memo(
  <T extends { id: number }>({
    item,
    name,
    value,
    onUpdate,
    borderColor,
    onChange,
    index,
    disable,
    mode,
    placeholder,
  }: TextInputCellProp<T>) => {
    const valueRef = React.useRef<string>();
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      var input = inputRef.current;
      if (input) {
        input.value = value ?? "";
      }
    }, [value]);

    const handleFocus = () => {
      var input = inputRef.current;
      if (input) {
        valueRef.current = input.value;
      }
    };

    const handleBlur = () => {
      if (onUpdate) {
        var input = inputRef.current;
        if (input && input.value !== valueRef.current) {
          onUpdate({ ...item, [name]: input.value });
          onChange?.(
            { ...item, [name]: input.value },
            { name: name, value: input.value },
            index
          );
        }
      }
    };

    if (mode && mode === "read")
      return (
        <Typography
          style={{
            width: "100%",

            paddingLeft: padding,
            paddingRight: padding,
          }}
          variant="body1"
        >
          {value}
        </Typography>
      );

    return (
      <>
        <SpreadSheetInput
          name={name}
          inputRef={inputRef}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          borderColor={borderColor}
          disabled={disable}
          placeholder={placeholder}
        />
      </>
    );
  }
);

interface NumericInputCellProp<T extends { id: number }> {
  item: T;
  name: string;
  value: string;
  onUpdate: any;
  borderColor?: string;
  onChange?: (row: any, e: any, index: number) => void;
  index: number;
  decimalScale?: number;
  maxValue?: number;
  disabled?: boolean;
  mode?: Mode;
  thousandSeparator?: boolean;
  emptyFieldAccept?: boolean;
}

const NumericInputCell = React.memo(
  <T extends { id: number }>({
    item,
    name,
    value,
    onUpdate,
    borderColor,
    onChange,
    index,
    decimalScale,
    maxValue,
    disabled,
    mode,
    thousandSeparator,
    emptyFieldAccept,
  }: NumericInputCellProp<T>) => {
    const handleChange = (e: any) => {
      if (onChange !== undefined && onChange !== null) {
        onChange?.(item, e, index);
      } else {
        const value =
          e.target.value === "" && emptyFieldAccept
            ? null
            : parseFloat(e.target.value);
        onUpdate({ ...item, [name]: value });
      }
    };

    return (
      <SpreadsheetNumericInput2
        value={value}
        onChange={handleChange}
        name={name}
        decimalScale={decimalScale}
        maxValue={maxValue}
        disabled={disabled}
        mode={mode}
        thousandSeparator={thousandSeparator}
        emptyFieldAccept={emptyFieldAccept}
      />
    );
  }
);

interface RadioButtonCellProp<T extends { id: number }> {
  item: T;
  name: string;
  value: boolean;
  onUpdate: any;
  index: number;
  onChange?: (row: any, e: any, index: number) => void;
  disabled?: boolean;
  mode?: Mode;
}

const RadioButtonCell = React.memo(
  <T extends { id: number }>({
    item,
    name,
    value,
    onUpdate,
    onChange,
    index,
    disabled,
    mode,
  }: RadioButtonCellProp<T>) => {
    const onUpdateName = (ev: any) => {
      if (onChange !== undefined && onChange !== null) {
        onChange?.(item, ev, index);
      } else {
        onUpdate({ ...item, [ev.target.name]: ev.target.value });
      }
    };

    if (mode && mode === "read") {
      return (
        <Typography
          style={{
            width: "100%",

            paddingLeft: padding,
            paddingRight: padding,
          }}
          variant="body1"
        >
          {value ? "Yes" : "No"}
        </Typography>
      );
    }

    return (
      <>
        <Radio
          checked={value}
          onChange={onUpdateName}
          name={name}
          inputProps={{ "aria-label": "A" }}
          disabled={disabled}
        />
      </>
    );
  }
);

const CheckBoxCell = React.memo(
  <T extends { id: number }>({
    item,
    name,
    value,
    onUpdate,
    onChange,
    index,
    disabled,
    mode,
  }: RadioButtonCellProp<T>) => {
    const onUpdateName = async (ev: any) => {
      onUpdate({ ...item, [ev.target.name]: ev.target.value });
      await onChange?.(item, ev, index);
    };

    const convertToDefEventPara = (name: string, value: boolean) => {
      return {
        target: {
          name,
          value,
        },
      };
    };

    if (mode && mode === "read")
      return (
        <Typography
          style={{
            width: "100%",

            paddingLeft: padding,
            paddingRight: padding,
          }}
          variant="body1"
        >
          {value ? "Yes" : "No"}
        </Typography>
      );

    return (
      <>
        <Checkbox
          checked={value}
          onChange={(e: any) => {
            onUpdateName(convertToDefEventPara(name, e.target.checked));
          }}
          name={name}
          disabled={disabled}
          style={{
            paddingLeft: padding,
            paddingRight: padding,
          }}
        />
      </>
    );
  }
);

interface SelectCellProp<T extends { id: number }> {
  item: T;
  name: string;
  value: string;
  onUpdate: any;
  index: number;
  onChange?: (row: any, e: any, index: number) => void;
  options: IKeyValue<any, string>[];
  disabled?: boolean;
  mode?: Mode;
}

const SelectCell = React.memo(
  <T extends { id: number }>({
    item,
    name,
    value,
    onUpdate,
    onChange,
    index,
    options,
    disabled,
    mode,
  }: SelectCellProp<T>) => {
    const onUpdateName = (ev: any) => {
      if (onChange !== undefined && onChange !== null) {
        onChange?.(item, ev, index);
      } else {
        onUpdate({ ...item, [ev.target.name]: ev.target.value });
      }
    };

    if (mode && mode === "read") {
      return (
        <Typography
          style={{
            width: "100%",

            paddingLeft: padding,
            paddingRight: padding,
          }}
          variant="body1"
          noWrap
        >
          {/* TODO: fix for number and strings */}
          {value ? options[parseInt(value)].value : "-"}
        </Typography>
      );
    }
    return (
      <div
        style={{
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <Select
          // label={label}
          name={name}
          value={value}
          onChange={onUpdateName}
          size="small"
          disabled={disabled}
          fullWidth
          // notched={true}
        >
          {options?.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
);

interface MemoRowProp<T extends { id: number }> {
  item: T;
  onUpdate: any;
  cols: ISpreadsheetColumn[];
  indexRow: number;
  transposed?: boolean;
}
const MemoRow = React.memo(
  <T extends { id: number }>({
    item,
    onUpdate,
    cols,
    indexRow,
    transposed,
  }: MemoRowProp<T>) => {
    return (
      <SpreadSheetRow transposed={transposed}>
        {cols
          .filter((col) => col?.hide !== true)
          .map((col: ISpreadsheetColumn, index) => {
            return (
              <React.Fragment key={`${col.name}-${index}`}>
                {col.type === "string" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <Typography mt={0.8} paddingLeft={1.8} color={col.color}>
                      {(item[col.name as keyof typeof item] as string) ?? ""}
                    </Typography>
                  </SpreadSheetCell>
                )}
                {col.type === "numeric" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <Typography
                      mt={0.8}
                      paddingLeft={1.8}
                      sx={{ color: col.color }}
                    >
                      <NumericFormat
                        displayType="text"
                        decimalScale={col.decimalScale}
                        thousandSeparator={col.thousandSeparator}
                        value={
                          (item[col.name as keyof typeof item] as string) ?? ""
                        }
                      />
                    </Typography>
                  </SpreadSheetCell>
                )}
                {col.type === "text" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                    borderColor={col.borderColor}
                  >
                    <TextInputCell
                      name={col.name}
                      value={item[col.name as keyof typeof item] as string}
                      item={item}
                      onUpdate={onUpdate}
                      borderColor={col.borderColor}
                      onChange={col.onChange}
                      index={indexRow}
                      disable={col.disabled}
                      mode={col.mode}
                      placeholder={col.placeholder}
                    />
                  </SpreadSheetCell>
                )}
                {col.type === "numericInput" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                    borderColor={col.borderColor}
                  >
                    <NumericInputCell
                      name={col.name}
                      value={item[col.name as keyof typeof item] as string}
                      item={item}
                      onUpdate={onUpdate}
                      borderColor={col.borderColor}
                      onChange={col.onChange}
                      index={indexRow}
                      decimalScale={col.decimalScale}
                      maxValue={col.maxValue}
                      disabled={col.disabled}
                      mode={col.mode}
                      thousandSeparator={col.thousandSeparator}
                      //emptyFieldAccept={col.emptyFieldAccept}
                    />
                  </SpreadSheetCell>
                )}
                {col.type === "index" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <Typography mt={0.8} paddingLeft={1.8} color={col.color}>
                      {indexRow + 1}
                    </Typography>
                  </SpreadSheetCell>
                )}
                {col.type === "radioButton" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <RadioButtonCell
                      name={col.name}
                      value={item[col.name as keyof typeof item] as boolean}
                      item={item}
                      onUpdate={onUpdate}
                      onChange={col.onChange}
                      index={indexRow}
                      disabled={col.disabled}
                      mode={col.mode}
                    />
                  </SpreadSheetCell>
                )}
                {col.type === "custom" && (
                  <>
                    {col !== undefined &&
                      col?.render &&
                      col?.render(item, col, indexRow)}
                  </>
                )}
                {col.type === "checkBox" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <CheckBoxCell
                      name={col.name}
                      value={item[col.name as keyof typeof item] as boolean}
                      item={item}
                      onUpdate={onUpdate}
                      onChange={col.onChange}
                      index={indexRow}
                      disabled={col.disabled}
                      mode={col.mode}
                    />
                  </SpreadSheetCell>
                )}
                {col.type === "select" && (
                  <SpreadSheetCell
                    transposed={transposed}
                    width={col.width}
                    minWidth={col.minWidth}
                    align={col.align}
                  >
                    <SelectCell
                      name={col.name}
                      value={item[col.name as keyof typeof item] as string}
                      item={item}
                      onUpdate={onUpdate}
                      onChange={col.onChange}
                      index={indexRow}
                      options={col.items ?? []}
                      disabled={col.disabled}
                      mode={col.mode}
                    />
                  </SpreadSheetCell>
                )}
              </React.Fragment>
            );
          })}
      </SpreadSheetRow>
    );
  }
);

interface ListOfRowsProps<T extends { id: number }> {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  cols: ISpreadsheetColumn[];
  transposed?: boolean;
  totals: any;
  showRowTotals?: boolean;
  page: number;
  rowsPerPage: number;
  showSkeleton?: boolean;
  customHeaders?: JSX.Element;
}

const ListOfRows = <T extends { id: number }>({
  items,
  setItems,
  cols,
  transposed,
  totals,
  showRowTotals,
  page,
  rowsPerPage,
  showSkeleton,
  customHeaders,
}: ListOfRowsProps<T>) => {
  const onUpdate = React.useCallback(
    (item: T) => {
      setItems((prevThings: T[]) =>
        prevThings.map((t: T) => (t.id === item.id ? item : t))
      );
    },
    [setItems]
  );

  const skeletonRows = 10;
  const skeletonArray = Array(skeletonRows ?? 10).fill("");
  return (
    <Table>
      <TableBody>
        {customHeaders ? (
          customHeaders
        ) : (
          <SpreadSheetRow transposed={transposed}>
            {cols
              .filter((col) => col?.hide !== true)
              .map(({ name, label, color }, index) => (
                <SpreadSheetCell
                  key={`${name}-${index}`}
                  transposed={transposed}
                  minWidth={transposed ? 200 : undefined}
                >
                  <Typography
                    mt={0.8}
                    paddingLeft={1.8}
                    fontWeight="bold"
                    color={color}
                  >
                    {label}
                  </Typography>
                </SpreadSheetCell>
              ))}
          </SpreadSheetRow>
        )}

        {!showSkeleton &&
          items.map((item: T, index: number) => (
            <MemoRow
              key={item.id}
              item={item}
              onUpdate={onUpdate}
              cols={cols}
              indexRow={page * rowsPerPage + index}
              transposed={transposed}
            />
          ))}

        {showSkeleton &&
          skeletonArray.map((row, indexRow) => {
            return (
              <SpreadSheetRow key={indexRow} transposed={transposed}>
                {cols
                  .filter((col) => col?.hide !== true)
                  .map((col, index) => {
                    return (
                      <SpreadSheetCell
                        key={col.name + "_" + indexRow + "_" + index}
                        transposed={transposed}
                        minWidth={70}
                      >
                        <Skeleton height={25} />
                      </SpreadSheetCell>
                    );
                  })}
              </SpreadSheetRow>
            );
          })}

        {showRowTotals && (
          <SpreadSheetRow>
            {cols
              .filter((col) => col?.hide !== true)
              .map(
                (
                  {
                    name,
                    label,
                    showTotals,
                    thousandSeparator,
                    decimalScale,
                    type,
                    textFooter,
                  },
                  index
                ) => (
                  <SpreadSheetCell
                    key={`${name}-${index}`}
                    transposed={transposed}
                    width={transposed ? 200 : undefined}
                  >
                    <Typography
                      mt={0.8}
                      paddingLeft={1.8}
                      fontWeight="bold"
                      textAlign={"right"}
                      mr={1}
                    >
                      {showTotals ? (
                        typeof totals[name] === "string" ? (
                          totals[name]
                        ) : (
                          <NumericFormat
                            displayType="text"
                            value={totals[name]}
                            thousandSeparator={thousandSeparator}
                            decimalScale={decimalScale}
                            fixedDecimalScale={thousandSeparator}
                          />
                        )
                      ) : textFooter ? (
                        textFooter
                      ) : (
                        ""
                      )}
                      {}
                    </Typography>
                  </SpreadSheetCell>
                )
              )}
          </SpreadSheetRow>
        )}
      </TableBody>
    </Table>
  );
};

interface SpreadsheetProp<T extends { id: number }> {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  cols: ISpreadsheetColumn[];
  transposed?: boolean;
  hidePagination?: boolean;
  defaultRowPerPage?: number;
  rowsPerPageOptions?: number[];
  totals?: any;
  showRowTotals?: boolean;
  showSkeleton?: boolean;
  customHeaders?: JSX.Element;
}

const Spreadsheet = <T extends { id: number }>({
  items,
  setItems,
  cols,
  transposed,
  hidePagination,
  defaultRowPerPage,
  rowsPerPageOptions,
  totals,
  showRowTotals,
  showSkeleton,
  customHeaders,
}: SpreadsheetProp<T>) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowPerPage ?? 5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedProjects = useMemo(() => {
    return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [items, page, rowsPerPage]);

  useEffect(() => {
    if (paginatedProjects.length === 0) {
      setPage(0);
    }
  }, [paginatedProjects]);

  return (
    <>
      <TableContainer>
        <ListOfRows
          items={paginatedProjects}
          totals={totals}
          setItems={setItems}
          cols={cols}
          transposed={transposed}
          showRowTotals={showRowTotals}
          page={page}
          rowsPerPage={rowsPerPage}
          showSkeleton={showSkeleton}
          customHeaders={customHeaders}
        />
      </TableContainer>
      <TablePagination
        hidden={hidePagination}
        rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Spreadsheet;
