import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import {
  useForm,
  Form,
  useLog,
  useAuth,
  usePermissions,
  useAppSelector,
} from "src/hooks";
import {
  FormButton,
  FormCancelButton,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { CompanyService, buildingService } from "src/services";
import {
  IBuildingSelected,
  ICompany,
  IProjectBuilding,
} from "src/ts/interfaces";
import Popup from "src/components/Popup";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import BuildingProjectPopup from "./BuildingProjectPopup";
import { AddIcon } from "src/components/icons";
import HeaderPage from "src/components/page/HeaderPage";
import projectService from "src/services/project/projectService";
import { ROLES } from "src/constants";
import { ViewList } from "@mui/icons-material";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";
import SatellitePopup from "../company/components/SatellitePopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { Validator } from "src/ts/types/Validator";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
  deactivationReason: "",
};

const columns = (
  navigate: NavigateFunction,
  companyId: string,
  isActive: boolean,
  setCurrentRow: (e: IBuildingSelected) => void,
  setShowDisableModal: (e: boolean) => void,
  setDuplicateModal: (e: boolean) => void,
  handlerCheckBuilding: (e: IBuildingSelected) => void,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "serial",
    label: "Serial",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "address",
    label: "Address",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: isActive ? "310px" : "171px",
    callback: (row: IBuildingSelected) => {
      return (
        <div>
          {fullAccess && isActive && row.subBuilding && (
            <GridActionButton
              type="add"
              onClick={() => {
                navigate(`/app/BuildingParent/0/${companyId}/${row.id}`);
              }}
              tooltip="Add Sub Building"
            />
          )}
          <GridActionButton
            type="edit"
            onClick={() => {
              navigate(`/app/Building/${row?.id}/${companyId}`);
            }}
            tooltip="Edit"
          />
          {fullAccess && row.subBuilding && (
            <GridActionButton
              type={isActive ? "disable" : "enable"}
              onClick={() => {
                setCurrentRow(row);
                setShowDisableModal(true);
              }}
              tooltip={isActive ? "Disable" : "Enable"}
            />
          )}

          {fullAccess && isActive && (
            <GridActionButton
              type="copy"
              onClick={() => {
                setCurrentRow(row);
                setDuplicateModal(true);
              }}
              tooltip="Copy"
            />
          )}
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(`/app/BuildingPhoto/${row.id}/${companyId}`);
            }}
            tooltip="Building Picture"
          />
          {fullAccess && isActive && (
            <GridActionButton
              type="delete"
              onClick={() => {
                setCurrentRow(row);
                handlerCheckBuilding(row);
              }}
              tooltip="Delete"
            />
          )}
        </div>
      );
    },
  },
];

const Building = () => {
  const { user } = useAuth();
  let { companyId } = useParams<{ companyId: string }>();
  const { execute, data, isLoading } = useAsyncQuery<IBuildingSelected[]>(
    buildingService.getByCompany
  );
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentRow, setCurrentRow] = useState<IBuildingSelected>();
  const [refreshPage, setRefreshPage] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteBuildingModal, setDeleteBuildingModal] = useState(false);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [subBuildingDeletionModal, setSubBuildingDeletionModal] =
    useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Building);
  const { execute: executeCompany, data: dataCompany } =
    useAsyncQuery<ICompany>(CompanyService.getById);
  const signalRStatus = useAppSelector((state) => state.signalR);
  const [buildingProject, setBuildingProject] = useState<IProjectBuilding[]>(
    []
  );
  const validate = (fieldValues = filterValues) => {
    let temp: Record<string, string> = { ...errors };
    temp.deactivationReason = new Validator(filterValues, "deactivationReason")
      .isRequired("The reason is required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === filterValues)
      return Object.values(temp).every((x) => x === "");
  };

  const navigate = useNavigate();
  const { log } = useLog();
  const {
    values: filterValues,
    handleInputChange: handleFilterChange,
    errors,
    setErrors,
  } = useForm(initialValues, true, []);

  useEffect(() => {
    if (signalRStatus.building !== 0) {
      execute(companyId ?? "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.building]);

  useEffect(() => {
    const getData = async () => {
      execute(companyId ?? "");
      await executeCompany(companyId);
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, refreshPage]);

  const handleSaveDeactivate = async () => {
    if (!validate()) return;
    try {
      setIsSubmitting(true);
      await buildingService.changeStatus(
        currentRow?.id ?? 0,
        filterValues.deactivationReason,
        !isActive
      );
      if (currentRow !== undefined) {
        currentRow.isActive = !currentRow?.isActive;
        setCurrentRow(currentRow);
      }
      log.success(
        "The test was successfully " +
          (isActive === true ? "deactivated" : "activated")
      );
      setShowDisableModal(false);
    } catch (error: any) {
      setIsSubmitting(false);
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: boolean) => {
    setIsActive(newValue);
  };
  const handleDuplicateBuilding = async () => {
    try {
      if (currentRow != null) {
        setIsSubmitting(true);
        await buildingService.duplicate(currentRow);
        log.success("The building has been succesfully created");
        setDuplicateModal(false);
        setRefreshPage(!refreshPage);
      }
    } catch (error: any) {
      setIsSubmitting(false);
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubBuildingDeletion = () => {};

  const handlerCheckBuilding = async (currentData: IBuildingSelected) => {
    if (currentData !== undefined && currentData?.id > 0) {
      try {
        const response = await projectService.getProjectBuildingDelete(
          currentData?.id
        );
        if (response.data.length >= 1) {
          setBuildingProject(response.data);
          setDeleteBuildingModal(true);
        } else {
          if (currentData?.subBuilding?.length > 0) {
            setSubBuildingDeletionModal(true);
          } else {
            setDeleteModal(true);
          }
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await buildingService.remove(currentRow?.id);
        log.success("The item was deleted");
        setRefreshPage(!refreshPage);
        setDeleteModal(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  return (
    <>
      <HeaderPage
        title={"Building"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container spacing={2}>
            <Grid item>
              {fullAccess && (
                <FormButton
                  text={"Create New Building"}
                  onClick={() => {
                    navigate(`/app/Building/0/${companyId}`);
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  color="primary"
                />
              )}
            </Grid>
            {dataCompany?.headquarters &&
              (user?.role === ROLES.CompanyOwner ||
                user?.role === ROLES.SuperAdministrator ||
                user?.role === ROLES.Administrator ||
                user?.role === ROLES.Training) &&
              dataCompany?.satelliteCount !== undefined &&
              dataCompany?.satelliteCount > 0 && (
                <Grid item>
                  <FormButton
                    text={"View Satellites"}
                    onClick={showSatelliteHandler}
                    startIcon={<ViewList />}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              )}
          </Grid>
        }
        infoSection={
          !dataCompany?.headquarters && (
            <CompanyInformation company={dataCompany} />
          )
        }
        pageYoutube="Buildings"
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={isActive}
                onChange={handleChangeTab}
                aria-label="Buildings"
              >
                <Tab label="Active" id="0" value={true} />
                <Tab label="Inactive" id="1" value={false} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IBuildingSelected>
                refreshGrid={true}
                columns={columns(
                  navigate,
                  companyId ?? "0",
                  isActive,
                  setCurrentRow,
                  setShowDisableModal,
                  setDuplicateModal,
                  handlerCheckBuilding,
                  fullAccess
                )}
                data={Object.values(data ?? []).filter((x) => {
                  return x.isActive === isActive;
                })}
                query={filterValues.name}
                showSkeleton={isLoading}
                subItems={"subBuilding"}
                orderColumn="desc"
                defaultSortColumn="createdDate"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Popup
        title="Building"
        openPopup={showDisableModal}
        setOpenPopup={setShowDisableModal}
        onClose={() => {
          setShowDisableModal(false);
        }}
        size="sm"
      >
        <>
          <Stack mb={4}>
            <Typography>
              Reason for {isActive === true ? "deactivation" : "activation"}:
            </Typography>
          </Stack>
          <FormTextArea
            name="deactivationReason"
            label={
              "Reason(s) for changing this building status to " +
              (isActive === true ? "inactive" : "active") +
              ":"
            }
            value={filterValues.deactivationReason}
            onChange={handleFilterChange}
            placeholder={
              "Reason(s) for changing this building status to " +
              (isActive === true ? "inactive" : "active")
            }
            error={errors.deactivationReason}
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={() => {
              setShowDisableModal(false);
            }}
            isSubmitting={isSubmitting}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSaveDeactivate}
          >
            Accept
          </Button>
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Duplicate"}
        text={"Are you certain you want to duplicate this Building?"}
        showPopup={duplicateModal}
        setShowPopup={setDuplicateModal}
        onSave={() => {
          handleDuplicateBuilding();
        }}
        isSubmitting={false}
      />

      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete building: ${currentRow?.name}?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
      />
      <BuildingProjectPopup
        openPopup={deleteBuildingModal}
        setOpenPopup={setDeleteBuildingModal}
        data={buildingProject}
      ></BuildingProjectPopup>
      <DialogMessagePopup
        title={"Deletion error"}
        text={
          "Warning: You are trying to delete a building that contains Sub-Buildings. /n/n Delete all the Sub-Buildings first."
        }
        showPopup={subBuildingDeletionModal}
        setShowPopup={setSubBuildingDeletionModal}
        onSave={() => {
          handleSubBuildingDeletion();
        }}
        isSubmitting={false}
      />
      <SatellitePopup
        showSatellitePopup={showSatelliteModal}
        setShowSatellitePopup={setShowSatelliteModal}
        companyId={user?.companyId}
        companyName={dataCompany?.name}
        historyView="Buildings"
      />
    </>
  );
};

export default Building;
