import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { FormCancelButton } from "src/components/formControls";
import { THEMES } from "src/constants";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import contractorRatingService from "src/services/contractorRatingService";
import { currencyformatter } from "src/utils/format";
import { ITechnicianAutoRenewal } from "src/ts/interfaces/technician";
import technicianService from "src/services/technicianService";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function AutorenwevalCertification() {
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const { execute: executeLoad, data } = useAsyncQuery<ITechnicianAutoRenewal>(
    technicianService.GetAutoRenewalInformation
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "35%",
                    marginRight: "35%",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              <Typography variant="h3" textAlign={"center"}>
                Auto-renewal
              </Typography>
              <Grid container>
                {data?.saved && (
                  <Grid item xs={12}>
                    <Typography variant="h6" textAlign={"center"} pt={3}>
                      Link is not longer valid
                    </Typography>
                  </Grid>
                )}
                {data && !data?.saved && (
                  <Grid item xs={12}>
                    <Typography variant="h6" textAlign={"center"} pt={3}>
                      Auto-renewal was activated for {data?.name}'s{" "}
                      {data?.certificationType === "Auditor" ? (
                        <Typography color={"primary"} fontWeight={"bold"}>
                          Testing & Balacing Technician
                        </Typography>
                      ) : (
                        <Typography color={"green"} fontWeight={"bold"}>
                          Energy Auditor / Infectious Building Control
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign={"center"} p={5}>
                  <FormCancelButton
                    onClick={() => {
                      navigate("/auth/sign-in");
                    }}
                    isSubmitting={false}
                    text="Go to AMP"
                  ></FormCancelButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
}
export default AutorenwevalCertification;
