/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from "@mui/material";
//Dialog
import Button from "@mui/material/Button";
//Radiogroup
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  FormButton,
  FormCheckBox,
  FormNumeric2,
} from "src/components/formControls";
import { getDescription } from "../helpers/helpers";
import { NumericFormat } from "react-number-format";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { GridActionButton } from "src/components/gridControls";
import AddIcon from "@mui/icons-material/Add";
import { FanWarning } from "./FanWarning";
import { MaDamperWarning } from "./MaDamperWarning";
import { SelectComponent } from "./SelectComponent";
import Popup from "src/components/Popup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IFormulaSystemService,
  IStaticIdentDTO,
  IStaticReportDTO,
  IStaticRowDTO,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import { useTheme } from "@mui/system";
import staticReportService from "src/services/study/staticReportService";

interface IStaticComponentsGridProps {
  staticReportId: number;
  values: IStaticReportDTO | undefined;
  setValues:
    | Dispatch<SetStateAction<IStaticReportDTO>>
    | Dispatch<SetStateAction<IStaticReportDTO | undefined>>;
  disableStudy: boolean;
  formulas: IFormulaSystemService | undefined;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
  execute: (...params: any[]) => Promise<void>;
  isEnergyProcess?: boolean;
  setGridLenght: (val: number) => void;
}

export const StaticComponentsGrid = (props: IStaticComponentsGridProps) => {
  const [staticRow, setStaticRow] = useState<IStaticRowDTO | null>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openComponentDialog, setOpenComponentDialog] = useState(false);
  const [currentIdentId, setCurrentIdentId] = useState(0);
  const [openFanWarningModal, setOpenFanWarningModal] = useState(false);
  const [selectedUpDown, setSelectedUpDown] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openMaDamperWarningModal, setOpenMaDamperWarningModal] =
    useState(false);
  const isDarkTheme = useTheme().palette.mode === "dark";
  const [showCFM, setShowCFM] = useState(false);
  const [passValueReq, setPassValueReq] = useState(false);
  const [saveIdent, setSaveIdent] = useState(false);

  const updateIdent = (newIdent: IStaticIdentDTO, index: number) => {
    if (props.values) {
      let crud = [...(props.values?.staticIdent as IStaticIdentDTO[])];
      crud.splice(index, 1, newIdent);
      props.setValues({
        ...props.values,
        staticIdent: [...crud],
      });
    }
  };

  useEffect(() => {
    const fans = props.values?.staticIdent?.filter(
      (element) =>
        element.upStaticRow?.component.includes("Fan") ||
        element.downStaticRow?.component
    );

    if (fans?.length) {
      setShowCFM(true);
    } else {
      setShowCFM(false);
    }
    if (passValueReq) {
      setPassValueReq(false);
      const indexNext = selectedIndex + 1;
      const [currentIdent] = (
        props.values?.staticIdent as IStaticIdentDTO[]
      ).filter((v, i) => i === selectedIndex);
      const { pressure, sign } = currentIdent.downStaticRow as IStaticRowDTO;
      if ((props.values?.staticIdent?.length as number) > indexNext) {
        if (
          !currentIdent.zeroToleranceSpacingBetween &&
          !currentIdent.concernsOfComponentDamage
        ) {
          const [nextIdent] = (
            props.values?.staticIdent as IStaticIdentDTO[]
          ).filter((v, i) => i === indexNext);
          updateIdent(
            {
              ...nextIdent,
              upStaticRow: {
                ...nextIdent.upStaticRow,
                pressure,
                sign,
              },
            } as IStaticIdentDTO,
            indexNext
          );
        }
      }
    } else if (saveIdent) {
      setSaveIdent(false);
      staticReportService.updateIdent(
        props.values?.id || 0,
        props.values?.staticIdent as IStaticIdentDTO[]
      );
    }
  }, [props.values?.staticIdent]);

  const handleOpenComponentDialog = async (
    direction: "up" | "down",
    index: number,
    staticRow: IStaticRowDTO
  ) => {
    setStaticRow(staticRow);
    setSelectedUpDown(direction);
    setSelectedIndex(index);
    setOpenComponentDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const newComponentHandler = async () => {
    if (props.values) {
      const maxReportRows = 25;
      const rows = props.values?.staticIdent ?? [];
      const length = rows.length ?? 0;

      if (length <= maxReportRows) {
        const nextCharacter = String.fromCharCode(65 + length);
        let sign = "";
        let pressure = null;
        const component = "Select component";

        if (rows.length > 0) {
          sign = rows[rows.length - 1].downStaticRow!.sign;
          pressure = rows[rows.length - 1].downStaticRow!.pressure;
        }

        let upStaticRow: IStaticRowDTO = {
          show: true,
          component,
          category: component,
          sign,
          point: "Upstream",
          pressure,
          id: 0,
          elementId: null,
          categoryId: 0,
          subCategory: "",
          subCategoryId: 0,
          totalSpDrop: 0,
          ductHeight: null,
          ductWidth: null,
          ductDiameter: null,
          totalPresure: null,
          actualFanSystemCFMTotal: null,
          componentOther: "",
          element: null,
        };

        let downStaticRow: IStaticRowDTO = {
          show: true,
          component,
          category: component,
          sign: "",
          point: "Downstream",
          pressure: null,
          id: 0,
          elementId: null,
          categoryId: 0,
          subCategory: "",
          subCategoryId: 0,
          totalSpDrop: 0,
          ductHeight: null,
          ductWidth: null,
          ductDiameter: null,
          totalPresure: null,
          actualFanSystemCFMTotal: null,
          componentOther: "",
          element: null,
        };

        const ident: IStaticIdentDTO = {
          ident: nextCharacter,
          bothAreFans: false,
          upStaticRow: upStaticRow,
          downStaticRow: downStaticRow,
          totalSPDrop: 0,
          id: 0,
          zeroToleranceSpacingBetween: false,
          concernsOfComponentDamage: false,
          upStaticRowId: null,
          downStaticRowId: null,
          fanTotalPressure: null,
          fanStaticPressure: null,
          notes: "",
          staticReportId: props.values?.id,
          selectedForTest: false,
          filterChangeScheduleSP5: null,
          filterChangeScheduleSP10: null,
          filterChangeScheduleSP15: null,
          filterChangeScheduleSP20: null,
          cantPerformDropComments: "",
          maDamperPositiveSP: false,
          maDamperPositiveSPAnswer: "",
        };

        props.setValues({
          ...props.values,
          staticIdent: [
            ...(props.values?.staticIdent as IStaticIdentDTO[]),
            ident,
          ],
        });

        await staticReportService.updateIdent(props.values?.id, [
          ...(props.values?.staticIdent as IStaticIdentDTO[]),
          ident,
        ]);

        props.execute(props.staticReportId);
      }
    }
  };

  const removeIdentHandler = async (identId: number) => {
    if (props.values) {
      await staticReportService.removeIdent(`${identId}`);

      const itemRemoved = props.values?.staticIdent?.filter(
        (x) => x.id !== identId
      );
      props.setValues({
        ...props.values,
        staticIdent: itemRemoved,
      });
      props.setGridLenght(itemRemoved?.length ?? 0);
      setOpenDeleteDialog(false);
    }
  };

  const getDuctAreaUp = (ident: IStaticIdentDTO) => {
    let area = 0;
    if (ident.upStaticRow?.component.includes("Rectangular")) {
      area = calculateRectangularArea(
        ident.upStaticRow?.ductHeight as number,
        ident.upStaticRow?.ductWidth as number
      );
    } else if (ident.upStaticRow?.component.includes("Round")) {
      area = calculateCircularArea(ident.upStaticRow?.ductDiameter as number);
    }
    return area;
  };

  const getDuctAreaDown = (ident: IStaticIdentDTO) => {
    var area = 0;
    if (ident.downStaticRow?.component.includes("Rectangular")) {
      area = calculateRectangularArea(
        ident.downStaticRow?.ductHeight as number,
        ident.downStaticRow?.ductWidth as number
      );
    } else if (ident.downStaticRow?.component.includes("Round")) {
      area = calculateCircularArea(ident.downStaticRow?.ductDiameter as number);
    }
    return area;
  };

  const calculateRectangularArea = (height: number, width: number) => {
    return props.formulas?.rectangularArea(height, width) as number;
  };

  const calculateCircularArea = (diameter: number) => {
    return props.formulas?.circularArea(diameter) as number;
  };

  const calculateAvgVel = (systemCFM: number, Sqft: number) => {
    let avgVel = 0;
    if (Sqft !== 0) {
      avgVel = props.formulas?.averageVelocity(systemCFM, Sqft) as number;
    }
    return avgVel;
  };

  const calculateVP = (AvgVel: number) => {
    return props.formulas?.vp(AvgVel) as number;
  };

  const calculateTP = (VP: number, SP: number) => {
    return VP + SP;
  };

  const handleTPChange = (
    ident: IStaticIdentDTO,
    upDown: string,
    index: number
  ) => {
    //Constants
    let upVP = 0;
    let upTP = 0;

    let downVP = 0;
    let downTP = 0;

    let fanTotalPressure = 0;
    let fanStaticPressure = 0;

    let upPressureInwg = 0;
    let downPressureInwg = 0;
    let totalSPDrop = 0;

    let newIdent = { ...ident } as IStaticIdentDTO;

    setSelectedIndex(index);

    upPressureInwg = parseFloat(
      `${newIdent.upStaticRow?.sign}${newIdent.upStaticRow?.pressure ?? 0}`
    );
    downPressureInwg = parseFloat(
      `${newIdent.downStaticRow?.sign}${newIdent.downStaticRow?.pressure ?? 0}`
    );

    totalSPDrop = upPressureInwg - downPressureInwg;

    newIdent = { ...newIdent, totalSPDrop: Math.abs(totalSPDrop) };

    //When is link to headsheet
    if (newIdent.upStaticRow?.component?.includes("Fan")) {
      const SqftUp = props.formulas?.sqft(getDuctAreaUp(newIdent)) as number;
      const actualCFM =
        typeof newIdent.upStaticRow?.actualFanSystemCFMTotal === "string"
          ? parseFloat(`${newIdent.upStaticRow?.actualFanSystemCFMTotal}`)
          : (newIdent.upStaticRow?.actualFanSystemCFMTotal as number);
      const AvgVelUp = calculateAvgVel(actualCFM, SqftUp);

      upVP = calculateVP(AvgVelUp);
      const upSP =
        typeof newIdent.upStaticRow?.pressure === "string"
          ? parseFloat(`${newIdent.upStaticRow?.pressure}`)
          : (newIdent.upStaticRow?.pressure as number);
      upTP = calculateTP(upVP, upSP);
      const SqftDown = props.formulas?.sqft(
        getDuctAreaDown(newIdent)
      ) as number;
      const AvgVelDown = calculateAvgVel(
        newIdent.upStaticRow?.actualFanSystemCFMTotal as number,
        SqftDown
      );
      downVP = calculateVP(AvgVelDown);
      const downSP =
        typeof newIdent.downStaticRow?.pressure === "string"
          ? parseFloat(`${newIdent.downStaticRow?.pressure}`)
          : (newIdent.downStaticRow?.pressure as number);
      downTP = calculateTP(downVP, downSP);
      newIdent = {
        ...newIdent,
        upStaticRow: {
          ...newIdent.upStaticRow,
          totalPresure: Math.abs(upTP),
        } as IStaticRowDTO,
        downStaticRow: {
          ...newIdent.downStaticRow,
          totalPresure: Math.abs(downTP),
        } as IStaticRowDTO,
      };
    }

    newIdent = {
      ...newIdent,
      bothAreFans:
        newIdent.upStaticRow?.component.includes("Fan") &&
        newIdent.downStaticRow?.component.includes("Fan"),
    } as IStaticIdentDTO;

    if (newIdent.bothAreFans) {
      const upTotalPressure =
        typeof newIdent.upStaticRow?.totalPresure === "string"
          ? parseFloat(`${newIdent.upStaticRow?.totalPresure}`)
          : (newIdent.upStaticRow?.totalPresure as number);
      const downTotalPressure =
        typeof newIdent.downStaticRow?.totalPresure === "string"
          ? parseFloat(`${newIdent.downStaticRow?.totalPresure}`)
          : (newIdent.downStaticRow?.totalPresure as number);
      fanTotalPressure = upTotalPressure - downTotalPressure;
      fanStaticPressure = fanTotalPressure - downVP;
    } else {
      fanTotalPressure = 0;
      fanStaticPressure = 0;
    }

    newIdent = {
      ...newIdent,
      fanStaticPressure: Math.abs(fanStaticPressure),
      fanTotalPressure: Math.abs(fanTotalPressure),
    } as IStaticIdentDTO;

    updateIdent(newIdent, index);
  };

  const handleSignClick = (
    sign: string,
    ident: IStaticIdentDTO,
    row: string,
    index: number
  ) => {
    let newIdent = { ...ident } as IStaticIdentDTO;
    setSelectedIndex(index);
    if (row === "down") {
      newIdent = {
        ...newIdent,
        downStaticRow: {
          ...(newIdent.downStaticRow as IStaticRowDTO),
          sign,
        },
      } as IStaticIdentDTO;
    } else if (row === "up") {
      newIdent = {
        ...newIdent,
        upStaticRow: {
          ...(newIdent.upStaticRow as IStaticRowDTO),
          sign,
        },
      } as IStaticIdentDTO;

      if (
        sign === "+" &&
        newIdent.upStaticRow?.subCategory === "Mixed Air Chamber"
      ) {
        newIdent.maDamperPositiveSP = true;
        setOpenMaDamperWarningModal(true);
      } else {
        newIdent = {
          ...newIdent,
          maDamperPositiveSP: false,
          maDamperPositiveSPAnswer: "",
        } as IStaticIdentDTO;
      }
    }

    updateIdent(newIdent, index);

    handleTPChange(newIdent, row, index);
  };

  const handleRowInputChange = (
    e: any,
    row: IStaticIdentDTO,
    index: number,
    rowUpDown: "up" | "down"
  ) => {
    const { name, value } = e.target;
    const destinationRow = `${rowUpDown}StaticRow`;
    let newValue = value;
    if (typeof newValue === "string") {
      const numberPattern = /^[-+]?\d{1,3}(?:,\d{3})*(?:\.\d+)?$/;
      newValue = numberPattern.test(newValue)
        ? newValue.replace(/,/g, "")
        : newValue;
    }
    const newIdent = {
      ...row,
      [destinationRow]: {
        ...(rowUpDown === "up" ? row.upStaticRow : row.downStaticRow),
        [name]: newValue,
      } as IStaticRowDTO,
    } as IStaticIdentDTO;
    updateIdent(newIdent, index);
    handleTPChange(newIdent, "up", index);
  };

  const handleSelectedForTestChange = (
    e: any,
    row: IStaticIdentDTO,
    index: number
  ) => {
    if (props.values?.staticIdent) {
      let oldIndex = -1;
      let crud = [...(props.values?.staticIdent as IStaticIdentDTO[])];
      const [oldSelectedCoil] = props.values?.staticIdent.filter(
        (row, index) => {
          if (row.selectedForTest) {
            oldIndex = index;
          }
          return row.selectedForTest;
        }
      );
      if (oldSelectedCoil) {
        const deselectedCoil = {
          ...oldSelectedCoil,
          selectedForTest: false,
        } as IStaticIdentDTO;
        crud.splice(oldIndex, 1, deselectedCoil);
      }
      const newIdent = {
        ...row,
        selectedForTest: e.target.value,
      } as IStaticIdentDTO;
      crud.splice(index, 1, newIdent);
      props.setValues({
        ...props.values,
        staticIdent: [...crud],
      });
    }
  };

  return (
    <>
      <Popup
        openPopup={openDeleteDialog}
        setOpenPopup={setOpenDeleteDialog}
        onClose={handleCloseDeleteDialog}
        title="Confirm delete Component"
      >
        <p>Are you certain you want to delete the selected component?</p>
        <Stack direction="row" spacing={3}>
          <Button variant="outlined" onClick={handleCloseDeleteDialog}>
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => removeIdentHandler(currentIdentId)}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>

      {props.values && (
        <>
          <SelectComponent
            openPopup={openComponentDialog}
            selectedIndex={selectedIndex}
            values={props.values}
            selectedUpDown={selectedUpDown}
            staticRow={staticRow as IStaticRowDTO}
            setOpenPopup={setOpenComponentDialog}
            setOpenFanWarningModal={setOpenFanWarningModal}
            setOpenMaDamperWarningModal={setOpenMaDamperWarningModal}
            setShowCFM={setShowCFM}
            updateIdent={updateIdent}
            isEnergyProcess={props.isEnergyProcess}
          />
          <MaDamperWarning
            openPopup={openMaDamperWarningModal}
            setOpenPopup={setOpenMaDamperWarningModal}
            setSaveIdent={setSaveIdent}
            selectedIndex={selectedIndex}
            updateIdent={updateIdent}
            values={props.values}
          />
        </>
      )}

      <FanWarning
        openPopup={openFanWarningModal}
        setOpenPopup={setOpenFanWarningModal}
      />

      <FormButton
        startIcon={<AddIcon />}
        onClick={newComponentHandler}
        text="Add Component"
        color="primary"
        size="small"
        disabled={
          props.isLoading || props.values?.isComplete || props.disableStudy
        }
      />

      <br />
      <br />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="static components">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>Id</TableCell>
              {props.isEnergyProcess && (
                <TableCell style={{ textAlign: "center" }}>
                  Select Coil Tested
                </TableCell>
              )}
              <TableCell style={{ textAlign: "center" }}>Point</TableCell>
              <TableCell style={{ textAlign: "center" }}>Component</TableCell>
              {showCFM && (
                <TableCell
                  style={{
                    textAlign: "center",
                    color: "red",
                    maxWidth: 115,
                    minWidth: 105,
                  }}
                >
                  **Fan Inlet & Discharge Dimensions**
                </TableCell>
              )}
              <TableCell style={{ textAlign: "center", minWidth: 147 }}>
                Pressure Designation
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Pressure {props.systemOfMeasurement.get("inwc")}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Total S.P. Drop
              </TableCell>
              {showCFM && (
                <>
                  <TableCell style={{ textAlign: "center", color: "red" }}>
                    TP
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "red" }}>
                    FTP
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "red" }}>
                    FSP
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "red",
                      maxWidth: 115,
                      minWidth: 105,
                    }}
                  >
                    **Actual Fan System {props.systemOfMeasurement.get("cfm")}{" "}
                    Total**
                  </TableCell>
                </>
              )}
              <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.values?.staticIdent &&
              props.values?.staticIdent.map(
                (row: IStaticIdentDTO, index: number) => (
                  <>
                    <TableRow
                      key={`${row.ident}-up-${index}`}
                      style={{
                        backgroundColor:
                          index % 2 > 0
                            ? isDarkTheme
                              ? "#243549"
                              : "#f3f3f3"
                            : "inherit",
                      }}
                    >
                      <TableCell
                        rowSpan={2}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {row.ident}
                      </TableCell>
                      {props.isEnergyProcess && (
                        <TableCell
                          rowSpan={2}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {row.upStaticRow?.category ===
                            "Heat Transfer Device" && (
                            <FormCheckBox
                              name="selectedForTest"
                              value={row.selectedForTest}
                              label=""
                              onChange={(e: any) => {
                                handleSelectedForTestChange(e, row, index);
                              }}
                              size="medium"
                              disabled={props.disableStudy}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Upstream
                      </TableCell>
                      {/* Component  */}
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {props.disableStudy ? (
                          <>
                            <p
                              style={{
                                color: row.upStaticRow?.element?.labelColor,
                              }}
                            >{`${row.upStaticRow?.category}-> ${row.upStaticRow?.subCategory}-> ${row.upStaticRow?.component}`}</p>
                          </>
                        ) : (
                          <FormButton
                            onClick={() => {
                              handleOpenComponentDialog(
                                "up",
                                index,
                                row.upStaticRow as IStaticRowDTO
                              );
                            }}
                            fullWidth
                            size="large"
                            color={
                              row.upStaticRow?.element?.labelColor === "red"
                                ? "error"
                                : "primary"
                            }
                            variant="outlined"
                            endIcon={<ArrowRightIcon />}
                            text={
                              row.upStaticRow?.category === "Select component"
                                ? "Select component"
                                : `${row.upStaticRow?.category}-> ${row.upStaticRow?.subCategory}-> ${row.upStaticRow?.component}`
                            }
                          />
                        )}
                      </TableCell>
                      {/* Fan Inlet & Discharge Dimensions  */}
                      {showCFM && (
                        <TableCell style={{ flex: 1, flexDirection: "row" }}>
                          {row.upStaticRow?.component.includes("Fan") &&
                            row.upStaticRow?.component.includes("Round") && (
                              <FormNumeric2
                                name="ductDiameter"
                                label=""
                                showSkeleton={props.isLoading}
                                disabled={props.disableStudy}
                                suffix={` ${props.systemOfMeasurement.get(
                                  "in"
                                )}`}
                                placeholder={`0.00(${props.systemOfMeasurement.get(
                                  "in"
                                )})`}
                                value={`${row.upStaticRow.ductDiameter}`}
                                rightAlign
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                onChange={(e: any) => {
                                  handleRowInputChange(e, row, index, "up");
                                }}
                              />
                            )}
                          {row.upStaticRow?.component.includes("Fan") &&
                            row.upStaticRow?.component.includes(
                              "Rectangular"
                            ) && (
                              <Box
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  alignContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Box>
                                  <FormNumeric2
                                    name="ductWidth"
                                    label=""
                                    showSkeleton={props.isLoading}
                                    disabled={props.disableStudy}
                                    suffix={` ${props.systemOfMeasurement.get(
                                      "in"
                                    )}`}
                                    placeholder={`0.00(${props.systemOfMeasurement.get(
                                      "in"
                                    )})`}
                                    value={`${row.upStaticRow.ductWidth}`}
                                    rightAlign
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    onChange={(e: any) => {
                                      handleRowInputChange(e, row, index, "up");
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <FormNumeric2
                                    name="ductHeight"
                                    label=""
                                    showSkeleton={props.isLoading}
                                    disabled={props.disableStudy}
                                    suffix={` ${props.systemOfMeasurement.get(
                                      "in"
                                    )}`}
                                    placeholder={`0.00(${props.systemOfMeasurement.get(
                                      "in"
                                    )})`}
                                    value={`${row.upStaticRow.ductHeight}`}
                                    rightAlign
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    onChange={(e: any) => {
                                      handleRowInputChange(e, row, index, "up");
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                        </TableCell>
                      )}
                      {row.zeroToleranceSpacingBetween ||
                      row.concernsOfComponentDamage ? (
                        <TableCell
                          colSpan={7}
                          rowSpan={2}
                          style={{ margin: 20 }}
                        >
                          <Alert severity="error">
                            DROP CAN'T BE PERFORMED
                            <br />
                            <p>
                              {getDescription(
                                row.zeroToleranceSpacingBetween,
                                row.concernsOfComponentDamage
                              )}
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Comments:
                              </span>{" "}
                              {row.cantPerformDropComments}
                            </p>
                          </Alert>
                        </TableCell>
                      ) : (
                        <>
                          {/* P. Designation  */}
                          <TableCell
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            {props.disableStudy ? (
                              <>
                                <p>{row.upStaticRow?.sign}</p>
                              </>
                            ) : (
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="+"
                                    control={<Radio />}
                                    label="+"
                                    checked={row.upStaticRow?.sign === "+"}
                                    style={{
                                      color: "blue",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleSignClick("+", row, "up", index);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="-"
                                    control={<Radio />}
                                    label="-"
                                    checked={row.upStaticRow?.sign === "-"}
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleSignClick("-", row, "up", index);
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          </TableCell>
                          {/* P. In/WC */}
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <FormNumeric2
                              sx={{ maxWidth: 105, minWidth: 100 }}
                              name="pressure"
                              label=""
                              showSkeleton={props.isLoading}
                              disabled={props.disableStudy}
                              placeholder="0.000"
                              value={`${row.upStaticRow?.pressure}`}
                              rightAlign
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator
                              onChange={(e: any) => {
                                handleRowInputChange(e, row, index, "up");
                              }}
                            />
                          </TableCell>
                          {/* Total S.P. Drop */}
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <NumericFormat
                              value={row.totalSPDrop}
                              displayType={"text"}
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator
                              thousandsGroupStyle={"thousand"}
                              renderText={(value: any) => <p>{value}</p>}
                            />
                          </TableCell>
                          {/* CFM */}
                          {showCFM && (
                            <>
                              {/* TP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {row.upStaticRow?.component.includes("Fan") && (
                                  <NumericFormat
                                    value={row.upStaticRow?.totalPresure}
                                    displayType={"text"}
                                    decimalScale={3}
                                    fixedDecimalScale
                                    thousandSeparator
                                    thousandsGroupStyle={"thousand"}
                                    renderText={(value: any) => <p>{value}</p>}
                                  />
                                )}
                              </TableCell>
                              {/* FTP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {row.upStaticRow?.component.includes("Fan") && (
                                  <NumericFormat
                                    value={row.fanTotalPressure}
                                    displayType={"text"}
                                    decimalScale={3}
                                    fixedDecimalScale
                                    thousandSeparator
                                    thousandsGroupStyle={"thousand"}
                                    renderText={(value: any) => <p>{value}</p>}
                                  />
                                )}
                              </TableCell>
                              {/* FSP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {row.upStaticRow?.component.includes("Fan") && (
                                  <NumericFormat
                                    value={row.fanStaticPressure}
                                    displayType={"text"}
                                    decimalScale={3}
                                    fixedDecimalScale
                                    thousandSeparator
                                    thousandsGroupStyle={"thousand"}
                                    renderText={(value: any) => <p>{value}</p>}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {row.upStaticRow?.component.includes("Fan") &&
                                  !props.isEnergyProcess && (
                                    <FormNumeric2
                                      sx={{ maxWidth: 115 }}
                                      name="actualFanSystemCFMTotal"
                                      label=""
                                      showSkeleton={props.isLoading}
                                      disabled={props.disableStudy}
                                      placeholder="Actual Fan CFM"
                                      value={`${row.upStaticRow.actualFanSystemCFMTotal}`}
                                      rightAlign
                                      decimalScale={2}
                                      fixedDecimalScale
                                      thousandSeparator
                                      onChange={(e: any) => {
                                        handleRowInputChange(
                                          e,
                                          row,
                                          index,
                                          "up"
                                        );
                                      }}
                                    />
                                  )}
                              </TableCell>
                            </>
                          )}
                        </>
                      )}

                      {/* Action */}
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GridActionButton
                          type="delete"
                          disabled={props.disableStudy}
                          onClick={() => {
                            setCurrentIdentId(row.id);
                            setOpenDeleteDialog(true);
                          }}
                          tooltip="Delete component"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        backgroundColor:
                          index % 2 > 0
                            ? isDarkTheme
                              ? "#243549"
                              : "#f3f3f3"
                            : "inherit",
                      }}
                      key={`${row.ident}-down-${index}`}
                    >
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Downstream
                      </TableCell>
                      {/* Component  */}
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {props.disableStudy ? (
                          <>
                            <p
                              style={{
                                color: row.downStaticRow?.element?.labelColor,
                              }}
                            >{`${row.downStaticRow?.category}-> ${row.downStaticRow?.subCategory}-> ${row.downStaticRow?.component}`}</p>
                          </>
                        ) : (
                          <FormButton
                            onClick={() => {
                              handleOpenComponentDialog(
                                "down",
                                index,
                                row.downStaticRow as IStaticRowDTO
                              );
                            }}
                            variant="outlined"
                            fullWidth
                            size="large"
                            color={
                              row.downStaticRow?.element?.labelColor === "red"
                                ? "error"
                                : "primary"
                            }
                            endIcon={<ArrowRightIcon />}
                            text={
                              row.downStaticRow?.category === "Select component"
                                ? "Select component"
                                : `${row.downStaticRow?.category}-> ${row.downStaticRow?.subCategory}-> ${row.downStaticRow?.component}`
                            }
                          />
                        )}
                      </TableCell>
                      {/* Fan Inlet & Discharge Dimensions  */}
                      {showCFM && (
                        <TableCell style={{ flex: 1, flexDirection: "row" }}>
                          {row.downStaticRow?.component.includes("Fan") &&
                            row.downStaticRow?.component.includes("Round") && (
                              <FormNumeric2
                                name="ductDiameter"
                                label=""
                                showSkeleton={props.isLoading}
                                disabled={props.disableStudy}
                                suffix={` ${props.systemOfMeasurement.get(
                                  "in"
                                )}`}
                                placeholder={`0.00(${props.systemOfMeasurement.get(
                                  "in"
                                )})`}
                                value={`${row.downStaticRow.ductDiameter}`}
                                rightAlign
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                                onChange={(e: any) => {
                                  handleRowInputChange(e, row, index, "down");
                                }}
                              />
                            )}
                          {row.downStaticRow?.component.includes("Fan") &&
                            row.downStaticRow?.component.includes(
                              "Rectangular"
                            ) && (
                              <Box
                                style={{
                                  flex: 1,
                                  alignItems: "center",
                                  alignContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Box>
                                  <FormNumeric2
                                    name="ductWidth"
                                    label=""
                                    showSkeleton={props.isLoading}
                                    disabled={props.disableStudy}
                                    suffix={` ${props.systemOfMeasurement.get(
                                      "in"
                                    )}`}
                                    placeholder={`0.00(${props.systemOfMeasurement.get(
                                      "in"
                                    )})`}
                                    value={`${row.downStaticRow.ductWidth}`}
                                    rightAlign
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    onChange={(e: any) => {
                                      handleRowInputChange(
                                        e,
                                        row,
                                        index,
                                        "down"
                                      );
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <FormNumeric2
                                    name="ductHeight"
                                    label=""
                                    showSkeleton={props.isLoading}
                                    disabled={props.disableStudy}
                                    suffix={` ${props.systemOfMeasurement.get(
                                      "in"
                                    )}`}
                                    placeholder={`0.00(${props.systemOfMeasurement.get(
                                      "in"
                                    )})`}
                                    value={`${row.downStaticRow.ductHeight}`}
                                    rightAlign
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator
                                    onChange={(e: any) => {
                                      handleRowInputChange(
                                        e,
                                        row,
                                        index,
                                        "down"
                                      );
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                        </TableCell>
                      )}

                      {row.zeroToleranceSpacingBetween ||
                      row.concernsOfComponentDamage ? null : (
                        <>
                          {/* P. Designation  */}
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {props.disableStudy ? (
                              <>
                                <p>{row.downStaticRow?.sign}</p>
                              </>
                            ) : (
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="+"
                                    control={<Radio />}
                                    label="+"
                                    checked={row.downStaticRow?.sign === "+"}
                                    style={{
                                      color: "blue",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleSignClick("+", row, "down", index);
                                    }}
                                  />
                                  <FormControlLabel
                                    value="-"
                                    control={<Radio />}
                                    label="-"
                                    checked={row.downStaticRow?.sign === "-"}
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleSignClick("-", row, "down", index);
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          </TableCell>
                          {/* P. In/WC */}
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <FormNumeric2
                              sx={{ maxWidth: 105, minWidth: 100 }}
                              name="pressure"
                              label=""
                              showSkeleton={props.isLoading}
                              disabled={props.disableStudy}
                              placeholder="0.000"
                              value={`${row.downStaticRow?.pressure}`}
                              rightAlign
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator
                              onChange={(e: any) => {
                                handleRowInputChange(e, row, index, "down");
                              }}
                              onBlur={(e: any) => {
                                setPassValueReq(true);
                              }}
                            />
                          </TableCell>
                          {/* Total S.P. Drop */}
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </TableCell>
                          {/* CFM */}
                          {showCFM && (
                            <>
                              {/* TP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {row.upStaticRow?.component.includes("Fan") && (
                                  <NumericFormat
                                    value={row.downStaticRow?.totalPresure}
                                    displayType={"text"}
                                    decimalScale={3}
                                    fixedDecimalScale
                                    thousandSeparator
                                    thousandsGroupStyle={"thousand"}
                                    renderText={(value: any) => <p>{value}</p>}
                                  />
                                )}
                              </TableCell>
                              {/* FTP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {""}
                              </TableCell>
                              {/* FSP */}
                              <TableCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {""}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center" }}
                              ></TableCell>
                            </>
                          )}
                        </>
                      )}

                      {/* Action */}
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      ></TableCell>
                    </TableRow>
                  </>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
