import { ICompanyTrainingCourse } from "src/ts/interfaces";
import axios from "../utils/axios";

const updateTrainingCourse = async (
  userId: number,
  trainingCourseId: number,
  isSelected: boolean
) => {
  return axios.put(
    `TrainingCoursesStudent?userId=${userId}&traininCourseId=${trainingCourseId}&isSelected=${isSelected}`
  );
};

const updateTrainingCourseList = async (
  trainingCourseId: number,
  data: ICompanyTrainingCourse
) => {
  return axios.put(
    `TrainingCoursesStudent/List?traininCourseId=${trainingCourseId}`,
    data
  );
};

const hasAssignedToTrainingCourse = async () => {
  return axios.get(`TrainingCoursesStudent`);
};

const trainingCoursesStudentService = {
  updateTrainingCourse,
  hasAssignedToTrainingCourse,
  updateTrainingCourseList,
};

export default trainingCoursesStudentService;
