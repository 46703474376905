import { useState, useEffect, ChangeEvent } from "react";
import FileUtils from "src/utils/file";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
  FormNumeric2,
  FormSelect,
  FormText,
  FormUploadButton,
} from "src/components/formControls";
import {
  Stack,
  Typography,
  Divider as MuiDivider,
  Grid,
  Box,
  Chip,
  Skeleton,
  IconButton,
} from "@mui/material";
import {
  IProposalBidContractor,
  IProjectContest,
  ProjectBidOtherContractor,
  IProjectOutForBidData,
  IProjectBidOtherContractor,
  IBuilding,
} from "src/ts/interfaces/projectOutToBid";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Add,
  ArticleOutlined,
  CloudDownload,
  Delete,
  Email,
} from "@mui/icons-material";
import moment from "moment";
import { IKeyValue, TableData } from "src/ts/interfaces";
import useLoading from "src/hooks/useLoading";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import { useAuth, useForm, useLog, usePermissions } from "src/hooks";
import projectContestService from "src/services/projectContestService";
import teamMemberServices from "src/services/teamMemberServices";
import userService from "src/services/userService";
import projectContestProposalBidService from "src/services/project/projectContestProposalBidService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { Validator } from "src/ts/types";
import { ROLES } from "src/constants";
import projectService from "src/services/project/projectService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import libraryDocumentService from "src/services/libraryDocumentService";
import ViewDocumentsPopUp from "./ViewDocumentsPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
import getProjectService from "../../../services/project/getProjectService";

const Divider = styled(MuiDivider)(spacing);
interface InformationProps {
  showTotalCombinedPopUp: boolean;
  setShowTotalCombinedPopUp: (val: boolean) => void;
  informationContractor?: number;
  codeContractor?: string;
  nameContractor: string;
  getprojectContest?: ProjectDTO | undefined;
  dataEntry?: boolean;
  currentRow?: IProjectOutForBidData;
  buildingInformation: IBuilding | undefined;
}
const initialValue = {
  key: "0",
  value: "Current Bid",
};

const initialValues: IProjectContest = {
  id: 0,
  projectContestId: 0,
  airTAndBCheck: false,
  airTAndBCheckBoma: false,
  airTAndBCheckBomaAccept: false,
  airTAndBBid: 0,
  hydronicTAndBCheck: false,
  hydronicTAndBCheckBoma: false,
  hydronicTAndBCheckBomaAccept: false,
  hydronicTAndBBid: 0,
  energyTestCheck: false,
  energyTestCheckBoma: false,
  energyTestCheckBomaAccept: false,
  energyTestBid: 0,
  energyInfectousCheck: false,
  energyInfectousCheckBoma: false,
  energyInfectousCheckBomaAccept: false,
  energyInfectousBid: 0,
  ductPressureCheck: false,
  ductPressureCheckBoma: false,
  ductPressureCheckBomaAccept: false,
  ductPressureBid: 0,
  vibrationTestingCheck: false,
  vibrationTestingCheckBoma: false,
  vibrationTestingCheckBomaAccept: false,
  vibrationTestingBid: 0,
  otherCheck: false,
  otherCheckBoma: false,
  otherBid: 0,
  otherText: "",
  totalCombined: 0,
  projectBidDays: 0,
  projectBidDate: new Date(),
  createdById: "",
  createdBy: "",
  isHitorical: false,
  otherBomaText: "",
  otherBomaCheck: false,
  otherBomaCheckBoma: false,
  otherBomaCheckBomaAccept: false,
  otherBomaBid: 0,
  buildingEnergyEfficiencyCheck: false,
  buildingEnergyEfficiencyCheckBoma: false,
  buildingEnergyEfficiencyCheckBomaAccept: false,
  buildingEnergyEfficiencyBid: 0,
  airTestingBalancingBid: 0,
  airTestingBalancingCheck: false,
  airTestingBalancingCheckBoma: false,
  airTestingBalancingCheckBomaAccept: false,
  hydronicWaterBalancingBid: 0,
  hydronicWaterBalancingCheck: false,
  hydronicWaterBalancingCheckBoma: false,
  hydronicWaterBalancingCheckBomaAccept: false,
  hvacEnergyPerformanceBid: 0,
  hvacEnergyPerformanceCheck: false,
  hvacEnergyPerformanceCheckBoma: false,
  hvacEnergyPerformanceCheckBomaAccept: false,
  commissioningBid: 0,
  commissioningCheck: false,
  commissioningCheckBoma: false,
  commissioningCheckBomaAccept: false,
  isTermsAccepted: false,
  projectContestOtherBid: null,
  projectBidOtherContractor: [],
  proposalDays: 0,
  acceptAll: false,
};

const arrayValues = [
  "airTestingBalancingCheckBoma",
  "ductPressureCheckBoma",
  "hydronicWaterBalancingCheckBoma",
  "hydronicTAndBCheckBoma",
  "hvacEnergyPerformanceCheckBoma",
  "commissioningCheckBoma",
  "energyTestCheckBoma",
  "vibrationTestingCheckBoma",
  "energyInfectousCheckBoma",
  "buildingEnergyEfficiencyCheckBoma",
  "otherCheckBoma",
];

export default function TotalCombinedPopUp({
  showTotalCombinedPopUp,
  setShowTotalCombinedPopUp,
  informationContractor,
  codeContractor,
  nameContractor,
  getprojectContest,
  dataEntry,
  currentRow,
  buildingInformation,
}: InformationProps) {
  const { isLoading, startRequest, endRequest } = useLoading();
  const { log } = useLog();
  const { user } = useAuth();
  const [contractorProjectContest, setContractorProjectContest] =
    useState<IProposalBidContractor>();
  const [contestProposal, setContestProposal] = useState<IProjectContest[]>([]);
  const [currentcontestProposal, setCurrentcontestProposal] =
    useState<IProjectContest>();
  const [currentDataSelected, setCurrentDataSelected] =
    useState<IProjectContest>();
  const [currentOtherOptionsProposal, setCurrentOtherOptions] = useState<
    IProjectContest[]
  >([]);
  const [selectOption, setSelectOption] = useState(initialValue);
  const [comboBoxProject, setComboBoxProject] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [teamMebers, setTeamMembers] = useState<TableData[]>();
  const [usersCurrentCompany, setUsersCurrentCompany] = useState<TableData[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showShareByEmail, setShowShareByEmail] = useState(false);
  const [totalBidPropsal, setTotalBidPropsal] = useState(0);
  const [projectAdditional, setProjectAdditional] =
    useState<ProjectBidOtherContractor[]>();
  const [projectContest, setProjectContest] = useState<ProjectDTO>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [descriptionItem, setDescriptionItem] = useState("");
  const { read, fullAccess } = usePermissions(PermissionTypes.Project_Bids);
  const { fullAccess: fullAccessContest } = usePermissions(
    PermissionTypes.ProjectContest
  );
  const { read: readProjectContest } = usePermissions(
    PermissionTypes.ProjectContest
  );
  const handleClosePopUp = () => {
    setShowTotalCombinedPopUp(false);
  };
  const [viewDocumentsShow, setViewDocumentsShow] = useState(false);
  const [projectBid, setProjectBid] = useState<IProjectBidOtherContractor[]>(
    []
  );

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.projectBidDays = new Validator(fieldValues, "projectBidDays")
      .validateIf(
        fieldValues.projectBidDays === null ||
          fieldValues.projectBidDays === 0 ||
          fieldValues.projectBidDays === ""
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.airTestingBalancingBid = new Validator(
      fieldValues,
      "airTestingBalancingBid"
    )
      .validateIf(
        fieldValues.airTestingBalancingCheck &&
          (fieldValues.airTestingBalancingBid === 0 ||
            fieldValues.ductPressureBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.ductPressureBid = new Validator(fieldValues, "ductPressureBid")
      .validateIf(
        fieldValues.ductPressureCheck &&
          (fieldValues.ductPressureBid === 0 ||
            fieldValues.ductPressureBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.hydronicWaterBalancingBid = new Validator(
      fieldValues,
      "hydronicWaterBalancingBid"
    )
      .validateIf(
        fieldValues.hydronicWaterBalancingCheck &&
          (fieldValues.hydronicWaterBalancingBid === 0 ||
            fieldValues.hydronicWaterBalancingBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.hydronicTAndBBid = new Validator(fieldValues, "hydronicTAndBBid")
      .validateIf(
        fieldValues.hydronicTAndBCheck &&
          (fieldValues.hydronicTAndBBid === 0 ||
            fieldValues.hydronicTAndBBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.hvacEnergyPerformanceBid = new Validator(
      fieldValues,
      "hvacEnergyPerformanceBid"
    )
      .validateIf(
        fieldValues.hvacEnergyPerformanceCheck &&
          (fieldValues.hvacEnergyPerformanceBid === 0 ||
            fieldValues.hvacEnergyPerformanceBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.commissioningBid = new Validator(fieldValues, "commissioningBid")
      .validateIf(
        fieldValues.commissioningCheck &&
          (fieldValues.commissioningBid === 0 ||
            fieldValues.commissioningBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.energyTestBid = new Validator(fieldValues, "energyTestBid")
      .validateIf(
        fieldValues.energyTestCheck &&
          (fieldValues.energyTestBid === 0 ||
            fieldValues.energyTestBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.vibrationTestingBid = new Validator(fieldValues, "vibrationTestingBid")
      .validateIf(
        fieldValues.vibrationTestingCheck &&
          (fieldValues.vibrationTestingBid === 0 ||
            fieldValues.vibrationTestingBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.energyInfectousBid = new Validator(fieldValues, "energyInfectousBid")
      .validateIf(
        fieldValues.energyInfectousCheck &&
          (fieldValues.energyInfectousBid === 0 ||
            fieldValues.energyInfectousBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.buildingEnergyEfficiencyBid = new Validator(
      fieldValues,
      "buildingEnergyEfficiencyBid"
    )
      .validateIf(
        fieldValues.buildingEnergyEfficiencyCheck &&
          (fieldValues.buildingEnergyEfficiencyBid === 0 ||
            fieldValues.buildingEnergyEfficiencyBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    temp.otherBomaBid = new Validator(fieldValues, "otherBomaBid")
      .validateIf(
        fieldValues.otherBomaCheck &&
          (fieldValues.otherBomaBid === 0 || fieldValues.otherBomaBid === null)
      )
      .greatThan(0, "Value is required.")
      .toString();

    if (
      fieldValues.projectBidOtherContractor &&
      fieldValues.projectBidOtherContractor.length > 0
    ) {
      fieldValues.projectBidOtherContractor.map(
        (item: ProjectBidOtherContractor, index: number) =>
          (temp[`description${index}`] = new Validator(
            fieldValues.projectBidOtherContractor[index],
            `description${index}`
          )
            .isRequired("Value is required.")
            .toString())
      );

      fieldValues.projectBidOtherContractor.map(
        (item: ProjectBidOtherContractor, index: number) =>
          (temp[`proposalBidCost${index}`] = new Validator(
            fieldValues.projectBidOtherContractor[index],
            `proposalBidCost${index}`
          )
            .greatThan(0, "Value is required.")
            .toString())
      );
    }

    if (
      fieldValues.projectContestOtherBid &&
      fieldValues.projectContestOtherBid.length > 0
    ) {
      fieldValues.projectContestOtherBid.map((item: any, index: number) => {
        if (
          item[`checked${index}`] &&
          item[`proposalBidCostOther${index}`] === 0
        ) {
          return (temp[`proposalBidCostOther${index}`] = new Validator(
            fieldValues.projectContestOtherBid[index],
            `proposalBidCostOther${index}`
          )
            .greatThan(0, "Value is required.")
            .toString());
        } else {
          temp[`proposalBidCostOther${index}`] = "";
        }
      });
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, setErrors, errors } = useForm(
    initialValues,
    true,
    validate
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleInputChangeAdditional = (e: any) => {
    const { name, value } = e.target;

    const number = name.match(/\d+/)[0];

    const updatedProjectBidOtherContractor = [
      ...values.projectBidOtherContractor,
    ];

    if (name.includes("description")) {
      updatedProjectBidOtherContractor[number] = {
        ...updatedProjectBidOtherContractor[number],
        [name]: value,
        description: value,
      };
    } else {
      updatedProjectBidOtherContractor[number] = {
        ...updatedProjectBidOtherContractor[number],
        [name]: value,
        proposalBidCost: value,
      };
    }

    setValues({
      ...values,
      projectBidOtherContractor: updatedProjectBidOtherContractor,
    });
  };

  const handleInputChangeOther = (e: any) => {
    const { name, value } = e.target;

    const number = name.match(/\d+/)[0];

    const updatedProjectBidOther = [...values.projectContestOtherBid];

    updatedProjectBidOther[number] = {
      ...updatedProjectBidOther[number],
      [name]: value,
      proposalBidCost: value,
    };

    setValues({
      ...values,
      projectContestOtherBid: updatedProjectBidOther,
    });
  };

  const handleInputChangeCheckBox = (e: any) => {
    const { name, value } = e.target;
    let index = name.indexOf("Check");
    let propertyBid = name.substring(0, index) + "Bid";

    if (name === "otherCheck" && !value) {
      setValues({
        ...values,
        [name]: value,
        projectBidOtherContractor: [],
      });
    } else if (name.includes("checked")) {
      const updatedProjectBidOther = [...values.projectContestOtherBid];
      const number = name.match(/\d+/)[0];
      updatedProjectBidOther[number] = {
        ...updatedProjectBidOther[number],
        [`checked`]: value,
        [`checked${number}`]: value,
        [`proposalBidCostOther${number}`]: 0,
        proposalBidCost: 0,
      };
      setValues({
        ...values,
        projectContestOtherBid: updatedProjectBidOther,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
        [propertyBid]: 0,
      });
    }
  };

  useEffect(() => {
    startRequest("proposal");
    const getProjectContestValue = async () => {
      try {
        const idContractor = Number(informationContractor);
        const responses = await Promise.all([
          projectContestService.getPrjojectTestProposalBid(idContractor),
          userService.getUserCurrenCompany(),
          teamMemberServices.getTeamMemberKeyValue(),
        ]);

        setContractorProjectContest(responses[0].data);
        setUsersCurrentCompany(responses[1].data);
        setTeamMembers(responses[2].data);
        setProjectContest(getprojectContest);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getProjectInformation = async () => {
      try {
        const id =
          projectContest !== undefined
            ? projectContest?.id
            : informationContractor !== undefined
            ? informationContractor
            : 0;
        const response = await projectContestService.getCurrentContestByProject(
          id
        );
        setContractorProjectContest(response.data);
        const responseProjects = await projectService.getContestProject(id);
        setProjectContest(responseProjects.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (
      (user?.subscriptionId === 1 && user?.role === ROLES.SuperAdministrator) ||
      (fullAccessContest && user?.role !== ROLES.SuperAdministrator)
    ) {
      getProjectContestValue();
    } else {
      getProjectInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const totalProposal = () => {
      const calculateBidSummary = (obj: any): number => {
        let totalBid = 0;

        const processOtherBid = (otherBid: ProjectBidOtherContractor[]) => {
          otherBid.forEach((item: ProjectBidOtherContractor) => {
            totalBid += Number(item.proposalBidCost);
          });
        };

        for (const key in obj) {
          if (
            Object.prototype.hasOwnProperty.call(obj, key) &&
            key.includes("Bid") &&
            key !== "projectBidDays" &&
            key !== "projectBidDate"
          ) {
            if (
              key === "projectBidOtherContractor" &&
              obj["projectBidOtherContractor"]
            ) {
              processOtherBid(obj["projectBidOtherContractor"]);
            } else if (
              key === "projectContestOtherBid" &&
              obj["projectContestOtherBid"]
            ) {
              processOtherBid(obj["projectContestOtherBid"]);
            } else {
              if (obj[key] !== null && obj[key] !== undefined) {
                totalBid += Number(obj[key].toString());
              }
            }
          }
        }
        return totalBid;
      };
      setTotalBidPropsal(calculateBidSummary(values));
    };

    totalProposal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const getAdditionalProposal = async () => {
      try {
        const response =
          await projectContestProposalBidService.getProjectContestProposalBid(
            contractorProjectContest?.id ?? 0
          );

        setContestProposal(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getAdditionalProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorProjectContest]);

  useEffect(() => {
    const processObtainData = () => {
      if (contestProposal.length > 0) {
        const maxDate = contestProposal
          .map((item) => ({ ...item, date: new Date(item.projectBidDate) }))
          .reduce((prev, curr) =>
            curr.projectBidDate > prev.projectBidDate ? curr : prev
          );

        setValues(maxDate);

        if (maxDate.projectBidOtherContractor.length > 0) {
          const dataProject = maxDate.projectBidOtherContractor.map(
            (item: ProjectBidOtherContractor, index: number) => {
              return {
                ...item,
                [`description${index}`]: item.description,
                [`proposalBidCost${index}`]: item.proposalBidCost,
                [`checkedBoma${index}`]: item.checkedBoma,
              };
            }
          );
          if (dataProject !== undefined)
            setProjectAdditional(dataProject ?? []);
        } else {
          setProjectAdditional([]);
        }

        setCurrentDataSelected(maxDate);
        const othersProposal = contestProposal.filter((item: any) => {
          return item.id !== maxDate.id;
        });
        endRequest("proposal");
        setCurrentOtherOptions(othersProposal);
        setComboBoxProject(
          othersProposal.map((item: any) => {
            return {
              ...comboBoxProject,
              key: item.id,
              value: moment(item.projectBidDate)
                .format("MMMM DD, YYYY hh:mmA")
                .toString(),
            };
          })
        );
      }
    };

    const projectBidOthers = () => {
      const otherBid =
        contractorProjectContest?.projectContestProposalBid
          .projectContestOtherBid ?? [];

      const newOtherBid = otherBid.map(
        (item: IProjectBidOtherContractor, index: number) => {
          return {
            ...item,
            [`checked${index}`]: item.checked,
            [`checkedBoma${index}`]: item.checkedBoma,
            [`proposalBidCostOther${index}`]: item.proposalBidCost,
            projectBidOther: {
              ...values.projectBidOther,
              [`description${index}`]: item.projectBidOther.description,
              [`description`]: item.projectBidOther.description,
            },
          };
        }
      );
      if (otherBid !== undefined) setProjectBid(newOtherBid);
    };
    processObtainData();
    projectBidOthers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestProposal]);

  useEffect(() => {
    const result = changeAccepAll();

    setValues({
      ...values,
      projectBidOtherContractor: projectAdditional,
      projectContestOtherBid: projectBid,
      acceptAll:
        user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux
          ? result
          : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAdditional]);

  const dowloadFile = async () => {
    const numberContractor = Number(informationContractor);
    try {
      setIsSubmitting(true);
      const response = await projectContestService.getDownloadFile(
        numberContractor
      );
      let fileName = "Project Bid.pdf";
      FileUtils.downloadFile(response.data, fileName);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const shareByEmailModal = () => {
    setShowShareByEmail(true);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setSelectOption({
      ...selectOption,
      [name]: value,
    });

    if (parseInt(value) === 0) {
      setCurrentcontestProposal(currentDataSelected);
    } else {
      const valueResult = currentOtherOptionsProposal.filter((item: any) => {
        return value === item.id;
      });
      setValues(valueResult[0]);
    }
  };
  const formattedDateTime = moment().format("MM/DD/YYYY hh:mmA");

  const skeletonCount = 10;

  const skeletons = Array.from({ length: skeletonCount }, (_, index) => (
    <Skeleton
      key={index}
      width={1000}
      height={25}
      style={{ marginTop: "5px" }}
    />
  ));

  const selectionOtherBid =
    values.projectContestOtherBid &&
    values.projectContestOtherBid
      .map((item: IProjectBidOtherContractor) => {
        if (item.checked) {
          return true;
        } else {
          return false;
        }
      })
      .some((value: boolean) => value === true);

  const selectionProjectBid =
    values?.airTestingBalancingCheck ||
    values?.ductPressureCheck ||
    values?.hydronicWaterBalancingCheck ||
    values?.hydronicTAndBCheck ||
    values?.hvacEnergyPerformanceCheck ||
    values?.commissioningCheck ||
    values?.energyTestCheck ||
    values?.vibrationTestingCheck ||
    values?.energyInfectousCheck ||
    values?.buildingEnergyEfficiencyCheck ||
    values?.otherCheck ||
    selectionOtherBid;

  const informationProject = (
    <Stack>
      <Stack direction="row" spacing={14}>
        <Typography> Project Code: </Typography>
        <Typography> {codeContractor ?? ""} </Typography>
      </Stack>
      <Stack direction="row" spacing={13} mt={2}>
        <Typography> Project Name: </Typography>
        <Typography> {projectContest?.name} </Typography>
      </Stack>
      <Stack direction="row" spacing={5} mt={2}>
        <Typography> Contractor's Name: </Typography>
        <Typography> {nameContractor ?? ""} </Typography>
      </Stack>
      <Divider my={2} mt={5} />
    </Stack>
  );

  const handleSendEmail = async (dataTable: TableData[]) => {
    let requestPayLoad: TableData[] = [];

    dataTable.map((item: TableData) =>
      requestPayLoad.push({
        id: item.id,
        name: item.name,
        email: item.email,
      })
    );
    const response = await projectContestService.sendBidDocument(
      Number(contractorProjectContest?.projectId),
      Number(contractorProjectContest?.id),
      requestPayLoad
    );
    if (response.data.length > 0) {
      response.data.forEach((item: any) => {
        log.success(`${item.email} - The email was sent`);
      });
    }
  };

  const addNewProposal = () => {
    const additionalLength = values?.projectBidOtherContractor?.length || 0;

    setValues({
      ...values,
      projectBidOtherContractor: [
        ...values.projectBidOtherContractor,
        {
          [`description${additionalLength}`]: "",
          [`proposalBidCost${additionalLength}`]: 0,
          description: "",
          proposalBidCost: 0,
        },
      ],
    });
  };

  const removeProposal = (description: string) => {
    const updatedProposals = values.projectBidOtherContractor.filter(
      (item: ProjectBidOtherContractor) => {
        return item.description !== description;
      }
    );

    setValues({
      ...values,
      otherCheck: updatedProposals.length === 0 ? false : true,
      projectBidOtherContractor: updatedProposals,
    });
  };

  const handleAddUpdateProjectContest = async () => {
    if (!validate()) {
      log.warning(
        "Bid contains empty entries, please fill all information and try again or deselect an empty check box option"
      );
      return;
    }
    if (!selectionProjectBid) {
      log.warning("Please select at least one option");
      return;
    }

    try {
      if (contractorProjectContest) {
        startRequest("UpdateAddBid");

        await projectContestService.putProjectContest(
          contractorProjectContest?.id ?? 0,
          {
            ...contractorProjectContest,
            projectContestProposalBid: values,
          }
        );
        endRequest("UpdateAddBid");
        setShowTotalCombinedPopUp(false);
        log.success("Proposal bid was saved");
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    try {
      startRequest("uploadingData");
      await libraryDocumentService.uploadFileAdditionalProposal({
        type: "File",
        content: file,
        projectId: projectContest?.id.toString() ?? "0",
      });
      endRequest("uploadingData");
      log.success("document uploaded successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      endRequest("uploadingData");
    }
  };

  const handlerDisabledCheckBoma =
    user?.role === ROLES.SysAdmin ||
    values.projectContest?.winning ||
    (values.projectContest?.declinedComments !== "" &&
      values.projectContest?.declinedComments !== null);

  const showDataBomaSysAdmin =
    user?.role === ROLES.SysAdmin ||
    user?.role === ROLES.SuperAdministrator ||
    user?.role === ROLES.Architect ||
    user?.role === ROLES.ArchitectAux;

  const projectBidDaysShow =
    user?.role === ROLES.Architect ||
    user?.role === ROLES.ArchitectAux ||
    user?.role === ROLES.CompanyOwner ||
    user?.role === ROLES.Administrator ||
    user?.role === ROLES.ProjectManager ||
    user?.role === ROLES.Training;

  const handlerAcceptAll = (e: any) => {
    const { value } = e.target;

    arrayValues.forEach((item) => {
      values[item] = value;
    });

    if (
      values?.projectContestOtherBid &&
      values.projectContestOtherBid.length > 0
    ) {
      values.projectContestOtherBid.forEach(
        (item: IProjectBidOtherContractor, index: number) => {
          values.projectContestOtherBid[index][`checkedBoma`] = value;
          values.projectContestOtherBid[index][`checkedBoma${index}`] = value;
        }
      );
    }
    if (
      values?.projectBidOtherContractor &&
      values?.projectBidOtherContractor.length > 0
    ) {
      values.projectBidOtherContractor.forEach(
        (item: IProjectBidOtherContractor, index: number) => {
          values.projectBidOtherContractor[index][`checkedBoma`] = value;
          values.projectBidOtherContractor[index][`checkedBoma${index}`] =
            value;
        }
      );
    }

    setValues({ ...values, acceptAll: value });
  };

  const handlerAcceptBomaOtherBid = (e: any) => {
    const { name, value } = e.target;

    const updatedProjectBidOther = [...values.projectContestOtherBid];
    const number = name.match(/\d+/)[0];
    updatedProjectBidOther[number] = {
      ...updatedProjectBidOther[number],
      [`checkedBoma`]: value,
      [`checkedBoma${number}`]: value,
    };
    setValues({
      ...values,
      projectContestOtherBid: updatedProjectBidOther,
    });
  };

  const handlerAcceptBomaOtherContractor = (e: any) => {
    const { name, value } = e.target;

    const updatedProjectBidOtherContractor = [
      ...values.projectBidOtherContractor,
    ];
    const number = name.match(/\d+/)[0];
    updatedProjectBidOtherContractor[number] = {
      ...updatedProjectBidOtherContractor[number],
      [`checkedBoma`]: value,
      [`checkedBoma${number}`]: value,
    };
    setValues({
      ...values,
      projectBidOtherContractor: updatedProjectBidOtherContractor,
    });
  };

  const changeAccepAll = () => {
    const acceptedAllValues = arrayValues.reduce((acc: any, item) => {
      acc[item] = values[item];
      return acc;
    }, {});

    const allValuesAreTrue = Object.values(acceptedAllValues).every(
      (value) => value === true
    );

    const valuesAreTrueprojectBidOtherContractor = projectBid.every(
      (value) => value.checkedBoma === true
    );

    const valuesAreTrueProjectAdditional = projectAdditional?.every(
      (value) => value.checkedBoma === true
    );

    const result =
      allValuesAreTrue &&
      valuesAreTrueprojectBidOtherContractor &&
      valuesAreTrueProjectAdditional;

    return result;
  };

  const hideUpdateAdd =
    (fullAccess ||
      (user?.role !== ROLES.Architect &&
        user?.role !== ROLES.ArchitectAux &&
        user?.role !== ROLES.SuperAdministrator)) &&
    (values.projectContest?.declinedComments !== "" ||
      values.projectContest?.declinedComments !== null) &&
    user?.role !== ROLES.SysAdmin;
  return (
    <>
      <Popup
        title="Proposal Bid"
        openPopup={showTotalCombinedPopUp}
        setOpenPopup={setShowTotalCombinedPopUp}
        onClose={handleClosePopUp}
        size="lg"
        disableClickOutside={true}
      >
        <>
          <Grid container>
            <Grid item xs={6}>
              <Grid container direction={"row"} spacing={3}>
                <Grid item xs={3} sx={{ textAlign: "end" }}>
                  <Typography> Project Code: </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormLabel text={codeContractor} showSkeleton={isLoading} />
                </Grid>
              </Grid>
              <Grid container direction={"row"} spacing={3}>
                <Grid item xs={3} sx={{ textAlign: "end" }}>
                  <Typography> Project Name: </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormLabel
                    text={projectContest?.name}
                    showSkeleton={isLoading}
                  />
                </Grid>
              </Grid>
              <Grid container direction={"row"} spacing={3}>
                <Grid item xs={3} sx={{ textAlign: "end" }}>
                  <Typography ml={8}> Building: </Typography>
                </Grid>
                <Grid item xs={9}>
                  {projectContest && (
                    <>
                      <Stack direction="row" spacing={1}>
                        <FormLabel
                          text={`${
                            buildingInformation?.name ??
                            projectContest.buildingName
                          }`}
                          sx={{ fontWeight: "bold" }}
                          showSkeleton={isLoading}
                        />
                        <FormLabel
                          text={
                            buildingInformation?.getFullAddress ??
                            projectContest.buildingAddress
                          }
                          showSkeleton={isLoading}
                        />
                      </Stack>
                    </>
                  )}
                </Grid>
              </Grid>
              {showDataBomaSysAdmin && (
                <Grid container direction={"row"} spacing={3}>
                  <Grid item xs={3} sx={{ textAlign: "end" }}>
                    <Typography> Contractor's Name: </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{nameContractor}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              {projectBidDaysShow && (
                <Stack
                  direction="row"
                  justifyContent="end"
                  alignContent={"end"}
                  mb={5}
                >
                  <Typography mt={2} mr={2}>
                    This proposal is good for
                  </Typography>
                  <FormNumeric2
                    label=""
                    name="projectBidDays"
                    value={values.projectBidDays}
                    onChange={handleInputChange}
                    error={errors.projectBidDays}
                    showSkeleton={isLoading}
                    sx={{ width: "50px" }}
                    disabled={dataEntry}
                    decimalScale={0}
                  />

                  <Typography mt={2} ml={2}>
                    days from (
                    {currentcontestProposal?.projectBidDate === null
                      ? formattedDateTime
                      : moment(currentcontestProposal?.projectBidDate).format(
                          "MM/DD/YYYY hh:mmA"
                        )}
                    )
                  </Typography>
                </Stack>
              )}
              {showDataBomaSysAdmin && (
                <Stack direction="row" spacing={5} justifyContent="end">
                  <>
                    <FormButton
                      text="Download"
                      onClick={dowloadFile}
                      size="small"
                      startIcon={<CloudDownload />}
                      isSubmitting={isSubmitting}
                    />
                    <FormButton
                      text="Share by email"
                      onClick={shareByEmailModal}
                      size="small"
                      startIcon={<Email />}
                      color="success"
                    />
                  </>
                </Stack>
              )}
              {fullAccess && readProjectContest && (
                <Stack
                  direction="row"
                  justifyContent={"center"}
                  alignContent={"end"}
                  ml={78}
                >
                  <FormCheckBox
                    name="acceptAll"
                    label="Accept All"
                    value={values?.acceptAll}
                    disabled={handlerDisabledCheckBoma}
                    onChange={handlerAcceptAll}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
          <Divider my={2} />
          {isLoading ? (
            <Stack direction="column">{skeletons}</Stack>
          ) : (
            <>
              {" "}
              <Box
                sx={{
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "250px",
                }}
              >
                {projectContest?.airTestingBalancing && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="airTestingBalancingCheck"
                          label=""
                          value={values?.airTestingBalancingCheck}
                          onChange={handleInputChangeCheckBox}
                          disabled={dataEntry}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b> Air Testing and Balancing</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.airTestingBalancingCheck && (
                            <FormNumeric2
                              name="airTestingBalancingBid"
                              label=""
                              value={`${
                                values?.airTestingBalancingBid === null
                                  ? 0
                                  : values?.airTestingBalancingBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.airTestingBalancingBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.airTestingBalancingCheck && (
                          <>$Proposal/Bid Cost</>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        {values.airTestingBalancingCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="airTestingBalancingCheckBoma"
                              label="Accept"
                              value={values?.airTestingBalancingCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.ductLeakageTesting && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="ductPressureCheck"
                          label=""
                          value={values?.ductPressureCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <b>
                          {" "}
                          Duct Leakage Testing, see additional Specifications.
                          Required for any duct equal to or greater than Medium
                          Pressure Classification and witnessed by this projects
                          Architect and/or Engineer (Recommended)
                        </b>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.ductPressureCheck && (
                            <FormNumeric2
                              name="ductPressureBid"
                              label=""
                              value={`${
                                values?.ductPressureBid === null
                                  ? 0
                                  : values?.ductPressureBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.ductPressureBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.ductPressureCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.ductPressureCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="ductPressureCheckBoma"
                              label="Accept"
                              value={values?.ductPressureCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.hydronicWaterBalancing && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="hydronicWaterBalancingCheck"
                          label=""
                          value={values?.hydronicWaterBalancingCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b> Hydronic/Water Testing and Balancing</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.hydronicWaterBalancingCheck && (
                            <FormNumeric2
                              name="hydronicWaterBalancingBid"
                              label=""
                              value={`${
                                values?.hydronicWaterBalancingBid === null
                                  ? 0
                                  : values?.hydronicWaterBalancingBid
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.hydronicWaterBalancingBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.hydronicWaterBalancingCheck && (
                          <>$Proposal/Bid Cost</>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        {values.hydronicWaterBalancingCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="hydronicWaterBalancingCheckBoma"
                              label="Accept"
                              value={values?.hydronicWaterBalancingCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.hydronicPipeleakage && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="hydronicTAndBCheck"
                          label=""
                          value={values?.hydronicTAndBCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b> Hydronic Pipe Leakage Testing</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.hydronicTAndBCheck && (
                            <FormNumeric2
                              name="hydronicTAndBBid"
                              label=""
                              value={`${
                                values?.hydronicTAndBBid === null
                                  ? 0
                                  : values?.hydronicTAndBBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.hydronicTAndBBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.hydronicTAndBCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.hydronicTAndBCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="hydronicTAndBCheckBoma"
                              label="Accept"
                              value={values?.hydronicTAndBCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.hvacEnergyPerformance && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="hvacEnergyPerformanceCheck"
                          label=""
                          value={values?.hvacEnergyPerformanceCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" mt={2}>
                          <Typography mt={1}>
                            <b> HVAC Systems Energy Performance Testing.</b>
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        {values.hvacEnergyPerformanceCheck && (
                          <FormNumeric2
                            name="hvacEnergyPerformanceBid"
                            label=""
                            value={`${
                              values?.hvacEnergyPerformanceBid === null
                                ? 0
                                : values?.hvacEnergyPerformanceBid.toLocaleString(
                                    "en-US"
                                  )
                            }`}
                            thousandSeparator={true}
                            decimalScale={2}
                            onChange={handleInputChange}
                            prefix="$"
                            rightAlign={true}
                            error={errors.hvacEnergyPerformanceBid}
                            disabled={dataEntry}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.hvacEnergyPerformanceCheck && (
                          <>$Proposal/Bid Cost</>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        {values.hvacEnergyPerformanceCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="hvacEnergyPerformanceCheckBoma"
                              label="Accept"
                              value={values?.hvacEnergyPerformanceCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.commissioning && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="commissioningCheck"
                          label=""
                          value={values?.commissioningCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          {" "}
                          <b> HVAC Commissioning</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.commissioningCheck && (
                            <FormNumeric2
                              name="commissioningBid"
                              label=""
                              value={`${
                                values?.commissioningBid === null
                                  ? 0
                                  : values?.commissioningBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.commissioningBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.commissioningCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.commissioningCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="commissioningCheckBoma"
                              label="Accept"
                              value={values?.commissioningCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.soundTestingLocations && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="energyTestCheck"
                          label=""
                          value={values?.energyTestCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b>
                            {" "}
                            Sound Testing. Locations chosen by this projects
                            Architect and/or Engineer. See additional
                            Specifications
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.energyTestCheck && (
                            <FormNumeric2
                              name="energyTestBid"
                              label=""
                              value={`${
                                values?.energyTestBid === null
                                  ? 0
                                  : values?.energyTestBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.energyTestBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.energyTestCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.energyTestCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="energyTestCheckBoma"
                              label="Accept"
                              value={values?.energyTestCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.vibrationTestingForMotors && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="vibrationTestingCheck"
                          label=""
                          value={values?.vibrationTestingCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b>
                            {" "}
                            Vibration Testing for motors equal to or greater
                            than 1.0 HP or 745.69 Watts. See additional
                            Specifications
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.vibrationTestingCheck && (
                            <FormNumeric2
                              name="vibrationTestingBid"
                              label=""
                              value={`${
                                values?.vibrationTestingBid === null
                                  ? 0
                                  : values?.vibrationTestingBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.vibrationTestingBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.vibrationTestingCheck && (
                          <>$Proposal/Bid Cost</>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        {values.vibrationTestingCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="vibrationTestingCheckBoma"
                              label="Accept"
                              value={values?.vibrationTestingCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.ufadPlenumLeakage && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="energyInfectousCheck"
                          label=""
                          value={values?.energyInfectousCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b>
                            {" "}
                            UFAD Plenum leakage testing. See additional
                            Specifications
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.energyInfectousCheck && (
                            <FormNumeric2
                              name="energyInfectousBid"
                              label=""
                              value={`${
                                values?.energyInfectousBid === null
                                  ? 0
                                  : values?.energyInfectousBid.toLocaleString(
                                      "en-US"
                                    )
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.energyInfectousBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.energyInfectousCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.energyInfectousCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="energyInfectousCheckBoma"
                              label="Accept"
                              value={values?.energyInfectousCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}
                {projectContest?.buildingEnergyEfficiency && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="buildingEnergyEfficiencyCheck"
                          label=""
                          value={values?.buildingEnergyEfficiencyCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" mt={2}>
                          {" "}
                          <b>
                            Building Energy Efficiency, Infectous Disease
                            Compliancy Testing (Plaque)
                          </b>
                        </Stack>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        {values.buildingEnergyEfficiencyCheck && (
                          <FormNumeric2
                            name="buildingEnergyEfficiencyBid"
                            label=""
                            value={`${
                              values?.buildingEnergyEfficiencyBid === null
                                ? 0
                                : values?.buildingEnergyEfficiencyBid.toLocaleString(
                                    "en-US"
                                  )
                            }`}
                            thousandSeparator={true}
                            decimalScale={2}
                            onChange={handleInputChange}
                            prefix="$"
                            rightAlign={true}
                            error={errors.buildingEnergyEfficiencyBid}
                            disabled={dataEntry}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.buildingEnergyEfficiencyCheck && (
                          <>$Proposal/Bid Cost</>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        {values.buildingEnergyEfficiencyCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="buildingEnergyEfficiencyCheckBoma"
                              label="Accept"
                              value={values?.buildingEnergyEfficiencyCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}

                {getprojectContest && getprojectContest.otherTextTesting && (
                  <>
                    <Grid container>
                      <Grid item xs={0} sx={{ textAlign: "center" }}>
                        <FormCheckBox
                          name="otherBomaCheck"
                          label=""
                          value={values?.otherBomaCheck}
                          disabled={dataEntry}
                          onChange={handleInputChangeCheckBox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography mt={3}>
                          <b>{getprojectContest.otherTextTesting}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1.4} ml={9.2}>
                        <Stack>
                          {values.otherBomaCheck && (
                            <FormNumeric2
                              name="otherBomaBid"
                              label=""
                              value={`${
                                values?.otherBomaBid === null
                                  ? 0
                                  : values?.otherBomaBid.toLocaleString("en-US")
                              }`}
                              thousandSeparator={true}
                              decimalScale={2}
                              onChange={handleInputChange}
                              prefix="$"
                              rightAlign={true}
                              error={errors.otherBomaBid}
                              disabled={dataEntry}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ textAlign: "center", marginTop: "7px" }}
                      >
                        {values.otherBomaCheck && <>$Proposal/Bid Cost</>}
                      </Grid>
                      <Grid item xs={1}>
                        {values.otherBomaCheck &&
                          showDataBomaSysAdmin &&
                          fullAccess && (
                            <FormCheckBox
                              name="otherBomaCheckBoma"
                              label="Accept"
                              value={values?.otherBomaCheckBoma}
                              disabled={handlerDisabledCheckBoma}
                              onChange={handleInputChange}
                            />
                          )}
                      </Grid>
                    </Grid>
                    <Divider my={2} />
                  </>
                )}

                {values?.projectContestOtherBid &&
                  values.projectContestOtherBid.length > 0 &&
                  values.projectContestOtherBid.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <Grid container>
                            <Grid item xs={0} sx={{ textAlign: "center" }}>
                              <FormCheckBox
                                name={`checked${index}`}
                                label=""
                                value={item[`checked${index}`]}
                                disabled={dataEntry}
                                onChange={handleInputChangeCheckBox}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography mt={3}>
                                <b>{item?.projectBidOther.description}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={1.4} ml={9.2}>
                              <Stack>
                                {item[`checked${index}`] && (
                                  <FormNumeric2
                                    name={`proposalBidCostOther${index}`}
                                    label=""
                                    value={`${
                                      item[`proposalBidCostOther${index}`] ===
                                      null
                                        ? 0
                                        : item[
                                            `proposalBidCostOther${index}`
                                          ].toLocaleString("en-US")
                                    }`}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onChange={handleInputChangeOther}
                                    prefix="$"
                                    rightAlign={true}
                                    error={
                                      errors[`proposalBidCostOther${index}`]
                                    }
                                    disabled={dataEntry}
                                  />
                                )}
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{ textAlign: "center", marginTop: "7px" }}
                            >
                              {item[`checked${index}`] && (
                                <>
                                  <Stack mt={1}>$Proposal/Bid Cost</Stack>
                                </>
                              )}
                            </Grid>
                            <Grid item xs={1}>
                              {item[`checked${index}`] &&
                                showDataBomaSysAdmin &&
                                fullAccess && (
                                  <FormCheckBox
                                    name={`checkedBoma${index}`}
                                    label="Accept"
                                    value={item[`checkedBoma${index}`]}
                                    disabled={handlerDisabledCheckBoma}
                                    onChange={handlerAcceptBomaOtherBid}
                                  />
                                )}
                            </Grid>
                          </Grid>
                          <Divider my={2} />
                        </>
                      );
                    }
                  )}

                <Grid container>
                  <Grid item xs={0} sx={{ textAlign: "center" }}>
                    <FormCheckBox
                      name="otherCheck"
                      label=""
                      value={values?.otherCheck}
                      disabled={dataEntry}
                      onChange={handleInputChangeCheckBox}
                    />
                  </Grid>
                  <Grid item xs={8} alignItems="end" justifyContent="center">
                    <Stack direction="row" mt={2}>
                      {isLoading ? (
                        <>
                          <Stack direction="column">{skeletons}</Stack>
                        </>
                      ) : (
                        <Stack direction="row" spacing={4} mb={2}>
                          <b> Additional Proposal Cost</b>
                          {dataEntry ||
                            (values.otherCheck && (
                              <FormButton
                                onClick={addNewProposal}
                                text="Add"
                                size="small"
                                endIcon={<Add />}
                              />
                            ))}
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                {values?.projectBidOtherContractor &&
                  values?.projectBidOtherContractor.length > 0 &&
                  values?.projectBidOtherContractor.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <Stack mb={2}>
                            <Grid container key={index}>
                              <Grid item xs={0}>
                                <Stack mt={2} mr={2}>
                                  <b>Description</b>
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <FormText
                                  name={`description${index}`}
                                  label=""
                                  value={item[`description${index}`]}
                                  readOnlyText={dataEntry}
                                  onChange={handleInputChangeAdditional}
                                  error={errors[`description${index}`]}
                                  disabled={dataEntry}
                                />
                              </Grid>
                              <Grid item xs={1.4}>
                                <Stack ml={2}>
                                  <FormNumeric2
                                    name={`proposalBidCost${index}`}
                                    label=""
                                    value={item[`proposalBidCost${index}`]}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onChange={handleInputChangeAdditional}
                                    prefix="$"
                                    rightAlign={true}
                                    error={errors[`proposalBidCost${index}`]}
                                    disabled={dataEntry}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={2} sx={{ textAlign: "center" }}>
                                {values.otherCheck && (
                                  <>
                                    <Stack mt={2}>$Proposal/Bid Cost</Stack>
                                  </>
                                )}
                                {!dataEntry && values.otherCheck && (
                                  <IconButton
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setDescriptionItem(item.description);
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                              <Grid item xs={1}>
                                {values.otherCheck &&
                                  showDataBomaSysAdmin &&
                                  fullAccess && (
                                    <FormCheckBox
                                      name={`checkedBoma${index}`}
                                      label="Accept"
                                      value={item[`checkedBoma${index}`]}
                                      disabled={handlerDisabledCheckBoma}
                                      onChange={
                                        handlerAcceptBomaOtherContractor
                                      }
                                    />
                                  )}
                              </Grid>
                            </Grid>
                          </Stack>
                        </>
                      );
                    }
                  )}
              </Box>
              <Divider my={2} />
              <Grid container>
                <Grid item xs={1} sx={{ textAlign: "center" }}></Grid>
                <Grid
                  item
                  xs={8}
                  alignItems="end"
                  justifyContent="center"
                ></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2} sx={{ textAlign: "center" }}>
                  {totalBidPropsal !== 0 ? (
                    <Typography variant="h6">
                      <b>
                        {`Total Combined Bid ${totalBidPropsal.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )}`}
                      </b>
                    </Typography>
                  ) : (
                    <Typography variant="h6">{`Total Combined Bid $0`}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider my={2} />
              <Grid container spacing={2}>
                <Grid item xs={7} sx={{ textAlign: "left" }}>
                  {user?.role === ROLES.SysAdmin && (
                    <Stack
                      direction="row"
                      justifyContent="left"
                      alignContent={"end"}
                    >
                      <Typography mt={2} mr={2}>
                        This proposal is good for
                      </Typography>
                      <FormNumeric2
                        label=""
                        name="projectBidDays"
                        value={values.projectBidDays}
                        onChange={handleInputChange}
                        error={errors.projectBidDays}
                        showSkeleton={isLoading}
                        sx={{ width: "50px" }}
                        disabled={dataEntry}
                        decimalScale={0}
                      />

                      <Typography mt={2} ml={2}>
                        days from (
                        {currentcontestProposal?.projectBidDate === null
                          ? formattedDateTime
                          : moment(
                              currentcontestProposal?.projectBidDate
                            ).format("MM/DD/YYYY hh:mmA")}
                        )
                      </Typography>
                    </Stack>
                  )}
                  {(user?.role === ROLES.CompanyOwner ||
                    user?.role === ROLES.Training) && (
                    <Stack direction={"row"} mt={2}>
                      <Typography variant="button" mt={1} mr={2}>
                        <b>Additional proposal documentation </b>
                      </Typography>
                      <Stack direction={"row"} spacing={2}>
                        {getprojectContest?.declinedDate === null && (
                          <FormUploadButton
                            accept=".pdf"
                            size="small"
                            onChange={(e: any) => handleFileUpload(e)}
                            text={"Add"}
                            isSubmitting={isSubmitting}
                          />
                        )}
                        <FormButton
                          onClick={() => {
                            setViewDocumentsShow(true);
                          }}
                          text="View Documents"
                          startIcon={<ArticleOutlined />}
                          size="small"
                          color="success"
                        />
                      </Stack>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "right" }}>
                  {user?.role === ROLES.SysAdmin &&
                  contestProposal.length > 1 ? (
                    <Typography mt={2}>Saved versions of the bid</Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                  {user?.role === ROLES.SysAdmin &&
                  contestProposal.length > 1 ? (
                    <FormSelect
                      name="key"
                      value={selectOption.key}
                      label=""
                      onChange={handleOnChange}
                      defaultValue={{ key: 0, value: "Current Bid" }}
                      options={comboBoxProject}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>

        <Stack direction="row" spacing={100}>
          <Chip
            label="The price can be modified up to the point the proposal is accepted."
            color="warning"
          />
          <Stack direction="row" spacing={3}>
            <FormButton
              text="Close"
              variant="outlined"
              onClick={() => {
                setShowTotalCombinedPopUp(false);
              }}
              size="small"
              disabled={isLoading}
            />
            {hideUpdateAdd && (
              <>
                {contractorProjectContest?.projectContestProposalBid
                  ?.totalCombined === null ? (
                  <>
                    {(getprojectContest?.declinedDate === null ||
                      getprojectContest?.declinedDate === undefined) && (
                      <FormButton
                        text="Add"
                        color="primary"
                        onClick={handleAddUpdateProjectContest}
                        size="small"
                        disabled={isLoading}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {(getprojectContest?.declinedDate === null ||
                      getprojectContest?.declinedDate === undefined) && (
                      <FormButton
                        text="Update"
                        color="primary"
                        onClick={handleAddUpdateProjectContest}
                        size="small"
                        disabled={isLoading}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Popup>
      {showShareByEmail && (
        <ShareByEmailPopUpComponent
          sendbyEmailShow={showShareByEmail}
          setSendbyEmailShow={setShowShareByEmail}
          sendEmailFunction={handleSendEmail}
          children={informationProject}
          dataTeamMember={teamMebers}
          dataUserCurrent={usersCurrentCompany}
        />
      )}
      {showDeleteModal && (
        <MatchWordConfirmModal
          onConfirm={() => {
            removeProposal(descriptionItem);
          }}
          text={`Are you certain you want to delete this item?`}
          matchWord={"delete"}
          isDialogOpen={showDeleteModal}
          setIsDialogOpen={setShowDeleteModal}
        />
      )}
      {viewDocumentsShow && (
        <ViewDocumentsPopUp
          viewDocumentsShow={viewDocumentsShow}
          setViewDocumentsShow={setViewDocumentsShow}
          projectId={projectContest?.id.toString() ?? "0"}
          companyId={user?.companyId}
        />
      )}
    </>
  );
}
