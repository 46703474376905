import {
  TextField,
  Skeleton,
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  Theme,
  SxProps,
} from "@mui/material";
import FormReadOnlyField from "./FormReadOnlyField";
import { Mode } from "src/ts/types/common";
import { CSSProperties } from "react";
import { listArray } from "src/constants";

interface FormTextProps {
  type?: "password" | "number" | "text" | "email";
  name: string;
  label: string | React.ReactNode;
  value?: string | number | null;
  error?: any;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  maxLength?: number;
  [key: string]: any;
  showSkeleton?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  rightAlign?: boolean;
  readOnlyText?: boolean;
  mode?: Mode;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  inputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  sx?: SxProps<Theme> | undefined;
  style?: CSSProperties | undefined;
  autocomplete?: boolean;
  onKeyDown?: (e: any) => void;
}

export default function FormText(props: FormTextProps) {
  const {
    style,
    sx,
    type,
    name,
    label,
    value,
    error = null,
    disabled = undefined,
    onChange,
    onBlur,
    maxLength,
    showSkeleton,
    placeholder,
    fullWidth,
    rightAlign,
    readOnlyText,
    mode,
    endAdornment,
    startAdornment,
    inputProps,
    autocomplete,
    onKeyDown,
  } = props;

  if (mode && mode === "read")
    return (
      <>
        {showSkeleton ? (
          <Skeleton height={40} />
        ) : (
          <FormReadOnlyField
            label={label}
            value={value === null || value === "" ? "-" : value?.toString()}
          />
        )}
      </>
    );

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={35} />
      ) : (
        <TextField
          style={{
            ...style,
            fontWeight: disabled ? "bolder" : "normal",
          }}
          sx={{ ...sx }}
          type={type}
          variant="outlined"
          fullWidth={fullWidth ?? true}
          label={label}
          size="small"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          InputProps={{
            ...inputProps,
            maxLength: maxLength,
            readOnly: readOnlyText ? true : false,
            endAdornment: endAdornment,
            startAdornment: startAdornment,
            inputProps: {
              ...inputProps?.inputProps,
              style: {
                ...inputProps?.inputProps?.style,
                textAlign: rightAlign ? "right" : "start",
              },
              maxLength: maxLength,
            },
          }}
          placeholder={placeholder}
          {...(error && { error: true, helperText: error })}
          InputLabelProps={{ shrink: !value ? false : true }}
          autoComplete={
            autocomplete
              ? ""
              : [name].some((value) => listArray.includes(value))
              ? "new-password"
              : "off"
          }
          onKeyDown={onKeyDown}
        />
      )}
    </>
  );
}
