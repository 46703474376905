import { useEffect, useMemo, useState } from "react";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  usePermissions,
} from "src/hooks";
import reportTypeService from "src/services/study/reportTypeService";
import {
  ICompany,
  IProjectInfectiousComplianceControl,
  IProjectReporDisplayt,
  IReportCategoryType,
  IReportType,
} from "src/ts/interfaces";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import {
  FormButton,
  FormCheckBox,
  FormRaitingStarts,
  FormTextArea,
} from "src/components/formControls";
import SelectStudyPopup from "./SelectStudyPopup";
import SelectQuantityPopup from "./SelectQuantityPopup";
import {
  CompanyService,
  commonStudyService,
  pdfExportService,
} from "src/services";
import StudiesList from "./StudiesList";
import TableViewIcon from "@mui/icons-material/TableView";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import AddSubStudies from "./AddSubStudiesPopup";
import projectAuditorService from "src/services/project/projectAuditorService";
import projectService from "src/services/project/projectService";
import ProgressBarModal from "src/components/modals/ProgressBarModal";
import FileUtils from "src/utils/file";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Permission } from "src/ts/enums";
import projectReportService from "src/services/study/projectReportService";
import CompleteProjectPopup from "./CompleteProjectPopup";
import IncompleteStudies from "./IncompleteStudies";
import {
  REPORT_CATEGORY_TYPE,
  ROLES,
  projectStatus,
  studiesConfig,
} from "src/constants";
import reportCategoryType from "src/services/study/reportCategoryType";
import { Link, useNavigate } from "react-router-dom";
import { Replay, TaskAlt } from "@mui/icons-material";
import PasswordConfirmModal from "src/components/modals/PasswordConfirmModal";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import TandbalanceEnergyPopup from "../../Components/TandbalanceEnergyPopup";
import EvaluatePopup from "./EvaluatePopup";
import RatingInformation from "src/pages/projectOutForBid/Components/RatingInformationPopUp";
import { IRatingContractors } from "src/ts/interfaces/projectOutToBid";
import HistoryBomaEvaluation from "./HistoryBomaEvaluation";
import ReopenProject from "./ReopenProject";
import { AddIcon } from "src/components/icons";

interface Props {
  project: ProjectDTO;
  refreshProject: () => void;
}

enum AddStudyToEnum {
  None,
  Project,
  Study,
}

const Studies = ({ project, refreshProject }: Props) => {
  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [refreshStudies, setRefreshStudies] = useState(false);
  const [showAddStudies, setShowAddStudies] = useState(false);
  const [isLoadingRating, setIsLoadingRate] = useState(false);
  const [showQuantityPopup, setShowQuantityPopup] = useState(false);
  const [showAddSubStudiesPopup, setShowAddSubStudiesPopup] = useState(false);
  const [showMissingTolerancesModal, setShowMissingTolerancesModal] =
    useState(false);
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
  const [showCompleteProjectPopup, setShowCompleteProjectPopup] =
    useState(false);
  const [showIncompleteStudiesPopup, setShowIncompleteStudiesPopup] =
    useState(false);
  const [studies, setStudies] = useState<IProjectReporDisplayt[]>([]);
  const [isLoadingStudies, setIsLoadingStudies] = useState(false);
  const [selectedStudyType, setSelectedStudyType] =
    useState<IReportType | null>(null);
  const [addStudyTo, setAddStudyTo] = useState<AddStudyToEnum>(
    AddStudyToEnum.None
  );
  const [companyUser, setCompanyUser] = useState<ICompany | null>(null);
  const [tandbalanceEnergyModal, setTandbalanceEnergyModal] = useState(false);
  const { execute: getReportCategoryByProjectType, data: studyCategories } =
    useAsyncQuery<IReportCategoryType[]>(
      reportCategoryType.getReportCategoryByProjectType
    );

  const { execute: getReportTypeByProjectType, data: studyTypes } =
    useAsyncQuery<IReportType[]>(reportTypeService.getReportTypeByProjectType);

  const {
    execute: getProjectInfectiousControlById,
    data: projectInfectiousControl,
    setData: setprojectInfectiousControl,
  } = useAsyncQuery<IProjectInfectiousComplianceControl>(
    projectService.getProjectInfectiousControlById
  );

  const { execute: updateInfectiousControl } = useAsyncMutation(
    projectService.updateInfectiousControl,
    {
      successfulMessage: "Project status was changed successfully",
    }
  );

  const { execute: saveComments } = useAsyncMutation(
    projectHistoryStatusCommentsService.postProjectHistoryStatusComments,
    { hideSuccessfulMessage: true }
  );

  const [isPartOfProject, setIsPartOfProject] = useState(false);
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [evaluatedModal, setEvaluatedModal] = useState(false);

  const getData = async () => {
    setIsLoadingStudies(true);
    const res = await projectReportService.getStudiesByProject(project.id);

    setStudies(res.data);
    setIsLoadingStudies(false);

    //setData([...res.data], []);
    //setItems(res.data.map((studies) => studies.subStudyOrder));
  };

  useEffect(() => {
    if (project.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, refreshStudies]);

  useEffect(() => {
    if (isLoadingRating) setIsLoadingRate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRating]);

  const getIsPartOfProject = async (projectId: number) => {
    try {
      if (
        user?.role === "Administrator" &&
        project?.companyId?.toString() !== user?.companyId.toString()
      ) {
        setIsPartOfProject(false);
      } else {
        const response = await projectAuditorService.getIsPartOfProject(
          projectId
        );
        setIsPartOfProject(response.data);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getReportCategoryByProjectType(project.id);
      await getReportTypeByProjectType(project.id);
      await getIsPartOfProject(project.id);
      if (project.isInfectiousControlProject) {
        await getProjectInfectiousControlById(project.id);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const selectStudyHandler = (studyTypes: IReportType) => {
    setSelectedStudyType(studyTypes);
    setShowQuantityPopup(true);
  };

  const { execute: createStudies, isSubmitting: creatingStudies } =
    useAsyncMutation(commonStudyService.createStudies, {
      successfulMessage: "The report(s) were succesfully created",
      errorMessage: "An error was ocurred",
    });

  const createStudiesHandler = async (quantity: number) => {
    if (addStudyTo === AddStudyToEnum.None) log.error("Not allowes");

    if (selectedStudyType) {
      const studyConfig = studiesConfig[selectedStudyType?.code];

      let url = "";
      if (addStudyTo === AddStudyToEnum.Project)
        url = `${studyConfig.url}/quantity?quantity=${quantity}&parentReportId=0&parentReportTypeId=0`;
      else if (addStudyTo === AddStudyToEnum.Study)
        url = `${studyConfig.url}/quantity?quantity=${quantity}&parentReportId=${selectedProjectStudy?.reportId}&parentReportTypeId=${selectedProjectStudy?.reportTypeId}`;

      const projectData: {
        buildingId: number;
        projectId: number;
        reportTypeId: any;
        coilsReportTypeId: number;
      } = {
        buildingId: project.buildingId,
        projectId: project.id,
        reportTypeId: studyConfig.reportTypeId,
        coilsReportTypeId:
          studyConfig.reportTypeId === 17
            ? 2
            : studyConfig.reportTypeId === 28
            ? 4
            : studyConfig.reportTypeId === 27
            ? 3
            : 1,
      };

      await createStudies(url, projectData);

      setShowQuantityPopup(false);
      setShowAddStudies(false);
      setRefreshStudies(!refreshStudies);
    }
  };

  const [selectedProjectStudy, setSelectedProjectStudy] =
    useState<IProjectReporDisplayt | null>(null);

  const selectProjectStudyHandler = (study: IProjectReporDisplayt) => {
    //TODO: check if tolerances were save and show tolerances modal
    if (study.reportTypeRouteComplete !== "" && study.reportTypeRoute !== "") {
      if (study.haveSubStudies) {
        setSelectedProjectStudy(study);
        //show study selection
        setShowAddSubStudiesPopup(true);
      } else {
        if (study.isComplete) {
          navigate(
            `/studies/${study.reportTypeRouteComplete}`.replace("//", "/")
          );
        } else {
          navigate(`/studies/${study.reportTypeRoute}`.replace("//", "/"));
        }
      }
    }
  };

  const selectProjectStudyNavgigateHandler = (study: IProjectReporDisplayt) => {
    if (study.reportTypeRouteComplete !== "" && study.reportTypeRoute !== "") {
      navigate(`/studies/${study.reportTypeRoute}`.replace("//", "/"));
    }
  };

  const showDownloadExecutiveSummary =
    (user?.role === "Training" ||
      user?.role === "SuperAdministrator" ||
      user?.role === "ProjectManager" ||
      user?.role === "Administrator" ||
      user?.role === "CompanyOwner" ||
      user?.role === "Architect" ||
      user?.role === "ArchitectAux" ||
      user?.role === "SysAdmin" ||
      user?.role === "EnergyAuditor" ||
      user?.role === "Auditor") &&
    project?.projectStatus?.name !== "New" &&
    project?.projectStatus?.name !== "Working" &&
    project?.projectStatus?.name !== "Removed" &&
    project?.projectStatus?.name !== "Incomplete" &&
    project?.projectStatus?.name !== "Canceled" &&
    project?.projectStatus?.name !== "New contest";

  const [progress, setProgress] = useState(0);
  const [showProgressBarModal, setProgressBarModal] = useState(false);
  const [showProjectCompletionFee, setShowProjectCompletionFee] =
    useState(false);
  const [showOpenDisputeModal, setShowOpenDisputeModal] = useState(false);
  const [reopenCommentsModal, setReopenCommentsModal] = useState(false);
  const [ratingInformationModal, setRatingInformationModal] = useState(false);
  const [ratingProject, setRatingProject] = useState<IRatingContractors>();
  const [reopenComments, setReopenComments] = useState("");
  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };

  const downloadExecutiveSummary = async () => {
    if (
      (project.isPaid == null || project.isPaid <= 0) &&
      project.isInfectiousControlProject === false
    ) {
      setShowProjectCompletionFee(true);
    } else {
      setProgressBarModal(true);
      setProgress(0);

      const response = await pdfExportService.getFileProject(
        project.id,
        true,
        onProgressDownload
      );
      FileUtils.downloadFile(response.data, "ProjectSummary.pdf");
      setProgressBarModal(false);
    }
  };

  const { fullAccess: projectAccess } = usePermissions(Permission.Project);
  const { fullAccess: projectAdminAccess } = usePermissions(
    Permission.Project_Admin
  );
  const isCovidProject = project.isInfectiousControlProject;
  const hideCreateStudy =
    (project?.projectStatus?.name === "Contest" &&
      user?.companyType === "Contractor") ||
    project?.projectStatus?.name === "Complete" ||
    project?.projectStatus?.name === "Paid" ||
    project?.projectStatus?.name === "Rejected" ||
    project?.projectStatus?.name === "Approved" ||
    project?.projectStatus?.name === "In Review" ||
    project?.projectStatus?.name === "Incomplete" ||
    project?.projectStatus?.name === "Canceled" ||
    isCovidProject ||
    //vm.showAddMoreStudies == false ||
    !(projectAccess || projectAdminAccess);
  const isBusy = false;
  const disableCreateStudy =
    !isPartOfProject &&
    ((user?.role !== "Architect" &&
      user?.role !== "ArchitectAux" &&
      user?.role !== "SysAdmin") ||
      project.disabled ||
      isBusy ||
      (user?.companyType === "Contractor" && project.companyId === null) ||
      project?.projectStatus?.name === "Complete" ||
      project?.projectStatus?.name === "Paid" ||
      project?.projectStatus?.name === "Contest");

  const hideCreateEnergyStudy =
    (project?.projectStatus?.name === "Contest" &&
      user?.companyType === "Contractor") ||
    project?.projectStatus?.name === "Complete" ||
    project?.projectStatus?.name === "Paid" ||
    !isCovidProject;

  const disableCreateEnergyStudy =
    !isPartOfProject ||
    isBusy ||
    (user?.companyType === "Contractor" && project.companyId == null) ||
    project?.projectStatus?.name === "Complete" ||
    project?.projectStatus?.name === "Paid" ||
    project?.projectStatus?.name === "Contest" ||
    project?.projectStatus?.name === "In Review";

  const showCompleteFinalizeProject =
    (user?.role === "Training" ||
      user?.role === "SuperAdministrator" ||
      user?.role === "ProjectManager" ||
      user?.role === "Administrator" ||
      user?.role === "CompanyOwner" ||
      user?.role === "Auditor" ||
      user?.role === "EnergyAuditor" ||
      user?.role === "Auditor") &&
    (project?.projectStatus?.name === "New" ||
      project?.projectStatus?.name === "Working");

  const canCompleteProject =
    studies?.length > 0
      ? studies[studies?.length - 1].canCompleteProject
      : false;
  const disableCompleteFinalizeProject =
    !isPartOfProject || isBusy || project.disabled || !canCompleteProject;

  const validateOpenDispute = async (projectId: number) => {
    try {
      const response = await projectReportService.validateOpenDispute(
        projectId
      );
      return response.data;
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      return true;
    }
  };

  const getCompany = async (companyId: number): Promise<ICompany | null> => {
    try {
      const response = await CompanyService.getById(companyId);
      return response.data;
    } catch (error: any) {
      log.error(error.message || "Something went wrong");
    } finally {
      return null;
    }
  };

  // const getAuditors = async (projectId: number) => {
  //   const response = await techniciansHistoryService.getAuditorList(projectId);
  //   const auditorsHistory = response.data;

  // };

  const completeFinalizeProject = async () => {
    const openDispute = await validateOpenDispute(project.id);
    if (openDispute) {
      setShowOpenDisputeModal(true);
      return;
    }

    if (!project.tolerancesWereSaved) {
      setShowMissingTolerancesModal(true);
      return;
    }

    if (
      !(
        user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "Training" ||
        user?.role === "SuperAdministrator"
      )
    ) {
      setShowUnauthorizedModal(true);
      return;
    }

    const company = await getCompany(user?.companyId);
    setCompanyUser(company);

    if (user?.role === "SuperAdministrator" || company?.walletAdminBlocked) {
      //TODO: send to cart
      //vm.optionComplete = "SendToCart";
    }

    try {
      const response = await projectReportService.validateReports(project.id);
      var result = response.data;
      if (result.totalReports === 0) log.error("The project has no reports.");
      else if (result.covidProject && result.energyReports < result.airHandlers)
        log.error(
          "The number of COVID Compliance Energy Studies (" +
            result.energyReports +
            ") is less than the Air Handlers (" +
            result.airHandlers +
            ") registered for the building."
        );
      else if (result.completedReports === 0)
        log.error("No studies have been completed.");
      else {
        setShowCompleteProjectPopup(true);
      }
    } catch (error: any) {
      log.error(error.message || "Something went wrong");
    }
  };

  const createStudyHandler = () => {
    if (!project.tolerancesWereSaved) {
      setShowMissingTolerancesModal(true);
      return;
    }

    setShowAddStudies(true);
    setAddStudyTo(AddStudyToEnum.Project);
  };

  const showIncompleteStudiesPopupHandler = () => {
    setShowIncompleteStudiesPopup(true);
  };

  const filteredStudyCategories = () => {
    if (
      (user?.role === "TemporalAuditor" || user?.role === "Auditor") &&
      project.isInfectiousControlProject
    ) {
      return [];
    } else if (user?.role === "TemporalAuditor" || user?.role === "Auditor")
      return studyCategories?.filter(
        (item) =>
          item.id !== REPORT_CATEGORY_TYPE.EnergyCommissioningReportTemplates
      );
    else if (project.isInfectiousControlProject)
      return studyCategories?.filter(
        (item) =>
          item.id === REPORT_CATEGORY_TYPE.EnergyCommissioningReportTemplates
      );

    return studyCategories;
  };

  const handleApproveProject = () => {
    setShowEvaluation(true);
    setProceed(false);
  };

  const approveProject = useMemo(() => {
    return (
      user?.role === ROLES.SuperAdministrator &&
      projectInfectiousControl?.projectStatusId === 12 &&
      project?.projectStatusId === 12
    );
  }, [
    project?.projectStatusId,
    projectInfectiousControl?.projectStatusId,
    user?.role,
  ]);

  const handleConfirmEvaluation = async () => {
    let newData = { ...projectInfectiousControl };
    newData.projectStatusId = 11;
    setprojectInfectiousControl({ ...newData });
    await updateInfectiousControl(newData, project.id);
  };

  const handleSaveReopen = async () => {
    await saveComments({
      comments: reopenComments,
      projectId: project.id,
      statusId: 13,
    });
    let newData = { ...projectInfectiousControl };
    newData.projectStatusId = 2;
    setprojectInfectiousControl({ ...newData });
    await updateInfectiousControl(newData, project.id);
  };

  const handleReopenModal = async () => {
    setReopenCommentsModal(true);
  };

  const handleShowModalAddStudies = async () => {
    if (user?.role === ROLES.TemporalAuditor || user?.role === ROLES.Auditor) {
      setTandbalanceEnergyModal(true);
    } else {
      setShowAddStudies(true);
      setAddStudyTo(AddStudyToEnum.Project);
    }
  };

  const handleEvaluate = () => {
    setEvaluatedModal(true);
  };

  const evaluateProject = useMemo(() => {
    return (
      (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) &&
      (project.projectStatusId === 3 || project.projectStatusId === 10) &&
      !(project.isPaid == null || project.isPaid <= 0) &&
      projectAccess
    );
  }, [project.isPaid, project.projectStatusId, user?.role, projectAccess]);

  return (
    <>
      <StudiesList
        projectId={project.id}
        refreshStudies={refreshStudies}
        onSelectStudy={selectProjectStudyHandler}
        project={project}
        isPartOfProject={isPartOfProject}
        projectInfectiousControl={projectInfectiousControl}
        setRefreshStudies={setRefreshStudies}
        studies={studies}
        setShowProjectCompletionFee={setShowProjectCompletionFee}
        isLoading={isLoadingStudies}
      />
      <Grid container>
        {ratingProject &&
          (user?.role === "Architect" ||
            user?.role === "ArchitectAux" ||
            user?.role === "SysAdmin") &&
          (((project?.projectStatusId === projectStatus.Complete.id ||
            project?.projectStatusId === projectStatus.Rejected.id ||
            project?.projectStatusId === projectStatus.Approved.id) &&
            !(project?.isPaid == null || project?.isPaid <= 0)) ||
            project?.projectStatusId === projectStatus.InReview.id) && (
            <Grid item xs={12} textAlign="center">
              <Typography>
                <Typography>Contractor's Rating</Typography>
                <Link
                  onClick={() => {
                    setRatingInformationModal(true);
                  }}
                  to={""}
                >
                  {isLoadingRating ? (
                    <Skeleton height={40}></Skeleton>
                  ) : (
                    <FormRaitingStarts
                      value={Math.round(ratingProject?.rating ?? 0)}
                    />
                  )}
                </Link>
              </Typography>
            </Grid>
          )}

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {showDownloadExecutiveSummary && (
              <FormButton
                text="Download Executive Project"
                onClick={downloadExecutiveSummary}
                startIcon={<TableViewIcon />}
                size="small"
                tooltip="Download only the Executive Project Summary and Certifications"
                variant="outlined"
              />
            )}
            {!hideCreateEnergyStudy && (
              <FormButton
                text="Create Energy Study"
                onClick={handleShowModalAddStudies}
                startIcon={<TableViewIcon />}
                size="small"
                disabled={disableCreateEnergyStudy}
              />
            )}
            {!hideCreateStudy && (
              <FormButton
                text="Create New Report"
                disabled={disableCreateStudy}
                onClick={createStudyHandler}
                startIcon={<AddIcon />}
                size="small"
              />
            )}
            {showCompleteFinalizeProject && (
              <FormButton
                text="Complete"
                disabled={disableCompleteFinalizeProject}
                onClick={completeFinalizeProject}
                startIcon={<TableViewIcon />}
                size="small"
                tooltip="Complete/Finalize Project & Report(s)"
              />
            )}
            {approveProject && (
              <FormButton
                text="Approve Project"
                onClick={handleApproveProject}
                startIcon={<TaskAlt />}
                size="small"
              />
            )}
            {approveProject && (
              <FormButton
                text="Reopen Project"
                onClick={handleReopenModal}
                startIcon={<Replay />}
                size="small"
                variant="outlined"
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} pt={2}>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <ReopenProject
              projectId={project.id}
              projectStatusId={project.projectStatusId}
              refreshProject={refreshProject}
            ></ReopenProject>
            {evaluateProject && (
              <FormButton
                text="Evaluate"
                onClick={handleEvaluate}
                startIcon={<TaskAlt />}
                size="small"
              />
            )}
            <HistoryBomaEvaluation
              projectId={project.id}
              projectStatusId={project.projectStatusId}
            ></HistoryBomaEvaluation>
          </Stack>
        </Grid>
      </Grid>
      <SelectStudyPopup
        studyCategories={filteredStudyCategories()}
        studyTypes={studyTypes}
        show={showAddStudies}
        setShow={setShowAddStudies}
        onSelectStudy={selectStudyHandler}
        project={project}
      />
      {selectedStudyType && (
        <SelectQuantityPopup
          isDialogOpen={showQuantityPopup}
          setIsDialogOpen={setShowQuantityPopup}
          studyName={selectedStudyType?.name}
          onSave={createStudiesHandler}
          isSubmitting={creatingStudies}
        />
      )}
      <AddSubStudies
        isDialogOpen={showAddSubStudiesPopup}
        setIsDialogOpen={setShowAddSubStudiesPopup}
        parentStudy={selectedProjectStudy}
        onSave={() => {
          setAddStudyTo(AddStudyToEnum.Study);
          setShowAddStudies(true);
        }}
        projectId={project.id}
        refreshStudies={refreshStudies}
        onSelectStudy={selectProjectStudyNavgigateHandler}
        isPartOfProject={isPartOfProject}
      />
      <ProgressBarModal
        progress={progress}
        isDialogOpen={showProgressBarModal}
        setIsDialogOpen={setProgressBarModal}
        text={"Download zip file"}
      />
      <DialogMessagePopup
        title="Project's Completion Fee"
        text="This project's completion fee has not been received.
        You will have availability to your PDF Report once the project's completion fee is received."
        showPopup={showProjectCompletionFee}
        setShowPopup={setShowProjectCompletionFee}
        onSave={() => {}}
        hideAccept={true}
        cancelTextButton="Close"
        isSubmitting={false}
      />
      <DialogMessagePopup
        title="Message"
        text="The project has an open complaint and cannot be completed. Please contact your company administrator."
        showPopup={showOpenDisputeModal}
        setShowPopup={setShowOpenDisputeModal}
        onSave={() => {}}
        isSubmitting={false}
      />
      <DialogMessagePopup
        title="Warning"
        text='"Save Tolerances" is still open above, you must review and change this projects 5 allowable tolerances 
        (all defaulted at +- 10%) if necessary, followed with selecting the flashing "Save Tolerances" button flashing above'
        showPopup={showMissingTolerancesModal}
        setShowPopup={setShowMissingTolerancesModal}
        onSave={() => {}}
        isSubmitting={false}
        hideCancel={true}
      />
      <DialogMessagePopup
        title="Unauthorized"
        text="Only Certified Technicians can complete Studies or Reports."
        showPopup={showUnauthorizedModal}
        setShowPopup={setShowUnauthorizedModal}
        onSave={() => {}}
        isSubmitting={false}
      />
      <CompleteProjectPopup
        isDialogOpen={showCompleteProjectPopup}
        setIsDialogOpen={setShowCompleteProjectPopup}
        companyUser={companyUser}
        projectId={project.id}
        project={project}
        studies={studies}
        refreshProject={refreshProject}
        showIncompleteStudiesPopup={showIncompleteStudiesPopupHandler}
      />
      <IncompleteStudies
        isDialogOpen={showIncompleteStudiesPopup}
        setIsDialogOpen={setShowIncompleteStudiesPopup}
        studies={studies}
      />
      <PasswordConfirmModal
        title={"Infectious Control Compliance Evaluation"}
        isDialogOpen={showEvaluation}
        setIsDialogOpen={setShowEvaluation}
        setIsValidPassword={handleConfirmEvaluation}
        text={
          <>
            <Typography>
              You are about to UPDATE the Status of this project Infectious
              Building Control Compliance Certification.
            </Typography>
            <Typography>
              The information could not be updated after you save the changes.
              Are you certain you want to continue?
            </Typography>
            <FormCheckBox
              label="Yes, proceed"
              name="proceed"
              value={proceed}
              onChange={(e: any) => {
                setProceed(e.target.value);
              }}
            />
            <Typography>
              To update this project Infectious Control Compliance Project's
              Evaluation, AMP needs to be certain that you are an AMP authorized
              Super Administrator
            </Typography>
          </>
        }
        disabled={!proceed}
      ></PasswordConfirmModal>
      <DialogMessagePopup
        title={"Reopen Comments"}
        text={
          <>
            <FormTextArea
              name={"reopenComments"}
              label={"Comments"}
              value={reopenComments}
              onChange={(e: any) => {
                setReopenComments(e.target.value);
              }}
            ></FormTextArea>
          </>
        }
        showPopup={reopenCommentsModal}
        setShowPopup={setReopenCommentsModal}
        onSave={handleSaveReopen}
        isSubmitting={false}
      ></DialogMessagePopup>
      <TandbalanceEnergyPopup
        showPopUp={tandbalanceEnergyModal}
        setShowPopUp={setTandbalanceEnergyModal}
      ></TandbalanceEnergyPopup>
      <EvaluatePopup
        modalPopup={evaluatedModal}
        setModalPopup={setEvaluatedModal}
        projectId={project.id}
        setRatingInformationModal={setRatingInformationModal}
        refresh={refreshProject}
        ratingProject={ratingProject}
        projectStatusId={project.projectStatusId}
      ></EvaluatePopup>
      <RatingInformation
        showRatingContractorPopUp={ratingInformationModal}
        setShowRatingContractorPopUp={setRatingInformationModal}
        informationContractor={project.id ?? 0}
        isProject={true}
        setInfo={setRatingProject}
        setRefresh={(refresh: boolean) => {
          setIsLoadingRate(true);
        }}
      ></RatingInformation>
    </>
  );
};

export default Studies;
