import { useState } from "react";
import { FormNumeric2, FormRadioGroup } from "src/components/formControls";
import { Grid, Typography } from "@mui/material";
import { useAsyncMutation, useLog } from "src/hooks";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import requestTechnicianPermissionService from "src/services/requestTechnicianPermissionService";
import { IRequestTechnicianPermission } from "src/ts/interfaces/requestTechnicianPermission";

interface PermissionGpsProps {
  showPermissionPopUp: boolean;
  setShowPermissionPopUp: (val: boolean) => void;
  gpsPermission: IRequestTechnicianPermission | undefined;
  comments: string;
  setShowDecisionModal: (val: boolean) => void;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
}

const initialValues = {
  radioButtonSelect: "1",
};

export const radioInformation = [
  {
    key: "1",
    value: "Hours",
  },
  {
    key: "2",
    value: "Days",
  },
  {
    key: "5",
    value: "Provide permanent permission for this project",
  },
];

export default function PermissionGPSPopUp({
  setShowPermissionPopUp,
  showPermissionPopUp,
  gpsPermission,
  comments,
  setShowDecisionModal,
  setRefresh,
  refresh,
}: PermissionGpsProps) {
  const { log } = useLog();
  const [permissionUnit, setPermissionUnit] = useState(1);
  const [permissionValue, setPermissionValue] = useState(24);
  const [selectRadioButton, setSelectRadioButton] = useState(
    initialValues.radioButtonSelect
  );

  const { execute, isSubmitting } = useAsyncMutation(
    requestTechnicianPermissionService.update,
    {
      hideSuccessfulMessage: true,
      onSuccess: (result, request) => {
        setShowPermissionPopUp(false);
        setShowDecisionModal(false);
        setRefresh(!refresh);
        log.success(`The permission was ${request[1]}ed`);
      },
    }
  );

  const handleConfirm = async (status: string) => {
    await execute(
      gpsPermission?.guid,
      status,
      comments,
      permissionValue,
      permissionUnit
    );
  };

  return (
    <>
      <DialogMessagePopup
        title={
          "Authorization request for temporary disable AMP Technicians App"
        }
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                {`Are you certain you want to approve this request?`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} pt={2}>
                  <Typography>Yes Provide</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormNumeric2
                    name="provide"
                    label=""
                    value={permissionValue}
                    onChange={(e) => {
                      setPermissionValue(Number(e.target.value));
                    }}
                    disabled={isSubmitting}
                    decimalScale={0}
                  ></FormNumeric2>
                </Grid>
                <Grid item xs={8} pl={3}>
                  <FormRadioGroup
                    name="radioButtonSelect"
                    label=""
                    value={permissionUnit}
                    items={radioInformation}
                    onChange={(e) => {
                      setPermissionUnit(Number(e.target.value));
                    }}
                    defaultValue={selectRadioButton}
                    row={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        showPopup={showPermissionPopUp}
        setShowPopup={setShowPermissionPopUp}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm("accept");
        }}
      ></DialogMessagePopup>
    </>
  );
}
