import { MeasurementSystemEnum, ProjectAddendum } from "./ts/enums/project";
import { ProjectDTO } from "./ts/interfaces/project/projectDto";
import {
  IArchitectData,
  IProjectCreateArch,
} from "./ts/interfaces/projectArchitect";

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const CertificationType = {
  TestingAndBalancing: 1,
  EnergyAuditorInfectiousBuildingControl: 2,
  Both: 3,
};

export const COUNTRIES = {
  USA: 235,
};

export const ROLES = {
  SuperAdministrator: "SuperAdministrator",
  Administrator: "Administrator",
  Architect: "Architect",
  Auditor: "Auditor",
  CompanyOwner: "CompanyOwner",
  EnergyAuditor: "EnergyAuditor",
  ProjectManager: "ProjectManager",
  SysAdmin: "SysAdmin",
  TemporalArchitect: "TemporalArchitect",
  TemporalAuditor: "TemporalAuditor",
  TemporalCompanyOwner: "TemporalCompanyOwner",
  TemporalEnergyAuditor: "TemporalEnergyAuditor",
  TemporalTraining: "TemporalTraining",
  Trainer: "Trainer",
  Training: "Training",
  UnionAdministrator: "UnionAdministrator",
  ArchitectAux: "ArchitectAux",
  TemporalSchool: "TemporalSchool",
};

export const ROLESALIAS = {
  SuperAdministrator: {
    name: "SuperAdministrator",
    alias: "AMP Administrator",
    color: "",
  },
  Administrator: { name: "Administrator", alias: "Administrator", color: "" },
  Architect: { name: "Architect", alias: "Architect", color: "" },
  Auditor: {
    name: "Auditor",
    alias: "Testing & Balancing Technician",
    color: "primary",
  },
  CompanyOwner: { name: "CompanyOwner", alias: "Company Owner", color: "" },
  EnergyAuditor: {
    name: "EnergyAuditor",
    alias: "Energy Auditor / Infectious Building Control",
    color: "green",
  },
  ProjectManager: {
    name: "ProjectManager",
    alias: "Project Manager",
    color: "",
  },
  SysAdmin: { name: "SysAdmin", alias: "System Administratorr", color: "" },
  TemporalArchitect: { name: "TemporalArchitect", alias: "", color: "" },
  TemporalAuditor: {
    name: "TemporalAuditor",
    alias: "Testing & Balancing (In Process)",
    color: "primary",
  },
  TemporalCompanyOwner: { name: "TemporalCompanyOwner", alias: "" },
  TemporalEnergyAuditor: {
    name: "TemporalEnergyAuditor",
    alias: "Energy Auditor / Infectious Building Control (In Process)",
    color: "green",
  },
  TemporalTraining: { name: "TemporalTraining", alias: "" },
  Trainer: { name: "Trainer", alias: "Trainer" },
  Training: { name: "Training", alias: "" },
  UnionAdministrator: {
    name: "UnionAdministrator",
    alias: "Union Administrator",
    color: "",
  },
  ArchitectAux: {
    name: "ArchitectAux",
    alias: "Architect Assistant",
    color: "",
  },
  TemporalSchool: {
    name: "TemporalSchool",
    alias: "Temporal School",
    color: "",
  },
};

export const TICKETLOGIN = {
  CLASSIFICATIONID: -1,
  ID: -1,
  NAME: "Login",
};

export const STATUSES = [
  { key: 1, value: "Enable" },
  { key: 2, value: "Disable" },
];

export const orderStatus = [
  { id: "1", name: "Received" },
  { id: "2", name: "Manufacturing" },
  { id: "3", name: "Shipped" },
  { id: "4", name: "Delivered" },
];

export const SEMESTER = [
  { key: 1, value: "Spring" },
  { key: 2, value: "Summer" },
  { key: 3, value: "Fall" },
  { key: 4, value: "Winter" },
];

export const CERTIFICATION = [
  { key: 1, value: "Testing & Balancing" },
  { key: 2, value: "Energy Auditor / Infectious Building Control" },
  { key: 3, value: "Both" },
];

export const statusExam = {
  FAIL: 0,
  PASS: 1,
  STARTED: 2,
};

export const viewStatusExam = {
  PENDING: 0,
  START: 1,
  PASSED: 2,
  RETEST: 3,
  EXAM: 4,
  NOTEST: 7,
};

export const projectStatus = {
  New: { id: 1, name: "New" },
  Working: { id: 2, name: "Working" },
  Complete: { id: 3, name: "Complete" },
  Removed: { id: 4, name: "Removed" },
  NewContest: { id: 5, name: "New contest" },
  Contest: { id: 6, name: "Contest" },
  Incomplete: { id: 7, name: "Incomplete" },
  Paid: { id: 8, name: "Paid" },
  Canceled: { id: 9, name: "Canceled" },
  Rejected: { id: 10, name: "Rejected" },
  Approved: { id: 11, name: "Approved" },
  InReview: { id: 12, name: "In Review" },
  Reopen: { id: 13, name: "Reopen" },
};

export const PROJECT_INITIAL_VALUES: ProjectDTO = {
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: false,
  buildingId: 0,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  estimatedTimeDurationUnit: null,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  laddersRequired: false,
  specificationsProvided: false,
  printDrawingsAvailableOnSite: false,
  currentProject: false,
  activeProject: false,
  addendumBidForThisProject: ProjectAddendum.None,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  measurementSystem: MeasurementSystemEnum.Imperial,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  isParkingAreaPartOfThisTest: false,
  projectEstimatedTime: [],
  auditAreaCuFt: null,
  auditAreaSqFt: null,
  bogusAuditors: [],
  projectType: "",
};

export const REPORT_CATEGORY_TYPE = {
  AirReportTemplates: 1,
  WaterReportTemplates: 2,
  EnergyCommissioningReportTemplates: 3,
  ComparativeTestReportTemplates: 4,
};

export const colorsStudies: Record<string, string> = {
  "btn-blue-alt": "info.main",
  "btn-purple": "text-purple",
  "bg-green": "success.main",
  "bg-orange": "#fa7753",
};

export const PROJECT_TYPES = {
  TestAndBalanceCertification: 1,
  EnergyCertification: 2,
  Both: 3,
};

export const Glosary = [
  {
    name: "Air-Conditioning",
    content:
      "The control of the temperature, humidity, cleanliness (quality) and movement of air in a confined space.",
  },
  {
    name: "Airflow",
    content:
      "The distribution or movement of air through a space; generally measured in cubic feet per minute (cfm).",
  },
  {
    name: "Air Handling Unit",
    content:
      "The central component of an HVAC system that distributes conditioned air to a variety of destinations. ",
  },
  {
    name: "Algae",
    content: "Small, usually aquatic plants which require light to grow. ",
  },
  { name: "Ambient", content: "The surrounding atmosphere." },
  {
    name: "Ambient Air Temperature",
    content:
      "The surrounding air temperature, such as the outdoor air temperature around a building. ",
  },
  {
    name: "Approach",
    content:
      "The difference between the leaving water temperature and the ambient wet-bulb temperature.",
  },
  { name: "ARI", content: "Air-Conditioning and Refrigeration Institute." },
  {
    name: "ASHRAE",
    content:
      "American Society of Heating, Refrigeration and Air Conditioning Engineers.",
  },
  {
    name: "Biocide",
    content: "A chemical capable of killing living microorganisms. ",
  },
  {
    name: "Biological Contaminants",
    content:
      'Living organisms or agents derived from those organisms (e.g., viruses, bacteria, fungi, and mammal and bird antigens) that can be inhaled and can cause many types of health effects including allergic reactions, respiratory disorders, hypersensitivity diseases, and infectious diseases. Also referred to as "microbiological" or "microbial.” ',
  },
  {
    name: "Bleed",
    content:
      "Water deliberately removed from evaporative cooling equipment to control the concentration of dissolved solids in the system. ",
  },
  {
    name: "BTU (British Thermal Unit)",
    content:
      "The amount of heat required to raise or lower the temperature of one pound of water one degree Fahrenheit. ",
  },
  {
    name: "BTUH (British Thermal Unit Per Hour)",
    content:
      "Establishes a time reference to BTU input or output. A BTUH is how many BTUs are used per hour. ",
  },
  {
    name: "Bypass Connection",
    content:
      "An inlet connection provided in the cold water basin of a unit that allows recirculating water to bypass the heat transfer media when system pumps are running but evaporative cooling is not required. ",
  },
  {
    name: "Capacity",
    content:
      'The output or producing ability of a piece of equipment. Evaporative cooling capacity is normally referred to in BTUHs; the capacity at a standard set of conditions is often referred to as "tons of cooling." ',
  },
  { name: "Carryover", content: "Excessive drift." },
  {
    name: "Casing",
    content: "The exterior panels of an evaporative cooling unit. ",
  },
  {
    name: "Cell",
    content:
      "The smallest subdivision of a unit that can operate independently; often multiple cells are used together to form one “unit” of a greater capacity. ",
  },
  {
    name: "Celsius (C)",
    content:
      "Temperature scale based on the freezing (0 degrees) and boiling (100 degrees) points of water also known as Centigrade. Conversion to Fahrenheit: °F = 1.8(°C) + 32 ",
  },
  {
    name: "CFM (Cubic Feet per Minute)",
    content:
      "A standard measurement of airflow that indicates how many cubic feet of air pass by a stationary point in one minute. ",
  },
  {
    name: "Charge",
    content: "The amount of refrigerant placed in a refrigeration unit. ",
  },
  {
    name: "Chiller",
    content:
      "A device that produces chilled water to provide cooling for HVAC and industrial applications. ",
  },
  { name: "Circulating Water", content: "See “Recirculating Water.” " },
  {
    name: "Cogeneration",
    content:
      "Simultaneous production of two or more forms of useable energy from a single fuel source, e.g., heat energy and electrical or mechanical power, in the same facility. ",
  },
  {
    name: "Coil",
    content:
      "A tube, often including fins, through which gas or liquid is passed, exchanging thermal energy with air or water surrounding it for heating or cooling purposes. ",
  },
  {
    name: "Cold Water Basin",
    content:
      "The collection pan that houses the cold water processed by the evaporative cooling unit.",
  },
  {
    name: "Combined Flow",
    content:
      "The use of both a coil and wet deck surface for heat transfer in a closed circuit cooling tower or evaporative condenser. Combined flow designs reduce evaporation in the coil section. ",
  },
  {
    name: "Comfort Cooling",
    content:
      "The process of treating air to control its temperature to meet the comfort requirements of the occupants of a conditioned space. ",
  },
  {
    name: "Commercial",
    content:
      "The commercial sector is generally defined as non-manufacturing business establishments; this classification includes hotels, restaurants, office buildings, retail stores, educational institutions, etc. ",
  },
  {
    name: "Commissioning",
    content:
      "The start-up of a building that includes testing and adjusting HVAC, electrical, plumbing, and other systems to assure proper functioning and adherence to design criteria. Commissioning also includes the instruction of operating personnel in the use of the building systems. ",
  },
  {
    name: "Compressor",
    content:
      "The pump of a refrigerating mechanism that draws a low-pressure gas on the cooling side of the refrigerant cycle and compresses the gas into the high-pressure side of the cycle. The compressor maintains adequate pressure to cause refrigerant to flow in sufficient quantities to meet the cooling requirements of the system. ",
  },
  {
    name: "Conduction",
    content:
      "The transfer of heat through a solid material. The transfer of heat energy through a material (solid, liquid or gas) by the motion of adjacent atoms and molecules without gross displacement of the particles. ",
  },
  {
    name: "Contactor",
    content: "A switch used to make or break an electrical circuit. ",
  },
  { name: "Convection", content: "The movement of heat by airflow. " },
  {
    name: "Cooling Tower",
    content:
      "Any device in which atmospheric air and water are distributed together over a heat transfer medium in order to lower the temperature of the water through evaporative cooling. ",
  },
  {
    name: "Corrosion Inhibitors",
    content:
      "Chemicals designed to prevent or slow down the waterside corrosion of metals. ",
  },
  {
    name: "Counter flow",
    content:
      "The flow of air is in the opposite direction of the flow of water.",
  },
  {
    name: "CRN",
    content:
      "Canadian Registration Number. This registration is sometimes required on coils shipping into Canada.",
  },
  {
    name: "Cross flow",
    content:
      "The flow of air is at a right angle to the direction of the flow of water. ",
  },
  {
    name: "CTI",
    content:
      "The Cooling Technology Institute (CTI) is an organization comprised of evaporative cooling equipment owners and operators, equipment manufacturers and component suppliers, and water treatment specialists, which advocates and promotes the use of environmentally responsible Evaporative Heat Transfer Systems for the benefit of the public through education, research, standards development, government relations, and technical information exchange. ",
  },
  {
    name: "Current",
    content:
      "A flow of electrons in an electrical conductor. The strength or rate of flow is generally measured in amperes.",
  },
  {
    name: "Damper",
    content:
      "A series of movable plates that can be opened or closed to control the flow of air through a space. ",
  },
  {
    name: "Decibel (dB)",
    content:
      "A decibel describes the relative loudness of a sound. The dimensionless unit of measurement used in noise control. Logarithmically expresses the ratio of sound level to a reference level (0.0002 microbar). ",
  },
  {
    name: "Defrost Cycle",
    content:
      "The process of removing ice or frost buildup from a piece of equipment during the winter months.",
  },
  {
    name: "Delta (or Delta T or ft.)",
    content:
      "A difference in temperature. Often used in the context of the difference between the entering water temperature and the leaving water temperature of a cooling tower or closed circuit cooling tower. ",
  },
  {
    name: "Demand (Utility)",
    content:
      "The rate at which electricity or natural gas is delivered to or by a system, part of a system, or piece of equipment at a given instant or averaged over any designated period of time. Electricity demand is typically expressed in kilowatts. ",
  },
  {
    name: "Demand Billing",
    content:
      "The electric capacity requirement for which a large user pays. It may be based on the customer's peak demand during the contract year, on a previous maximum or on an agreed minimum. Measured in kilowatts. ",
  },
  {
    name: "Demand Charge",
    content:
      "The sum to be paid by a large electricity consumer for its peak usage level.",
  },
  {
    name: "Design Conditions",
    content:
      "A set of conditions specific to the local climate and expected building usage, used to calculate the cooling load for a building. ",
  },
  {
    name: "Dew point",
    content:
      "The temperature at which air becomes saturated with water and begins to condense, forming a dew. ",
  },
  {
    name: "Drift",
    content:
      "The water aerosol carried out of an evaporative cooling unit by the discharge air. ",
  },
  {
    name: "Drift eliminator",
    content:
      "A component of most evaporative cooling units that is designed to remove water droplets from the air passing through it. ",
  },
  {
    name: "Dry-Bulb Temperature (DB)",
    content:
      "The temperature measured by a standard thermometer. A measure of the sensible temperature of air. ",
  },
  {
    name: "Efficiency",
    content: "The ratio of the output to the input of any system.",
  },
  {
    name: "Electric Resistance Heater",
    content: "A device that produces heat through electric resistance. ",
  },
  {
    name: "Energy",
    content:
      "Broadly defined, energy is the capability of doing work. In the electric power industry, energy is more narrowly defined as electricity supplied over time, generally expressed in kilowatts. ",
  },
  {
    name: "Energy Management System",
    content:
      "A control system designed to regulate the energy consumption of a building by controlling the operation of energy consuming systems, such as the heating, ventilation and air conditioning (HVAC), lighting and water heating systems. ",
  },
  {
    name: "Entering Water Temperature (EWT)",
    content:
      "The temperature of the fluid as it returns to the evaporative cooling equipment from the system heat source. ",
  },
  {
    name: "Enthalpy",
    content:
      "A thermodynamic function of a system, equivalent to the sum of the internal energy of the system plus the product of its volume multiplied by the pressure exerted on it by its surroundings. ",
  },
  {
    name: "Equalizer Connection",
    content:
      "A connection in the cold water basin of a unit that allows piping (the piping is called an “equalizer line”) to be run from that unit to the basin of another unit; equalizer lines serve to correct any difference in water levels that may develop during operation. ",
  },
  {
    name: "Evaporative Cooling",
    content:
      "Cooling accomplished through the exchange of latent heat in the form of evaporation. ",
  },
  {
    name: "External Pull down Volume",
    content:
      "The volume of water in any external piping and heat exchangers that will drain back to the unit when the pump is shut down, which is equal to the total pull down volume minus the water suspended in the unit and its distribution system. ",
  },
  {
    name: "External Static Pressure",
    content:
      "The pressure imposed on cooling equipment by external sources such as ductwork and sound attenuation. ",
  },
  {
    name: "Fahrenheit (F)",
    content:
      "A temperature scale in which the boiling point of water is 212 degrees and its freezing point is 32 degrees at normal atmospheric pressure. Conversion to Celsius: °C = (°F – 32) / 1.8 ",
  },
  {
    name: "Fan, Axial",
    content:
      "An air moving device consisting of impeller blades oriented around a central shaft, usually with an aerodynamic inlet housing; axial fans typically move large volumes of air at low pressures as compared to centrifugal fans for the same fan horsepower. ",
  },
  {
    name: "Fan, Centrifugal",
    content:
      "An air moving device consisting of impeller blades radially oriented parallel to a central shaft, bound with a rim and hub; centrifugal fans typically move smaller volumes of air than axial fans but at a higher pressure for the same fan horsepower. ",
  },
  {
    name: "Fan Coil Unit",
    content:
      "A terminal unit that delivers conditioned air directly to the occupied space.",
  },
  {
    name: "Fan Deck",
    content:
      "The finished surface adjacent to a horizontally mounted axial fan, sometimes used as a working surface to perform maintenance when the proper safety precautions are taken (handrails, ladder, etc",
  },
  {
    name: "Fiberglass Reinforced Polyester (FRP)",
    content:
      "A non-corrosive composite material comprised of a plastic resin matrix, glass fiber reinforcement and other additives. ",
  },
  { name: "Fill", content: "See “Wet Deck.” " },
  {
    name: "Filtration",
    content:
      "The process of separating solids from a liquid by means of a filter media through which only the liquid passes. ",
  },
  {
    name: "Flume Box",
    content:
      "A short channel that runs between two cooling towers, allowing water to flow from one cold water basin to another; a flume box serves to correct any difference in water levels that may develop during operation and generally has a greater capacity of water flow than an equalizer line. ",
  },
  {
    name: "Forced Draft",
    content:
      "Refers to the location of the fan(s) on evaporative cooling equipment. On forced draft equipment, the fans are located at the air inlets to “force” or push air through the unit. ",
  },
  {
    name: "Fouling",
    content:
      "Organic growth or other deposits on heat transfer surfaces causing loss of efficiency.",
  },
  {
    name: "Frequency",
    content:
      "The number of cycles that an alternating current moves through in each second. Standard electric utility frequency in the United States is 60 cycles per second (60 Hertz).",
  },
  {
    name: "Full Load Amps (FLA)",
    content: "The current draw of a motor under full load.",
  },
  {
    name: "GPM",
    content:
      "Measure of liquid flow rate (Gallons Per Minute). Typically based on U.S. gallons, sometimes clarified as USGPM.",
  },
  {
    name: "Heat Exchanger",
    content:
      "A device for the transfer of heat energy from the source to the conveying medium. ",
  },
  {
    name: "Heat Pump",
    content:
      "A device that is capable of both heating and cooling space, depending on user comfort requirements. Heat pumps are generally individually controlled and therefore a heat pump in one room may be heating, while a heat pump in an adjacent room may be cooling. ",
  },
  {
    name: "Heat Transfer",
    content: "Moving heat from one location to another. ",
  },
  {
    name: "Hertz (Hz)",
    content:
      "A unit of electromagnetic wave frequency that is equal to one cycle per second. ",
  },
  {
    name: "Horsepower (HP)",
    content:
      "A unit for measuring the rate of doing work. One horsepower equals approximately 746 watts. ",
  },
  {
    name: "Hot Water Basin",
    content:
      "The collection pan that houses the hot water in an evaporative cooling unit with a gravity distribution system. ",
  },
  { name: "Humidity", content: "The amount of moisture in the air." },
  { name: "HVAC", content: "Heating, Ventilation and Air Conditioning. " },
  {
    name: "Induced Draft",
    content:
      "Refers to the location of the fan(s) on evaporative cooling equipment. On induced draft equipment, the fans are located on the air discharge side of the equipment to “induce” air through the unit. ",
  },
  {
    name: "Industrial",
    content:
      "The industrial sector is generally defined as manufacturing, construction, mining, agriculture, fishing, and forestry establishments (Standard Industrial Classification [SIC] codes 01-39). ",
  },
  {
    name: "Interference",
    content:
      "The reintroduction of warm discharge air from one evaporative cooling unit into the air inlet of an adjacent unit. To avoid interference, layout guidelines provided by equipment manufacturers should be closely followed. ",
  },
  { name: "Inverter", content: "See “Variable Frequency Drive.”" },
  {
    name: "ISO 9001",
    content:
      "A comprehensive, internationally recognized standard which is concerned with all aspects of quality management in the design, engineering and manufacturing of a product. ",
  },
  {
    name: "Latent Heat",
    content:
      "Heat that causes a change in state when added or removed, but does not cause a change in temperature. For example, heat that evaporates a substance from a liquid to a vapor but does not increase its temperature. ",
  },
  {
    name: "Leaving Water Temperature (LWT)",
    content:
      "The temperature of the fluid as it leaves the evaporative cooling equipment to return to the system heat source. ",
  },
  {
    name: "Legionella",
    content:
      "A genus of bacteria; most species of this genus are capable of causing disease in humans. LD, Legionnaires’ Disease, is a pneumonia like disease caused by one genus of Legionella. ",
  },
  {
    name: "Life-Cycle Cost",
    content:
      "The amount of money required to own, operate and maintain a piece of equipment over its useful life. ",
  },
  {
    name: "Load",
    content:
      "The demand for services or performance made on a machine or system, i.e. amount of heat rejection required by the evaporative cooling equipment ",
  },
  {
    name: "Louver",
    content:
      "A series of sloping vanes that allow the entrance of air but prevent the escape of water droplets. ",
  },
  {
    name: "Make-Up Water",
    content:
      "Water added to the recirculating water to compensate for losses from evaporation and bleed. ",
  },
  { name: "NEMA", content: "National Electrical Manufacturing Association. " },
  {
    name: "Nominal Cooling Tower Ton",
    content:
      "Defined as 3 GPM of water-cooled from a 95°F (35°C) entering water temperature to an 85°F (29.4°C) leaving water temperature at a 78°F (25.6°C) entering wet-bulb temperature. ",
  },
  {
    name: "Nozzle",
    content: "A device used for regulating and directing the flow of a fluid.",
  },
  {
    name: "Parts Per Million (PPM)",
    content:
      "A unit which represents a comparison of mass to mass, volume to volume, mass to volume, etc.; commonly used to represent the concentration of dissolved solids in the recirculating water of evaporative cooling equipment. ",
  },
  {
    name: "Plenum",
    content:
      "The open area of a cross flow evaporative cooling unit through which air is pulled before being discharged to the atmosphere. ",
  },
  {
    name: "Plume",
    content:
      "Saturated discharge air that forms a visible cloud over evaporative cooling equipment under certain temperature and humidity conditions. ",
  },
  {
    name: "Polyvinyl Chloride (PVC)",
    content:
      "A polymer of vinyl chloride often used for a heat transfer media surface (film) and piping on factory-assembled evaporative cooling equipment. ",
  },
  {
    name: "Power",
    content:
      "The rate at which energy is transferred. Electricity for use as energy is also referred to as power. ",
  },
  {
    name: "Preventive Maintenance",
    content:
      "Regular maintenance implemented to reduce the possibility of sudden or unexpected equipment failures. ",
  },
  { name: "PSI", content: "Pounds per square inch. " },
  { name: "PSIA", content: "Pounds per square inch, absolute. " },
  { name: "PSIG", content: "Pounds per square inch, gauge. " },
  {
    name: "Pull-down",
    content:
      "Water that collects in the cold water basin of a unit when the system pumps shut off. ",
  },
  {
    name: "Pump, Spray",
    content:
      "A water moving device on a closed circuit cooling tower or evaporative condenser for transporting the spray water from the basin to the water distribution system in order to wet the heat transfer surface. ",
  },
  {
    name: "Pump, System",
    content:
      "A flow moving device for transporting the fluid to be cooled (water in a cooling tower; water, glycol, or other fluid in the case of a closed circuit cooling tower) to the tower and back to the system in a continuous loop. ",
  },
  {
    name: "Range",
    content:
      "The difference between the entering water temperature and leaving water temperature of an evaporative cooling unit. See also “Delta.” ",
  },
  {
    name: "Recirculating Water",
    content:
      "The water being circulated over the coil or fill in an evaporative cooling unit. ",
  },
  {
    name: "Recirculation",
    content:
      "Situation that occurs when the warm discharge air flows back into the air inlets of the evaporative cooling equipment. To avoid recirculation, layout guidelines provided by equipment manufacturers should be closely followed. ",
  },
  {
    name: "Reclaiming",
    content:
      "Processing or returning used refrigerant to the manufacturer or processor for disposal or reuse. ",
  },
  {
    name: "Refrigerant",
    content:
      "A chemical that condenses from a vapor to liquid and, in the process, decreases in temperature. ",
  },
  {
    name: "Refrigerant Charge",
    content: "The amount of refrigerant in a system. ",
  },
  {
    name: "Retrofit",
    content:
      "Broad term that applies to any change after the original purchase, such as adding equipment or accessories to an existing installation. ",
  },
  {
    name: "Saturation Temperature",
    content:
      "Also referred to as the boiling point or the condensing temperature. This is the temperature at which a refrigerant will change state from a liquid to a vapor or vice versa. ",
  },
  {
    name: "Scale",
    content:
      "The accumulation of solids from the minerals contained in water, most often referred to as hardness deposits, i.e. calcium and magnesium. ",
  },
  {
    name: "Scale Inhibitor",
    content: "Chemical added to water to inhibit formation of scale.",
  },
  {
    name: "Sensible Heat",
    content:
      "Heat that causes a change in temperature when added or removed, but does not cause a change in state. ",
  },
  {
    name: "Separator",
    content:
      "A device which uses centrifugal force to separate particles from a suspension; used to remove sediment from evaporative cooling systems. ",
  },
  {
    name: "Set point",
    content:
      "The temperature to which a thermostat is set to result in a desired heated space temperature.",
  },
  {
    name: "Sound Attenuator",
    content:
      "Component used on the air inlet or air discharge of an evaporative cooling unit to reduce airborne noise. ",
  },
  {
    name: "Specific Heat",
    content:
      "In English units, the quantity of heat, in BTU, needed to raise the temperature of one pound of material one degree Fahrenheit. ",
  },
  {
    name: "Strainer",
    content: "A filter used to remove large, suspended solids from a liquid.",
  },
  {
    name: "Sub cooled Liquid",
    content:
      "Liquid refrigerant that is cooled below its saturation temperature.",
  },
  {
    name: "Suction Connection",
    content:
      "The outlet connection through which leaving water is pumped back to the chiller. ",
  },
  {
    name: "Sump",
    content: "The cold water basin of the evaporative cooling equipment.",
  },
  {
    name: "Superheated Vapor",
    content:
      "Refrigerant vapor that is heated above its saturation temperature. ",
  },
  {
    name: "Thermal (Energy) Storage",
    content:
      "A technology that lowers the amount of electricity needed for comfort conditioning during utility peak load periods. A building’s thermal energy storage system might, for example, use off-peak power to make ice at night, then use the ice for cooling during the day. ",
  },
  {
    name: "Thermostat",
    content:
      "A temperature control device that consists of a series of sensors and relays that monitor and control the functions of a heating and cooling system. ",
  },
  {
    name: "Ton",
    content:
      "A unit of measurement used for determining cooling capacity. One ton of refrigeration is the equivalent of 12,000 BTUs per hour, or the amount of heat absorbed by melting one ton of ice in twenty-four hours. One “cooling tower” ton takes the heat of compression into account and is therefore the equivalent of 15,000 BTUs per hour. ",
  },
  {
    name: "Total Pull-down Volume",
    content:
      "The sum of the water suspended within the unit and its distribution system during operation, plus the water in any external piping and heat exchangers draining back to the unit when the pump is shut down. ",
  },
  {
    name: "Valve",
    content: "Any device used to control the flow of a fluid through piping.",
  },
  {
    name: "Variable Frequency Drive (VFD)",
    content:
      " An electronic device that controls the speed of a motor by controlling the frequency of the voltage supplied to that motor. Also known as an inverter. ",
  },
  {
    name: "Wet-Bulb Temperature (WB)",
    content:
      "The temperature at which water, by evaporating into air, can bring the air to saturation at the same temperature. ",
  },
  {
    name: "Wet Deck",
    content:
      "A heat transfer surface where air and water interface; also known as fill. ",
  },
];

export const CertificationName = {
  TestingBalancingTechnician: "Testing & Balancing Technician",
  EnergyAuditorInfectiousBuildingControl:
    "Energy Auditor / Infectious Building Control",
};

export const IDCertification = {
  TestingBalancingTechnician: 3,
  EnergyAuditorInfectiousBuildingControl: 6,
};

export const ColorStyleCertification = {
  TestingBalancingTechnician: "blue",
  EnergyAuditorInfectiousBuildingControl: "green",
};

export const ContFuelType = 1;

export const AuditorOptionRemove = {
  Inmediately: 1,
  AtSomeDateTime: 2,
  PrevProjectCompleted: 3,
  EndWorkDay: 4,
};

export const MandatoryMinimumFieldType = {
  Title: 1,
  Switch: 2,
  Required: 3,
};

export const DailyLogType = {
  StartTime: 1,
  BeginBreak: 2,
  EndBreak: 3,
  BeginLunch: 4,
  EndLunch: 5,
  EndOfWorkDay: 6,
  DownTime: 7,
  ProjectMeeting: 8,
  SafetyMeeting: 9,
  TravelTimeLeave: 10,
  TravelTimeArrive: 11,
  Other: 12,
};

export const memorandumStatus = {
  PendingApproval: { id: 1, name: "Pending Approval" },
  Approved: { id: 2, name: "Approved" },
  Rejected: { id: 3, name: "Rejected" },
};

export const changeOrdersStatus = {
  PendingApproval: { id: 1, name: "Pending Approval" },
  Approved: { id: 2, name: "Approved" },
  Rejected: { id: 3, name: "Rejected" },
};

export const COMMENTSTYPE = {
  COMMENTS: 1,
  PRIVATE_NOTES: 2,
  DEFICIENCIES: 3,
};

export const MENU_PROJECTS_ALL = {
  DAILYLOG: 1,
  PRIVATE_NOTES: 2,
  COMMENTS: 3,
  DEFICIENCIES: 4,
  MEMOFUND: 5,
  CWORDER: 6,
  EMC: 7,
  CONVERSION: 8,
  TESTING_UNIT_COST: 9,
  GPS_APP: 10,
  PUNCHLIST: 11,
};

export const PROJECTMENU_ALL: number[] = [
  MENU_PROJECTS_ALL.DAILYLOG,
  MENU_PROJECTS_ALL.PRIVATE_NOTES,
  MENU_PROJECTS_ALL.COMMENTS,
  MENU_PROJECTS_ALL.DEFICIENCIES,
  MENU_PROJECTS_ALL.MEMOFUND,
  MENU_PROJECTS_ALL.CWORDER,
  MENU_PROJECTS_ALL.EMC,
  MENU_PROJECTS_ALL.CONVERSION,
  MENU_PROJECTS_ALL.TESTING_UNIT_COST,
];

export const COMMON_MENU: number[] = [
  MENU_PROJECTS_ALL.EMC,
  MENU_PROJECTS_ALL.CONVERSION,
  MENU_PROJECTS_ALL.GPS_APP,
  MENU_PROJECTS_ALL.PUNCHLIST,
];
export const radioInformation = [
  {
    key: "0",
    value: "Licensed User",
  },
  {
    key: "1",
    value: "Team Member",
  },
];

export const radioInformationContractors = [
  {
    key: "0",
    value: "Contractors",
  },
  {
    key: "1",
    value: "Team Member",
  },
];

export const radioInformationExternal = [
  {
    key: "0",
    value: "Licensed User",
  },
  {
    key: "1",
    value: "Team Member",
  },
  {
    key: "2",
    value: "External",
  },
];

export const radioInformationTeamMember = [
  {
    key: "1",
    value: "Team Member",
  },
];

export const OPTIONSHAREBYEMAIL = {
  DEFAULT_VALUE: "-1",
  LICENSEED_USER: "0",
  TEAM_MEMBER: "1",
  EXTERNAL: "2",
  CONTRACTORS: "3",
};

export const estimatedTime = [
  {
    key: 0,
    value: "Hours",
  },
  {
    key: 1,
    value: "Days",
  },
];

export const storageUnitType = [
  { key: 1, value: "Gb" },
  { key: 2, value: "Mb" },
];

export const TECHNICIANPERMISSIONS = [
  {
    applicationPermissions: "Allow technician to create Buildings and Projects",
    enabled: false,
    caption: "",
    valueData: "canAddBuildingsAndProjects",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions: "Allow technician to add technicians to a Project",
    enabled: false,
    caption: "",
    valueData: "canAddTechniciansToProject",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions:
      "Allow technician to see their Estimated Time Duration and Computer Estimated Time Remaining",
    enabled: false,
    caption: "",
    valueData: "canTechnicianEstimatedTime",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions: "Allow technician to Bypass their Time Card",
    enabled: false,
    caption: "",
    valueData: "canByPassTimeCard",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions:
      "Allow technician to Bypass their Estimated Time Remaining",
    enabled: false,
    caption: "",
    valueData: "canByPassEstimatedTime",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions: "Allow technician to Assign Test Equipment",
    enabled: false,
    caption: "",
    valueData: "canAssignTestEquipment",
    object: "",
    valueText: 0,
  },
  {
    applicationPermissions: "GPS App over-ride",
    enabled: false,
    caption:
      "Technician not required to work through GPS App: Project Location, Project Radius, Daily Time Log, etc. (Not Recommended)",
    valueData: "permanentPermissionTechniciansApp",
    object: "",
    valueText: 0,
  },
];

export const AMPTECHNICIANPERMISSIONS = [
  {
    applicationPermissions:
      "Allow technician a request to temporarily disable their AMP's Technicians App",
    enabled: false,
    caption: "",
    valueData: "canRequestGpsPermission",
    object: "checkBox",
    valueText: 0,
  },
  {
    applicationPermissions: "Make comments required for their travel time stop",
    enabled: false,
    caption: "",
    valueData: "stopReasonRequired",
    object: "checkBox",
    valueText: 0,
  },
  {
    applicationPermissions: "Travel Stop Time Alert After (in minutes)",
    enabled: false,
    caption: "",
    valueData: "paidTravelPermittedStopTime",
    object: "textField",
    valueText: 0,
  },
  {
    applicationPermissions: "Daily Working Time Limit (in hours)",
    enabled: false,
    caption: "",
    valueData: "workingTime",
    object: "textField",
    valueText: 0,
  },
  {
    applicationPermissions: "Maximum Daily Working Time Limit (in hours)",
    enabled: false,
    caption: "",
    valueData: "maxWorkingTime",
    object: "textField",
    valueText: 0,
  },
];

export const CertificationID = {
  TestingBalancingTechnician: 3,
  EnergyAuditorInfectiousBuildingControl: 6,
};

export const RecommendationStatusTab = {
  Pending: 0,
  New: 1,
  Accepted: 3,
  Stored: 4,
  Rewarded: 5,
};

export const DuctType = {
  Rectangular: 1,
  Round: 2,
  Oval: 3,
};

export const PayCartItemsResultMessage = {
  insufficientFunds: 1,
};

export const TechinicianType = {
  PermissionsRequest: "requests",
  PermissionsAssignments: "assignments",
  Users: "users",
};

export const ConfigurableStudiesGroup = {
  configurable: 1,
  nonConfigurable: 3,
};

export const studiesConfig: Record<
  string,
  {
    url: string;
    reportTypeId: number;
    extraField?: string;
    extraFieldValue?: number;
    name?: string;
    reportTitle?: string;
  }
> = {
  PUMP: {
    url: "PumpReport",
    reportTypeId: 1,
    name: "Pump Report",
    reportTitle: "Pump Report",
  },
  HSFA: {
    url: "HeadSheet",
    reportTypeId: 2,
    name: "Engineered Fan Array Report",
    reportTitle: "Engineered Fan Array Report",
  },
  HSFS: {
    url: "HeadSheet",
    reportTypeId: 3,
    name: "Engineered Fan Report",
    reportTitle: "Engineered Fan Report",
  },
  CHIL: {
    url: "Chiller",
    reportTypeId: 21,
    name: "Chiller Test Report",
    reportTitle: "Chiller Test Report",
  },
  TOWE: {
    url: "Tower",
    reportTypeId: 22,
    name: "Tower Test Report",
    reportTitle: "Tower Test Report",
  },
  COTD: {
    url: "Coils",
    reportTypeId: 4,
    extraField: "coilsReportTypeId",
    extraFieldValue: 1,
    name: "Coil Terminal Device Report",
    reportTitle: "Coil Terminal Device Report",
  },
  COAH: {
    url: "Coils",
    reportTypeId: 17,
    extraField: "coilsReportTypeId",
    extraFieldValue: 2,
    name: "Coil AHU Mixed Air Report",
    reportTitle: "Coil AHU Mixed Air Report",
  },
  COET: {
    url: "ElectricCoil",
    reportTypeId: 27,
    extraField: "coilsReportTypeId",
    extraFieldValue: 3,
    name: "Electric Coil Terminal Device Report",
    reportTitle: "Electric Coil Terminal Device Report",
  },
  COEA: {
    url: "ElectricCoil",
    reportTypeId: 28,
    extraField: "coilsReportTypeId",
    extraFieldValue: 4,
    name: "Electric Coil AHU Mixed Air Report",
    reportTitle: "Electric Coil AHU Mixed Air Report",
  },
  PITT: {
    url: "PitotTraverseTest",
    reportTypeId: 5,
    name: "Pitot Traverse Report",
    reportTitle: "Pitot Traverse Report",
  },
  PTFR: {
    url: "PitotTraverseTest",
    reportTypeId: 20,
    name: "Pitot Fan Report",
    reportTitle: "Pitot Fan Report",
  },
  VEMR: {
    url: "VelGridMatrix",
    reportTypeId: 6,
    name: "Velgrid Report",
    reportTitle: "Velgrid Report",
  },
  STCT: {
    url: "StaticReport",
    reportTypeId: 7,
    name: "Static Pressure Report",
    reportTitle: "Static Pressure Report",
  },
  OUTR: { url: "OutletBalnce", reportTypeId: 0, name: "", reportTitle: "" },
  OUMA: {
    url: "OutletMaster",
    reportTypeId: 9,
    name: "Diffuser, Register & Grille Test Report",
    reportTitle: "Diffuser, Register, & Grille Test Report",
  },
  GEMA: {
    url: "GeneralMatrix",
    reportTypeId: 10,
    extraField: "generalReportTypeId",
    extraFieldValue: 1,
    name: "General Matrix Report",
    reportTitle: "General Matrix Report",
  },
  HOWI: {
    url: "GeneralMatrix",
    reportTypeId: 11,
    extraField: "generalReportTypeId",
    extraFieldValue: 2,
    name: "Hot Wire Anemometer Report",
    reportTitle: "Hot Wire Anemometer Report",
  },
  ROVA: {
    url: "GeneralMatrix",
    reportTypeId: 12,
    extraField: "generalReportTypeId",
    extraFieldValue: 3,
    name: "Rotating Vane Anemometer Report",
    reportTitle: "Rotating Vane Anemometer Report",
  },
  AIFO: {
    url: "GeneralMatrix",
    reportTypeId: 13,
    extraField: "generalReportTypeId",
    extraFieldValue: 4,
    name: "Air Foil Report",
    reportTitle: "Air Foil Report",
  },
  CAVR: {
    url: "OutletReport",
    reportTypeId: 14,
    name: "CAV Test Report",
    reportTitle: "CAV Test Report",
  },
  FPBR: {
    url: "OutletReport",
    reportTypeId: 15,
    name: "FPB Test Report",
    reportTitle: "FPB Test Report",
  },
  VAVR: {
    url: "OutletReport",
    reportTypeId: 16,
    name: "VAV Test Report",
    reportTitle: "VAV Test Report",
  },
  ESAH: {
    url: "EnergyStudyAirHydronicsPost",
    reportTypeId: 18,
    name: "Primary Energy Air - Hydronics Report",
    reportTitle: "Primary Energy Air - Hydronics Report",
  },
  BOTS: {
    url: "BoilerTest",
    reportTypeId: 19,
    name: "Boiler Test Report",
    reportTitle: "Boiler Test Report",
  },
  ELEC: {
    url: "EnergyStudyAirHydronicsPost",
    reportTypeId: 24,
    name: "Primary Energy Air - Electric Heat Report",
    reportTitle: "Primary Energy Air - Electric Heat Report",
  },
};

export const psycometrics = [
  {
    key: 1,
    value: "Relative Humidity",
  },
  {
    key: 2,
    value: "Wet bulb °F",
  },
  {
    key: 3,
    value: "Dew Point",
  },
];

export const DryBulbF = {
  Relativeumidity: 1,
  WetBulbF: 2,
  DewPoint: 3,
};

export const MethodMeasurement = {
  AABC: 1,
  LogTchebycheff: 2,
  EqualAreaTABB: 3,
  NEBB: 4,
  AMCA: 5,
};

export const CenterlineStaticPressure = {
  Plus: 1,
  Minus: 2,
  NotAvailable: 3,
};

export const PlaqueOrderStatus = {
  Start: -1,
  Pending: 0,
  Paid: 1,
};

export const ADDUPDATEASSET = {
  GENERAL: 0,
  PURCHASE: 1,
};

export const ASSET_TILE = {
  EQUIPMENT: "Equipment",
  VEHICLE: "Vehicle",
};
export const EquipmentCalibrationTitles = {
  EquimentCalibration: "Equipment Calibration Report Required",
  Equipment: "Equipment Calibration Requirement",
  HVACBelt:
    "To meet design +- tolerance fluid flows through replacing any HVAC Belts and Pulleys that may be required",
  HVACElectric:
    "To meet design +- tolerance fluid flows through replacing HVAC Electric Motors that may be required",
};

export const EquipmentCalibrationShow = {
  OneYear: {
    Text: "1 Year",
    Description:
      "(Ideal for Non-Critical: Commercial Office Buildings, LightIndustrial, Industrial, Transportation Buildings, Education, etc.)",
  },
  SixMonths: {
    Text: "6 Months",
    Description: `(Ideal for Critical: Health Care Facilities, Laboratories, Pharmaceutical Clean Rooms, Isolation Rooms, Nuclear Pharmaceutical Facilities, Nuclear Facilities, Pressure Dependent, Military and Commercial BCR ("Biological Chemical Radiological") Applications, Etc.)`,
  },
};

export const EquipmentCalibrationOthers = {
  HVACMechanical:
    "The installing HVAC mechanical contractor shall bear the cost of purchasing any belts and pulleys necessary to meet the specified tolerances for fluid flow (Recommended).",
  AMPEnergy:
    "The AMP-Energy, Test and Balance (T&B), and infectious disease control contractor for this project shall bear the cost of purchasing any belts and pulleys necessary to meet the specified tolerances for fluid flow.",
  AbovePurposely: "Above purposely not selected.",
  HVACElectric:
    "The installing HVAC mechanical contractor shall bear the cost of purchasing any HVAC electric motors necessary to meet the specified tolerances for fluid flow. (Recommended).",
  CostEnergy:
    "Cost for any Electric Motors requiring purchase will be carried by this projects AMP Energy, T&B, & Infectous Disease Control Project Control",
  AirTesting: "Air Testing and Balancing.",
  DuctLeakage:
    "Duct Leakage Testing, see additional Specifications. Required for any duct equal to or greater than Medium Pressure Classification and witnessed by this projects Architect and/or Engineer (Recommended).",
  HydronicWater: "Hydronic/Water Testing and Balancing.",
  HydromocPipe: "Hydronic pipe leakage testing. See additional Specifications.",
  HvacEnergy: "HVAC Systems Energy Performance Testing.",
  Commissioning: "HVAC Commissioning.",
  SoundTesting:
    "Sound Testing. Locations chosen by this projects Architect and/or Engineer. See additional Specifications.",
  VibrationTesting:
    "Vibration Testing for motors equal to or greater than 1.0 HP or 745.69 Watts. See additional Specifications. ",
  UFADPLenum: "UFAD Plenum leakage testing. See additional Specifications.",
  AbovePurposelyContractor:
    "Above purposely not selected. Contractor will be providing a scpecific proposal based on a previous discussion and directive regarding this project.",
  Other: "Other",
  BuildingEnergybuildingEnergyEfficiency:
    "HVAC Systems Energy Performance Testing & Building Infectious Disease Control Certification.",
  hvacEnergyPerformance:
    "HVAC Systems Energy Performance Testing & Building Infectious Disease Control Certification.",
  NotSelected: "Not Apply",
};

export const typeProject = {
  PROJECTS: "projects",
  INFECTIOUS: "infectious",
  BOTH: "both",
};

export const AssetsConstant = {
  EQUIPMENT: "EQIP",
  VEHICLE: "VEHI",
};

export const HeatingElectricResistancePhase = {
  ThreePhase: "3 Phase",
  TwoWireSinglePhase: "2 Wire Single Phase",
};

export const ASSETSTATUS = {
  ACTIVE: "Active",
  OUTOFSERVICE: "OutOfService",
};

export const TRANSFERTYPE = {
  HEADQUARTERS: "0",
  SATELLITE: "1",
};

export const OUTOFSERVICEOPTIONS = {
  STOLEN: "stolen",
  LOST: "lost",
  REMOVEDFROMSERVICE: "removedFromService",
};

export const ARCHITECTTABS = {
  NEW: 0,
  OUTFORBID: 1,
  AWARDED: 2,
  READYFORREVIEW: 3,
  APPROVED: 4,
  REJECTED: 5,
  CLOSED: 6,
  ARCHIVED: 7,
};

export const ARCHITECTDATA: IArchitectData = {
  NEW: { tab: "0", queryData: 5, pill: "assignedPill", name: "new" },
  OUTFORBID: { tab: "1", queryData: 6, pill: "", name: "outForBid" },
  AWARDED: { tab: "2", queryData: 1, pill: "", name: "awarded" },
  READYFORREVIEW: { tab: "3", queryData: 3, pill: "", name: "readyForReview" },
  APPROVED: { tab: "4", queryData: 11, pill: "approvedPill", name: "approved" },
  REJECTED: { tab: "5", queryData: 10, pill: "rejectedPill", name: "rejected" },
  CLOSED: { tab: "6", queryData: 9, pill: "", name: "closed" },
  ARCHIVED: { tab: "7", queryData: 7, pill: "", name: "archived" },
};

export const stepsCreateProject: IProjectCreateArch[] = [
  { label: "Step 1", key: 0, description: "Project general information" },
  { label: "Step 2", key: 1, description: "Tolerances" },
  { label: "Step 3", key: 2, description: "Studies" },
  { label: "Step 4", key: 3, description: "Configuration" },
  { label: "Step 5", key: 4, description: "Project Out for Bid" },
];
export const SubscriptionType = {
  Free: 1,
  Pay: 2,
  Training: 3,
};

export const complaintDisputeOption = [
  {
    key: "1",
    value: "Potential Project Reporting Fraud.",
  },
  {
    key: "2",
    value:
      "Refusal to correct Reported Versus Actual Field Performance and Data Inconsistencies.",
  },
  {
    key: "3",
    value:
      "Contractor Project and Report Validation Failed their second correction attempt. ",
  },
  {
    key: "4",
    value: "Other. (Please explain and describe below in comments)",
  },
];

export const ratingOptions = [
  {
    key: "1",
    value: "Very Dissatisfied",
  },
  {
    key: "2",
    value: "Dissatisfied",
  },
  {
    key: "3",
    value: "Neutral",
  },
  {
    key: "4",
    value: "Satisfied",
  },
  {
    key: "5",
    value: "Very Satisfied",
  },
];

export const ratingOptionReversed = [
  {
    key: "5",
    value: "Very Satisfied",
  },
  {
    key: "4",
    value: "Satisfied",
  },
  {
    key: "3",
    value: "Neutral",
  },
  {
    key: "2",
    value: "Dissatisfied",
  },
  {
    key: "1",
    value: "Very Dissatisfied",
  },
];

export const ratingOptionsSurvey = [
  {
    key: "5",
    value: "Extremely likely",
  },
  {
    key: "4",
    value: "Very likely",
  },
  {
    key: "3",
    value: "Neutral",
  },
  {
    key: "2",
    value: "Unlikely",
  },
  {
    key: "1",
    value: "Not at all likely",
  },
];

export const lastQuestionRating = [
  {
    key: "5",
    value: "Definitely",
  },
  {
    key: "4",
    value: "Probably",
  },
  {
    key: "3",
    value: "Not sure",
  },
  {
    key: "2",
    value: "Probably not",
  },
  {
    key: "1",
    value: "Definitely not",
  },
];

export const DISPUTEOPTION = {
  POTENTIAL: "1",
  REFUSAL: "2",
  CONTRACTOR: "3",
  OTHER: "4",
};

export const DISPUTECONSTANT = {
  ME: "Me",
  B: "B",
  C: "C",
};

export const INFECTIOUSPROJECTSTABS = {
  NEW: 5,
  OUTFORBID: 6,
  AWARDED: 1,
  COMPLETED: 12,
  APPROVEDBYAMP: 11,
  CLOSED: 9,
  ARCHIVED: 7,
  OTHER: 2,
};

export const NOTIFICATIONTYPE = {
  ID: "id",
  PHOTO: "photo",
};

export const EXAMENRESULT = {
  PASS: "Pass",
  FAIL: "Fail",
};

export const MEASUREMENT = {
  IMPERIAL: { id: 1, measurement: "ft", name: "Miles" },
  METRIC: { id: 2, measurement: "mt", name: "Kms" },
};

export const DemoOptions = {
  Disabled: 1,
  Hide: 2,
};

export const TrainingMessage = "Not available in training mode";

export const RequestStatus = {
  Pending: 1,
  Accepted: 2,
  Rejected: 3,
  Canceled: 4,
  Permanent: 5,
};

export const TABVALUES = {
  PENDING: 1,
};

export const QuestionRatingType = {
  VeryDissatisfied: 1,
  Dissatisfied: 2,
  Neutral: 3,
  Satisfied: 4,
  VerySatisfied: 5,
};

export const MY_PENDINGS_REQUEST_TILES = {
  CHANGEORDER: "Change/Work Order",
  MEMORADUM: "Memorandum Of Understanding",
  EQUIPMENT: "Test Equipment",
  VEHICLE: "Vehicle",
  TECHNICIANGPS: "Technicians GPS Permissions",
};

export const ASSETS_STATUS = {
  PendingApproval: { id: 1, name: "Pending Approval" },
  Approved: { id: 2, name: "Approved" },
  Rejected: { id: 3, name: "Rejected" },
};

export const TYPE_DEVICE = {
  RECTANGULAR: "0",
  ROUND: "1",
};

export const CERTIFICATION_CODE = {
  TESTINGBALANCE: "TBTC",
  ENERGYAUDITOR: "EACR",
};

export const STEPSVALUES = {
  STEP1: "step1",
  STEP2: "step2",
};

export const listArray = ["name", "address", "zip", "city", "stateName"];
