import React from "react";
import { useAuth } from "src/hooks";
import CompanyOwnerDashboard from "../CompanyOwner";
import ArchitectDashboard from "../Architect";
import TrainingDashboard from "../Training";

const SuperAdministratorDashboard = () => {
  const { user } = useAuth();

  return (
    <>
      {(user?.subscriptionId === 2 || user?.subscriptionId === 4) && (
        <CompanyOwnerDashboard />
      )}
      {user?.subscriptionId === 1 && <ArchitectDashboard />}
      {user?.subscriptionId === 3 && <TrainingDashboard />}
    </>
  );
};

export default SuperAdministratorDashboard;
