import axios from "../utils/axios";
import {
  AttendanceTypeEnum,
  ITrainingCoursesAttendance,
} from "src/ts/interfaces/catalogs/trainingCoursesAttendance";

const getByDate = async (trainingCourseId: number, date: Date) => {
  return axios.get<ITrainingCoursesAttendance[]>(
    `TrainingCoursesAttendance?trainingCourseId=${trainingCourseId}&date=${date}`
  );
};

const updateAttendance = async (
  trainingCoursesAttendanceDateId: number,
  trainingCoursesStudentId: number,
  type: AttendanceTypeEnum,
  comments: string,
  date: Date
) => {
  return axios.put<ITrainingCoursesAttendance[]>(
    `TrainingCoursesAttendance?trainingCoursesAttendanceDateId=${trainingCoursesAttendanceDateId}&trainingCoursesStudentId=${trainingCoursesStudentId}&type=${type}&comments=${comments}&date=${date}`
  );
};

const markAll = async (
  trainingCoursesId: number,
  date: Date,
  type: AttendanceTypeEnum
) => {
  return axios.put<ITrainingCoursesAttendance[]>(
    `TrainingCoursesAttendance/MarkAll?trainingCoursesId=${trainingCoursesId}&date=${date}&type=${type}`
  );
};

const trainingCoursesAttendanceService = {
  getByDate,
  updateAttendance,
  markAll,
};

export default trainingCoursesAttendanceService;
