import { useEffect, useMemo } from "react";
import { studiesConfig } from "src/constants";
import HeaderPage from "./HeaderPage";
import { useAsyncQuery } from "src/hooks";
import { IReportType } from "src/ts/interfaces";
import projectReportService from "src/services/study/projectReportService";

interface IHeaderStudiesPage {
  code?: string | null;
  system?: string | null;
  id: number;
}

interface IHeaderPage {
  parentText: string;
  parentLink: string;
  actionSection?: React.ReactNode;
  infoSection?: React.ReactNode;
  pageYoutube?: string;
  reportTypeYoutube?: string;
  headerStudiesPage: IHeaderStudiesPage;
}

const HeaderStudyPage = ({
  actionSection,
  parentText,
  parentLink,
  pageYoutube,
  reportTypeYoutube,
  infoSection,
  headerStudiesPage,
}: IHeaderPage) => {
  const { execute, data } = useAsyncQuery<IReportType>(
    projectReportService.GetParentReport
  );

  useEffect(() => {
    const getData = async () => {
      const studyConfig = studiesConfig[headerStudiesPage?.code ?? ""];
      await execute(headerStudiesPage?.id, studyConfig.reportTypeId);
    };
    if (headerStudiesPage?.id && headerStudiesPage?.code) {
      getData();
    }
  }, [headerStudiesPage?.code, headerStudiesPage?.id]);

  const titleName = useMemo(() => {
    let resultTitle = "";
    if (headerStudiesPage) {
      const studyConfig = studiesConfig[headerStudiesPage?.code ?? ""];
      if (studyConfig) {
        if (headerStudiesPage.system)
          resultTitle = `${studyConfig.name} | ${headerStudiesPage.system}`;
        else resultTitle = `${studyConfig.name}`;
      }
    }
    return resultTitle;
  }, [headerStudiesPage]);

  const parentLinkReport = data
    ? `/studies/${data.route}`.replace("//", "/")
    : parentLink;

  return (
    <>
      <HeaderPage
        title={titleName}
        parentText={data ? data.name : parentText}
        parentLink={parentLinkReport}
        actionSection={actionSection}
        infoSection={infoSection}
        pageYoutube={pageYoutube}
        reportTypeYoutube={reportTypeYoutube}
      ></HeaderPage>
    </>
  );
};

export default HeaderStudyPage;
