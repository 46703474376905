export interface ITermsAndConditionsOptions {
  id: number;
  termsAndConditionsTabsId: number;
  isRequired: boolean;
  description: string;
  descriptionHTML: string;
  parentId?: number;
  subItems?: ITermsAndConditionsOptions[];
  descriptionNew: string;
  acceptTerms?: boolean;
  optionType?: OptionType;
  placeHolder?: string;
  customDescription?: string;
  labelText?: string;
}

export enum OptionType {
  CheckBox = 1,
  TextArea = 2,
  TextBox = 3,
  RadioButton = 4,
}
