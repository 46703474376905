import SendIcon from "@mui/icons-material/Send";
import { Dispatch, SetStateAction, useState } from "react";
import { useAsyncQuery, useLog } from "src/hooks";
import { useNavigate } from "react-router-dom";
import Popup from "src/components/Popup";
import { Alert, Button, Stack } from "@mui/material";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import studyApprovalProcessStatusService from "src/services/study/studyApprovalProcessStatusService";
import {
  IStudyApprovalProcessStatus,
  IStudyApprovalProcessStatusComments,
} from "src/ts/interfaces/study/studyApprovalProcess";
import SvgAmp from "../assets/SvgAmp";
import { AuthUser } from "src/types/auth";

interface SaveEvaluationProps {
  reportId: number;
  projectId: number;
  reportTypeId: number;
  isInfectiousControlProject: boolean;
  statusId: number;
  sectionApprovalComments: IStudyApprovalProcessStatusComments[];
  projectReportId: number;
  userRole: string;
  mainComments: string;
  user: AuthUser;
  setError: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  getProjectReportData?: () => Promise<void>;
}

const SaveEvaluation = (props: SaveEvaluationProps) => {
  const {
    reportId,
    projectId,
    reportTypeId,
    isInfectiousControlProject,
    statusId,
    sectionApprovalComments,
    projectReportId,
    userRole,
    mainComments,
    user,
    setError,
    disabled,
    getProjectReportData,
  } = props;
  const [openRetestWarning, setOpenSaveStudyApprovalWarning] = useState(false);
  const { log } = useLog();
  const navigate = useNavigate();

  const {
    execute: executeSaveStudyApproval,
    isLoading: isLoadingStudyApproval,
  } = useAsyncQuery(studyApprovalProcessStatusService.save, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        await studiesHistoryService.save(
          projectId,
          reportTypeId,
          reportId,
          "StudyApprovalProcessStatusSave"
        );
        log.success(
          `${
            isInfectiousControlProject ? "Infectious Disease / " : ""
          }Energy Study Approval Status was successfully saved.`
        );
        if (getProjectReportData) await getProjectReportData();
      };
      getData();
    },
  });

  const handleSaveStudyApprovalWarningClose = () =>
    setOpenSaveStudyApprovalWarning(false);
  const handleSaveStudyApprovalWarningOk = () => {
    setOpenSaveStudyApprovalWarning(false);
    const currentDate = new Date();
    const sectionApprovalCommentsFinal = [
      ...sectionApprovalComments,
      {
        section: "main",
        comment: mainComments,
        decision: "",
        date: currentDate.toString(),
        id: `main${currentDate.valueOf()}`,
        userId: `${user?.userId}`,
        userName: `${user?.userName}`,
        active: false,
        new: false,
      },
    ];
    const item = {
      studyStatusId: statusId,
      comment: JSON.stringify(
        sectionApprovalCommentsFinal.map((comment) => ({
          ...comment,
          new: false,
        }))
      ),
      projectReportId: projectReportId,
    } as IStudyApprovalProcessStatus;
    executeSaveStudyApproval(item);
  };

  const handleSaveBtnClick = () => {
    if (!mainComments) {
      setError("Main comments must be provided to save the evaluation.");
      return;
    }
    setOpenSaveStudyApprovalWarning(true);
  };

  return (
    <>
      <Popup
        title="Important"
        openPopup={openRetestWarning}
        setOpenPopup={setOpenSaveStudyApprovalWarning}
        onClose={handleSaveStudyApprovalWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="warning">
            Are you certain you want to save this{" "}
            {isInfectiousControlProject ? "Infectious Disease / " : ""}
            Energy study evaluation?.
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleSaveStudyApprovalWarningClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSaveStudyApprovalWarningOk}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={isLoadingStudyApproval || disabled}
        onClick={handleSaveBtnClick}
        autoFocus
        startIcon={<SvgAmp color="#FFFFFF" />}
      >
        {userRole === "SuperAdministrator"
          ? "Save Evaluation"
          : "Request Evaluation Review"}
      </Button>
    </>
  );
};
export default SaveEvaluation;
