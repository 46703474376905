import { IUserPerson } from "../user";
import { ITrainingCoursesAttendancePrivateNote } from "./trainingCoursesAttendancePrivateNote";
import { ITrainingCoursesStudent } from "./trainingCoursesStudent";

export interface ITrainingCoursesAttendance {
  id: number;
  trainingCoursesStudentId?: number;
  trainingCoursesStudent?: ITrainingCoursesStudent;
  comments: string;
  attendanceType: AttendanceTypeEnum;
  trainingCoursesAttendanceDateId?: number;
  //trainingCoursesAttendanceDate?: ITrainingCoursesAttendanceDate;
  createdDate?: Date;
  createdById?: number;
  createdBy?: IUserPerson;
  updatedDate?: Date;
  updatedId?: number;
  updatedBy?: IUserPerson;
  attendance?: number;
  photo: string;
  date?: string;
  trainingCoursesAttendancePrivateNote?: ITrainingCoursesAttendancePrivateNote[];
  arriveLateTime?: string;
  leaveEarlyTime?: string;
}

export enum AttendanceTypeEnum {
  Clear = 0,
  Present = 1,
  ArriveLate = 2,
  LeaveEarly = 3,
  Absent = 4,
  Excused = 5,
}
