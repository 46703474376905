import {
  IconButton,
  List,
  ListItem,
  ListItemButton as ListItemButtonMui,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";
import { CopyAllOutlinedIcon, DeleteIcon } from "src/components/icons";
import {
  IOutletReport,
  IOutletReportComponent,
  IOutletReportCustomParameters,
  IOutletReportItem,
} from "src/ts/interfaces";
import useOutletReport from "../hooks/useOutletReport";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import outletReportItemService from "src/services/study/outletReportItemService";
import { useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useAsyncQuery, useLog } from "src/hooks";
import { ToolTip } from "src/components/others/ToolTip";
import { AddCircleOutline } from "@mui/icons-material";
import outletReportCustomNameParametersService from "src/services/study/outletReportCustomNameParametersService";
import outletReportCustomParametersService from "src/services/study/outletReportCustomParametersService";

const ListItemButton = styled(ListItemButtonMui)`
  padding-right: 25px;
  width: 130px;
`;

interface Props {
  components: IOutletReportComponent[];
  setComponentHandler: (components: IOutletReportComponent[]) => void;
  outletReport: IOutletReport;
  showActions?: boolean;
  updateStudy: () => Promise<void>;
  code: string;
}

const ComponentList = ({
  setComponentHandler: setComponents,
  components,
  outletReport,
  showActions,
  updateStudy,
  code,
}: Props) => {
  const theme = useTheme();
  const {
    outletReportComponentId,
    setOutletReportComponentId,
    items,
    componentValues,
  } = useOutletReport();
  const [showDeleteComponentPopup, setShowDeleteComponentPopup] =
    useState(false);
  const [showDeleteComponentConfirmPopup, setShowDeleteComponentConfirmPopup] =
    useState(false);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { log } = useLog();

  const selectComponetHandler = async (component: IOutletReportComponent) => {
    if (!outletReport.isComplete) {
      await updateStudy();
    }

    setOutletReportComponentId(component.id);
  };

  const { execute: updateOrder } = useAsyncQuery(
    outletReportComponentService.updateOrder
  );

  const copyComponetHandler = async (
    component: IOutletReportComponent,
    index: number
  ) => {
    try {
      const totalCFM = items?.reduce(
        (acc, data: IOutletReportItem) => acc + (data?.requiredCFM ?? 0),
        0
      );

      const newComponent: any = {
        ...componentValues,
        ouletReportId: outletReport.id,
        order: (components.length ?? 0) + 1,
        serialNumber: "",
        serialNumberNotAvailable: false,
        boxAddress: `${component.name ? component.name : "1"} Copy`,
        name: `${component.name ? component.name : "1"} Copy`,
        totalRequiredCFM: totalCFM,
        ouletReport: null,
        size: null,
        actualBoxInletSizeH: null,
        actualInletSqtFt: 0,
      };

      const addComponentRes = await outletReportComponentService.add(
        outletReport.id,
        newComponent
      );

      const customParamRes =
        await outletReportCustomParametersService.getByComponent(component.id);

      if (customParamRes && customParamRes?.data.length > 0) {
        const updatedCustomParams = customParamRes.data.map(
          (item: IOutletReportCustomParameters) => {
            return {
              ...item,
              outletReportComponentId: addComponentRes.data.id,
            };
          }
        );

        await outletReportCustomParametersService.updateAllCustomParameterss(
          updatedCustomParams
        );
      }

      const newComponets = [...components];
      newComponets.splice(index + 1, 0, addComponentRes.data);
      setComponents(newComponets);

      items?.forEach((data: IOutletReportItem) => {
        const outletReportItems: IOutletReportItem = {
          ...data,
          barometricCorrectionRequired: false,
          temperatureCorrectionRequired: false,
          temperatureDB: null,
          wetBulb: null,
          dewPoint: null,
          relHum: null,
          sensibleHeat: 0,
          requiredVelocity: null,
          actualVelocity: null,
          btuh: null,
          actualCFM: null,
          minimumOutletCFMRecorded: null,
          actualMinimumVelocity: null,
          finalCorrectedAirflowACFM: null,
          percentaje: 0,
          outletReportComponentId: addComponentRes.data.id,
        };
        outletReportItemService.addItem(outletReportItems);
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const addNewComponetHandler = async () => {
    const newComponent: any = {
      ouletReportId: outletReport.id,
      order: components.length + 1,
      coilTypeLinked: "None",
      name: (components.length + 1).toString(),
      boxAddress: (components.length + 1).toString(),
    };
    const addComponentRes = await outletReportComponentService.add(
      outletReport.id,
      newComponent
    );

    const newComponets = [...components];
    newComponets.splice(components.length + 1, 0, addComponentRes.data);
    setComponents(newComponets);

    const outletReportItems: any = {};

    if (items.length === 0) outletReportItems.order = 1;
    else outletReportItems.order = (items[items.length - 1]?.order ?? 0) + 1;

    outletReportItems.device = "Hood";
    outletReportItems.outletReportComponentId = addComponentRes.data.id;
    outletReportItemService.addItem(outletReportItems);
  };

  const [componentForDelete, setComponentForDelete] = useState<{
    component: IOutletReportComponent;
    index: number;
  } | null>(null);

  const deleteComponetHandler = (
    component: IOutletReportComponent,
    index: number
  ) => {
    setComponentForDelete({ component: component, index: index });
    setShowDeleteComponentPopup(true);
  };

  const confirmDelete = () => {
    setShowDeleteComponentPopup(false);
    setShowDeleteComponentConfirmPopup(true);
  };

  const deleteHandler = async () => {
    if (
      componentForDelete === undefined ||
      componentForDelete?.component?.id === null
    )
      return;

    try {
      const customParamRes =
        await outletReportCustomParametersService.getByComponent(
          componentForDelete?.component.id!
        );
      if (customParamRes && customParamRes?.data.length > 0) {
        customParamRes.data.forEach(
          async (item: IOutletReportCustomParameters) => {
            await outletReportCustomParametersService.remove(item.id);
          }
        );
      }

      await outletReportItemService.removeByComponent(
        componentForDelete?.component.id!
      );

      const removeComponents = [...components];
      removeComponents.splice(componentForDelete?.index!, 1);
      setComponents(removeComponents);

      await outletReportComponentService.remove(
        componentForDelete?.component.id!
      );

      if (removeComponents.length > 0) {
        //getOutletReportComponentByIdDelete(vm.Components[0].id);
        setOutletReportComponentId(removeComponents[0].id);
      }
      log.success("Item was deleted");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
    }
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (
    event: React.DragEvent<HTMLLIElement>,
    index: number
  ) => {
    event.preventDefault();

    if (draggedIndex === null || draggedIndex === index) return;

    const updatedComponents = [...components];
    const [draggedItem] = updatedComponents.splice(draggedIndex, 1);
    updatedComponents.splice(index, 0, draggedItem);
    updatedComponents.map((item, count) => (item.order = count));

    setOutletReportComponentId(updatedComponents[0].id);
    setComponents(updatedComponents);
    await updateOrder(updatedComponents);
    setDraggedIndex(null);
  };

  return (
    <>
      <Stack direction={"row"}>
        {showActions && (
          <Tooltip title={`Add Another ${code}`}>
            <IconButton onClick={addNewComponetHandler}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        )}
        <List
          component={Stack}
          direction="row"
          flexWrap={"wrap"}
          sx={{
            gap: 1.5,
            padding: 2,
            borderRadius: 3,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          {components.map((component, index) => {
            return (
              <ListItem
                key={component.id}
                disablePadding
                sx={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  cursor: "move",
                  borderRadius: 3,
                }}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={(event) => handleDragOver(event)}
                onDrop={(event) => handleDrop(event, index)}
              >
                <ListItemButton
                  selected={outletReportComponentId === component.id}
                  onClick={() => selectComponetHandler(component)}
                >
                  <ListItemText
                    primary={
                      component.name
                        ? component.name.substring(0, 13)
                        : index + 1
                    }
                  />
                </ListItemButton>

                <ListItemSecondaryAction>
                  <>
                    {showActions && (
                      <>
                        {outletReportComponentId === component.id && (
                          <ToolTip title="Copy Component">
                            <IconButton
                              aria-label="comments"
                              onClick={() =>
                                copyComponetHandler(component, index)
                              }
                            >
                              <CopyAllOutlinedIcon />
                            </IconButton>
                          </ToolTip>
                        )}
                        <ToolTip title="Delete Component">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              deleteComponetHandler(component, index)
                            }
                            disabled={components.length === 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ToolTip>
                      </>
                    )}
                  </>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Stack>
      <DialogMessagePopup
        title="Delete Component"
        text="Are certain you want to remove this Box?"
        showPopup={showDeleteComponentPopup}
        setShowPopup={setShowDeleteComponentPopup}
        onSave={confirmDelete}
        isSubmitting={false}
      />
      <DialogMessagePopup
        title="Delete Component"
        text={
          <>
            <p>
              It is understood that if you continue, you will lose all the data,
              items and parameters within this Box.
            </p>
            <p>Are certain you want to continue?</p>
          </>
        }
        showPopup={showDeleteComponentConfirmPopup}
        setShowPopup={setShowDeleteComponentConfirmPopup}
        onSave={deleteHandler}
        isSubmitting={false}
      />
    </>
  );
};

export default ComponentList;
