import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { Form, useForm, useLog } from "src/hooks";
import LocalUnionsForm from "./LocalUnionsForm";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";
import localUnionsService from "src/services/catalogs/localUnionsService";
import { FormText } from "src/components/formControls";
import DeleteItemMessagePopUp from "../DeleteItemMessagePopUp";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ILocalUnions = {
  id: 0,
  name: "",
  laborUnionsId: 0,
  orderPosition: 0,
  laborUnionsName: "",
  createdDate: new Date(),
  createdById: 0,
  updatedDate: new Date(),
  updatedId: 0,
  statusId: 1,
  countryId: 235,
  country: undefined,
  stateId: 0,
  stateName: "",
  state: undefined,
};

const LocalUnions = () => {
  const { log } = useLog();
  const [selectdTab, setSelectedTab] = useState(0);

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);
  const { execute, data } = useAsyncQuery<ILocalUnions[]>(
    localUnionsService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ILocalUnions>();
  const [showPopUp, setShowPopUp] = useState(false);

  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: ILocalUnions) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const selectDeleteHandler = (row: ILocalUnions) => {
    setCurrentRow(row);
    setConfirmDialogIsOpen(true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await localUnionsService.remove(currentRow?.id);
        log.success("The item was deleted");
        refreshGridHandler();
      } catch (error: any) {
        if (
          error?.message?.exceptionMessage &&
          error?.message?.exceptionMessage.includes("LocalIdAssigned")
        )
          setShowPopUp(true);
        else
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const newHandler = () => {
    setCurrentRow(initialValues);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "laborUnionsName",
      label: "Labor Unions",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "country",
      label: "Country",
      type: "custom",
      sort: false,
      callback: (row: ILocalUnions) => {
        return <div>{row.country?.name}</div>;
      },
    },
    {
      id: "state",
      label: "State",
      type: "custom",
      sort: false,
      callback: (row: ILocalUnions) => {
        return <div>{row.state?.name ?? row.stateName}</div>;
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ILocalUnions) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                handleEdit(row);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => selectDeleteHandler(row)}
                tooltip="Delete"
              />
            )}
          </Stack>
        </div>
      ),
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setRefreshPage(!refreshPage);
  };

  return (
    <>
      <Helmet title="Local Unions" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Local Unions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Local Unions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={newHandler} variant="contained" color="primary">
                <AddIcon />
                {`New Local Unions`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid
        container
        spacing={10}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: 5,
        }}
      >
        <Grid item xs={6}>
          <Tabs value={selectdTab} onChange={handleChangeTab} aria-label="">
            <Tab label="Active" id="1" />
            <Tab label="Inactive" id="2" />
          </Tabs>
        </Grid>
      </Grid>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              {data && (
                <LocalEnhancedTable<ILocalUnions>
                  refreshGrid={true}
                  columns={columns}
                  data={
                    data.filter((item) => item.statusId === selectdTab + 1) ??
                    []
                  }
                  query={filterValues.name}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <LocalUnionsForm
        selectedValue={currentRow}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onUpdate={refreshGridHandler}
      />
      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete Local Unions: ${currentRow?.name}?`}
        matchWord={"DELETE"}
        isDialogOpen={confirmDialogIsOpen}
        setIsDialogOpen={setConfirmDialogIsOpen}
      />
      <DeleteItemMessagePopUp
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
      />
    </>
  );
};

export default LocalUnions;
