import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import { useAsyncQuery, useLog } from "src/hooks";
import projectReportSendAmpService from "src/services/study/projectReportSendAmpService";
import { useNavigate } from "react-router-dom";
import Popup from "src/components/Popup";
import { Alert, Button, Stack } from "@mui/material";
import studiesHistoryService from "src/services/study/studiesHistoryService";

interface SendToAmpProps {
  reportId: number;
  projectId: number;
  reportTypeId: number;
  isInfectiousControlProject: boolean;
  executeReport: (...params: any[]) => Promise<void>;
  getProjectReportData: () => Promise<void>;
  code: string;
}

const SendToAmp = (props: SendToAmpProps) => {
  const {
    reportId,
    projectId,
    reportTypeId,
    isInfectiousControlProject,
    executeReport,
    getProjectReportData,
    code,
  } = props;
  const [openRetestWarning, setOpenSendAmpWarning] = useState(false);
  const { log } = useLog();
  const navigate = useNavigate();

  const { execute: executeSendToAmp, isLoading: isLoadingSendToAmp } =
    useAsyncQuery(projectReportSendAmpService.sendToAmp, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await studiesHistoryService.save(
            projectId,
            reportTypeId,
            reportId,
            "sendToAmp"
          );
          log.success(
            `${
              isInfectiousControlProject ? "Infectious Disease / " : ""
            }Energy study and all of its sub-studies were sent to AMP for evaluation.`
          );
          await executeReport(reportId, code);
          await getProjectReportData();
        };
        getData();
      },
    });

  const handleSendAmpWarningClose = () => setOpenSendAmpWarning(false);
  const handleSendAmpWarningOk = () => {
    setOpenSendAmpWarning(false);
    executeSendToAmp(reportId, projectId, reportTypeId);
  };

  return (
    <>
      <Popup
        title="Important"
        openPopup={openRetestWarning}
        setOpenPopup={setOpenSendAmpWarning}
        onClose={handleSendAmpWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="warning">
            Are you certain you want to send this{" "}
            {isInfectiousControlProject ? "Infectious Disease / " : ""}
            Energy study and all of its sub-studies to AMP to be evaluated?.
            <br />
            <br />
            Do you want to proceed?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleSendAmpWarningClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSendAmpWarningOk}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled={isLoadingSendToAmp}
        onClick={() => setOpenSendAmpWarning(true)}
        autoFocus
        startIcon={<SendIcon />}
      >
        Send this Individual Energy Study for Review
      </Button>
    </>
  );
};
export default SendToAmp;
