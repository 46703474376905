import { ITrainingCourses } from "src/ts/interfaces/catalogs/trainingCourse";
import axios from "../utils/axios";

const getById = async (id: number) => {
  return axios.get<ITrainingCourses>(`/TrainingCourses/` + id);
};

const trainingCoursesService = {
  getById,
};

export default trainingCoursesService;
