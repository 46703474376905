import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Tabs,
  Tab,
  Chip as MuiChip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import {
  useForm,
  Form,
  useLog,
  useLoading,
  usePermissions,
  useAuth,
  useAsyncMutation,
  useAppSelector,
  useAppDispatch,
} from "src/hooks";
import { FormButton, FormSelect, FormText } from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import { AddIcon } from "src/components/icons";
import HeaderPage from "src/components/page/HeaderPage";
import { useTable } from "src/hooks/useTable";
import TechnicianService from "src/services/technicianService";
import { getQueryString } from "./util/common";
import {
  IAudiTechnDataPage,
  IScheduleModal,
} from "src/ts/interfaces/technician";
import moment from "moment";
import { Permission as PermissionTypes } from "src/ts/enums";
import {
  CompanyService,
  NotificationService,
  StateService,
  technicianQuizExamService,
  userCountryAccessService,
} from "src/services";
import TrainingDate from "./components/popUp/TrainingDate";
import technicianService from "src/services/technicianService";
import FileUtils from "src/utils/file";
import DailyLogTechnicians from "./components/DailyLogTechnicians";
import { ICompany, IKeyValue, ISystemCostsCatalog } from "src/ts/interfaces";
import SatellitePopup from "../company/components/SatellitePopup";
import { ViewList } from "@mui/icons-material";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";
import { COUNTRIES, ROLES } from "src/constants";
import userDocumentService from "src/services/userDocumentService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { signalrActions } from "src/redux/slices/signalrActions";
import CertificationRenewalsPopup from "../company/components/CertificationRenewalsPopup";
import systemCostCatalogService from "src/services/systemCostCatalogService";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)<ChipProps>(spacing);

const initialValues = {
  name: "",
  countryId: 235,
  stateId: 0,
  stateName: "",
};

const columns = (
  navigate: NavigateFunction,
  downloadDocument: (row: IAudiTechnDataPage) => void,
  downloadFile: boolean,
  setCurrentRow: (row: IAudiTechnDataPage) => void,
  setDailyLogShowPopUp: (row: boolean) => void,
  companyId: string,
  user: any,
  setDeleteStudyModal: (val: boolean) => void
): ColumnType[] => [
  {
    id: "company",
    label: "Company Name",
    type: "custom",
    sort: true,
    hide: user?.role !== ROLES.SuperAdministrator,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.companyName ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}</Stack>
          {fields.length > 1 && <Stack>@{fields[1]}</Stack>}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      return <>{`${row.name} ${row.lastName}`}</>;
    },
  },
  {
    id: "email",
    label: "Email/Username",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.email ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}@</Stack>
          <Stack>{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "certificationId",
    label: "Certification Type",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          {row.certificationType === "Auditor" ? (
            <Typography color={"primary"} fontWeight={"bold"}>
              Testing & Balacing Technician
            </Typography>
          ) : (
            <Typography color={"green"} fontWeight={"bold"}>
              Energy Auditor / Infectious Building Control
            </Typography>
          )}
        </>
      );
    },
  },
  {
    id: "trainingDate",
    label: "Test/Training Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    align: "center",
    contentAlign: "center",
  },
  {
    id: "testTrainingResult",
    label: "Test/Training Results",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          <Typography textAlign="center">
            {`Q:${row.totalQuestions}, A:${row.correctAnswers} `}
          </Typography>
        </>
      );
    },
  },
  {
    id: "certification",
    label: "Certification",
    type: "custom",
    sort: false,
    maxWidth: "100px",
    align: "center",
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          <Stack direction="column" justifyContent="center" alignItems="center">
            {downloadFile && <CircularProgress size="1rem" />}
            <Stack direction={"row"}>
              {row.certification !== "" && !downloadFile && (
                <GridActionButton
                  type="download"
                  onClick={() => {
                    downloadDocument(row);
                  }}
                  tooltip="Picture "
                />
              )}
              {user?.role === ROLES.SuperAdministrator && !downloadFile && (
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    setCurrentRow(row);
                    setDeleteStudyModal(true);
                  }}
                  tooltip="Remove Pdf Certification "
                />
              )}
            </Stack>
            <Stack direction="row" textAlign="center">
              {row.certification}
            </Stack>
          </Stack>
        </>
      );
    },
  },
  {
    id: "certificationDate",
    label: "Certification Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    maxWidth: "80px",
    align: "center",
    contentAlign: "center",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    align: "center",
    sort: false,
    callback: (row: IAudiTechnDataPage) => {
      return (
        <Stack direction="row">
          <GridActionButton
            type={"edit"}
            onClick={() => {
              navigate(`/app/TechnicianModify/${row.id}/${companyId}`);
            }}
            tooltip="Edit"
          />

          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/UserDocuments/${row.userId}/${companyId}/1`);
            }}
            tooltip="Documents"
          />

          <GridActionButton
            type="key"
            onClick={() => {
              navigate(
                `/app/UserPermissions/${row.userId}/${companyId}/requests`
              );
            }}
            tooltip="Permissions"
          />
          <GridActionButton
            type="location"
            onClick={() => {
              setCurrentRow(row);
              setDailyLogShowPopUp(true);
            }}
            tooltip="Daily log"
          />
        </Stack>
      );
    },
  },
];

const columnsPending = (
  navigate: NavigateFunction,
  fullAccess: boolean,
  handlerTrainingDate: (val: IAudiTechnDataPage) => void,
  setCurrentRow: (row: IAudiTechnDataPage) => void,
  setDailyLogShowPopUp: (row: boolean) => void,
  companyId: string,
  user: any
): ColumnType[] => [
  {
    id: "company",
    label: "Company Name",
    type: "custom",
    sort: true,
    hide: user?.role !== ROLES.SuperAdministrator,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.companyName ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}</Stack>
          {fields.length > 1 && <Stack>@{fields[1]}</Stack>}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      return <>{`${row.name} ${row.lastName}`}</>;
    },
  },
  {
    id: "email",
    label: "Email/Username",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.email ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}</Stack>
          <Stack>@{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "certificationId",
    label: "Certification Type",
    type: "custom",
    sort: true,
    width: "10px",
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          {row.certificationType === "Auditor" ? (
            <Typography color={"primary"} fontWeight={"bold"}>
              Testing & Balacing Technician
            </Typography>
          ) : (
            <Typography color={"green"} fontWeight={"bold"}>
              Energy Auditor / Infectious Building Control
            </Typography>
          )}
        </>
      );
    },
  },
  {
    id: "createdBy",
    label: "Created By",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.createdBy ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}</Stack>
          <Stack>@{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    align: "center",
    contentAlign: "center",
  },
  {
    id: "trainingDate",
    label: "Test/Training Date",
    type: "custom",
    contentAlign: "center",
    sort: true,
    align: "center",

    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          {fullAccess &&
            !row.isCertified &&
            row.certificationId === 6 &&
            !row.pendingPayment && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {row.certification !== "" && (
                  <GridActionButton
                    type="calendar"
                    onClick={() => {
                      handlerTrainingDate(row);
                    }}
                    tooltip="Schedule training "
                  />
                )}
              </Stack>
            )}
          {fullAccess &&
            !row.isCertified &&
            row.certificationId === 3 &&
            !row.pendingPayment && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {row.certification !== "" && (
                  <GridActionButton
                    type="calendar"
                    onClick={() => {
                      handlerTrainingDate(row);
                    }}
                    tooltip="Schedule test "
                  />
                )}
              </Stack>
            )}

          {row.trainingDate !== null && (
            <Stack ml={4}>
              {moment(row.trainingDate).format("MM/DD/YYYY")}
            </Stack>
          )}
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IAudiTechnDataPage) => {
      return (
        <Stack direction="row">
          <GridActionButton
            type={"edit"}
            onClick={() => {
              navigate(`/app/TechnicianModify/${row.id}/${companyId}`);
            }}
            tooltip="Edit"
          />

          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/UserDocuments/${row.userId}/${companyId}/1`);
            }}
            tooltip="Documents"
          />

          <GridActionButton
            type="key"
            onClick={() => {
              navigate(
                `/app/UserPermissions/${row.userId}/${companyId}/requests`
              );
            }}
            tooltip="Permissions"
          />
          <GridActionButton
            type="location"
            onClick={() => {
              setCurrentRow(row);
              setDailyLogShowPopUp(true);
            }}
            tooltip="Daily log"
          />
        </Stack>
      );
    },
  },
  {
    id: "testTraningResults",
    label: "Test/Training Results",
    type: "custom",
    sort: false,
    align: "center",
    contentAlign: "center",
    maxWidth: "10px",
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          {!row.passed && row.totalQuestions !== 0 && !row.isCertified && (
            <Chip
              label="Fail"
              color="error"
              m={1}
              size="small"
              sx={{ width: "-webkit-fill-available" }}
            />
          )}
          {row.passed && row.totalQuestions !== 0 && !row.pendingPayment && (
            <Chip
              label="Pass"
              color="success"
              m={1}
              size="small"
              sx={{ width: "-webkit-fill-available" }}
            />
          )}
          {row.totalQuestions === 0 &&
            !row.pendingPayment &&
            row.trainingDate === null && (
              <Chip
                label="Exam not scheduled"
                color="info"
                m={1}
                size="small"
                sx={{ width: "-webkit-fill-available" }}
              />
            )}
          {row.totalQuestions === 0 &&
            !row.pendingPayment &&
            row.trainingDate !== null && (
              <Chip
                label="Exam scheduled"
                color="primary"
                m={1}
                size="small"
                sx={{ width: "-webkit-fill-available" }}
              />
            )}
          {row.pendingPayment && (
            <Chip
              label="Pending Payment"
              color="warning"
              m={1}
              size="small"
              sx={{ width: "-webkit-fill-available" }}
            />
          )}
        </>
      );
    },
  },
];

const columnsDeactivated = (
  navigate: NavigateFunction,
  setCurrentRow: (row: IAudiTechnDataPage) => void,
  setDailyLogShowPopUp: (row: boolean) => void,
  companyId: string,
  user: any,
  openRenuevalModal: (
    techId: number,
    techType: string,
    name: string
  ) => Promise<void>
): ColumnType[] => [
  {
    id: "company",
    label: "Company Name",
    type: "custom",
    sort: true,
    hide: user?.role !== ROLES.SuperAdministrator,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.companyName ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}</Stack>
          {fields.length > 1 && <Stack>@{fields[1]}</Stack>}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      return <>{`${row.name} ${row.lastName}`}</>;
    },
  },
  {
    id: "email",
    label: "Email/Username",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      var input = row.email ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}@</Stack>
          <Stack>{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "certificationId",
    label: "Certification Type",
    type: "custom",
    sort: true,
    callback: (row: IAudiTechnDataPage) => {
      return (
        <>
          {row.certificationType === "Auditor" ? (
            <Typography color={"primary"} fontWeight={"bold"}>
              Testing & Balacing Technician
            </Typography>
          ) : (
            <Typography color={"green"} fontWeight={"bold"}>
              Energy Auditor / Infectious Building Control
            </Typography>
          )}
        </>
      );
    },
  },
  {
    id: "endCertificationDate",
    label: "Certification End Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    maxWidth: "80px",
    align: "center",
    contentAlign: "center",
  },
  {
    id: "autoRenewal",
    label: "Auto-Renewal",
    type: "custom",
    sort: true,
    align: "center",
    contentAlign: "center",
    callback: (row: IAudiTechnDataPage) => {
      return <>{`${row.autoRenewal ? "Yes" : "No"}`}</>;
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    align: "center",
    sort: false,
    callback: (row: IAudiTechnDataPage) => {
      return (
        <Stack direction="row">
          <GridActionButton
            type={"currencyExchange"}
            onClick={() => {
              openRenuevalModal(
                row.id,
                row.certificationType ?? "",
                `${row.name ?? ""} ${row.lastName ?? ""}`
              );
            }}
            tooltip="Certification Renewal"
          />
          <GridActionButton
            type={"edit"}
            onClick={() => {
              navigate(`/app/TechnicianModify/${row.id}/${companyId}`);
            }}
            tooltip="Edit"
          />

          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/UserDocuments/${row.userId}/${companyId}/1`);
            }}
            tooltip="Documents"
          />

          <GridActionButton
            type="key"
            onClick={() => {
              navigate(
                `/app/UserPermissions/${row.userId}/${companyId}/requests`
              );
            }}
            tooltip="Permissions"
          />
          <GridActionButton
            type="location"
            onClick={() => {
              setCurrentRow(row);
              setDailyLogShowPopUp(true);
            }}
            tooltip="Daily log"
          />
        </Stack>
      );
    },
  },
];

const AuditorTechnicians = () => {
  let { companyId } = useParams<{ companyId: string }>();
  const { startRequest, endRequest } = useLoading();
  const { user } = useAuth();

  const [isActive, setIsActive] = useState<number>(
    user?.role === ROLES.SuperAdministrator ? 1 : 0
  );
  const [scheduleModalInfo, setScheduleModalInfo] = useState<IScheduleModal>();
  const [companyData, setCompanyData] = useState<ICompany>();
  const [currentRow, setCurrentRow] = useState<IAudiTechnDataPage>();
  const [showTraningModal, setShowTraningModal] = useState(false);
  const [showSatellite, setShowSatellite] = useState(false);
  const [itemCostCatalog, setItemCostCatalog] =
    useState<ISystemCostsCatalog | null>(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [certificationRenewalsModal, setCertificationRenewalsModal] =
    useState(false);
  const [deleteStudyModal, setDeleteStudyModal] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const [techName, setTechName] = useState("");
  const [techType, setTechType] = useState("");
  const [technicianId, setTechnicianId] = useState<number>();
  const [dailyLogShowPopUp, setDailyLogShowPopUp] = useState(false);
  const [countryData, setCountryData] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const [statesData, setStatesData] = useState<IKeyValue<number, string>[]>([]);

  const { log } = useLog();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { fullAccess } = usePermissions(PermissionTypes.SuperAdministrator);
  const { fullAccess: fullAccessTechniciansAdmin } = usePermissions(
    PermissionTypes.TechniciansAdmin
  );

  const { execute: executeDeleteDoc, isSubmitting: isLoadingDeleteDoc } =
    useAsyncMutation(technicianService.deleteDocument, {
      successfulMessage:
        "The pdf rcertification have been successfully deleted.",
    });

  const signalRStatus = useAppSelector((state) => state.signalR);

  useEffect(() => {
    NotificationService.readByType(1);
    dispatch(signalrActions.newTechnician(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalRStatus.technicianList > 0) {
      setRefreshPage(!refreshPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.technicianList]);

  const {
    values: filterValues,
    handleInputChange: handleFilterChange,
    setValues: setFilterValues,
  } = useForm(initialValues, true, []);

  const handlerTrainingDate = async (row: IAudiTechnDataPage) => {
    try {
      const responseVerify = await userDocumentService.postVerifyDocument(
        row.userId ?? 0,
        row.id
      );
      if (responseVerify.data === true) {
        const response = await technicianQuizExamService.getByTechId(
          row?.id ?? 0
        );
        const dataModalTitle =
          row?.certificationId === 6
            ? "Schedule training date"
            : "Schedule test date";

        const dataModalText =
          row?.certificationId === 6
            ? "Please select the date for energy auditor test date"
            : "Please select a technician T&B test date";

        const dateModalNotes =
          row?.certificationId === 6
            ? "Once the date is saved, the technician will receive notification indicating the scheduled training date."
            : "Once the date is saved, the technician will receive notification indicating their scheduled test date.";

        const selectedType = row?.certificationId === 6 ? 1 : 2;
        setScheduleModalInfo({
          ...scheduleModalInfo,
          quizId: response.data === null ? 0 : response.data.technicianQuizId,
          quizName:
            response.data === null ? "" : response.data.technicianQuiz.title,
          certificationId: row?.certificationId ?? 0,
          dateModalText: dataModalText,
          dateModalNotes: dateModalNotes,
          dateModalTitle: dataModalTitle,
          technicianId: row?.id ?? 0,
          selectType: selectedType,
          startDate: response.data === null ? null : response.data.startDate,
          passed: row.passed,
        });
        setShowTraningModal(true);
      } else {
        log.error(
          "One of the following documents is missing: ID and/or photo."
        );
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const downloadDocument = async (row: IAudiTechnDataPage) => {
    if (row) {
      setDownloadFile(true);
      try {
        const response = await technicianService.getCertificationTechnician(
          Number(row?.id ?? 0)
        );

        let fileName = "Certification.pdf";

        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setDownloadFile(false);
      }
    } else {
      log.error("Something went wrong");
    }
  };

  const openRenuevalModal = async (
    techId: number,
    techTypes: string,
    name: string
  ) => {
    setTechnicianId(techId);
    setTechName(name);
    setTechType(techTypes);
    const response = await systemCostCatalogService.getByClassification(
      techTypes === "EnergyAuditor"
        ? "EnergyMaintenanceFee"
        : "TestAndBalanceMaintenanceFee"
    );

    if (response.data.length > 0) {
      setItemCostCatalog(response.data[0]);
      setCertificationRenewalsModal(true);
    } else {
      log.error("There is no cost for this classification");
    }
  };

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(
      isActive === 0
        ? columns(
            navigate,
            downloadDocument,
            downloadFile,
            setCurrentRow,
            setDailyLogShowPopUp,
            companyId ?? "0",
            user,
            setDeleteStudyModal
          )
        : isActive === 2
        ? columnsDeactivated(
            navigate,
            setCurrentRow,
            setDailyLogShowPopUp,
            companyId ?? "0",
            user,
            openRenuevalModal
          )
        : columnsPending(
            navigate,
            fullAccess && fullAccessTechniciansAdmin,
            handlerTrainingDate,
            setCurrentRow,
            setDailyLogShowPopUp,
            companyId ?? "0",
            user
          ),
      {
        rowsPerPageOptions: [10],
        defaultOrder: "desc",
        defaultOrderBy: "id",
      }
    );

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("AuditorTechn");
        const request = await TechnicianService.getAll(
          {
            page: page + 1,
            filter: filterValues.name,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            companyId: Number(companyId),
            certified: isActive === 0 || isActive === 2 ? true : false,
            search: filterValues.name,
            countryId:
              user?.role === ROLES.SuperAdministrator
                ? filterValues.countryId
                : 0,
            stateId:
              user?.role === ROLES.SuperAdministrator
                ? filterValues.stateId
                : 0,
            stateName:
              user?.role === ROLES.SuperAdministrator
                ? filterValues.stateName
                : "",
            isActive: isActive === 0,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        endRequest("AuditorTechn");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("AuditorTechn");
      }
    };
    const getCompany = async () => {
      const response = await CompanyService.getById(Number(companyId) ?? 0);
      setCompanyData(response.data);
    };
    if (Number(companyId) !== 0) getData();
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, refreshPage, page, filterValues, orderBy, order, isActive]);

  useEffect(() => {
    const getCountries = async () => {
      startRequest("country");
      const request = await userCountryAccessService.getKeyValues();
      endRequest("country");
      setCountryData(request);
      setFilterValues({ ...filterValues, countryId: COUNTRIES.USA });
    };

    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getState = async () => {
      try {
        startRequest("getstates");
        const request = await StateService.getKeyValues(filterValues.countryId);
        setStatesData(request);
      } catch (error: any) {
        endRequest("getstates");
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("getstates");
      }
    };

    if (filterValues.countryId !== "") getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.countryId]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIsActive(newValue);
    setRefreshPage(!refreshPage);
  };

  const deleteCertificateDocument = () => {
    executeDeleteDoc(currentRow?.id ?? 0);
  };

  const completeRenewal = () => {
    setCertificationRenewalsModal(false);
    setRefreshPage(!refreshPage);
  };

  return (
    <>
      <HeaderPage
        title={"Technicians Request"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <Grid item p={1}>
              {(user?.role === ROLES.Administrator ||
                user?.role === ROLES.CompanyOwner ||
                user?.role === ROLES.Training) && (
                <FormButton
                  text={"Request Technician"}
                  onClick={() => {
                    navigate(`/app/TechnicianModify/0/${companyId}`);
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  color="primary"
                />
              )}
            </Grid>

            <Grid item p={1}>
              {companyData?.satelliteCount !== undefined &&
                companyData?.satelliteCount > 0 &&
                companyData.headquarters && (
                  <FormButton
                    text={"View Satellites Requests"}
                    onClick={() => {
                      setShowSatellite(true);
                    }}
                    startIcon={<ViewList />}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                )}
            </Grid>
          </Grid>
        }
        infoSection={
          !companyData?.headquarters &&
          companyData && <CompanyInformation company={companyData} />
        }
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                {user?.role === ROLES.SuperAdministrator && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormSelect
                        name="countryId"
                        label="Country"
                        value={filterValues.countryId}
                        onChange={handleFilterChange}
                        options={countryData}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {filterValues.countryId !== Number.MIN_SAFE_INTEGER &&
                      filterValues.countryId === COUNTRIES.USA ? (
                        <FormSelect
                          name="stateId"
                          label="State"
                          value={filterValues.stateId}
                          onChange={handleFilterChange}
                          options={statesData}
                          defaultValue={{
                            key: 0,
                            value: "All",
                          }}
                        />
                      ) : (
                        <FormText
                          name="stateName"
                          label="State"
                          value={filterValues.stateName}
                          onChange={handleFilterChange}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                <Divider my={6} />
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={isActive}
                onChange={handleChangeTab}
                aria-label="AuditTech"
              >
                <Tab label="Certified" id="0" value={0} />
                <Tab label="Pending" id="1" value={1} />
                <Tab label="Deactivated" id="2" value={2} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <Table />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showTraningModal && (
        <TrainingDate
          setShowTraningModal={setShowTraningModal}
          showTraningModal={showTraningModal}
          IScheduleModal={scheduleModalInfo ?? {}}
          setRefreshPage={setRefreshPage}
          refreshPage={refreshPage}
        />
      )}
      {dailyLogShowPopUp && (
        <DailyLogTechnicians
          dailyLogShowPopUp={dailyLogShowPopUp}
          setDailyLogShowPopUp={setDailyLogShowPopUp}
          dataTechn={currentRow ?? { id: 0 }}
        />
      )}
      {showSatellite && (
        <SatellitePopup
          showSatellitePopup={showSatellite}
          setShowSatellitePopup={setShowSatellite}
          companyId={Number(companyId) ?? 0}
          companyName={companyData?.name}
          historyView="Techinicians Request"
        />
      )}
      <MatchWordConfirmModal
        onConfirm={deleteCertificateDocument}
        text={`Are you certain you want delete the certification PDF file?`}
        otherText="With this action, the PDF document will be created again when downloaded next time."
        matchWord={"DELETE"}
        isDialogOpen={deleteStudyModal}
        setIsDialogOpen={setDeleteStudyModal}
        isSubmitting={isLoadingDeleteDoc}
      />
      {itemCostCatalog && technicianId && (
        <CertificationRenewalsPopup
          itemCostCatalog={itemCostCatalog}
          showBuyModal={certificationRenewalsModal}
          onChangeShowBuyModal={completeRenewal}
          technicianId={technicianId}
          techName={techName}
          techType={techType}
        ></CertificationRenewalsPopup>
      )}
    </>
  );
};

export default AuditorTechnicians;
