import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useCountry, useForm, useLog } from "src/hooks";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormSelectState,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import { IKeyValue } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";
import laborUnions from "src/services/catalogs/laborUnionsService";
import localUnionsService from "src/services/catalogs/localUnionsService";
import { COUNTRIES, STATUSES } from "src/constants";

interface LocalUnionsFormProps {
  selectedValue?: ILocalUnions;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: ILocalUnions = {
  id: 0,
  name: "",
  laborUnionsId: 0,
  orderPosition: 0,
  laborUnionsName: "",
  createdDate: new Date(),
  createdById: 0,
  updatedDate: new Date(),
  updatedId: 0,
  statusId: 1,
  countryId: 235,
  stateId: 0,
};

const LocalUnionsForm = (props: LocalUnionsFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();
  const [laborUnionsKeyValue, setLaborUnionsKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLaborUnionsActive, setIsLaborUnionsActive] = useState(false);
  const { countriesKeyValue } = useCountry();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isStateLoading, setIsStateLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The SystemCostsCatalog's name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.laborUnionsId = new Validator(fieldValues, "laborUnionsId")
      .selectedOption("", "The Union Organizations's is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    const getLaborUnions = async () => {
      const response = await laborUnions.getKeyValues();
      setLaborUnionsKeyValue(response);

      const find = response.find(
        (element) => element.key === values.laborUnionsId
      );
      setIsLaborUnionsActive(find ? true : false);
    };

    getLaborUnions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.laborUnionsId]);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      setIsSubmiting(true);
      if (values.id === 0) {
        await localUnionsService.add(values);
        log.success("The Local Unions was added succesfully");
      } else {
        await localUnionsService.update(values);
        log.success("The Local Unions was updated succesfully");
      }
    } catch (error: any) {
      if (
        error?.message?.exceptionMessage &&
        error?.message?.exceptionMessage.includes("LaborIdDisabled")
      )
        log.error(
          "This registration cannot be activated because the Labor Union is currently disabled."
        );
      else
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmiting(false);
      setIsDialogOpen(false);
      onUpdate();
    }
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={fullAccess ? (values.id === 0 ? "New" : "Edit") : "view"}
        onClose={() => setIsDialogOpen(false)}
        disableClickOutside
        isSubmitting={isSubmiting}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={isSubmiting}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="laborUnionsId"
                label="Labor Unions"
                value={
                  isLaborUnionsActive ? values.laborUnionsId ?? "null" : "null"
                }
                onChange={handleInputChange}
                options={laborUnionsKeyValue}
                error={errors.laborUnionsId}
                disable={readOnly}
                showSkeleton={isSubmiting}
                defaultValue={{ key: -1, value: "Select a value" }}
                itemDisabledValue={
                  !isLaborUnionsActive
                    ? {
                        key: "null",
                        value: values.laborUnionsName ?? "",
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="countryId"
                label="Country"
                value={values.countryId}
                onChange={handleInputChange}
                options={countriesKeyValue}
                error={errors.countryId}
                showSkeleton={isSubmiting}
                disable={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              {values && values.countryId === COUNTRIES.USA ? (
                <FormSelectState
                  stateId={values.stateId}
                  stateName={values.stateName}
                  countryId={values.countryId}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  disable={readOnly}
                  showSkeleton={isSubmiting}
                />
              ) : (
                <FormText
                  name="stateName"
                  label="State"
                  value={values.stateName}
                  onChange={handleInputChange}
                  error={errors.stateName}
                  disabled={readOnly}
                  showSkeleton={isSubmiting}
                />
              )}
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="statusId"
                label="Status"
                value={values.statusId}
                onChange={handleInputChange}
                options={STATUSES}
                error={errors.statusId}
                defaultValue={{ key: -1, value: "Select a value" }}
                disabled={readOnly}
                showSkeleton={isSubmiting}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={3}>
            <FormCancelButton
              onClick={handleClose}
              isSubmitting={isSubmiting}
            />
            {fullAccess && (
              <FormSaveButton onClick={handleSave} isSubmitting={isSubmiting} />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default LocalUnionsForm;
