import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
  FormRadioGroup,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import {
  Stack,
  Typography,
  Divider as MuiDivider,
  Grid,
  FormControlLabel,
  Radio,
} from "@mui/material";
import FormAccordion from "src/components/formControls/FormAccordion";
import { useEffect, useMemo, useState } from "react";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useAsyncQuery, useForm, useLoading, useLog } from "src/hooks";
import termsAndConditionsService from "src/services/termsAndConditionsService";
import { ITermsAndConditions } from "src/ts/interfaces/catalogs/termsAndConditions";
import termsAndConditionsContractorService from "src/services/termsAndConditionsContractorService";
import projectContestService from "src/services/projectContestService";
import { useNavigate } from "react-router-dom";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import moment from "moment";
import {
  ITermsAndConditionsOptions,
  OptionType,
} from "src/ts/interfaces/catalogs/termsAndConditionsOptions";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

interface ContestTermsProps {
  contestTermsShowPopUp: boolean;
  setContestTermsShowPopUpShowPopUp: (val: boolean) => void;
  currentRow: IProjectOutForBidData | undefined;
}

const initialValues = {
  clause0: false,
  clause1: false,
  clause2: false,
};

export default function ContestTermsPopUp({
  contestTermsShowPopUp,
  setContestTermsShowPopUpShowPopUp,
  currentRow,
}: ContestTermsProps) {
  const [expanded, setExpanded] = useState<string | false>("panel0");
  const [termsAndConditionsOptions, setTermsAndConditionsOptions] =
    useState<ITermsAndConditionsOptions>();
  const [termsAndConditionsOptionsList, setTermsAndConditionsOptionsList] =
    useState<ITermsAndConditionsOptions[]>();
  const [termsAndConditionsOptionsDesc, setTermsAndConditionsOptionsDesc] =
    useState<ITermsAndConditionsOptions>();
  const [disableButton, setDisableButton] = useState(true);

  const { data, execute, isLoading, setData } =
    useAsyncQuery<ITermsAndConditions>(termsAndConditionsService.getCurrent);
  const {
    isLoading: isLoadingAccepted,
    startRequest,
    endRequest,
  } = useLoading();
  const { log } = useLog();
  const { values, setValues } = useForm(initialValues, false, []);
  let navigate = useNavigate();

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const changestatusButton = data.termsAndConditionsTabs.map(
        (value, index) => {
          return values[`clause${index}`];
        }
      );
      const result = changestatusButton.every((value) => value === true);

      if (result) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleCheck = (e: any, item: any, listItems: any) => {
    const { name } = e.target;
    setValues({
      ...values,
      [name]: !values?.[name],
    });
    if (item.optionType === OptionType.RadioButton) {
      listItems.forEach((subitemElement: any) => {
        if (subitemElement.optionType === OptionType.RadioButton) {
          subitemElement.acceptTerms = false;
        }
      });
      setTermsAndConditionsOptionsList([...listItems]);
    }
    item.acceptTerms = !item.acceptTerms;
    setTermsAndConditionsOptions({ ...item });
  };

  const handlerAcceptTerms = async () => {
    try {
      startRequest("Accepted");
      await termsAndConditionsContractorService.postAcceptedTerms(
        currentRow?.id ?? 0,
        { ...data, acceptTerms: true }
      );
      await projectContestService.postCurrentContest(currentRow?.id ?? 0);
      endRequest("Accepted");
      navigate(`/app/TemporalProjectContest/${currentRow?.id}`);
    } catch (error: any) {
      endRequest("Accepted");
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const isTermsAccepted = useMemo(() => {
    let nextOption = -1;
    if (data === null) return false;
    data?.termsAndConditionsTabs.map((value: any, key) => {
      value.isOpen = false;
      if (value.isRequired) {
        let countSelected = 0;
        let countSelectedRequired = 0;
        value.termsAndConditionsOptions
          .filter((element: any) => element.parentId === null)
          ?.map((valueOption: any) => {
            if (
              valueOption.parentId === null &&
              valueOption.isRequired === true &&
              valueOption.subItems.length === 0 &&
              valueOption.optionType !== OptionType.RadioButton
            ) {
              countSelectedRequired++;
            }

            if (
              valueOption.parentId === null &&
              (valueOption.acceptTerms === true ||
                valueOption.customDescription) &&
              valueOption.subItems.length === 0 &&
              valueOption.optionType !== OptionType.RadioButton
            )
              countSelected++;
            if (valueOption.acceptTerms) {
              valueOption.subItems?.map((valueSubOption: any) => {
                if (
                  (valueSubOption.isRequired === true ||
                    valueOption.acceptTerms === true) &&
                  valueSubOption.optionType !== OptionType.RadioButton
                ) {
                  countSelectedRequired++;
                }
                if (
                  (valueSubOption.acceptTerms === true ||
                    valueSubOption.customDescription !== null) &&
                  valueSubOption.optionType !== OptionType.RadioButton
                )
                  countSelected++;
              });
              var findItems = valueOption.subItems.findIndex(
                (emp: any) => emp.optionType === OptionType.RadioButton
              );
              if (findItems !== -1) countSelectedRequired++;

              var findItemsAccept = valueOption.subItems.findIndex(
                (emp: any) =>
                  emp.optionType === OptionType.RadioButton &&
                  emp.acceptTerms === true
              );
              if (findItemsAccept !== -1) countSelected++;
            }
          });
        var findItem = value.termsAndConditionsOptions.findIndex(
          (emp: any) => emp.optionType === OptionType.RadioButton
        );
        if (findItem !== -1) countSelectedRequired++;

        var findItemAccept = value.termsAndConditionsOptions.findIndex(
          (emp: any) =>
            emp.optionType === OptionType.RadioButton &&
            emp.acceptTerms === true
        );
        if (findItemAccept !== -1) countSelected++;

        if (
          (countSelectedRequired <= countSelected &&
            countSelectedRequired > 0) ||
          (countSelectedRequired === 0 && countSelected > 0)
        )
          value.acceptTerms = true;
        else value.acceptTerms = false;
        if (value.isRequired) {
          if (nextOption === -2 && !value.acceptTerms) nextOption = key;
          if (
            nextOption < 0 &&
            value.acceptTerms &&
            (key < 1 ||
              (key >= 1 &&
                data?.termsAndConditionsTabs[key - 1]?.acceptTerms === true))
          )
            nextOption = -2;
        }
      }
    });
    if (nextOption !== -2 && data?.termsAndConditionsTabs?.length > 1) {
      if (nextOption === -1) nextOption = 0;
      setExpanded(`panel${nextOption}`);
      return false;
    } else {
      setExpanded(false);
      return true;
    }
  }, [termsAndConditionsOptions]);

  return (
    <>
      <Popup
        title={`Contest for a project: ${currentRow?.code}`}
        openPopup={contestTermsShowPopUp}
        setOpenPopup={setContestTermsShowPopUpShowPopUp}
        onClose={() => {
          setContestTermsShowPopUpShowPopUp(false);
        }}
        size="md"
        disableClickOutside={true}
        hideClose={isLoadingAccepted}
      >
        <>
          <Stack>
            <Stack direction="row" spacing={11}>
              <Typography> Project Name: </Typography>
              <FormLabel text={currentRow?.name} showSkeleton={isLoading} />
            </Stack>
            <Stack direction="row" spacing={20} mt={2}>
              <Typography> Building: </Typography>
              <Stack direction="row" spacing={2}>
                <FormLabel
                  text={currentRow?.buildingName}
                  showSkeleton={isLoading}
                  sx={{ fontWeight: "bold" }}
                />

                <FormLabel
                  text={`${currentRow?.buildingAddress}`}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={5} mt={2}>
              <Typography> Contest due date: </Typography>
              <FormLabel
                text={`${moment(currentRow?.contestDueDate ?? "")?.format(
                  "MM/DD/YYYY h:mm a"
                )}`}
                showSkeleton={isLoading || isLoadingAccepted}
              />
            </Stack>
          </Stack>
          <br />
          {data?.termsAndConditionsTabs.map((item, index) => {
            return (
              <FormAccordion
                title={`${item.description}`}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <>
                  {item.termsAndConditionsOptions
                    ?.filter((element) => element.parentId === null)
                    ?.map((itemOptions, indexOptions) => {
                      const value = (
                        <FormTextEditor
                          value={itemOptions?.description ?? ""}
                          onChange={() => {}}
                          name={"description"}
                          title={""}
                          readonly={true}
                        />
                      );

                      return (
                        <>
                          {itemOptions.optionType === OptionType.CheckBox && (
                            <FormCheckBox
                              label={value}
                              name={`acceptTerms`}
                              value={itemOptions.acceptTerms}
                              onClick={(e: any) => {
                                handleCheck(
                                  e,
                                  itemOptions,
                                  item.termsAndConditionsOptions
                                );
                              }}
                              labelPlacement="end"
                              checkOnTop={true}
                            />
                          )}
                          {itemOptions.optionType ===
                            OptionType.RadioButton && (
                            <>
                              <FormControlLabel
                                checked={itemOptions.acceptTerms}
                                labelPlacement="end"
                                control={
                                  <Radio
                                    sx={{
                                      marginTop: 1,
                                    }}
                                  />
                                }
                                label={value}
                                onClick={(e: any) => {
                                  handleCheck(
                                    e,
                                    itemOptions,
                                    item.termsAndConditionsOptions
                                  );
                                }}
                                sx={{
                                  alignItems: "flex-start",
                                }}
                              />
                            </>
                          )}
                          {itemOptions.optionType === OptionType.TextArea && (
                            <FormTextArea
                              name={`customDescription`}
                              label={itemOptions.labelText}
                              placeholder={itemOptions.placeHolder}
                              value={itemOptions.customDescription}
                              onChange={(e: any) => {
                                itemOptions.customDescription = e.target.value;
                                if (itemOptions.customDescription)
                                  itemOptions.acceptTerms = true;
                                setTermsAndConditionsOptionsDesc({
                                  ...itemOptions,
                                });
                              }}
                            ></FormTextArea>
                          )}
                          {itemOptions.optionType === OptionType.TextBox && (
                            <FormText
                              name={`customDescription`}
                              placeholder={itemOptions.placeHolder}
                              label={itemOptions.labelText}
                              value={itemOptions.customDescription}
                              onChange={(e: any) => {
                                itemOptions.customDescription = e.target.value;
                                if (itemOptions.customDescription)
                                  itemOptions.acceptTerms = true;
                                setTermsAndConditionsOptionsDesc({
                                  ...itemOptions,
                                });
                              }}
                            ></FormText>
                          )}
                          {itemOptions.acceptTerms &&
                            itemOptions?.subItems?.map(
                              (subItem, indexSubItem) => {
                                const valueSubItem = (
                                  <FormTextEditor
                                    value={subItem?.description ?? ""}
                                    onChange={() => {}}
                                    name={"description"}
                                    title={""}
                                    readonly={true}
                                  />
                                );
                                return (
                                  <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={10}>
                                      {subItem.optionType ===
                                        OptionType.CheckBox && (
                                        <FormCheckBox
                                          label={valueSubItem}
                                          name={`clause${index}${indexOptions}${indexSubItem}`}
                                          value={subItem.acceptTerms}
                                          onClick={(e: any) => {
                                            handleCheck(
                                              e,
                                              subItem,
                                              itemOptions?.subItems
                                            );
                                          }}
                                          labelPlacement="end"
                                          checkOnTop={true}
                                        />
                                      )}
                                      {subItem.optionType ===
                                        OptionType.RadioButton && (
                                        <>
                                          <FormControlLabel
                                            checked={subItem.acceptTerms}
                                            labelPlacement="end"
                                            control={
                                              <Radio
                                                sx={{
                                                  marginTop: 1,
                                                }}
                                              />
                                            }
                                            label={valueSubItem}
                                            onClick={(e: any) => {
                                              handleCheck(
                                                e,
                                                subItem,
                                                itemOptions?.subItems
                                              );
                                            }}
                                            sx={{
                                              alignItems: "flex-start",
                                            }}
                                          />
                                        </>
                                      )}
                                      {subItem.optionType ===
                                        OptionType.TextArea && (
                                        <FormTextArea
                                          name={`customDescription`}
                                          label={subItem.labelText}
                                          placeholder={subItem.placeHolder}
                                          value={subItem.customDescription}
                                          onChange={(e: any) => {
                                            subItem.customDescription =
                                              e.target.value;
                                            if (subItem.customDescription)
                                              subItem.acceptTerms = true;
                                            setTermsAndConditionsOptionsDesc({
                                              ...subItem,
                                            });
                                          }}
                                        ></FormTextArea>
                                      )}
                                      {subItem.optionType ===
                                        OptionType.TextBox && (
                                        <FormText
                                          name={`customDescription`}
                                          placeholder={subItem.placeHolder}
                                          label={subItem.labelText}
                                          value={subItem.customDescription}
                                          onChange={(e: any) => {
                                            subItem.customDescription =
                                              e.target.value;
                                            if (subItem.customDescription)
                                              subItem.acceptTerms = true;
                                            setTermsAndConditionsOptionsDesc({
                                              ...subItem,
                                            });
                                          }}
                                        ></FormText>
                                      )}
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                          <Divider />
                        </>
                      );
                    })}
                </>
              </FormAccordion>
            );
          })}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            onClick={() => {
              setContestTermsShowPopUpShowPopUp(false);
            }}
            disabled={isLoadingAccepted}
            variant="outlined"
          />
          <FormButton
            size="small"
            text="Accept and Continue"
            color="primary"
            onClick={handlerAcceptTerms}
            disabled={!isTermsAccepted || isLoadingAccepted}
          />
        </Stack>
      </Popup>
    </>
  );
}
