import { Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormCheckBox,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { useAsyncQuery } from "src/hooks";
import staticReportService from "src/services/study/staticReportService";
import { CatalogService } from "src/services";
import {
  IStaticIdentDTO,
  IStaticPressureComponentCategoryDTO,
  IStaticPressureComponentDTO,
  IStaticPressureComponentElementDTO,
  IStaticReportDTO,
  IStaticRowDTO,
} from "src/ts/interfaces";
interface ISelectComponentProps {
  openPopup: boolean;
  selectedIndex: number;
  values: IStaticReportDTO;
  selectedUpDown: string;
  staticRow: IStaticRowDTO;
  setOpenPopup: (val: boolean) => void;
  setOpenFanWarningModal: (val: boolean) => void;
  setOpenMaDamperWarningModal: (val: boolean) => void;
  setShowCFM: (val: boolean) => void;
  updateIdent: (val1: IStaticIdentDTO, val2: number) => void;
  isEnergyProcess?: boolean;
}

export const SelectComponent = (props: ISelectComponentProps) => {
  const [category, setCategory] =
    useState<IStaticPressureComponentDTO | null>();
  const [categories, setCategories] = useState<
    IStaticPressureComponentDTO[] | null
  >();
  const [subCategory, setSubCategory] =
    useState<IStaticPressureComponentCategoryDTO | null>();
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [component, setComponent] =
    useState<IStaticPressureComponentDTO | null>();
  const [componentId, setComponentId] = useState(0);
  const [componentOther, setComponentOther] = useState("");
  const [subCategories, setSubCategories] = useState<
    IStaticPressureComponentCategoryDTO[] | null
  >();
  const [components, setComponents] = useState<
    IStaticPressureComponentElementDTO[] | null
  >();
  const [zeroToleranceSpacingBetween, setZeroToleranceSpacingBetween] =
    useState(false);
  const [concernsOfComponentDamage, setConcernsOfComponentDamage] =
    useState(false);
  const [cantPerformDropComments, setCantPerformDropComments] = useState("");

  const [colorSelected, setColorSelected] = useState("inherit");

  const handleCloseComponentDialog = () => {
    props.setOpenPopup(false);
  };

  const getCategories = useAsyncQuery<IStaticPressureComponentDTO>(
    CatalogService.getAll,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCategories(dataResult);
        };
        getData();
      },
    }
  );

  const getSubCategories = useAsyncQuery<IStaticPressureComponentCategoryDTO>(
    staticReportService.getSubcategories,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setSubCategories(dataResult);
        };
        getData();
      },
    }
  );

  const getSubCategoriesHelper = async (
    categoryId: string,
    subCategoryId: number,
    componentId: number
  ) => {
    setSubCategoryId(subCategoryId);
    setComponentId(componentId);
    await getSubCategories.execute(categoryId);
    setComponents([] as IStaticPressureComponentElementDTO[]);
  };

  const getComponents = useAsyncQuery<IStaticPressureComponentElementDTO>(
    staticReportService.getElements,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setComponents(dataResult);
        };
        getData();
      },
    }
  );

  useEffect(() => {
    getCategories.execute("StaticPressureComponent");
  }, []);

  useEffect(() => {
    const getRowData = async () => {
      const [selectedIdent] = (
        props.values?.staticIdent as IStaticIdentDTO[]
      ).filter((v, k) => k === props.selectedIndex);
      setZeroToleranceSpacingBetween(
        selectedIdent?.zeroToleranceSpacingBetween
      );
      setConcernsOfComponentDamage(selectedIdent?.concernsOfComponentDamage);
      setCantPerformDropComments(selectedIdent?.cantPerformDropComments);
      const [rowCategory] = (
        categories as IStaticPressureComponentDTO[]
      ).filter((x) => x.id === props.staticRow.categoryId);
      setCategory(
        rowCategory?.id ? rowCategory : ({} as IStaticPressureComponentDTO)
      );
      await getSubCategoriesHelper(
        `${props.staticRow.categoryId}`,
        props.staticRow.subCategoryId as number,
        props.staticRow.elementId as number
      );
    };

    if (props.staticRow) {
      getRowData();
    }
  }, [props.staticRow]);

  useEffect(() => {
    if (subCategoryId > 0 && subCategories) {
      getComponents.execute(`${subCategoryId}`);
      const [localSubCategory] = (
        subCategories as IStaticPressureComponentCategoryDTO[]
      ).filter((x) => x.id === subCategoryId);
      setSubCategory(localSubCategory);
    } else {
      setSubCategory({} as IStaticPressureComponentCategoryDTO);
      setComponent({} as IStaticPressureComponentElementDTO);
    }
  }, [subCategories, subCategoryId, componentId]);

  useEffect(() => {
    if (componentId > 0 && components) {
      const [component] = (
        components as IStaticPressureComponentElementDTO[]
      ).filter((x) => x.id === componentId);
      setComponent(component);
      setColorSelected(component?.labelColor);
    } else {
      setComponent({} as IStaticPressureComponentElementDTO);
    }
  }, [components, componentId]);

  const changeCategoryHandle = (next: () => void) => {
    setZeroToleranceSpacingBetween(false);
    setConcernsOfComponentDamage(false);
    setCantPerformDropComments("");
    setComponent({} as IStaticPressureComponentDTO);
    setComponentOther("");
    return next;
  };

  const selectedComponent = () => {
    props.setOpenPopup(false);

    if (
      component?.name.includes("Fan") &&
      category?.name === "Fans" &&
      !props.isEnergyProcess
    ) {
      props.setOpenFanWarningModal(true);
      props.setShowCFM(true);
    }

    const fans = props.values.staticIdent?.filter(
      (element) =>
        element.upStaticRow?.component.includes("Fan") ||
        element.downStaticRow?.component
    );

    if (fans?.length) {
      props.setShowCFM(true);
    } else {
      props.setShowCFM(false);
    }

    const selectedIdent = props.values.staticIdent
      ? props.values.staticIdent[props.selectedIndex]
      : ({} as IStaticIdentDTO);

    let newIdent = {
      ...selectedIdent,
      zeroToleranceSpacingBetween,
      concernsOfComponentDamage,
      cantPerformDropComments,
      totalSPDrop:
        zeroToleranceSpacingBetween || concernsOfComponentDamage
          ? 0
          : selectedIdent.totalSPDrop,
      upStaticRow: {
        ...(selectedIdent.upStaticRow as IStaticRowDTO),
        category: category?.name,
        subCategory: subCategory?.name,
        categoryId: category?.id,
        subCategoryId: subCategory?.id,
      },
      downStaticRow: {
        ...(selectedIdent.downStaticRow as IStaticRowDTO),
        category: category?.name,
        subCategory: subCategory?.name,
        categoryId: category?.id,
        subCategoryId: subCategory?.id,
      },
    } as IStaticIdentDTO;

    if (
      component?.name?.includes("Inlet") ||
      component?.name?.includes("Discharge") ||
      component?.name?.includes("inlet") ||
      component?.name?.includes("discharge")
    ) {
      if (props.selectedUpDown === "up") {
        newIdent = {
          ...newIdent,
          upStaticRow: {
            ...(newIdent.upStaticRow as IStaticRowDTO),
            component: component?.name,
            elementId: component?.id,
            element: component,
            componentOther: componentOther,
          },
        } as IStaticIdentDTO;

        //if (vm.staticReport.isEnergy) {
        //  $scope.rows[selectedIndex].upStaticRow.actualFanSystemCFMTotal =
        //    vm.systemCFM;
        //}
      } else if (props.selectedUpDown === "down") {
        newIdent = {
          ...newIdent,
          downStaticRow: {
            ...(newIdent.downStaticRow as IStaticRowDTO),
            component: component.name,
            elementId: component?.id,
            element: component,
            componentOther: componentOther,
          },
        } as IStaticIdentDTO;
      }
    } else {
      newIdent = {
        ...newIdent,
        upStaticRow: {
          ...(newIdent.upStaticRow as IStaticRowDTO),
          component: component?.name,
          elementId: component?.id,
          element: component,
          componentOther: componentOther,
        },
        downStaticRow: {
          ...(newIdent.downStaticRow as IStaticRowDTO),
          component: component?.name,
          elementId: component?.id,
          element: component,
          componentOther: componentOther,
        },
      } as IStaticIdentDTO;
    }
    newIdent = {
      ...newIdent,
      upStaticRow: {
        ...(newIdent.upStaticRow as IStaticRowDTO),
        sign:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? ""
            : selectedIdent.upStaticRow?.sign || "",
        pressure:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? 0
            : selectedIdent.upStaticRow?.pressure || null,
        totalSpDrop:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? 0
            : selectedIdent.upStaticRow?.totalSpDrop || 0,
      },
      downStaticRow: {
        ...(newIdent.downStaticRow as IStaticRowDTO),
        sign:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? ""
            : selectedIdent.downStaticRow?.sign || "",
        pressure:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? 0
            : selectedIdent.downStaticRow?.pressure || null,
        totalSpDrop:
          zeroToleranceSpacingBetween || concernsOfComponentDamage
            ? 0
            : selectedIdent.downStaticRow?.totalSpDrop || 0,
      },
    } as IStaticIdentDTO;

    //Reviewing MA Chamber SP
    if (
      newIdent.upStaticRow?.sign === "+" &&
      newIdent.upStaticRow?.subCategory === "Mixed Air Chamber"
    ) {
      newIdent = {
        ...newIdent,
        maDamperPositiveSP: true,
      } as IStaticIdentDTO;
      props.setOpenMaDamperWarningModal(true);
    } else {
      newIdent = {
        ...newIdent,
        maDamperPositiveSP: false,
        maDamperPositiveSPAnswer: "",
      } as IStaticIdentDTO;
    }

    props.updateIdent(newIdent, props.selectedIndex);
  };

  const categoryChangeHandler = (event: PointerEvent) => {
    const value = (event.target as unknown as { name: string; value: string })
      .value;
    const [item] = (categories as IStaticPressureComponentDTO[]).filter(
      (x) => x.id === parseInt(value)
    );
    setCategory(item);
    changeCategoryHandle(
      getSubCategoriesHelper(
        value as unknown as string,
        -1,
        -1
      ) as unknown as () => void
    );
  };

  const subCategoryChangeHander = (event: PointerEvent) => {
    const value = (event.target as unknown as { name: string; value: string })
      .value;
    const [item] = (
      subCategories as IStaticPressureComponentCategoryDTO[]
    ).filter((x) => x.id === parseInt(value));
    setSubCategory(item);
    changeCategoryHandle(
      getComponents.execute(value as unknown as string) as unknown as () => void
    );
  };

  const componentChangeHandler = (event: PointerEvent) => {
    const value = (event.target as unknown as { name: string; value: string })
      .value;
    const [item] = (components as IStaticPressureComponentElementDTO[]).filter(
      (x) => x.id === parseInt(value)
    );
    setComponent(item);
    setColorSelected(item.labelColor);
  };

  const zeroToleranceChangeHandler = (event: any) => {
    setZeroToleranceSpacingBetween(event.target.value as boolean);
    setConcernsOfComponentDamage(false);
    setCantPerformDropComments("");
  };

  const concernsOfDamageChangeHandler = (event: any) => {
    setConcernsOfComponentDamage(event.target.value as boolean);
    setZeroToleranceSpacingBetween(false);
    setCantPerformDropComments("");
  };

  return (
    <>
      <Popup
        openPopup={props.openPopup}
        onClose={handleCloseComponentDialog}
        setOpenPopup={props.setOpenPopup}
        title="Select Component"
        size="sm"
      >
        <>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormSelect
                options={categories}
                sourceKey="id"
                sourceValue="name"
                name="category"
                label="Category"
                fullWidth
                value={`${category?.id}`}
                onChange={categoryChangeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                options={subCategories}
                sourceKey="id"
                sourceValue="name"
                name="componentSubCategory"
                label="Sub Category"
                fullWidth
                value={`${subCategory?.id}`}
                onChange={subCategoryChangeHander}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                options={components}
                sourceKey="id"
                sourceValue="name"
                name="element"
                label="Component"
                fullWidth
                value={`${component?.id}`}
                onChange={componentChangeHandler}
                colorItems="labelColor"
                colorLabel={colorSelected}
              />
            </Grid>
            {component?.name === "Other" && (
              <Grid item xs={12}>
                <FormText
                  name="componentOther"
                  label="Component Other"
                  value={componentOther}
                  onChange={(event: any) => {
                    setComponentOther(event.target?.value);
                  }}
                />
              </Grid>
            )}
            {component?.id && (
              <>
                <Grid item xs={12}>
                  <FormCheckBox
                    name="zeroToleranceSpacingBetween"
                    label="Component Drop can't be performed due to Zero Tolerance Spacing Between"
                    value={zeroToleranceSpacingBetween}
                    onChange={zeroToleranceChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckBox
                    name="zeroToleranceSpacingBetween"
                    label="Component Drop can't be performed due to Concerns of Drilling and Potential Component Damage"
                    value={concernsOfComponentDamage === true}
                    onChange={concernsOfDamageChangeHandler}
                  />
                </Grid>
              </>
            )}
            {(zeroToleranceSpacingBetween || concernsOfComponentDamage) && (
              <Grid item xs={12}>
                <FormTextArea
                  name="cantPerformDropComments"
                  label="Comments"
                  placeholder="Explain the situation"
                  value={cantPerformDropComments}
                  onChange={(event: any) => {
                    setCantPerformDropComments(event.target?.value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
        <Stack>
          {component?.id &&
            ((!zeroToleranceSpacingBetween && !concernsOfComponentDamage) ||
              cantPerformDropComments.length > 0) && (
              <Button
                variant="contained"
                color="primary"
                onClick={selectedComponent}
              >
                Accept
              </Button>
            )}
        </Stack>
      </Popup>
    </>
  );
};
