import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import {
  useAsyncMutation,
  useAsyncQuery,
  useCountry,
  useForm,
  useStateCatalog,
} from "src/hooks";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import { Validator } from "src/ts/types";
import { ICreditCard, IUserContactInfoSignup } from "src/ts/interfaces";
import {
  FormButton,
  FormCancelButton,
  FormMaskText,
  FormNumeric2,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { COUNTRIES } from "src/constants";
import { WalletService, userService } from "src/services";
import useZipCode from "src/hooks/useZipCode";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import CreditCardForm from "src/components/creditCardForm/CreditCardForm";

const initialValues: ICreditCard = {
  id: 0,
  companyId: 0,
  recurringId: null,
  cardNumber: "",
  name: "",
  lastName: "",
  expirationMonth: "",
  expirationYear: "",
  lastAmount: 1,
  email: "",
  phone: "",
  avsAddress: "",
  avsAddress2: "",
  city: "",
  avsZip: null,
  isActive: true,
  isDeleted: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  alias: "Main",
  serial: "",
  stateId: null,
  stateName: "",
  countryId: 0,
  cardType: "",
  default: false,
  billingCycle: "",
  nextPaymentDate: new Date(),
  cardCCV: "",
  token: "",
  originalAuthData: "",
  ps2000Data: "",
  demo: false,
};
interface Props {
  setModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: (cardConfirm: boolean) => Promise<void>;
}
function ConfirmCreditCard({ setModalCancel, handleNext }: Props) {
  const { setSelectedCountryById, selectedCountry, countriesKeyValue } =
    useCountry();
  const [modalConfirm, setModalConfirm] = useState(false);
  const { states, setCountryId, isLoading } = useStateCatalog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The User's first name is required.")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("The User's last name is required.")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.cardNumber = new Validator(fieldValues, "cardNumber")
      .isRequired("The Card Number is required.")
      .toString();

    temp.expirationMonth = new Validator(fieldValues, "expirationMonth")
      .isRequired("Required.")
      .toString();

    temp.expirationYear = new Validator(fieldValues, "expirationYear")
      .isRequired("Required.")
      .toString();

    temp.cardCCV = new Validator(fieldValues, "cardCCV")
      .isRequired("Required.")
      .toString();

    temp.avsAddress = new Validator(fieldValues, "avsAddress")
      .isRequired("The User's address is required.")
      .maxLength(500, "Only 500 character are allowed.")
      .toString();

    temp.avsAddress2 = new Validator(fieldValues, "avsAddress2")
      .maxLength(500, "Only 500 character are allowed.")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The User's country is required.")
      .toString();

    temp.avsZip = new Validator(fieldValues, "avsZip")
      .isRequired("The User's zipcode is required.")
      .maxLength(5, "Only 5 character are allowed.")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The User's city is required.")
      .maxLength(50, "Only 50 character are allowed.")
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("The User's phone is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();

    temp.email = new Validator(fieldValues, "email")
      .isRequired("The User's email is required.")
      .emailFormat("Incorrect email format.")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .selectedOption(0, "The User's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(fieldValues.countryId !== COUNTRIES.USA)
      .isRequired("The User's state is required.")
      .maxLength(50, "Only 50 character are allowed.")
      .toString();
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );
  const { zipValue } = useZipCode(values.zip);

  const { execute } = useAsyncQuery<IUserContactInfoSignup>(
    userService.GetCurrentContactInfo,
    {
      hideSuccessfulMessage: true,
      onSuccess: (result) => {
        setValues({
          ...values,
          avsAddress: result.address,
          avsAddress2: result.address2,
          avsZip: result.zip,
          email: result.email,
          phone: result.phone,
          countryId: result.countryId,
          city: result.city,
          stateId: result.stateId,
          stateName: result.stateName,
        });
      },
    }
  );

  useEffect(() => {
    setSelectedCountryById(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const { execute: executeCreateCard, isSubmitting } = useAsyncMutation(
    WalletService.createCard,
    {
      hideSuccessfulMessage: true,
      onSuccess: async () => {
        await handleNext(true);
      },
    }
  );

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    setCountryId(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const row = 6;

  const cancelHandler = () => {
    setModalCancel(true);
  };

  const saveHandler = async () => {
    if (!validate()) return;
    setModalConfirm(true);
  };

  const handleCreateCard = async () => {
    if (values.expirationMonth.toString().length === 1)
      values.expirationMonth = `0${values.expirationMonth}`;
    await executeCreateCard(values);
  };

  const handleUseSameContactInfo = async () => {
    await execute();
  };

  const onBlurHandler = async () => {
    let tempValues = { ...values };
    if (tempValues.expirationMonth.toString().length === 1) {
      tempValues.expirationMonth = `0${tempValues.expirationMonth}`;
      setValues({ ...tempValues });
    }
  };

  const validateMonth = async (e: any) => {
    if (e.target.value.toString().length < 3) {
      const re = /^[0-9\b]+$/;
      let tempValues = { ...values };
      if (e.target.value === "" || re.test(e.target.value)) {
        tempValues.expirationMonth = e.target.value;
      }
      if (Number(e.target.value) <= 12) {
        setValues({ ...tempValues });
      }
    }
  };

  const validateCcv = async (e: any) => {
    const re = /^[0-9\b]+$/;
    let tempValues = { ...values };
    if (e.target.value === "" || re.test(e.target.value)) {
      tempValues.cardCCV = e.target.value;
    }
    setValues({ ...tempValues });
  };

  return (
    <>
      <FormTitleBox title={"Card Information "}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={6}>
            <FormText
              name="name"
              label="First Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
              placeholder="Type the first name as it appears in the card"
            />
          </Grid>
          <Grid item xs={6}>
            <FormText
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleInputChange}
              error={errors.lastName}
              showSkeleton={isLoading}
              placeholder="Type the last name as it appears in the card"
            />
          </Grid>
          <CreditCardForm
            values={values}
            setValues={setValues}
            formErrors={errors}
            cardNumberFieldName="cardNumber"
            expiryMonthFieldName="expirationMonth"
            expityYearFieldName="expirationYear"
            ccvFieldName="cardCCV"
          />
        </Grid>
      </FormTitleBox>
      <FormTitleBox title={"Billing Information  "}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            <FormButton
              text={"Billing information same as contact information"}
              variant="outlined"
              onClick={handleUseSameContactInfo}
            ></FormButton>
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="email"
              label="Email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={row}>
            <Stack direction="row" spacing={2}>
              <FormText
                name="countryCode"
                label="Country Code"
                value={selectedCountry?.countryCode?.toString() ?? ""}
                error={errors.countryCode}
                disabled={true}
                fullWidth={false}
                showSkeleton={isLoading}
              />
              <FormMaskText
                name="phone"
                label="Phone"
                value={values.phone}
                onChange={handleInputChange}
                error={errors.phone}
                mask="(999) 999 - 9999"
                showSkeleton={false}
              />
            </Stack>
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="avsAddress"
              label="Address"
              value={values.avsAddress}
              onChange={handleInputChange}
              error={errors.avsAddress}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="avsAddress2"
              label="Address 2"
              value={values.avsAddress2}
              onChange={handleInputChange}
              error={errors.avsAddress2}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={row}>
            <FormSelect
              name="countryId"
              label="Country"
              value={values.countryId}
              onChange={handleInputChange}
              options={countriesKeyValue}
              error={errors.countryId}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="avsZip"
              label="Zip Code"
              value={values.avsZip}
              onChange={handleInputChange}
              error={errors.avsZip}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="city"
              label="City"
              value={values.city}
              onChange={handleInputChange}
              error={errors.city}
              showSkeleton={false}
            />
          </Grid>
          {values.countryId !== Number.MIN_SAFE_INTEGER &&
          values.countryId === COUNTRIES.USA ? (
            <Grid item xs={row}>
              <FormSelect
                name="stateId"
                label="State"
                value={values.stateId}
                onChange={handleInputChange}
                options={states}
                error={errors.stateId}
                showSkeleton={false}
              />
            </Grid>
          ) : (
            <Grid item xs={row}>
              <FormText
                name="stateName"
                label="State"
                value={values.stateName}
                onChange={handleInputChange}
                error={errors.stateName}
                showSkeleton={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormCancelButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
              />
              <FormSaveButton
                text="Save & Continue"
                onClick={saveHandler}
                isSubmitting={isSubmitting}
              />
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
      <DialogMessagePopup
        title={"We need to validate your card"}
        text={
          <Typography>
            <Typography>
              We will withdraw $1.00 USD to validate your card. The funds will
              be returned to your account in the future days.
            </Typography>
            <Typography pt={5}>
              Are you certain you want to proceed to add the card?
            </Typography>
          </Typography>
        }
        showPopup={modalConfirm}
        setShowPopup={setModalConfirm}
        onSave={handleCreateCard}
        isSubmitting={isSubmitting}
      ></DialogMessagePopup>
    </>
  );
}

export default ConfirmCreditCard;
