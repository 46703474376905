/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useAsyncQuery, useAuth } from "src/hooks";
import { FormText } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IStaticReportDTO } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import staticReportService from "src/services/study/staticReportService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StaticStep2 from "../step2";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const StaticStep1 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IStaticReportDTO>(initialValues, false, validate);

  const { data: dataReport, setData: setDataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IStaticReportDTO>(
    staticReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "STCT",
          system: values?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    readOnlyText={disableStudy()}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="system"
                    label="System"
                    value={values.system}
                    onChange={handleInputChange}
                    error={errors.system}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="equipmentLocation"
                    label="Equipment Location"
                    value={values.equipmentLocation}
                    onChange={handleInputChange}
                    error={errors.equipmentLocation}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="conditionOfTest"
                    label="Area Served"
                    value={values.conditionOfTest}
                    onChange={handleInputChange}
                    error={errors.conditionOfTest}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <StaticStep2
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  setErrors={setErrors}
                  setDataReport={setDataReport}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StaticStep1;
