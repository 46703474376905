import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  Box,
  Chip as MuiChip,
  Card as MuiCard,
  CardContent,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { ICatalogType, IKeyValue } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useLog } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import {
  FormDatePicker,
  FormNumeric2,
  FormSelect,
  FormTimePicker,
} from "src/components/formControls";
import FormSaveButton from "src/components/formControls/FormSaveButton";
import FormCancelButton from "src/components/formControls/FormCancelButton";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import CatalogService from "src/services/catalogService";
import {
  CERTIFICATION,
  CertificationType,
  COUNTRIES,
  SEMESTER,
  STATUSES,
} from "src/constants";
import { Validator } from "src/ts/types/Validator";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { localUnionsService } from "src/services";
import laborUnionsService from "src/services/catalogs/laborUnionsService";
import userSchoolsAccessService from "src/services/userSchoolsAccessService";
import DeleteItemMessagePopUp from "./DeleteItemMessagePopUp";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Chip = styled(MuiChip)<ChipProps>(spacing);
const Card = styled(MuiCard)(spacing);

let scores: Record<string, string> = {};
scores.SafetyEquipment = "Safety Equipment";
scores.BuildingType = "Building Type";

scores.FanManufacturer = "Fan Manufacturer";
scores.FanType = "Fan Type";
scores.FanSubType = "Fan SubType";
scores.BeltType = "Belt Type";
scores.FanSheave = "FanSheave";

scores.FanMotorManufacturer = "Fan Motor Manufacturer";
scores.MotorManufacturer = "Motor Manufacturer";
scores.MotorSheaveMfg = "Motor Sheave Mfg.";
scores.MotorFrameCategory = "Motor Frame Category";

scores.PumpType = "Pump Type";
scores.PumpSvc = "Pump Svc";
scores.PumpManufacturer = "Pump Manufacturer";
scores.FlowDesign = "Flow Design";

scores.TicketClassification = "Ticket Classification";
scores.TechnicianQuizCategory = "Quiz Category";
scores.TrainingCourses = "Training Courses";
scores.LaborUnions = "Labor Unions";

const initialValues = {
  id: 0,
  name: "",
  statusId: 1,
  description: "",
  endDate: null,
  startTime: null,
  endTime: null,
  beginDate: null,
  schoolsId: null,
  certification: null,
  semester: null,
  orderPosition: 0,
  localUnionsId: 0,
  laborUnionsId: 0,
  laborUnionsOther: "",
  localUnionsOther: "",
};

const Catalog = () => {
  let { catalog } = useParams<{ catalog: string }>();

  let safeCatalog: string = "";
  if (catalog !== undefined) safeCatalog = catalog;

  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataSet, setDataSet] = useState<ICatalogType[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<ICatalogType>();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [selectdTab, setSelectedTab] = useState(0);
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);

  const [schools, setSchools] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const [localUnions, setLocalUnions] = useState<IKeyValue<number, string>[]>(
    []
  );
  const [laborUnions, setLaborUnions] = useState<IKeyValue<number, string>[]>(
    []
  );
  const minDate = new Date();
  useEffect(() => {
    const getCatalogs = async () => {
      var result = await userSchoolsAccessService.getKeyValues();
      setSchools(result);

      const laborUnionResult = await laborUnionsService.getKeyValues();
      setLaborUnions([...laborUnionResult]);
    };
    getCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    temp.name = new Validator(fieldValues, "name")
      .isRequired("The name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    if (catalog === "TrainingCourses") {
      temp.description = new Validator(fieldValues, "description")
        .isRequired("The description is required")
        .toString();

      temp.endDate = new Validator(fieldValues, "endDate")
        .isRequired("The date is required.")
        .toString();

      temp.startTime = new Validator(fieldValues, "startTime")
        .isRequired("The time is required.")
        .toString();

      temp.endTime = new Validator(fieldValues, "endTime")
        .isRequired("The time is required.")
        .toString();

      temp.beginDate = new Validator(fieldValues, "beginDate")
        .isRequired("The date is required.")
        .toString();

      if (process.env.REACT_APP_ENVIRONMENT === "SCHOOL") {
        temp.schoolsId = new Validator(fieldValues, "schoolsId")
          .selectedOption(null, "The school is required.")
          .toString();
      }

      if (!isOtherLaborUnion()) {
        temp.laborUnionsId = new Validator(fieldValues, "laborUnionsId")
          .selectedOption(0, "The Labor Union is required.")
          .toString();
        temp.laborUnionsOther = "";
      } else {
        temp.laborUnionsOther = new Validator(fieldValues, "laborUnionsOther")
          .isRequired("The Labor Union is required.")
          .toString();
        temp.laborUnionsId = "";
      }

      if (!isOtherLaborUnion()) {
        temp.localUnionsId = new Validator(fieldValues, "localUnionsId")
          .selectedOption(0, "The Local Union is required.")
          .toString();
        temp.localUnionsOther = "";
      } else {
        temp.localUnionsOther = new Validator(fieldValues, "localUnionsOther")
          .isRequired("The Local Union is required.")
          .toString();
        temp.localUnionsId = "";
      }
    }

    temp.statusId = new Validator(fieldValues, "statusId")
      .selectedOption("", "The status is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const { log } = useLog();

  useEffect(() => {
    const getLocalUnions = async () => {
      const localUnionResult = await localUnionsService.getKeyValuesByLabor(
        values.laborUnionsId
      );
      setLocalUnions(localUnionResult);
    };

    getLocalUnions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.laborUnionsId]);

  useEffect(() => {
    const getData = async () => {
      if (!catalog) return;
      setShowSkeleton(true);
      const response = await CatalogService.getAll(catalog + "/status");
      setDataSet(response.data);
      setShowSkeleton(false);
    };

    getData();
  }, [catalog, refreshGrid]);

  const handleOpen = () => {
    resetForm();
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleEdit = (row: ICatalogType) => {
    setValues(row);
    setIsDialogOpen(true);
  };

  const handleSave = async () => {
    if (!validate()) return;

    setIsSubmitting(true);
    let newValues = { ...values };
    if (!isOtherLaborUnion) newValues.isOtherLaborUnion = "";
    if (!isOtherLocalUnion) newValues.localUnionsOther = "";
    setValues({
      ...newValues,
    });

    if (values.id === 0) {
      await CatalogService.add(catalog!, values)
        .then((response) => {
          setIsDialogOpen(false);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          log.success(`${scores[safeCatalog]} was created`);
          resetForm();
        })
        .catch((error: any) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        });
    } else {
      await CatalogService.update(catalog!, values.id, values)
        .then((response) => {
          setIsDialogOpen(false);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          log.success(`${scores[safeCatalog]} was saved`);
          resetForm();
        })
        .catch((error: any) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
          setIsSubmitting(false);
          setIsDialogOpen(false);
        });
    }
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await CatalogService.deleteById(catalog!, currentRow.id);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        if (
          (error?.message?.exceptionMessage &&
            error?.message?.exceptionMessage.includes("LaborIdAssigned")) ||
          error?.message?.exceptionMessage.includes("BuildingTypeAssigned")
        )
          setShowPopUp(true);
        else
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const isOtherLaborUnion = () => {
    if (!laborUnions) return false;
    const found = laborUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.laborUnionsId === found?.key;
  };

  const isOtherLocalUnion = () => {
    if (!localUnions) return false;
    const found = localUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.localUnionsId === found?.key;
  };

  const { fullAccess, readOnly } = usePermissions(
    catalog === "TechnicianQuizCategory"
      ? PermissionTypes.Quiz
      : catalog === "TrainingCourses"
      ? PermissionTypes.TrainingCoursesCatalog
      : PermissionTypes.Catalogs
  );

  const columns = (catalog: string): ColumnType[] => [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "orderPosition",
      label: "Order Position",
      type: "string",
      sort: true,
      hide: catalog !== "LaborUnions",
    },
    {
      id: "location",
      label: "Location",
      type: "custom",
      sort: true,
      hide:
        catalog !== "TrainingCourses" ||
        process.env.REACT_APP_ENVIRONMENT === "UNION" ||
        process.env.REACT_APP_ENVIRONMENT === "SCHOOL",
      callback: (row: ICatalogType) => {
        return (
          <>
            {row.unionAffiliationNumber &&
              `${row.unionAffiliationNumber?.localUnion} - ${
                row.unionAffiliationNumber?.city
              }, ${
                row.unionAffiliationNumber?.countryId === COUNTRIES.USA
                  ? row.unionAffiliationNumber?.state.name
                  : row.unionAffiliationNumber?.stateName
              }`}
          </>
        );
      },
    },
    {
      id: "beginDate",
      label: "Begin Date",
      type: "date",
      sort: true,
      hide: catalog !== "TrainingCourses",
      format: "MM/DD/YYYY",
    },
    {
      id: "endDate",
      label: "End Date",
      type: "date",
      sort: true,
      hide: catalog !== "TrainingCourses",
      format: "MM/DD/YYYY",
    },
    {
      id: "laborUnionsId",
      label: "Labor Union",
      type: "custom",
      sort: true,
      hide:
        catalog !== "TrainingCourses" ||
        process.env.REACT_APP_ENVIRONMENT !== "UNION",
      callback: (row: ICatalogType) => {
        return <>{row.laborUnions?.name}</>;
      },
    },
    {
      id: "localUnionsId",
      label: "Local Union",
      type: "custom",
      sort: true,
      hide:
        catalog !== "TrainingCourses" ||
        process.env.REACT_APP_ENVIRONMENT !== "UNION",
      callback: (row: ICatalogType) => {
        return <>{row.localUnions?.name}</>;
      },
    },
    {
      id: "schoolsId",
      label: "School",
      type: "custom",
      sort: true,
      hide:
        catalog !== "TrainingCourses" ||
        process.env.REACT_APP_ENVIRONMENT !== "SCHOOL",
      callback: (row: ICatalogType) => {
        return <>{row.schools?.name}</>;
      },
    },
    {
      id: "certification",
      label: "Certification",
      type: "custom",
      sort: true,
      hide:
        catalog !== "TrainingCourses" ||
        process.env.REACT_APP_ENVIRONMENT !== "SCHOOL",
      callback: (row: ICatalogType) => {
        return (
          <>
            {row.certification ===
            CertificationType.EnergyAuditorInfectiousBuildingControl
              ? "Energy Auditor Infectious Building Control"
              : row.certification === CertificationType.TestingAndBalancing
              ? "Testing And Balancing"
              : "Energy Auditor Infectious Building Control / Testing And Balancing"}
          </>
        );
      },
    },
    {
      id: "statusId",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: ICatalogType) => {
        return (
          <>
            {row.statusId === 1 ? (
              <Chip label="Enabled" color="primary" m={1} size="small" />
            ) : (
              <Chip
                label="Disabled"
                color="primary"
                variant="outlined"
                m={1}
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      minWidth: catalog === "TrainingCourses" ? "180px" : "",
      callback: (row: ICatalogType) => {
        return (
          <div>
            <Box mr={2}>
              {catalog === "TrainingCourses" && (
                <GridActionButton
                  type={"users"}
                  onClick={() => {
                    navigate("/app/TrainingCourseAttendance/" + row.id);
                  }}
                  tooltip={"Users"}
                />
              )}
              <GridActionButton
                type={fullAccess ? "edit" : "view"}
                onClick={() => handleEdit(row)}
                tooltip={fullAccess ? "Edit" : "View"}
              />
              {fullAccess &&
                (catalog !== "TrainingCourses" || row.canDelete) && (
                  <GridActionButton
                    type="delete"
                    onClick={() => handleDeleteDocument(row)}
                    tooltip="Delete"
                  />
                )}
            </Box>
          </div>
        );
      },
    },
  ];

  const handleDeleteDocument = (row: ICatalogType) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setRefreshGrid(!refreshGrid);
  };

  return (
    <React.Fragment>
      <Helmet title={scores[safeCatalog]} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {scores[safeCatalog]}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>{scores[safeCatalog]}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={handleOpen} variant="contained" color="primary">
                <AddIcon />
                {`New ${
                  catalog === "TrainingCourses"
                    ? "Training Course"
                    : scores[safeCatalog]
                }`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid
        container
        spacing={10}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: 5,
        }}
      >
        <Grid item xs={6}>
          <Tabs value={selectdTab} onChange={handleChangeTab} aria-label="">
            <Tab label="Active" id="1" />
            <Tab label="Inactive" id="2" />
          </Tabs>
        </Grid>
      </Grid>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ICatalogType>
                refreshGrid={refreshGrid}
                columns={columns(catalog ?? "")}
                data={dataSet.filter((x) => x.statusId === selectdTab + 1)}
                query={filterValues.name}
                showSkeleton={showSkeleton}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Add New ${scores[safeCatalog]}`}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                disabled={readOnly}
              />
            </Grid>
            {catalog === "TrainingCourses" && (
              <Grid item xs={12}>
                <FormText
                  name="description"
                  label="Description"
                  value={values.description}
                  error={errors.description}
                  onChange={handleInputChange}
                  disabled={readOnly}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={6}>
                <FormDatePicker
                  label={"Begin Date"}
                  name="beginDate"
                  disablePast={false}
                  value={values.beginDate}
                  onChange={handleInputChange}
                  error={errors.beginDate}
                  fullWidth={true}
                  minDate={minDate.toDateString()}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={6}>
                <FormDatePicker
                  label={"End Date"}
                  name="endDate"
                  disablePast={false}
                  value={values.endDate}
                  onChange={handleInputChange}
                  error={errors.endDate}
                  fullWidth={true}
                  minDate={values?.beginDate?.toString()}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={6}>
                <FormTimePicker
                  label="Start Time"
                  value={values.startTime}
                  onChange={handleInputChange}
                  name={"startTime"}
                  error={errors.startTime}
                  mode={"read&Write"}
                  fullWidth={true}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={6}>
                <FormTimePicker
                  label="End Time"
                  value={values.endTime}
                  onChange={handleInputChange}
                  name={"endTime"}
                  error={errors.endTime}
                  mode={"read&Write"}
                  fullWidth={true}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={6}>
                <FormSelect
                  name="semester"
                  label="Semester"
                  value={values.semester}
                  onChange={handleInputChange}
                  options={SEMESTER}
                  error={errors.semester}
                  defaultValue={{ key: -1, value: "Select a semester" }}
                  disabled={readOnly}
                />
              </Grid>
            )}
            {catalog === "TrainingCourses" && (
              <Grid item xs={12}>
                <FormSelect
                  name="certification"
                  label="Certification"
                  value={values.certification}
                  onChange={handleInputChange}
                  options={CERTIFICATION}
                  error={errors.certification}
                  defaultValue={{ key: -1, value: "Select a certification" }}
                  disabled={readOnly}
                />
              </Grid>
            )}
            {process.env.REACT_APP_ENVIRONMENT === "SCHOOL" &&
              catalog === "TrainingCourses" && (
                <Grid item xs={12}>
                  <FormSelect
                    name="schoolsId"
                    label="Schools"
                    value={values.schoolsId}
                    onChange={handleInputChange}
                    options={schools}
                    error={errors.schoolsId}
                    defaultValue={{ key: -1, value: "Select one" }}
                    disable={readOnly}
                  />
                </Grid>
              )}
            {process.env.REACT_APP_ENVIRONMENT === "UNION" &&
              catalog === "TrainingCourses" && (
                <Grid item xs={6}>
                  <FormSelect
                    name="laborUnionsId"
                    label="Labor Union"
                    value={values.laborUnionsId}
                    onChange={handleInputChange}
                    options={laborUnions}
                    error={errors.laborUnionsId}
                    defaultValue={{ key: -1, value: "Select one" }}
                    disable={readOnly}
                  />
                </Grid>
              )}
            {process.env.REACT_APP_ENVIRONMENT === "UNION" &&
              catalog === "TrainingCourses" && (
                <Grid item xs={6}>
                  <FormSelect
                    name="localUnionsId"
                    label="Local Union"
                    value={values.localUnionsId}
                    onChange={handleInputChange}
                    options={localUnions}
                    error={errors.localUnionsId}
                    defaultValue={{ key: -1, value: "Select one" }}
                    disable={readOnly}
                  />
                </Grid>
              )}
            {process.env.REACT_APP_ENVIRONMENT === "UNION" &&
              catalog === "TrainingCourses" && (
                <>
                  {isOtherLaborUnion() && (
                    <Grid item xs={6}>
                      <FormText
                        name={"laborUnionsOther"}
                        label={"Labor Unions Other"}
                        value={values.laborUnionsOther}
                        onChange={handleInputChange}
                        disabled={readOnly}
                        error={errors.laborUnionsOther}
                      ></FormText>
                    </Grid>
                  )}
                </>
              )}
            {process.env.REACT_APP_ENVIRONMENT === "UNION" &&
              catalog === "TrainingCourses" && (
                <>
                  {isOtherLocalUnion() && (
                    <>
                      {!isOtherLaborUnion() && <Grid item xs={6}></Grid>}
                      <Grid item xs={6}>
                        <FormText
                          name={"localUnionsOther"}
                          label={"Local Unions Other"}
                          value={values.localUnionsOther}
                          onChange={handleInputChange}
                          disabled={readOnly}
                          error={errors.localUnionsOther}
                        ></FormText>
                      </Grid>
                    </>
                  )}
                </>
              )}
            {catalog === "LaborUnions" && (
              <Grid item xs={12}>
                <FormNumeric2
                  name="orderPosition"
                  label="Order Position"
                  value={values.orderPosition}
                  onChange={handleInputChange}
                  disabled={isSubmitting}
                  decimalScale={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormSelect
                name="statusId"
                label="Status"
                value={values.statusId}
                onChange={handleInputChange}
                options={STATUSES}
                error={errors.statusId}
                defaultValue={{ key: -1, value: "Select a value" }}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={3}>
            <FormCancelButton
              onClick={handleClose}
              isSubmitting={isSubmitting}
            />
            {fullAccess && (
              <FormSaveButton
                onClick={handleSave}
                isSubmitting={isSubmitting}
              />
            )}
          </Stack>
        </>
      </Popup>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={deleteDocument}
      ></MatchWordConfirmModal>

      <DeleteItemMessagePopUp
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
      />
    </React.Fragment>
  );
};

export default Catalog;
