import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Chip as MuiChip, Stack, Typography } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { ICompanyData } from "src/ts/interfaces";
import CompanyService from "src/services/companyService";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import { getQueryString } from "./util/common";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import useLoading from "src/hooks/useLoading";
import ChangeStatusPopup from "./components/ChangeStatusPopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useAuth, useLog, usePermissions } from "src/hooks";
import { ROLES } from "src/constants";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const columns = (
  navigate: NavigateFunction,
  setSelectedCompany: (val: ICompanyData) => void,
  setShowEnableCompanyPopup: (val: boolean) => void,
  fullAccess: boolean | undefined,
  fullAccessActiveInactive: boolean | undefined,
  userRole: string
): ColumnType[] => [
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.companyType === "Other" && (
            <Chip
              label={row.otherCompanyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    hide: userRole === ROLES.UnionAdministrator,
  },
  {
    id: "localAndLabor",
    label: "Local and Labor Unions",
    type: "custom",
    sort: false,
    hide: userRole !== ROLES.UnionAdministrator,
    minWidth: "170px",
    callback: (row: ICompanyData) => (
      <>
        <Typography variant="subtitle2">{row.laborUnion}</Typography>
        <Typography>{row.localUnionName}</Typography>
      </>
    ),
  },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "deactivatedBy",
    label: "Deactivated By",
    type: "string",
    sort: true,
  },
  {
    id: "deactivatedDate",
    label: "Deactivation Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "deactivationReason",
    label: "Deactivation Reason",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          {fullAccessActiveInactive && (
            <GridActionButton
              type="enable"
              disabled={row.deactivatedBy === "System"}
              tooltip="Activate"
              onClick={() => {
                setSelectedCompany(row);
                setShowEnableCompanyPopup(true);
              }}
            />
          )}

          <GridActionButton
            type="forward"
            tooltip="View"
            onClick={() => {
              navigate(`/app/company/view/${row.id}`);
            }}
          />
        </Stack>
      </div>
    ),
  },
];

const columnsAffiliated = (
  navigate: NavigateFunction,
  setSelectedCompany: (val: ICompanyData) => void,
  setShowEnableCompanyPopup: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => {
      return row.code;
    },
  },
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.companyType === "Other" && (
            <Chip
              label={row.otherCompanyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  { id: "localUnion", label: "Local Union #", type: "string", sort: true },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "deactivatedBy",
    label: "Deactivated By",
    type: "string",
    sort: true,
  },
  {
    id: "deactivatedDate",
    label: "Deactivation Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "deactivationReason",
    label: "Deactivation Reason",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type="enable"
            disabled={row.deactivatedBy === "System"}
            tooltip="Activate"
            onClick={() => {
              setSelectedCompany(row);
              setShowEnableCompanyPopup(true);
            }}
          />
          <GridActionButton
            type="forward"
            tooltip="View"
            onClick={() => {
              navigate(`/app/company/view/${row.id}`);
            }}
          />
        </Stack>
      </div>
    ),
  },
];

interface CompanyTableProps {
  filters: FilterType;
  refresh: boolean;
  parentCompanyId: number;
}

function InactiveCompanies(props: CompanyTableProps) {
  const { log } = useLog();
  const { parentCompanyId } = props;
  const { user } = useAuth();
  let navigate = useNavigate();
  const { startRequest, endRequest } = useLoading();
  const [selectedCompany, setSelectedCompany] = useState<ICompanyData>();

  const [showEnableCompanyPopup, setShowEnableCompanyPopup] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Companies);
  const { fullAccess: fullAccessActiveInactive } = usePermissions(
    PermissionTypes.Companies_Active_Inactive
  );

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(
      props.filters.isUnionAffiliated
        ? columnsAffiliated(
            navigate,
            setSelectedCompany,
            setShowEnableCompanyPopup
          )
        : columns(
            navigate,
            setSelectedCompany,
            setShowEnableCompanyPopup,
            fullAccess,
            fullAccessActiveInactive,
            user?.role
          )
    );
  const [refresh, setRefresh] = useState(props.refresh);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("InactiveCompanies");
        const request = await CompanyService.getAll(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            isActive: false,
            parentCompanyId: parentCompanyId,
            signupDone: true,
            countryId: props.filters.countryId as number,
            stateId: props.filters.stateId as number,
            stateName: props.filters.stateName,
            subscriptionId: props.filters.subscriptionId as number,
            filter: props.filters.filter,
            isUnionAffiliated:
              user?.role === "UnionAdministrator"
                ? true
                : props.filters.isUnionAffiliated,
            canceledUnion: false,
            pendingUnion: false,
            laborUnionId: props.filters.laborUnionId,
            localUnionId: props.filters.localUnionId,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        endRequest("InactiveCompanies");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("InactiveCompanies");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    refresh,
    props.refresh,
    parentCompanyId,
    props.filters.isUnionAffiliated,
  ]);

  const handleSave = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Table />
      <ChangeStatusPopup
        currentValues={selectedCompany}
        disablePopup={showEnableCompanyPopup}
        setDisablePopup={setShowEnableCompanyPopup}
        onSave={handleSave}
        onClick={() => null}
        status={false}
      />
    </>
  );
}

export default InactiveCompanies;
